/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 2-Nov-2023
* @author      : Shiyamkumar.G
* @since       : 0.0.1
* Requirement# :
* Purpose      : Task Selection page Form Create Bid
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE      |   AUTHOR      |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 1937           |  15/05/24   | Vanitha       |  Mandatory implementation in Property name
* 1953           |  16/05/24   | Vanitha       |  City,State,Country fields are binded
* 2222           |  06/06/24   | Vanitha       |   Add Customer redirect page implementation changed
* 
*/

import React, { useEffect, useRef, useState } from 'react';
import { Autocomplete, Card, FormControl, Grid, InputLabel, Link, MenuItem, Select, TextField, Typography, createFilterOptions, useTheme, Tooltip } from '@mui/material';
import { tokens } from '../../../hooks/theme';
import SelectionCard from './SelectionCard';
import { getAssets, getTradeDetails, getTradesHavingTemplate, getUsers, getValidTradeData } from '../../../services/BidService';
import { useNavigate } from 'react-router-dom';
import "../CreateBid.scss";
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import "../selectorComponent/TaskSelector.scss";
import { getUserSession, useHandleUnauthorized } from '../../../hooks/UserSession';
import CreateCustomer from '../../../pages/Admin templates/CreateCustomer';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { getCustomerDetails, getCustomers } from '../../../services/UserService';
import { getConfiguredDataByKey, getPreconstructionPeriod, getTradeByTradeOid } from '../../../services/TradeConfigurationService';
import { weekdays } from '../../../utils/Dateutils';

interface UserOptionType {
    inputValue?: string;
    userName: string;
    mobileNo?: number;
    status: string;
    displayName: string;
}

const filter = createFilterOptions<any>();

const TaskSelector: React.FC<any> = (data) => {

    const [bidData, setBidData] = useState(data.data);
    // console.log(data.data);
    const componentData = useRef<any>();
    const [customerList, setCustomerList] = useState<any>();
    const { accessToken, accessTokenPayload } = useOidcAccessToken();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [count, setCount] = useState(0);
    const [role, setRole] = React.useState<any>([] as any[]);
    const [userdata, setUserData] = React.useState<any>([] as any[]);
    const [completed, setCompleted] = React.useState<any | null>(data.completed);
    const [loading, setLoading] = useState(true);
    const [preconstructionPeriod, setPerConstructinPeriod] = React.useState<any>();

    const { handleUnauthorized } = useHandleUnauthorized();
    const [properties, setProperties] = useState<any>([]);
    const [selectedData, setSelectedData] = useState<any>({
        customerUid: "",
        assetUid: "",
        trade: "",
        startDate: ""
    });
    const selectedAsset = useRef();
    const [selctionCardProps, setSelectionCardprops] = useState({
        title: "Select a Trade to Create an Estimate",
        type: "trade",
        data: [],
        selectedValue: bidData.tradeOid,
        suggestion: false,
        returnValue: "name",
        displayValue: "name"
    });

    const [value, setValue] = React.useState<any | null>(data.selectedCustomer);
    const [user, setUser] = React.useState<any>([] as any[])
    const [showAnotherComponent, setShowAnotherComponent] = useState(false);
    const [rows, setRows] = React.useState<any>([]);
    const [rowData, setRowData] = useState();
    const [workingDays, setWorkingDays] = React.useState<any>([]);

    const closeConfirmation = () => {
        setShowAnotherComponent(false);
    }

    const navigate = useNavigate()
    const addCustomer = () => {
        setShowAnotherComponent(true);
    }
    const onAddAssetPage = () => {
        navigate("/asset", {
            state: {
                action: "addProperty",
                customerUid: selectedData.customerUid
            }
        });
    }

    const updateDate = (date: any) => {
        console.log(date)
        setSelectedData({ ...selectedData, startDate: dayjs(date.$d).format('YYYY-MM-DD') + "T00:00Z" });
        Object.assign(componentData.current, { startDate: dayjs(date.$d).format('YYYY-MM-DD') + "T00:00Z" });
        validator();
    }

    const userList: readonly UserOptionType[] = user


    const workSchedule = async () => {
        const response = await getConfiguredDataByKey("workingDays", accessToken);
        if (response.data !== undefined && (response.status === 200 || response.status === 201)) {
            let tenantWokringDays = response.data.split(",");
            weekdays.map((day: any) => {
                if (tenantWokringDays.includes(day)) {
                    workingDays.push(weekdays.indexOf(day));
                }
            })
            //setWorkingDays(response.data.split(",").map((num:any) => parseInt(num)));
        }
    }

    useEffect(() => {

        getUserSession(accessToken)
            .then(res => {
                setUserData(res);
                setRole(res.role.name);
                setLoading(false)

                Object.assign(componentData.current, { customerUid: res.objectId });
                getAssets(accessToken, res.objectId)
                    .then(assetsResponse => {
                        setProperties(assetsResponse.data);
                    })
                    .catch(error => console.error("Error fetching assets data:", error));
            })
            .catch(error => console.error("Error fetching user session data:", error));

        // getUsers(accessToken)
        //     .then(res => setUser(res.data))
        //     .catch(error => console.error("Error fetching user data:", error));

        getcustomerList();

        getValidTradeData(accessToken)
            .then(res => {
                handleUnauthorized(res);
                setSelectionCardprops(prevState => ({
                    ...prevState,
                    data: res.data, // Assuming res.data is an array
                }));
                const customerUid = res.data.customerUid || userdata.objectId;
            })
            .catch(error => console.error("Error fetching trades data:", error));
        setSelectedData({
            customerUid: bidData.customerUid || userdata.objectId,
            assetUid: bidData.assetUid,
            trade: bidData.trade,
            startDate: "jobStartDate" in data.data ? bidData.jobStartDate : bidData.startDate,
            // startDate:data?.data?.jobStartDate ?data?.data?.jobStartDate :bidData.startDate
        });
        componentData.current = {
            customerUid: bidData.customerUid || userdata.objectId,
            assetUid: bidData.assetUid,
            trade: bidData.trade,
            startDate: "jobStartDate" in data.data ? bidData.jobStartDate : bidData.startDate
            // startDate:data?.data?.jobStartDate ?data?.data?.jobStartDate :bidData.startDate

        };

        if (data.count !== 0) {
            setCompleted(false);
        }
        getUsers(accessToken)
            .then((res: any) => setCustomerList(res.data))
            .catch(error => console.error("Error fetching customer list:", error));

        getPreConstructionPeriod();
        workSchedule();
    }, []);

    const validator = () => {
        const exist = Object.values(componentData.current).includes(undefined);

        if (exist) {
            data.function({ data: componentData.current, validated: false });
        }
        else {
            data.function({ data: componentData.current, validated: true, customerName: value, SelectedAsset: selectedAsset.current });
        }
        Object.entries(componentData.current).map((value) => {
            if (value[1] === undefined) {
                setCompleted(true);
                data.function({ data: componentData.current, validated: false });
            }
        })
    }

    const onselect = async (e: any) => {

        setSelectedData({ ...selectedData, [e.target.name]: e.target.value });
        Object.assign(componentData.current, { [e.target.name]: e.target.value });
        validator()
    };

    const selectedTrade = async (e: any) => {
        // Update selectionCardProps
        setSelectionCardprops((prevData) => ({
            ...prevData,
            selectedValue: e.target.title,
        }));

        try {
            const res = await getTradeByTradeOid(e.target.title, accessToken);


            if (res.data.name) {
                const updatedData = {
                    ...selectedData,
                    [e.target.name]: res.data.name,
                    trade: res.data.name,
                };

                // Update selectedData immutably
                setSelectedData(updatedData);

                // Update componentData.current immutably
                componentData.current = {
                    ...componentData.current,
                    [e.target.name]: res.data.name,
                    tradeOid: e.target.title,
                };

                console.log("Updated selectedData:", updatedData);
                console.log("Updated componentData:", componentData.current);
            }
        } catch (error) {
            console.error("Error fetching trade data:", error);
        }

        validator();
    };


    const getcustomerList = async () => {
        const result = await getCustomers(accessToken);
        handleUnauthorized(result);
        setUser(result.data);
        console.log("user", result.data);
    }

    const getPreConstructionPeriod = async () => {
        const result = await getPreconstructionPeriod("preconstructionPeriod", accessToken);
        handleUnauthorized(result);
        setPerConstructinPeriod(result?.data);
    }

    const isDateDisabled = (date: Dayjs) => {
        // Ensure `date` is a valid Dayjs date
        if (!date || !date.isValid()) return true;
        // console.log(date, "workingDays", workingDays);
        const today = dayjs();
        let period = preconstructionPeriod;
        const blockedDates: string[] = [];
        //console.log(blockedDates)
        // Generate blocked dates based on the preconstruction period and working days
        if (workingDays.length > 0) {
            for (let i = 0; i <= period; i++) {
                const currentDate = today.add(i, 'day');
                if (workingDays.includes(currentDate.day())) {
                    blockedDates.push(currentDate.format("YYYY-MM-DD"));
                } else {
                    period++; // Extend period only if the current date is not a working day
                }
            }
        }

        // Check if the date is blocked or falls on an invalid day
        if (blockedDates.includes(date.format("YYYY-MM-DD")) || !workingDays.includes(date.day())) {
            return true;
        }
        // Disable weekends (Saturday and Sunday)
        // return date.day() === 6 || date.day() === 0;
        return false;
    };


    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Card sx={{ height: "100%" }} variant="outlined" className='createBidFormCard'>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography variant='h5' color="rgb(60 60 60 / 87%)">Would you like to create a job for one of your customers?</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={9} md={9} lg={9} xl={9}>
                                                {role !== 'customer' ?
                                                    (<Autocomplete
                                                        ListboxProps={{ style: { maxHeight: 150 } }}
                                                        size='small'
                                                        value={value}
                                                        onChange={(event, newValue: any) => {
                                                            if (newValue && newValue.inputValue) {
                                                                setSelectedData({ ...selectedData, customerUid: newValue.objectId });
                                                                Object.assign(componentData.current, { customerUid: newValue.objectId });
                                                                addCustomer();
                                                                setValue(newValue.displayName);
                                                            } else if (newValue === null) {
                                                                setValue(newValue);
                                                            } else {
                                                                setValue(newValue.displayName);
                                                                setSelectedData({ ...selectedData, customerUid: newValue.objectId });
                                                                getAssets(accessToken, newValue.objectId || userdata.objectId)
                                                                    .then(res => {
                                                                        setProperties(res.data);
                                                                    });

                                                                Object.assign(componentData.current, { customerUid: newValue.objectId });
                                                                validator();
                                                            }
                                                        }}
                                                        filterOptions={(options, params) => {
                                                            const filtered = filter(options, params);
                                                            const { inputValue } = params;
                                                            const isExisting = options.some((option) => inputValue === option.displayName);
                                                            if (inputValue !== '' && !isExisting) {
                                                                filtered.push({
                                                                    inputValue,
                                                                    displayName: `Add Customer`,
                                                                });

                                                            }

                                                            return filtered;
                                                        }}
                                                        selectOnFocus
                                                        // clearOnBlur
                                                        handleHomeEndKeys
                                                        id="free-solo-with-text-demo"
                                                        options={role !== 'customer' ? (userList.length > 0 ? userList : []) : [userdata.objectId
                                                        ]}
                                                        getOptionLabel={(option) => {
                                                            if (typeof option === 'string') {
                                                                return option;
                                                            }
                                                            if (option.inputValue) {
                                                                return option.inputValue;
                                                            }
                                                            return option.displayName;
                                                        }}
                                                        renderOption={(props, option) => <li {...props}>{option.displayName}</li>}

                                                        freeSolo
                                                        renderInput={(params) => (
                                                            <TextField {...params} label="Customer Name" error={selectedData.customerUid === undefined && completed === true} />
                                                        )} />) : (
                                                        <TextField
                                                            id="standard-read-only-input"
                                                            fullWidth
                                                            size='small'
                                                            disabled={true}
                                                            label="Customer Name"
                                                            value={userdata.firstname + " " + userdata.lastname}
                                                            defaultValue={userdata.firstname + " " + userdata.lastname}
                                                            variant="outlined" />
                                                    )}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography variant='h5' color="rgb(60 60 60 / 87%)">When do you want to start the work?</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={9} md={9} lg={9} xl={9}>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <FormControl fullWidth size="small" error={selectedData.startdate === null && completed}>
                                                        <DatePicker
                                                            format='DD MMM YYYY'
                                                            label="Choose Date"
                                                            disablePast
                                                            // value={}
                                                            slotProps={{ textField: { fullWidth: true, size: "small" } }}
                                                            disableHighlightToday
                                                            shouldDisableDate={isDateDisabled}//disable properties     
                                                            value={selectedData.startDate !== null ? dayjs(selectedData.startDate) : null}
                                                            // value={(selectedData.startdate !== undefined) ? data.data.startDate !== undefined ? dayjs(data.data.startDate) : dayjs(data.data.jobStartdate) : null}
                                                            // minDate={dayjs().add(1, 'day').add(preconstructionPeriod, 'week')}
                                                            onChange={(e) => updateDate(e)}
                                                        />
                                                    </FormControl>
                                                </LocalizationProvider>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography variant='h5' color="rgb(60 60 60 / 87%)">Select the property that needs work.</Typography>
                                            </Grid>
                                            <Grid item xs={12} sm={9} md={9} lg={9} xl={9}>
                                                <FormControl variant="outlined" fullWidth size="small">
                                                    <InputLabel id="demo-select-small" required error={!selectedData.assetUid === undefined && completed === true}>Property Name</InputLabel>
                                                    <Tooltip
                                                        title={role !== "customer" ?
                                                            (!value ? "Choose any customer name first" : "Add Property name") :
                                                            (!userdata ? "Choose any customer name first" : "Add Property name")}
                                                        arrow
                                                    >
                                                        <Select
                                                            error={selectedData.assetUid === undefined && completed}
                                                            required
                                                            labelId="demo-select-small-label"
                                                            id="demo-select-small"
                                                            value={selectedData.assetUid}
                                                            label="Property Name"
                                                            onChange={(e) => onselect(e)}
                                                            name='assetUid'
                                                            disabled={role !== "customer" ? !value : !userdata}
                                                        >
                                                            {properties && properties?.length > 0 ?
                                                                properties.map((data: any) => (
                                                                    <MenuItem key={data.assetUid} value={data.assetUid}>{data.name}</MenuItem>
                                                                )) :
                                                                <MenuItem disabled>{properties}</MenuItem>}
                                                        </Select>
                                                    </Tooltip>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Typography color="grey">Property Not Found?</Typography>
                                                    <Link
                                                        component='button'
                                                        underline="none"
                                                        key="1"
                                                        color="inherit"
                                                        id=''
                                                        onClick={() => onAddAssetPage()}
                                                        sx={{
                                                            "&:hover": {
                                                                color: colors.primary[500] + ' !important',
                                                                background: 'none !important',
                                                            },
                                                        }}
                                                        className='formSubTitle'
                                                        disabled={role !== "customer" ? !value : !userdata}
                                                    >
                                                        <Tooltip
                                                            title={role !== "customer" ?
                                                                (!value ? "Choose any customer name first" : "Add your Property") :
                                                                (!userdata ? "Choose any customer name first" : "Add your Property")}
                                                            arrow
                                                        >
                                                            <Link>
                                                                Add New Property
                                                            </Link>
                                                        </Tooltip>
                                                    </Link>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <SelectionCard data={selctionCardProps} count={count} function={selectedTrade} update={data.update} />
                </Grid>
            </Grid>
            {showAnotherComponent && (<CreateCustomer open={showAnotherComponent} onClose={() => closeConfirmation()} setRows={setRows} rowDatas={rowData} />)}
        </>

    );
}

export default TaskSelector;