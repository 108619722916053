/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 2-Nov-2023
* @author      : Priyankaa.S
* @since       : 0.0.1
* Requirement# :
* Purpose      : Listing of Invoice
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem|   DATE     |    AUTHOR    |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
*       1512    |  25/3/24   |   Nandha     | During create invoice first successful error message displayed after successfuly created message displayed
*      2399     | 06-08-2024 | shiyam kumar | made changes to fix pagination issue
*      3429     | 30-01-2025 | Sharmine     | Created Export Invoice button for exporting csv 
 
*
*
*/
import * as React from 'react';
import { styled } from '@mui/material/styles';
import {
  Box, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination,
  TableRow, TableSortLabel, Paper, Checkbox, IconButton, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, TextField,
  Button, Grid, MenuItem, Select, Typography, SelectChangeEvent, Snackbar, Alert
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import './InvoiceList.scss';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getInvoiceByProjectId, getPaymentreceipt, generateInvoice, getInvoiceCountByProjectId } from '../../services/InvoiceServices';
import { Stack, useTheme } from '@mui/system';
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import InvoiceModelBox from './InvoiceModelBox';
import Link from '@mui/material/Link';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import FileUpload from '../fileUploader/FileUploader';
import BlobData from './BlobData';
import { CheckInvoiceAvailability } from '../../services/ProjectService';
import dayjs from 'dayjs';
import { isUserHasPermission, useHandleUnauthorized } from '../../hooks/UserSession';
import CustomDialog from '../../pages/Dialog';
import EditIcon from '@mui/icons-material/Edit';
import { exportInvoices } from '../../services/ExportService';
import { jsonToCSV } from 'react-papaparse';

interface Data {
  id: number;
  dueDate: any,
  invoiceDate: any,
  invoiceNo: number;
  projectCost: number
  customerName: string;
  amount: number;
  status: string;
  payment: any;

}

type Order = 'asc' | 'desc';

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}
const headCells: readonly HeadCell[] = [
  {
    id: 'invoiceNo',
    numeric: true,
    disablePadding: false,
    label: 'Invoice No',
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Status',
  },
  {
    id: 'invoiceDate',
    numeric: false,
    disablePadding: true,
    label: 'Issue Date',
  },
  {
    id: 'customerName',
    numeric: true,
    disablePadding: false,
    label: 'Customer Name',
  },
  {
    id: 'projectCost',
    numeric: true,
    disablePadding: false,
    label: 'Total Project Cost',
  },
  {
    id: 'amount',
    numeric: true,
    disablePadding: false,
    label: 'Invoice Amount',
  },
  {
    id: 'dueDate',
    numeric: false,
    disablePadding: true,
    label: 'Due Date',
  },
];


interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <>
      <TableHead >
        <TableRow className='tablerows tableHead'>
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              // align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel

                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
          <TableCell>Action</TableCell>
        </TableRow>
      </TableHead>
    </>
  );
}
const InvoiceList: React.FC<any> = (props: any) => {
  const { accessToken } = useOidcAccessToken();
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('customerName');
  const [selected, setSelected] = React.useState<readonly number[]>([]);
  const [file, setFile] = useState<any>();
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const navigate = useNavigate();
  const theme = useTheme();
  const [openDialogs, setOpenDialogs] = React.useState(false);
  const [createSuccess, setCreateSuccess] = React.useState(false);
  const [createError, setCreateError] = React.useState(false);
  const [count, setCount] = React.useState(0);
  const [role, setRole] = React.useState(props.role);
  const [projectView, setProjectView] = React.useState(props.projectView);
  const location = useLocation();
  const { state } = location;
  const { handleUnauthorized } = useHandleUnauthorized();
  const [successExport, setSuccessExport] = React.useState(false);
  const [emptyData, setEmptyData] = useState(false);

  const handleClose = () => {
    setOpenDialogs(false);
    setIsBlobData(false)
    setSuccessExport(false);
  }

  const onclose = () => {
    setOpenDialogs(false);
    setCreateError(false);
    setCreateSuccess(false)
  }

  const [open, setOpen] = React.useState(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [status, setStatus] = React.useState('All');
  const [openToast, setOpenToast] = React.useState(false);
  const [toastMessage, setToastMessage] = React.useState({
    message: '',
    security: ''
  });
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isBlobData, setIsBlobData] = useState(false);
  const [isInvoiceButton, setIsInvoiceButton] = useState();
  const openDialog = (row: any) => {
    setSelectedRow(row);
    setIsDialogOpen(true)
  }
  const closeDialog = () => {
    setIsDialogOpen(false);
  };
  let receipt: any;
  const onFileUpload = async (file: File) => {
    const reader = new FileReader();
    // Define a callback function to handle the file reading completion
    reader.onload = (event) => {
      if (event.target?.result) {
        // Access the result property of the FileReader object to obtain the file data
        const fileData = event.target.result;
        // You can now use fileData as an object or process it further
        receipt = fileData;
        setFile(receipt);
      }
    };

    // Start reading the file as text
    reader.readAsText(file);


  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows?.invoiceDetails.map((n: any) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };


  const [rows, setRows] = React.useState<any>([]);
  const [invoiceData, setInvoiceData] = React.useState<any>([]);

  const changeStatus = (data: any) => {
    for (const i of data?.invoiceDetails) {
      if (i.status === "Paid Approved" || i.status === "Paid") {
        i['data'] = i.status
        i.status = 'Paid';
      }
      else
        if (i.status === "Un Paid") {
          if ((new Date(i.dueDate) <= new Date()) === true) {
            i['data'] = i.status
            i.status = "Over Due";
          }
          else {
            i['data'] = i.status
            i.status = "Due";
          }
        }
    }
    setRows(data);
  }

  const fetchRows = async () => {
    if (props.role === "customer") {
      const res = await getInvoiceCountByProjectId(props.project, status, true, accessToken);
      handleUnauthorized(res);
      setCount(res.data);
      const data = await getInvoiceByProjectId(props.project, status, true, accessToken);
      handleUnauthorized(data);
      let invoiceData = data.data;
      changeStatus(invoiceData);
    }
    else {
      const res = await getInvoiceCountByProjectId(props.project, status, false, accessToken);
      handleUnauthorized(res);
      setCount(res.data);
      const data = await getInvoiceByProjectId(props.project, status, false, accessToken);
      handleUnauthorized(data);
      let invoiceData = data.data;
      changeStatus(invoiceData);
    }
  };


  const handleChange = async (event: SelectChangeEvent) => {
    if (props.role === "customer") {
      const res = await getInvoiceCountByProjectId(props.project, event.target.value, true, accessToken);
      setCount(res.data);
      const data = await getInvoiceByProjectId(props.project, event.target.value, true, accessToken);
      let invoiceData = data.data;
      setStatus(event.target.value);
      if (event.target.value === "All") {
        changeStatus(invoiceData);
      }
      else {
        setRows(invoiceData);
      }
    }
    else {
      const res = await getInvoiceCountByProjectId(props.project, event.target.value, false, accessToken);
      setCount(res.data);
      const data = await getInvoiceByProjectId(props.project, event.target.value, false, accessToken);
      let invoiceData = data.data;
      setStatus(event.target.value);
      if (event.target.value === "All") {
        changeStatus(invoiceData);
      }
      else {
        setRows(invoiceData);
      }
    }

  };

  const InvoiceButtonVisibility = async () => {
    let result = await CheckInvoiceAvailability(props.project, accessToken);
    setIsInvoiceButton(result.data);
  }
  useEffect(() => {

    InvoiceButtonVisibility();
    // hide or show invoice button api call
    const fetData = async () => {

    }


    const fetchInvoice = async () => {
      try {
        const data = await getInvoiceByProjectId(props.project, 'All', true, accessToken);
        handleUnauthorized(data);

        setInvoiceData(data.data);
      } catch (error) {
        console.error('Error fetching invoice data:', error);
      }
    };
    fetData();
    fetchRows();
    fetchInvoice();

  }, []);

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };
  const [imageData, setImageData] = useState<any | null>(null);

  const downloadImage = async (invoiceId: any) => {
    try {
      // Fetch the image data as a Blob

      // const data = await getInvoiceByProjectId(props.project, 'All', true, accessToken);

      // setInvoiceData(data.data);
      const response = await getPaymentreceipt(invoiceId, accessToken);
      handleUnauthorized(response);
      setIsBlobData(true);
      setImageData(response)

      //  const blob = new Blob([response.data], { type: 'application/octet-stream' }); // Assuming the response is a PNG image, adjust the MIME type accordingly
      // // const imageBlob = await response.data.blob();
      // const reader = new FileReader();
      // reader.readAsDataURL(blob);
      // reader.onloadend = () => {
      //   const base64data = reader.result as string;
      // setImageData(base64data)
      // // Create a temporary anchor element
      // const a = document.createElement('a');
      // a.href = base64data;
      // a.download = 'image.png'; // Set the filename here

      // // Programmatically click the anchor element to initiate the download
      // a.click();
      // setImgUrl(base64data);
      // };
      // Create a download link
      // const url = URL.createObjectURL(blob);
      // const a = document.createElement('a');
      // a.href = url;
      // a.download = 'image.png'; // Set the filename here
      // document.body.appendChild(a);
      // a.click();
      // document.body.removeChild(a);

      // // Clean up
      // URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading image:', error);
    }
  };
  const invoiceFormView = (rowData: any) => {
    navigate("/soldproduction/projectinformation/invoiceview", { state: { rowData, role, projectView } });
  };

  const invoicePreviewView = (rowData: any) => {
    navigate("/soldproduction/projectinformation/invoicePreview", { state: { rowData, role, projectView } });
  };
  const handleToastClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenToast(false);
  };

  const createInvoice = async () => {

    if (props.project != null) {
      // let result = await generateInvoice(projectId, paymentTerm, selectInvoice, accessToken);
      let result = await generateInvoice(props.project, accessToken);
      setOpenDialogs(true);
      if (result.status === 200 || result.status === 201) {
        // setOpenToast(true);
        // setToastMessage({ ...toastMessage, message: "Invoice Created", security: "success" });


        // setOpenDialogs(false);
        await fetchRows();
        await InvoiceButtonVisibility();
        setCreateSuccess(true);


      }
      else {
        // setOpenToast(true);
        // setToastMessage({ ...toastMessage, message: "Error", security: "error" });
        setCreateError(true);

      }
    }

  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };
  const CustomCheckbox = styled(Checkbox)({
    '& .MuiSvgIcon-root': {
      width: '1em', // Customize the width of the checkbox
      height: '1em',
      fontWeight: '300',
      color: '#ccc'// Customize the height of the checkbox
    },
    '&.Mui-checked .MuiSvgIcon-root': {
      fill: theme.palette.primary.main, // Customize the fill color when checked
    },
  });
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  const handleDownload = (data: any) => {

    const blob = new Blob([jsonToCSV(data)], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'invoices.csv';
    link.click();
  };

  const exportInvoice = async () => {

    const response = await exportInvoices(selected, accessToken);
    if (response.status === 200) {
      if (response?.data?.length !== 0 && response?.data !== null) {
        handleDownload(response.data)
        setOpenDialogs(true);
        setSuccessExport(true);
      } else {
        setEmptyData(true);
        setOpenDialogs(true);
        setSuccessExport(true);
      }
    } else {
      setOpenDialogs(true);
      setSuccessExport(false);
    }
  }

  const SearchBar = () => (
    <div className='searchBar'>
      <Grid container rowSpacing={1} mt={0} columnSpacing={{ xs: 0, sm: 2, md: 3 }}>

        <Grid item xs={2}>
          <label><small>Status</small></label>
          <Select
            fullWidth
            value={status}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            size='small'
            onChange={handleChange}
          >
            <MenuItem value="All">
              All
            </MenuItem>
            <MenuItem value="Paid">Paid</MenuItem>
            {/* <MenuItem value="Un Paid">Un Paid</MenuItem> */}
            <MenuItem value="Due">Due</MenuItem>
            <MenuItem value="Over Due">Over Due</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={6}>
        </Grid>
        {/* <Grid item xs={4}>
          <Grid container rowSpacing={0} mt={0} columnSpacing={{ xs: 0, sm: 2, md: 3 }}>
            <Grid item xs={12} mt={2} className='alignEnd' pr={2}>
              <Box>
                {isUserHasPermission("CreateInvoice") &&
                  <><Button
                    variant="contained" size="large"
                    onClick={() => createInvoice()}
                    disabled={isInvoiceButton}
                  >
                    &nbsp; Create invoice
                  </Button>
                    <CustomDialog
                      open={openDialogs}
                      onClose={() => onclose()}
                      success={createSuccess}
                      error={createError}
                      Content={createSuccess ? "Created Successfully" : " Error Couldnot Created "} /></>}
              </Box>
            </Grid>
          </Grid>
        </Grid> */}
      </Grid>
    </div>
  );
  return (
    <>
      {/* <div>
      <button onClick={handleImageLoad}>Load Image</button>
      <img src={dataUrl} />
    </div> */}
      <Snackbar open={openToast} autoHideDuration={2000} onClose={handleToastClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} sx={{ paddingTop: 6 }}>
        <Alert onClose={handleToastClose} security={toastMessage.security} sx={{ width: '100%' }}>
          {toastMessage.message}
        </Alert>
      </Snackbar>
      <Grid container rowSpacing={1} mt={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {/* <Grid item xs={1}></Grid> */}
        <Grid item xs={12} md={12}>

          <Grid container rowSpacing={1} mt={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12} md={12}>
              <div>
                <Grid item xs={12} className="alignEnd" sx={{ paddingBottom: '5px,10px' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={exportInvoice}
                    disabled={selected.length === 0}
                  >
                    Export Invoices
                  </Button>
                </Grid>

                {/* Dialog Box */}
                <CustomDialog
                  open={openDialogs}
                  onClose={handleClose}
                  success={successExport}
                  error={!successExport}
                  Content={successExport ? emptyData ? "There is no Data to Export" : "Exported Successfully" : "Error Couldn't Export"} />

              </div>
            </Grid>
          </Grid>


          <Box sx={{ width: '100%', }}>
            <Paper className='cardBoxShadow' sx={{ width: '100%', mb: 2 }}>
              <SearchBar />
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                <Grid item md={12}>
                  <Box sx={{ width: '100%' }}>
                    <Paper className='POCard' sx={{ width: '100%', mb: 2 }} />
                  </Box>
                </Grid>
              </Grid>
              <TableContainer >
                <Table
                  sx={{ minWidth: 750, border: 'none', }}
                  aria-labelledby="tableTitle"
                  size={dense ? 'small' : 'medium'}
                >
                  <EnhancedTableHead
                    numSelected={selected?.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={count}
                  />
                  {rows?.invoiceDetails?.length === 0 ?

                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={10}>
                          <Typography align="center">No Invoices</Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                    :
                    <TableBody>
                      {rows?.invoiceDetails && rows?.invoiceDetails.map((row: any, index: any) => {
                        const isItemSelected = isSelected(row.id);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow

                            hover
                            onClick={(event) => handleClick(event, row.id)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.id}
                            selected={isItemSelected}
                            sx={{ cursor: 'pointer' }}
                          >
                            <TableCell padding="checkbox">
                              <CustomCheckbox
                                className='check'
                                color="primary"
                                checked={isItemSelected}
                                inputProps={{
                                  'aria-labelledby': labelId,
                                }}
                              />
                            </TableCell>
                            <TableCell>

                              <Link underline="none" onClick={() => invoiceFormView(row)}>
                                {row?.invoiceNo}
                              </Link>
                            </TableCell>
                            <TableCell >
                              <div className='iconTextCenter'>
                                <ErrorOutlineIcon className='errorOutlineIcon' style={{ marginRight: '5px' }} />
                                {rows.status !== null ? rows.status : row.status}
                                {/* {row?.status} */}
                                {/* {rows.status} */}
                              </div>
                            </TableCell>
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                            >
                              {dayjs(row?.createdAt).format("DD MMM YYYY")}
                            </TableCell>
                            <TableCell>{row?.customerName}</TableCell>
                            <TableCell >${row?.projectCost}</TableCell>
                            <TableCell >${row?.invoiceAmount}</TableCell>
                            <TableCell

                              component="th"
                              id={labelId}
                              scope="row"
                              padding="none"
                            >
                              {dayjs(row?.dueDate).format("DD MMM YYYY")}
                            </TableCell>
                            <TableCell >
                              <Stack direction={'row'}>
                                {row.data === "Paid" && <Button onClick={() => openDialog(row)}>Upload Receipt</Button>}
                                {row.data === "Paid Approved" && <Button onClick={() => downloadImage(row.id)}>View Receipt</Button>}
                                <IconButton>
                                  <RemoveRedEyeOutlinedIcon onClick={() => invoicePreviewView(row)} />
                                </IconButton>
                                {row?.status === "Generated" ?
                                  <IconButton>
                                    <EditIcon onClick={() => invoiceFormView(row)} />
                                  </IconButton>
                                  :
                                  <></>
                                }
                              </Stack>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  }
                </Table>
              </TableContainer>
              <TablePagination
                sx={{ border: 'none', }}
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Box>
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
      {open ? <InvoiceModelBox open={open} projectId={props.project}
        onClose={() => setOpen(false)} getList={fetchRows} />
        : null}
      {isDialogOpen && <FileUpload open={isDialogOpen} props={selectedRow} onClose={closeDialog} onFileUpload={onFileUpload} />}
      {isBlobData && <BlobData open={isBlobData} props={imageData} rows={invoiceData} onClose={handleClose} />}
    </>

  );
}
export default InvoiceList;