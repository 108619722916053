import { PropsWithChildren } from 'react';
import { CssBaseline } from '@mui/material';

const pageStyles: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'right',
  padding: '10px',
  minHeight: '100vh', // Make sure the container stretches to the full height of the viewport  
};

const containerStyles: React.CSSProperties = {
  marginTop: '8rem',
  padding: '2rem',
  borderRadius: '8px',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Optional: Add a shadow
  backgroundColor: 'white', // Container background color
};

const Authenticating: PropsWithChildren<any> = () => (
  <div style={pageStyles}>
    <CssBaseline />
    <div className="oidc-authenticating" style={{ overflow: 'hidden' }}>
      <div className="oidc-authenticating__container">
        {/* <h1 className="oidc-authenticating__title">Authentication in progress  </h1>
        <p className="oidc-authenticating__content">You will be redirected to the login page.</p> */}
      </div>
    </div>
  </div>
  // <div className="oidc-authenticating" style={{ overflow: 'hidden' }}>
  //   <div className="oidc-authenticating__container">
  //     <h1 className="oidc-authenticating__title">Authentication in progress for </h1>
  //     <p className="oidc-authenticating__content">You will be redirected to the login page.</p>
  //   </div>
  // </div>
);

export default Authenticating;