import { BasicDefinitions } from "../interface/SdmInterfaces";

 export const basicDefinitions: BasicDefinitions = {
    MeasurementUnit: [
      {
        Name: "MU.1",
        OID: "MU.1",
        Symbol: {
          TranslatedText: {
            lang: "en",
            text: "%"
          }
        }
      },
      {
        Name: "MU.2",
        OID: "MU.2",
        Symbol: {
          TranslatedText: {
            lang: "en",
            text: "kg"
          }
        }
      },
      {
        Name: "MU.3",
        OID: "MU.3",
        Symbol: {
          TranslatedText: {
            lang: "en",
            text: "mL"
          }
        }
      },
      {
        Name: "MU.5",
        OID: "MU.5",
        Symbol: {
          TranslatedText: {
            lang: "en",
            text: "cm<sup>3</sup>"
          }
        }
      },
      {
        Name: "MU.6",
        OID: "MU.6",
        Symbol: {
          TranslatedText: {
            lang: "en",
            text: "s"
          }
        }
      },
      {
        Name: "MU.7",
        OID: "MU.7",
        Symbol: {
          TranslatedText: {
            lang: "en",
            text: "(24HR)"
          }
        }
      },
      {
        Name: "MU.8",
        OID: "MU.8",
        Symbol: {
          TranslatedText: {
            lang: "en",
            text: "(m)"
          }
        }
      },
      {
        Name: "MU.10",
        OID: "MU.10",
        Symbol: {
          TranslatedText: {
            lang: "en",
            text: "(mGy)"
          }
        }
      },
      {
        Name: "MU.12",
        OID: "MU.12",
        Symbol: {
          TranslatedText: {
            lang: "en",
            text: "(°C)"
          }
        }
      },
      {
        Name: "MU.4",
        OID: "MU.4",
        Symbol: {
          TranslatedText: {
            lang: "en",
            text: "Equipment"
          }
        }
      },
      {
        Name: "MU.13",
        OID: "MU.13",
        Symbol: {
          TranslatedText: {
            lang: "en",
            text: "KG 0 LB"
          }
        }
      },
      {
        Name: "MU.15",
        OID: "MU.15",
        Symbol: {
          TranslatedText:
          {
            lang: "en",
            text: "kg"
          },

        }
      },
      {
        Name: "MU.16",
        OID: "MU.16",
        Symbol: {
          TranslatedText: {
            lang: "en",
            text: "% of surface"
          }
        }
      },
      {
        Name: "MU.19",
        OID: "MU.19",
        Symbol: {
          TranslatedText: {
            lang: "en",
            text: "Return of Conduction"
          }
        }
      },
      {
        Name: "MU.20",
        OID: "MU.20",
        Symbol: {
          TranslatedText: {
            lang: "en",
            text: "in"
          }
        }
      },
      {
        Name: "MU.21",
        OID: "MU.21",
        Symbol: {
          TranslatedText: {
            lang: "en",
            text: "lb"
          }
        }
      },
      {
        Name: "MU.17",
        OID: "MU.17",
        Symbol: {
          TranslatedText: {
            lang: "en",
            text: "cm"
          }
        }
      },
      {
        Name: "MU.18",
        OID: "MU.18",
        Symbol: {
          TranslatedText: {
            lang: "en",
            text: "mg"
          }
        }
      },
      {
        Name: "MU.14",
        OID: "MU.14",
        Symbol: {
          TranslatedText: {
            lang: "en",
            text: "(mL)"
          }
        }
      },
      {
        Name: "MU.9",
        OID: "MU.9",
        Symbol: {
          TranslatedText: {
            lang: "en",
            text: "(mm:ss)"
          }
        }
      },
      {
        Name: "MU.22",
        OID: "MU.22",
        Symbol: {
          TranslatedText: {
            lang: "en",
            text: "mL/m<sup>2</sup>"
          }
        }
      },
      {
        Name: "MU.23",
        OID: "MU.23",
        Symbol: {
          TranslatedText: {
            lang: "en",
            text: "mcg"
          }
        }
      },
      {
        Name: "MU.24",
        OID: "MU.24",
        Symbol: {
          TranslatedText:
          {
            lang: "en",
            text: "in"
          }

        }
      },
      {
        Name: "MU.25",
        OID: "MU.25",
        Symbol: {
          TranslatedText:
          {
            lang: "en",
            text: "sq.ft"
          }

        }
      },
      {
        Name: "MU.26",
        OID: "MU.26",
        Symbol: {
          TranslatedText:
          {
            lang: "en",
            text: "PSF"
          }

        }
      },
      {
        Name: "MU.27",
        OID: "MU.27",
        Symbol: {
          TranslatedText:
          {
            lang: "en",
            text: "days"
          }

        }
      },
      {
        Name: "MU.28",
        OID: "MU.28",
        Symbol: {
          TranslatedText:
          {
            lang: "en",
            text: "$"
          }

        }
      },
      {
        Name: "MU.29",
        OID: "MU.29",
        Symbol: {
          TranslatedText:
          {
            lang: "en",
            text: "ft"
          }

        }
      }, {
        Name: "MU.30",
        OID: "MU.30",
        Symbol: {
          TranslatedText:
          {
            lang: "en",
            text: "years"
          }

        }
      }, {
        Name: "MU.31",
        OID: "MU.31",
        Symbol: {
          TranslatedText:
          {
            lang: "en",
            text: "LF"
          }

        }
      },
      {
        Name: "MU.32",
        OID: "MU.32",
        Symbol: {
          TranslatedText:
          {
            lang: "en",
            text: "ft and in"
          }

        }
      },
    ]

  }