
import * as React from 'react';
import { styled } from '@mui/material/styles';
import {
    Box, Table, TableBody, TableCell, TableContainer, TableHead,
    TablePagination, TableRow, TableSortLabel,
    Paper, Checkbox, IconButton, Tooltip,
    Button, Grid, MenuItem, Select, TextField,
    FormControlLabel, Switch
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import './BillList.scss'
import {
    Print as PrintIcon,
    Share as ShareIcon,
    Settings as SettingsIcon,
} from '@mui/icons-material'; // Import necessary icons from @mui/icons-material
import { useNavigate } from 'react-router-dom';
import { getBillList, getPOlessBill, getPOlessBillCount } from '../../../services/BillService';
import { getVendorBill } from '../../../services/DeliverySlipService';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import dayjs from 'dayjs';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import CustomDialog from '../../../pages/Dialog';
import { jsonToCSV } from 'react-papaparse';
import { exportbill } from '../../../services/ExportService';
import UploadSlip from '../../deliverySlip/UploadSlip';
import { UserContext } from '../../../hooks/UserSession';
import EditIcon from '@mui/icons-material/Edit';
import ReviewBill from '../ReviewBill';
import PaymentTypes from '../../paymentTypes/PaymentTypes';
import VendorPayment from '../../paymentTypes/VendorPayment';



interface Data {
    id: number;
    billNo: string;
    vendor: string;
    date: string;
    customer: string;
    billAmount: number;
    openBalance: number;
    status: string;
}

function createData(
    id: number,
    billNo: string,
    vendor: string,
    date: string,
    customer: string,
    billAmount: number,
    openBalance: number,
    status: string,
): Data {
    return {
        id,
        billNo,
        vendor,
        date,
        customer,
        billAmount,
        openBalance,
        status,
    };
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}
interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}
const headCells: readonly HeadCell[] = [
    {
        id: 'billNo',
        numeric: false,
        disablePadding: true,
        label: 'BILL NO',
    },
    {
        id: 'vendor',
        numeric: false,
        disablePadding: true,
        label: 'VENDOR',
    },
    {
        id: 'date',
        numeric: true,
        disablePadding: false,
        label: 'DUE DATE',
    },

    {
        id: 'billAmount',
        numeric: true,
        disablePadding: false,
        label: 'BILL AMOUNT',
    },
    {
        id: 'status',
        numeric: true,
        disablePadding: false,
        label: 'STATUS',
    },
];

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler =
        (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };
    return (
        <>
            <TableHead >
                <TableRow className='tablerows tableHead'>
                    <TableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{
                                'aria-label': 'select all desserts',
                            }}
                        />
                    </TableCell>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            // align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel

                                //active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                    <TableCell >ACTION</TableCell>
                </TableRow>
            </TableHead>
        </>
    );
}

const POLessBillList: React.FC<any> = (props: any) => {
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('vendor');
    const [selected, setSelected] = React.useState<readonly number[]>([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const navigate = useNavigate();
    const [openDialogs, setOpenDialogs] = React.useState(false);
    const [successExport, setSuccessExport] = React.useState(false);
    const [emptyData, setEmptyData] = React.useState<boolean>(false);
    const [count, setCount] = React.useState(0);
    const [billView, setBillView] = React.useState(false);

    const { accessToken } = useOidcAccessToken();
    const { userSessionDetails, setUserDetails } = React.useContext(UserContext);

    const handleClose = () => {
        setOpenDialogs(false);
        setSuccessExport(false);
    }

    const [billList, setBillList] = React.useState([] as any[]);
    const [openBillList, setOpenBillList] = React.useState(false);
    const [receiptId, setReceiptId] = React.useState('');
    const [role, setRole] = React.useState('');
    const [openReviewBill, setOpenReviewBill] = React.useState(false);

    React.useEffect(() => {
        setRole(userSessionDetails?.role?.name);
        const fetData = async () => {
            const response = await getPOlessBill(props?.projectId, "VendorBill", page, rowsPerPage, accessToken);
            console.log(response);
            setBillList(response?.data);
            const res = await getPOlessBillCount(props?.projectId, "VendorBill", accessToken)
            setCount(res.data)
        }
        fetData();
    }, [])
    React.useEffect(() => {
        setRole(userSessionDetails?.role?.name);
        const fetData = async () => {
            const response = await getPOlessBill(props?.projectId, "VendorBill", page, rowsPerPage, accessToken);
            console.log(response);
            setBillList(response?.data);
            const res = await getPOlessBillCount(props?.projectId, "VendorBill", accessToken)
            setCount(res.data)
        }
        fetData();
    }, [page,rowsPerPage])

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = billList?.map((row) => row.receiptId);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };
    const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: readonly number[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };
    const CustomCheckbox = styled(Checkbox)({
        '& .MuiSvgIcon-root': {
            width: '1em', // Customize the width of the checkbox
            height: '1em',
            fontWeight: '300',
            color: '#ccc'// Customize the height of the checkbox
        },
        '&.Mui-checked .MuiSvgIcon-root': {
            fill: '#007bff', // Customize the fill color when checked
        },
    });
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDense(event.target.checked);
    };

    const isSelected = (id: number) => selected.indexOf(id) !== -1;
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - billList.length) : 0;


    const handleDownload = (data: any) => {
        const blob = new Blob([jsonToCSV(data)], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'bill.csv';
        link.click();
    };

    const exportbills = async () => {
        console.log(selected)
        const response = await exportbill(selected, accessToken);
        if (response.status === 200) {
            if (response?.data?.length !== 0 && response?.data !== null) {
                handleDownload(response.data)
                setOpenDialogs(true);
                setSuccessExport(true);
            } else {
                setEmptyData(true);
                setOpenDialogs(true);
                setSuccessExport(true);
            }
        } else {
            setOpenDialogs(true);
            setSuccessExport(false);
        }
    }


    const visibleRows = React.useMemo(
        () =>
            stableSort(billList, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage],
    );
    const vendorBillFormView = (row: any, projectId: any) => {
        setBillView(true);
        setReceiptId(row)
        setOpenBillList(true);
    };

    const vendorBillReview = (rowData: any) => {
        console.log(rowData)
        setReceiptId(rowData)
        setOpenReviewBill(true);
    };

    const vendorPayView = (row: any, projectId: any) => {
        setReceiptId(row)
    };

    console.log(props)

    const SearchBar = () => (
        <div className='searchBar'>
            <Grid container rowSpacing={1} mt={0} columnSpacing={{ xs: 0, sm: 2, md: 3 }}>
                {/* <Grid item xs={12}>
          <Grid container rowSpacing={0} mt={0} columnSpacing={{ xs: 0, sm: 2, md: 3 }}>
            <Grid item xs={10}></Grid>
            
          </Grid>
        </Grid> */}
                <Grid item xs={2} mt={2}>
                    <Select
                        className='filter'
                        fullWidth
                        value=""
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        size='small'
                    >
                        <MenuItem value="" disabled>
                            Filters
                        </MenuItem>
                        <MenuItem value="1">Option 1</MenuItem>
                        <MenuItem value="2">Option 2</MenuItem>
                        <MenuItem value="3">Option 3</MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={3} mt={2}>
                    <TextField
                        className='billFilter'
                        id="outlined-basic"
                        // label="Outlined"
                        variant="outlined"
                        fullWidth
                        size='small'
                        value="Bill date:&nbsp; Last 12 months"
                        sx={{
                            borderRadius: '10px', // Adjust the value to set the desired border radius
                            border: 'none',
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: 'transparent',
                                },
                            },
                        }}
                    // Add more props as needed
                    />
                </Grid>
                <Grid item xs={1}>  </Grid>
                <Grid item xs={4}>  <Grid container rowSpacing={0} mt={0} columnSpacing={{ xs: 0, sm: 2, md: 3 }}><Grid item xs={6}></Grid>
                    <Grid item xs={6} mt={2}>
                        {/* <Box>
                    <Button
                        variant="contained" size="large"
                        onClick={billAddForm}
                    >
                        Add Bill
                    </Button>
                </Box> */}
                    </Grid></Grid></Grid>
                <Grid item xs={2} className='createInvoice'>
                    <Grid item xs={12} mt={2}>
                        <Tooltip title="Print">
                            <IconButton>
                                <PrintIcon fontSize="medium" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Share">
                            <IconButton>
                                <ShareIcon fontSize="medium" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Settings">
                            <IconButton>
                                <SettingsIcon fontSize="medium" />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
    return (
        <>
            <Grid container rowSpacing={1} mt={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={1}></Grid>
                <Grid item xs={10}>

                    <Grid item xs={12} className="alignEnd" sx={{ paddingBottom: '5px,10px' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={exportbills}
                            disabled={selected?.length === 0}>
                            Export vendorBill
                        </Button>
                    </Grid>

                    <CustomDialog
                        open={openDialogs}
                        onClose={handleClose}
                        success={successExport}
                        error={!successExport}
                        Content={successExport ? emptyData ? "There is no Data to Export" : "Exported Successfully" : "Error Couldn't Export"} />

                    <Box sx={{ width: '100%', }}>
                        <Paper className='FinanceCard' sx={{ width: '100%', mb: 2 }}>
                            {/* <SearchBar /> */}
                            <TableContainer >
                                <Table
                                    sx={{ minWidth: 750, border: 'none', }}
                                    aria-labelledby="tableTitle"
                                    size={dense ? 'small' : 'medium'}
                                >
                                    <EnhancedTableHead
                                        numSelected={selected?.length}
                                        order={order}
                                        orderBy={orderBy}
                                        onSelectAllClick={handleSelectAllClick}
                                        onRequestSort={handleRequestSort}
                                        rowCount={billList?.length}
                                    />
                                    <TableBody>
                                        {billList?.length == 0 ? <TableRow>
                                            <TableCell colSpan={6} align="center">
                                                No Data Found
                                            </TableCell>
                                        </TableRow>
                                            : billList?.map((row, index) => {
                                                const isItemSelected = isSelected(row.receiptId);
                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                return (
                                                    <TableRow

                                                        hover
                                                        onClick={(event) => handleClick(event, row.receiptId)}
                                                        role="checkbox"
                                                        aria-checked={isItemSelected}
                                                        tabIndex={-1}
                                                        key={row.receiptId}
                                                        selected={isItemSelected}
                                                        sx={{ cursor: 'pointer' }}
                                                    >
                                                        <TableCell padding="checkbox">
                                                            <CustomCheckbox
                                                                className='check'
                                                                color="primary"
                                                                checked={isItemSelected}
                                                                inputProps={{
                                                                    'aria-labelledby': labelId,
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            // className='invoiceRows'
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            padding="none"
                                                        >
                                                            #{row.billNo}
                                                        </TableCell>
                                                        <TableCell
                                                            // className='invoiceRows'
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            padding="none"
                                                        >
                                                            {row.companyName}
                                                        </TableCell>
                                                        <TableCell >{dayjs(row.billDate).format("DD MMM YYYY")}</TableCell>
                                                        <TableCell >${row.total}</TableCell>
                                                        {/* <TableCell ></TableCell> */}
                                                        <TableCell >
                                                            {row?.billStatus === "paid" ? "Paid" : "Unpaid"}
                                                        </TableCell>
                                                        <TableCell >
                                                            <IconButton
                                                                className="anchor"
                                                                onClick={() => vendorBillFormView(row?.receiptId, props?.projectId)}
                                                                style={{ textDecoration: 'none' }}
                                                            >
                                                                <RemoveRedEyeOutlinedIcon />
                                                            </IconButton>
                                                            {(role === "purchaseOfficer" || role === "admin") && row?.billStatus === null ?
                                                                <IconButton>
                                                                    <EditIcon onClick={() => vendorBillReview(row.receiptId)} />
                                                                </IconButton>
                                                                : <></>}
                                                            {(role === "purchaseOfficer" || role === "admin") && row?.billStatus === "Approve"?
                                                    <VendorPayment props={row} projectId={props?.projectId} receiptId={row?.receiptId} status={props?.projectStatus}/>
                                                                : <></>}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody> 
                                </Table>
                            </TableContainer>
                            <TablePagination
                                sx={{ border: 'none', }}
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={count}
                                rowsPerPage={rowsPerPage}
                                page={page}   
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </Box>
                </Grid>
                <Grid item xs={1}></Grid>
                {openBillList && <UploadSlip open={openBillList} billDetails={receiptId} projectId={props?.projectId} handleClose={() => setOpenBillList(false)} billView={billView} />}
                {openReviewBill && <ReviewBill open={openReviewBill} billDetails={receiptId} projectId={props?.projectId} handleClose={() => setOpenReviewBill(false)} />}
                {/* <PaymentTypes props={billList} projectId={props?.projectId} receiptId={receiptId} status={""} /> */}
            </Grid>
        </>

    );
}


export default POLessBillList;