import React, { useState } from 'react';
import { Tabs, Tab, Box } from '@mui/material';
import VendorBillList from './VendorBillList';
import POLessBillList from './POLessBillList';

const VendorBill: React.FC<any> = (props: any) => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs value={value} onChange={handleChange} aria-label="vendor bill tabs">
        <Tab label="Vendor Bills with PO" />
        <Tab label="Vendor Bills with no PO" />
      </Tabs>
      <TabPanel value={value} index={0}>
        <VendorBillList projectId={props?.projectId}/>
        {/* Add your vendor bill details content here */}
      </TabPanel>
      <TabPanel value={value} index={1}>
        <POLessBillList projectId={props?.projectId}/>
        {/* Add your vendor bill history content here */}
      </TabPanel>
    </Box>
  );
}

function TabPanel(props: {
  children: React.ReactNode;
  value: number;
  index: number;
}) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

export default VendorBill;