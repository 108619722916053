/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 20-Nov-2023
* @author      : Priyankaa.S
* @since       : 0.0.1
* Requirement# :
* Purpose      : Listing of Bid added to Cart
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE         |   AUTHOR          |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 701             22-12-2023         vijaykumar.S         Integrate Delete api  
*
*
*/
import {

  DialogTitle, Dialog, DialogContent, DialogActions, Button
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';


const emails = ['username@gmail.com', 'user02@gmail.com'];

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;

}


const PaymentCancelDialog: React.FC<any> = (props: SimpleDialogProps) => {
  const { onClose, open } = props;
  const navigate = useNavigate();
  const handleCancelConfirmation = () => {

      navigate("/soldproduction");
  };
  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={props.open}
        // onClose={handleClose}
        //onBackdropClick={handleBackdropClick}
        disableEscapeKeyDown

      >
        <DialogContent>Are you sure you want to cancel this transaction? </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCancelConfirmation()}>Yes</Button>
          <Button onClick={() => onClose()}>No</Button>
        </DialogActions>
      </Dialog> </>
  )
}


export default PaymentCancelDialog;