/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 6-Nov-2023
* @author      : Priyankaa.S
* @since       : 0.0.1
* Requirement# :
* Purpose      : Listing View for Delivery Slip
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |     DATE    |    AUTHOR    |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
*      2399      | 06-08-2024  | shiyam kumar | made changes to fix pagination issue
*
*
*
*/
import * as React from 'react';
import { styled } from '@mui/material/styles';
import {
    Box, Table, TableBody, TableCell, TableContainer, TableHead,
    TablePagination, TableRow, TableSortLabel,
    Paper, Checkbox, IconButton, Grid,
    InputBase,
    Button,
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import '../Bill/billList/BillList.scss'
import { useNavigate } from 'react-router-dom';
import { Stack, useTheme } from '@mui/system';
import SearchIcon from "@mui/icons-material/Search";
import { checkDeliveryExists, getDIResults, getReceiptByprojectId, getReceiptCountByprojectId } from '../../services/DeliverySlipService';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { useHandleUnauthorized } from '../../hooks/UserSession';
import PODSComparison from './PODSComparison';
import { formatFileName } from '../../utils/fileUtils';


interface Data {
    id: number;
    vendor: string;
    date: string;
    customer: string;
    status: string;
}

type Order = 'asc' | 'desc';

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}
const headCells: readonly HeadCell[] = [
    {
        id: 'id',
        numeric: false,
        disablePadding: true,
        label: 'Delivery Id',
    },
    {
        id: 'vendor',
        numeric: false,
        disablePadding: true,
        label: 'VENDOR',
    },
    {
        id: 'date',
        numeric: true,
        disablePadding: false,
        label: 'DUE DATE',
    },
    {
        id: 'customer',
        numeric: true,
        disablePadding: false,
        label: 'CUSTOMER',
    },
    {
        id: 'status',
        numeric: true,
        disablePadding: false,
        label: 'STATUS',
    },
];

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler =
        (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };
    return (
        <>
            <TableHead >
                <TableRow className='tablerows tableHead'>
                    <TableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{
                                'aria-label': 'select all desserts',
                            }}
                        />
                    </TableCell>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            // align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel

                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                    <TableCell >ACTION</TableCell>
                </TableRow>
            </TableHead>
        </>
    );
}

const DeliveryList: React.FC<any> = (props: any) => {
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('vendor');
    const [selected, setSelected] = React.useState<readonly number[]>([]);
    const [page, setPage] = React.useState(0);
    const [selectedId, setSelectedId] = React.useState<any>();

    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [count, setCount] = React.useState(Number);
    const { handleUnauthorized } = useHandleUnauthorized();
    const navigate = useNavigate();
    const projectId = props.projectId;
    const { accessToken } = useOidcAccessToken();
    const theme = useTheme();
    const [openFormData, setOpenFormData] = React.useState(false);

    const [slipList, setSlipList] = React.useState([] as any[]);

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = slipList.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };
    const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: readonly number[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };
    const getDIExists = async (receiptId: any): Promise<boolean> => {
        try {
            const res: any = await checkDeliveryExists(receiptId, accessToken);
            console.log(res);
            return !!res?.data; // Returns true if res.data exists, otherwise false
        } catch (error) {
            console.error("Error checking delivery existence:", error);
            return false; // Handle errors gracefully
        }
    };
    const CustomCheckbox = styled(Checkbox)({
        '& .MuiSvgIcon-root': {
            width: '1em', // Customize the width of the checkbox
            height: '1em',
            fontWeight: '300',
            color: '#ccc'// Customize the height of the checkbox
        },
        '&.Mui-checked .MuiSvgIcon-root': {
            fill: theme.palette.primary.main, // Customize the fill color when checked
        },
    });
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(page);

    };

    const poFormView = (purchaseId: any, receiptId: any, status: any) => {
        setSelectedId(receiptId);
        navigate("/soldproduction/projectinformation/deliveryslip", { state: { projectId, purchaseId, receiptId, status } });
    };

    const isSelected = (id: number) => selected.indexOf(id) !== -1;

    const fetchData = async (searchParams: any) => {
        const res = await getReceiptCountByprojectId(props.projectId, searchParams, accessToken);
        handleUnauthorized(res);
        setCount(res.data);
        const response = await getReceiptByprojectId(props.projectId, searchParams, page, rowsPerPage, accessToken);
        console.log(response)
        handleUnauthorized(response);
        setSlipList(response.data);
    }

    const inputchange = async (e: any) => {
        var value = e.target.value === "" ? null : e.target.value;
        if (e.target.value.length >= 3) {
            fetchData(value);
        }
        if (e.target.value.length === 0) {
            fetchData(value);
        }
    };

    React.useEffect(() => {
        fetchData(null);
    }, [])
    const handleClose = () => {
        fetchData(null);
        setOpenFormData(false);
    }
    React.useEffect(() => {
        fetchData(null);
    }, [rowsPerPage, page])

    const DIStatus = ({ receiptId, onClick }: { receiptId: number; onClick: () => void }) => {
        const [exists, setExists] = React.useState<boolean | null>(null);
        const [poData, setPoData] = React.useState({} as any);

        React.useEffect(() => {
            const fetchData = async () => {
                setSelectedId(receiptId)

                const result = await getDIExists(receiptId);
                setExists(result);
            };
            fetchData();


        }, [receiptId]);

        if (exists === null) return <span>Loading...</span>; // Optional: Show loading state

        return exists ? (
            <a className="anchor" style={{ textDecoration: "none" }} onClick={onClick}>
                View
            </a>
        ) : (
            <Button onClick={openForm}>Delivery Slip Verification</Button>
        );
    };
    const openForm = (id:any) => {
        setSelectedId(id);
        setOpenFormData(true);
    }

    return (
        <>
            <Grid container rowSpacing={1} mt={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={1}></Grid>
                <Grid item xs={10}>
                    <Box sx={{ width: '100%', }}>
                        <Paper className='FinanceCard' sx={{ width: '100%', mb: 2 }}>
                            <Grid container mt={1}>
                                <Grid item xs={3} p={3}>
                                    <Paper
                                        variant="outlined"
                                        component="form"
                                        className='searchField'
                                        sx={{ p: "2px 4px", display: "flex", alignItems: "center" }}
                                    >
                                        <InputBase
                                            sx={{ ml: 1, flex: 1 }}
                                            placeholder="Search by vendor"
                                            inputProps={{ "aria-label": "search google maps" }}
                                            onChange={(e) => inputchange(e)}
                                        />
                                        <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                                            <SearchIcon />
                                        </IconButton>
                                    </Paper>
                                </Grid>
                            </Grid>
                            <TableContainer >
                                <Table
                                    sx={{ minWidth: 750, border: 'none', }}
                                    aria-labelledby="tableTitle"
                                    size={dense ? 'small' : 'medium'}
                                >
                                    <EnhancedTableHead
                                        numSelected={selected.length}
                                        order={order}
                                        orderBy={orderBy}
                                        onSelectAllClick={handleSelectAllClick}
                                        onRequestSort={handleRequestSort}
                                        rowCount={count}
                                    />
                                    <TableBody>
                                        {slipList.length == 0 ? (
                                            <TableRow>
                                                <TableCell colSpan={7} align="center">
                                                    No data available
                                                </TableCell>
                                            </TableRow>) : (

                                            slipList
                                                .filter(row => row.status !== "rejected")
                                                .map((row, index) => {
                                                    const isItemSelected = isSelected(row.id);
                                                    const labelId = `enhanced-table-checkbox-${index}`;
                                                    return (<TableRow

                                                        hover
                                                        onClick={(event) => handleClick(event, row.id)}
                                                        role="checkbox"
                                                        aria-checked={isItemSelected}
                                                        tabIndex={-1}
                                                        key={row.id}
                                                        selected={isItemSelected}
                                                        sx={{ cursor: 'pointer' }}
                                                    >

                                                        <TableCell padding="checkbox" size="small">
                                                            <CustomCheckbox
                                                                className='check'
                                                                color="primary"
                                                                checked={isItemSelected}
                                                                inputProps={{
                                                                    'aria-labelledby': labelId,
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell size="small"
                                                            // className='invoiceRows'
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            padding="none"
                                                        >
                                                            {row.id}
                                                        </TableCell>
                                                        <TableCell size="small"
                                                            // className='invoiceRows'
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            padding="none"
                                                        >
                                                            {row.vendor_name}
                                                        </TableCell>
                                                        <TableCell size="small">{row.order_date}</TableCell>
                                                        <TableCell size="small">{row.customer_name}</TableCell>
                                                        <TableCell size="small">
                                                            {formatFileName(row.status ?? '')}
                                                        </TableCell>
                                                        <TableCell size="small">
                                                            {row.validation ? (
                                                                <a
                                                                    className="anchor"
                                                                    style={{ textDecoration: "none", cursor: "pointer" }}
                                                                    onClick={() => poFormView(row.purchase_id, row.id, row.status)}
                                                                >
                                                                    View
                                                                </a>
                                                            ) : (
                                                                <Button onClick={() =>openForm(row.id)}>Delivery Slip Verification</Button>
                                                            )}
                                                        </TableCell>

                                                    </TableRow>
                                                    )
                                                })
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                sx={{ border: 'none', }}
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={count}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </Box>
                </Grid>
                <Grid item xs={1}></Grid>
            </Grid>
            
            {openFormData && <PODSComparison receiptId={selectedId} open={openFormData} handleClose={handleClose} />}
        </>

    );
}


export default DeliveryList;