
import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import {
    Box, Table, TableBody, TableCell, TableContainer, TableHead,
    TablePagination, TableRow, TableSortLabel,
    Paper, Checkbox, IconButton, Tooltip,
    Button, Grid, MenuItem, Select, TextField,
    FormControlLabel, Switch
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import './BillList.scss'
import {
    Print as PrintIcon,
    Share as ShareIcon,
    Settings as SettingsIcon,
} from '@mui/icons-material'; // Import necessary icons from @mui/icons-material
import { useNavigate } from 'react-router-dom';
import { getBillList } from '../../../services/BillService';
import { getVendorBill, getVendorBillCount } from '../../../services/DeliverySlipService';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import dayjs from 'dayjs';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { jsonToCSV } from 'react-papaparse';
import CustomDialog from '../../../pages/Dialog';
import { exportbill } from '../../../services/ExportService';
import { formatFileName } from '../../../utils/fileUtils';
import { getVendorcount } from '../../../services/VendorService';


interface Data {
    id: number;
    billNo:string;
    vendor: string;
    date: string;
    customer: string;
    billAmount: number;
    openBalance: number;
    status: string;
}

function createData(
    id: number,
    billNo: string,
    vendor: string,
    date: string,
    customer: string,
    billAmount: number,
    openBalance: number,
    status: string,
): Data {
    return {
        id,
        billNo,
        vendor,
        date,
        customer,
        billAmount,
        openBalance,
        status,
    };
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}
interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}
const headCells: readonly HeadCell[] = [
    {
        id: 'billNo',
        numeric: false,
        disablePadding: true,
        label: 'BILL NO',
    },
    {
        id: 'vendor',
        numeric: false,
        disablePadding: true,
        label: 'VENDOR',
    },
    {
        id: 'date',
        numeric: true,
        disablePadding: false,
        label: 'DUE DATE',
    },
    
    {
        id: 'billAmount',
        numeric: true,
        disablePadding: false,
        label: 'BILL AMOUNT',
    },
    {
        id: 'status',
        numeric: true,
        disablePadding: false,
        label: 'STATUS',
    },
];

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler =
        (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };
    return (
        <>
            <TableHead >
                <TableRow className='tablerows tableHead'>
                    <TableCell padding="checkbox">
                        <Checkbox
                            color="primary"
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{
                                'aria-label': 'select all desserts',
                            }}
                        />
                    </TableCell>
                    {headCells.map((headCell) => (
                        <TableCell 
                            key={headCell.id}
                            // align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel

                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                    <TableCell >ACTION</TableCell>
                </TableRow>
            </TableHead>
        </>
    );
}

const VendorBillList: React.FC<any> = (props: any) => {
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Data>('vendor');
    const [selected, setSelected] = React.useState<readonly number[]>([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const navigate = useNavigate();
    const { accessToken } = useOidcAccessToken();
    const [vendorBillCount, setVendorBillCount] = React.useState(0);

    const [billList, setBillList] = React.useState([] as any[]);
    const [openDialogs, setOpenDialogs] = React.useState(false);
    const [successExport, setSuccessExport] = React.useState(false);
    const [emptyData, setEmptyData] = useState<boolean>(false);


    const handleClose = () => {
        setOpenDialogs(false);
        setSuccessExport(false);
      }
    

    React.useEffect(() => {
        const fetData = async () => {
            const resCount=await getVendorBillCount(props?.projectId,accessToken);
            const response = await getVendorBill(props?.projectId, page, rowsPerPage,accessToken);
            console.log(response);
            setVendorBillCount(resCount?.data)
            setBillList(response?.data);
        }
        fetData();
    }, [])

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof Data,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = billList?.map((row) => row.receiptId);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };
    
    const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected: readonly number[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
    };
    React.useEffect(() => {
        const fetData = async () => {
            const resCount=await getVendorBillCount(props?.projectId,accessToken);
            const response = await getVendorBill(props?.projectId, page, rowsPerPage,accessToken);
            console.log(response);
            setVendorBillCount(resCount?.data)
            setBillList(response?.data);
        }
        fetData();
    }, [page,rowsPerPage])
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };
    const CustomCheckbox = styled(Checkbox)({
        '& .MuiSvgIcon-root': {
            width: '1em', // Customize the width of the checkbox
            height: '1em',
            fontWeight: '300',
            color: '#ccc'// Customize the height of the checkbox
        },
        '&.Mui-checked .MuiSvgIcon-root': {
            fill: '#007bff', // Customize the fill color when checked
        },
    });
    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDense(event.target.checked);
    };

    const isSelected = (id: number) => selected.indexOf(id) !== -1;
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - billList.length) : 0;



        const handleDownload = (data: any) => {
        
            const blob = new Blob([jsonToCSV(data)], { type: 'text/csv' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'vendorbill.csv';
            link.click();
          };
        
          const exportvendorbills = async () => {
        
            const response = await exportbill(selected, accessToken);
            if (response.status === 200) {
              if (response?.data?.length !== 0 && response?.data !== null) {
                handleDownload(response.data)
                setOpenDialogs(true);
                setSuccessExport(true);
              } else {
                setEmptyData(true);
                setOpenDialogs(true);
                setSuccessExport(true);
              }
            } else {
              setOpenDialogs(true);
              setSuccessExport(false);
            }
          }

    const visibleRows = React.useMemo(
        () =>
            stableSort(billList, getComparator(order, orderBy)).slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage,
            ),
        [order, orderBy, page, rowsPerPage],
    );
    const vendorBillFormView = (row: any, projectId: any) => {
        navigate("/bill", {
            state: {
                row,
                projectId
            }
        });
    };

    return (
        <>
            <Grid container rowSpacing={1} mt={3} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={1}></Grid>
                <Grid item xs={10}>
                
                    <Grid item xs={12} className="alignEnd" sx={{ paddingBottom: '5px,10px' }}>
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={exportvendorbills}
                                        disabled={selected?.length === 0}
                                      >
                                        Export VendorBill
                                      </Button>
                    
                                    

                                        <CustomDialog
                                        open={openDialogs}
                                        onClose={handleClose}
                                        success={successExport}
                                        error={!successExport}
                                        Content={successExport ? emptyData ? "There is no Data to Export" : "Exported Successfully" : "Error Couldn't Export"} />
                                       </Grid>

           
                    <Box sx={{ width: '100%', }}>
                        <Paper className='FinanceCard' sx={{ width: '100%', mb: 2 }}>
                            {/* <SearchBar /> */}
                            <TableContainer >
                                <Table
                                    sx={{ minWidth: 750, border: 'none', }}
                                    aria-labelledby="tableTitle"
                                    size={dense ? 'small' : 'medium'}
                                >
                                    <EnhancedTableHead
                                        numSelected={selected?.length}
                                        order={order}
                                        orderBy={orderBy}
                                        onSelectAllClick={handleSelectAllClick}
                                        onRequestSort={handleRequestSort}
                                        rowCount={billList?.length}
                                    />
                                    <TableBody>
                                        {billList?.length === 0 ? <TableRow>
                                            <TableCell colSpan={6} align="center">
                                                No Data Found
                                            </TableCell>
                                        </TableRow>
                                            : billList?.map((row, index) => {
                                                const isItemSelected = isSelected(row.receiptId);
                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                return (
                                                    <TableRow

                                                        hover
                                                        onClick={(event) => handleClick(event, row.receiptId)}
                                                        role="checkbox"
                                                        aria-checked={isItemSelected}
                                                        tabIndex={-1}
                                                        key={row.receiptId}
                                                        selected={isItemSelected}
                                                        sx={{ cursor: 'pointer' }}
                                                    >
                                                        <TableCell padding="checkbox">
                                                            <CustomCheckbox
                                                                className='check'
                                                                color="primary"
                                                                checked={isItemSelected}
                                                                inputProps={{
                                                                    'aria-labelledby': labelId,
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell
                                                            // className='invoiceRows'
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            padding="none"
                                                        >
                                                            #{row.billNo}
                                                        </TableCell>
                                                        <TableCell
                                                            // className='invoiceRows'
                                                            component="th"
                                                            id={labelId}
                                                            scope="row"
                                                            padding="none"
                                                        >
                                                            {row.vendorName}
                                                        </TableCell>
                                                        {/* <TableCell >{dayjs(row.billDate).format("DD MMM YYYY")}</TableCell> */}
                                                        <TableCell>
                                                            {row?.status === "unPaid" ? row?.dueDays > 0 ?
                                                                <Box sx={{ color: "#56ca00" }}>due in {row?.dueDays} days
                                                                </Box>
                                                                :
                                                                <Box sx={{ color: "red" }}>{String(row?.dueDays).replace("-", "")} days past due
                                                                </Box>
                                                                :
                                                                <Box>Paid On</Box>}
                                                        </TableCell>

                                                        <TableCell >${row.total}</TableCell>
                                                        {/* <TableCell ></TableCell> */}
                                                        <TableCell >
                                                            {formatFileName(row.status ?? '')}
                                                        </TableCell>
                                                        <TableCell >
                                                            {row?.status === "paid" ?
                                                                <IconButton
                                                                    className="anchor"
                                                                    onClick={() => vendorBillFormView(row, props?.projectId)}
                                                                    style={{ textDecoration: 'none' }}
                                                                >
                                                                    <RemoveRedEyeOutlinedIcon />
                                                                </IconButton>
                                                                :
                                                                <Button size = "small" variant="contained" onClick={() => vendorBillFormView(row, props?.projectId)}>
                                                                    Make Payment
                                                                </Button>}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        {emptyRows > 0 && (
                                            <TableRow
                                                style={{
                                                    height: (dense ? 33 : 53) * emptyRows,
                                                }}
                                            >
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                sx={{ border: 'none', }}
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={vendorBillCount}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </Box>
                </Grid>
                <Grid item xs={1}></Grid>
            </Grid>
        </>

    );
}


export default VendorBillList;