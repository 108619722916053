
import React, { useState } from 'react';
import {
    Box,
    Button,
    Card,
    Grid,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    TextField,
    Divider,
    IconButton,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import Header from '../components/Header';
import dayjs from 'dayjs';
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from "@mui/icons-material/Edit";
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { useLocation } from 'react-router-dom';
import { isUserHasPermission } from '../hooks/UserSession';
import PaymentTypes from '../components/paymentTypes/PaymentTypes';

export default function BillForm() {
    const [value, setValue] = React.useState(0);
    const [openDialogs, setOpenDialogs] = React.useState(false); // Dialog state for success/error
    const [openSendDialogs, setOpenSendDialogs] = React.useState(false);
    const [openTaxDialogs, setOpenTaxDialogs] = React.useState(false);
    const [createSuccess, setCreateSuccess] = React.useState(false);
    const [createError, setCreateError] = React.useState(false);
    const [createSendSuccess, setCreateSendSuccess] = React.useState(false);
    const [createSendError, setCreateSendError] = React.useState(false);
    const [createTaxSuccess, setCreateTaxSuccess] = React.useState(false);
    const [createTaxError, setCreateTaxError] = React.useState(false);
    const [newRows, setNewRows] = React.useState<any>([]);
    const [isEdit, setEdit] = React.useState(false);
    const location = useLocation();
    const [openmatrec, setOpenmatrec] = useState<boolean>(false); // Declare openMatrec state variable
    const [totalCost, setTotalCost] = React.useState<any>('');

    let billView = location?.state?.row;
    let projectId = location?.state?.projectId;
    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.up('sm'));
    const totalCosts = () => {

        const totalCost = billView?.poItems?.reduce((sum, item) => {
            return sum + (item.cost || 0);  // Ensure cost is a number and handle undefined values
        }, 0);
        console.log(totalCost)
        setTotalCost(totalCost);
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleBack = () => {
        // Implement navigation logic here
    };

    const updateInvoiceStatus = () => {
        setOpenmatrec(true);
        // Implement invoice status update logic here
    };
    const [billData, setBillData] = useState({
        id: '',
        tenantLogo: '',
        tenantAddress: '',
        tenantCity: '',
        tenantState: '',
        tenantEmail: '',
        tenantName: '',
        tenantPrimaryContact: '',
        tenantSecondaryContact: '',
        billNo: '',
        billDate: null,
        dueDate: "2025-02-17T10:59:06.397Z",
        vendorName: '',
        vendorAddress: '',
        vendorCity: '',
        vendorState: '',
        vendorMobileNo: '',
        vendorEmail: '',
        billingAddress: '',
        billingCity: '',
        billingState: '',
        total: 0,
        taxExemption: false,
        invoicedAmount: 0,
        status: '',
        salesPersonName: '',
    });

    const [taxRows, setTaxRows] = React.useState<any>([
        { name: 'Tax1', percentage: 10, value: 10 },
        { name: 'Tax2', percentage: 5, value: 15 },
    ]);
    const [editTaxIndex, setEditTaxIndex] = React.useState<number | null>(null);
    const [editTaxRowData, setEditTaxRowData] = React.useState({
        name: '',
        percentage: '',
        value: '',
    });

    const handleTaxInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setEditTaxRowData((prevData) => ({
            ...prevData,
            [name]: value, // Update the edited field
        }));
    };


    const handleEditTax = (index: number) => {
        const taxRowToEdit = taxRows[index];
        setEditTaxRowData({ ...taxRowToEdit }); // Set the data to edit
        setEditTaxIndex(index); // Mark this row as in edit mode
    };



    const handleSaveTaxClick = () => {
        if (editTaxIndex !== null) {
            const updatedTaxRows = [...taxRows]; // Clone the current tax rows
            updatedTaxRows[editTaxIndex] = { ...editTaxRowData }; // Update the edited row
            setTaxRows(updatedTaxRows); // Update the tax rows state
            setEditTaxIndex(null); // Exit edit mode
        }
    };


    const handleDeleteTaxClick = (index: number) => {
        const updatedTaxRows = taxRows.filter((_, i) => i !== index);
        setTaxRows(updatedTaxRows);
    };

    const handleAddTaxRow = () => {
        setTaxRows((prevTaxRows) => [
            ...prevTaxRows,
            { name: '', percentage: '', value: '' },
        ]);
    };

    const [rows, setRows] = React.useState<any>([
        { code: '001', description: 'Item 1', quantity: 10, unitcost: 100, amount: 1000 },
        { code: '002', description: 'Item 2', quantity: 5, unitcost: 200, amount: 1000 }
    ]);

    const [editIndex, setEditIndex] = useState<number | null>(null);
    const [editRowData, setEditRowData] = useState({
        code: '',
        description: '',
        quantity: '',
        unitcost: '',
        amount: '',
    });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        setEditRowData((prevData) => {
            const updatedData = {
                ...prevData,
                [name]: value,
            };

            // Auto-save after input change
            const updatedRows = [...rows];
            updatedRows[editIndex!] = updatedData;
            setRows(updatedRows);

            return updatedData;
        });
    };

    const handleEditItem = (index: number) => {
        const rowToEdit = rows[index];
        setEditRowData({
            code: rowToEdit.code.toString(),
            description: rowToEdit.description,
            quantity: rowToEdit.quantity.toString(),
            unitcost: rowToEdit.unitcost.toString(),
            amount: rowToEdit.amount.toString(),
        });

        setEditIndex(index);
    };

    const handleSaveClick = (index: number) => {
        const updatedRows = [...rows];
        updatedRows[index] = editRowData;
        setRows(updatedRows);
        setEditIndex(null); // Reset the edit mode
    };

    const handleDeleteItem = (index: number) => {
        setRows((prevRows) => prevRows.filter((_, i) => i !== index));
    };

    const handleAddRow = () => {
        setRows((prevRows) => [
            ...prevRows,
            { code: '', description: '', quantity: '', unitcost: '', amount: '' },
        ]);
    };

    React.useEffect(() => {
        console.log(location.state.row)
        if (location.state.row != null) {
            setBillData(location.state.row)
            totalCosts()
        }
    }, [])
    const handlematrecOpen = () => {
        setOpenmatrec(true);
    };

    return (
        <>
            <Box
                display={smScreen ? "flex" : "block"}
                flexDirection={smScreen ? "row" : "column"}
                justifyContent={smScreen ? "space-between" : "start"}
                alignItems={smScreen ? "center" : "start"}
            >
                <Header title="Vendor Bill" subtitle="" />
            </Box>



            {/* Container for Invoice Data */}
            <Grid container rowSpacing={2}>
                <Grid item xs={12} sm={9}>
                    <Box sx={{ display: 'block', alignItems: 'center', '& > :not(style)': { m: 2 } }}>
                        <Card className='POCard' variant="outlined" sx={{ padding: '48px' }}>
                            <Grid container className='invoiceHeader' rowSpacing={0} columnSpacing={0}>
                                {/* Header - Tenant Details */}
                                <Grid item xs={12} sm={6}>
                                    <Typography>
                                        <img src={billData?.tenantLogo} alt="Tenant Logo" className="logo" />
                                    </Typography>
                                    <Typography paddingTop={2}>{billData?.tenantAddress}</Typography>
                                    <Typography>{billData?.tenantCity}, {billData?.tenantState}</Typography>
                                    <Typography>{billData?.tenantPrimaryContact}, {billData?.tenantSecondaryContact}</Typography>
                                </Grid>

                                {/* Header - Invoice Info */}
                                <Grid item xs={12} sm={6} className='alignEnd'>
                                    <Stack>
                                        <Typography className='invoiceNumber'>Bill Number #{billData?.billNo}</Typography>
                                        <Typography paddingTop={2}>Date Issued: {dayjs(billData?.billDate).format("DD MMM YYYY")}</Typography>
                                        <Typography>Date Due: {dayjs(billData?.dueDate).format("DD MMM YYYY")}</Typography>
                                    </Stack>
                                </Grid>
                            </Grid>

                            {/* Invoice To and Billing Address */}
                            <Grid container rowSpacing={2} columnSpacing={2} paddingTop={3}>
                                <Grid item xs={12} sm={6}>
                                    <Typography className='subHeader'>Bill From:</Typography>
                                    <Typography paddingTop={2}>{billData?.tenantName}</Typography>
                                    <Typography>{billData?.tenantAddress},</Typography>
                                    <Typography>{billData?.tenantCity}, {billData?.tenantState}</Typography>
                                    <Typography>{billData?.tenantPrimaryContact},</Typography>
                                    <Typography>{billData?.tenantEmail},</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography className='subHeader'>Billing To:</Typography>
                                    <Typography paddingTop={2}>{billData?.vendorName}</Typography>
                                    <Typography>{billData?.vendorAddress}</Typography>
                                    <Typography>{billData?.vendorCity} {billData?.vendorState}</Typography>
                                    <Typography>{billData?.vendorMobileNo}</Typography>
                                    <Typography>{billData?.vendorEmail}</Typography>
                                </Grid>
                            </Grid>

                            {/* Table - Invoice Items */}
                            <Grid container rowSpacing={2} columnSpacing={2} paddingTop={4}>
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>code</TableCell>
                                                <TableCell align="left">Description</TableCell>
                                                <TableCell align="left">Quantity</TableCell>
                                                <TableCell align="left">Unit Cost</TableCell>
                                                <TableCell align="left">Amount</TableCell>
                                                {/* <TableCell align="right">Action</TableCell> */}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {billView?.poItems?.map((row, index) => (
                                                <TableRow key={index}>
                                                    {/* {editIndex === index ? ( */}
                                                    {/* <>
                                                            <TableCell>
                                                                <TextField
                                                                    name="code"
                                                                    value={editRowData.code}
                                                                    onChange={handleInputChange}
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <TextField
                                                                    name="description"
                                                                    value={editRowData.description}
                                                                    onChange={handleInputChange}
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <TextField
                                                                    name="quantity"
                                                                    value={editRowData.quantity}
                                                                    onChange={handleInputChange}
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <TextField
                                                                    name="unitcost"
                                                                    value={editRowData.unitcost}
                                                                    onChange={handleInputChange}
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <TextField
                                                                    name="amount"
                                                                    value={editRowData.amount}
                                                                    onChange={handleInputChange}
                                                                />
                                                            </TableCell>
                                                            <TableCell>
                                                                <Button onClick={() => handleSaveClick(index)}>Save</Button>
                                                            </TableCell>
                                                        </>
                                                    ) : ( */}
                                                    <>
                                                        <TableCell align="left">{row.itemCode}</TableCell>
                                                        <TableCell align="left">{row.description}</TableCell>
                                                        <TableCell align="left">{row.received_quantity}</TableCell>
                                                        <TableCell align="left">{row.rate}</TableCell>
                                                        <TableCell align="left">{row.cost}</TableCell>
                                                        {/* <TableCell align="right"> 
                                                                <IconButton onClick={() => handleEditItem(index)}>
                                                                    <EditIcon />
                                                                </IconButton>
                                                                <IconButton onClick={() => handleDeleteItem(index)}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </TableCell> */}
                                                    </>
                                                    {/* )} */}
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>

                                    {/* <Grid item xs={15}>
                                        <Box display="flex" justifyContent="flex-end" paddingTop={3} >

                                            <Typography
                                                variant="body2"
                                                style={{ cursor: 'pointer', color: 'blue', fontSize: '15px' }}
                                                onClick={handleAddRow}
                                            >
                                                Add Row
                                            </Typography>
                                        </Box>
                                    </Grid> */}
                                </TableContainer>
                            </Grid>


                            {/* Salesperson, Subtotal, Total */}
                            <Grid container rowSpacing={2} columnSpacing={1} paddingTop={3}>
                                <Grid item xs={12} sm={8}>
                                    <Stack direction={'row'}>
                                        <Typography className='subHeader'>Foreman:System</Typography>
                                        {/* <Typography>&nbsp;&nbsp;{billData?.System}</Typography> */}
                                    </Stack>
                                    <Typography>Thanks for your business</Typography>
                                </Grid>
                                <Grid item xs={6} sm={2} className='alignEnd'>
                                    <Box>
                                        <Typography className='subHeader' textAlign={'right'}>Subtotal:</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={6} sm={2} className='alignEnd'>
                                    <Box>
                                        <Typography textAlign={'right'}>$&nbsp;{totalCost}</Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            {/* Tax Rows */}
                            {/* Tax Rows */}
                            {/* {billData?.taxExemption === false ? ( */}
                            <Grid container rowSpacing={1} columnSpacing={1}>
                                {/* {billView?.taxInfos.map((tax, index) => (
                                        <Grid container key={index} rowSpacing={5} columnSpacing={4}>
                                            <Grid item xs={12} sm={6}></Grid>
                                            <Grid item xs={12} sm={2}>
                                                <Box>
                                                    <TextField
                                                        label="Tax Name"
                                                        value={editTaxIndex === index ? editTaxRowData.name : tax.name}
                                                        name="name"
                                                        onChange={handleTaxInputChange}
                                                        disabled={editTaxIndex !== index}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6} sm={2} className="alignEnd">
                                                <Box>
                                                    <TextField
                                                        label="Percentage"
                                                        value={editTaxIndex === index ? editTaxRowData.percentage : tax.percentage}
                                                        name="percentage"
                                                        onChange={handleTaxInputChange}
                                                        disabled={editTaxIndex !== index}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6} sm={2} className="alignEnd">
                                                <Box>
                                                    <Stack direction={"row"} spacing={1}>
                                                        <TextField
                                                            label="Value"
                                                            value={editTaxIndex === index ? editTaxRowData.value : tax.value}
                                                            name="value"
                                                            onChange={handleTaxInputChange}
                                                            disabled={editTaxIndex !== index}
                                                        />
                                                        {editTaxIndex === index ? (
                                                            <Button onClick={handleSaveTaxClick}>Save</Button>
                                                        ) : (
                                                            <>
                                                                <IconButton onClick={() => handleEditTax(index)}>
                                                                    <EditIcon />
                                                                </IconButton>
                                                                <IconButton onClick={() => handleDeleteTaxClick(index)}>
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </>
                                                        )}
                                                    </Stack>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    ))} */}
                                {billView?.taxInfos?.map((tax, index) => (
                                    <><Grid item xs={12} sm={6}>
                                    </Grid>
                                        <Grid item xs={12} sm={2}>
                                            <Box>
                                                <TextField
                                                    label="Tax Name"
                                                    id="outlined-size-small"
                                                    size="small"
                                                    value={tax?.name}
                                                    // disabled={taxShow && !newRows.includes(index)}
                                                    // error={tax?.name === '' && error}
                                                    name="name"
                                                    disabled
                                                // onChange={(e) => onInputTaxNameChange(e, index)}
                                                />
                                            </Box>
                                        </Grid><Grid item xs={6} sm={2} className='alignEnd'>
                                            <Box>
                                                <TextField
                                                    label="Percentage"
                                                    id="outlined-size-small"
                                                    size="small"
                                                    value={tax?.percentage}
                                                    name="percentage"
                                                    type='number'
                                                    disabled
                                                // error={tax?.percentage === '' && error}
                                                // disabled={taxShow && !newRows.includes(index)}
                                                // onChange={(e) => onInputChange(e, index, totalCost)}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid item xs={6} sm={2} className='alignEnd'>
                                            <Box>
                                                <Stack direction={'row'} spacing={1}>
                                                    <TextField
                                                        label="Value"
                                                        id="outlined-size-small"
                                                        size="small"
                                                        type='number'
                                                        value={tax?.value ? Number(tax.value).toFixed(2) : ''}
                                                        name="value"
                                                        disabled
                                                    // onChange={(e) => onInputChange(e, index)}
                                                    />
                                                    {/* {poData?.status === "Generated" && (poData?.taxInfos).length !== 0 && newRows.includes(index) ?
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <IconButton onClick={() => handleRemoveItem(index)}>
                                                            <DeleteIcon fontSize='small'
                                                            />
                                                        </IconButton>
                                                    </Box>
                                                    :
                                                    <></>
                                                } */}
                                                </Stack>
                                            </Box>
                                        </Grid>

                                    </>
                                ))}
                                {/* Add Row button for Tax */}
                                {/* <Grid item xs={12}>
                                        <Box display="flex" justifyContent="flex-end" paddingTop={3}>
                                            <Typography
                                                variant="body2"
                                                style={{ cursor: "pointer", color: "blue", fontSize: "15px" }}
                                                onClick={handleAddTaxRow}
                                            >
                                                Add Row
                                            </Typography>
                                        </Box>
                                    </Grid> */}
                            </Grid>





                            {/* Total Calculation and Notes */}
                            <Grid container rowSpacing={2} paddingBottom={3} mt={1}>
                                <Grid item xs={12} sm={8}></Grid>
                                <Grid item xs={6} sm={2} className='alignEnd'>
                                    <Box>
                                        <Typography className='subHeader'>Total:</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={6} sm={2} className='alignEnd'>
                                    <Box>
                                        <Typography textAlign={'right'}>$&nbsp;{Number(billData?.total)?.toFixed(2)}</Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Divider />

                            <Grid container rowSpacing={2} columnSpacing={2} paddingTop={3} paddingBottom={3}>
                                <Grid item xs={12}>
                                    <Stack direction={'row'}>
                                        <Typography className='subHeader'>Note:&nbsp;&nbsp;</Typography>
                                        <Typography>It was a pleasure working with you and your team. We hope you will keep us in mind for future freelance projects. Thank You!</Typography>
                                    </Stack>
                                </Grid>
                            </Grid>

                            {/* Save Button */}
                            {billData?.status === "Generated" && billData?.taxExemption === false ? (
                                <Grid container rowSpacing={2} columnSpacing={2}>
                                    <Grid item xs={12} className='alignEnd'>
                                        <Button className='sucessButton' variant="contained" size="medium" onClick={handleBack}>Save</Button>
                                    </Grid>
                                </Grid>
                            ) : null}
                        </Card>
                    </Box>
                </Grid>
                {/* {billView?.status === "unPaid" ?
                    <></> :
                    <> */}
                {/* {isUserHasPermission("PaymentCreate") && */}
                {/* <Card className='POCard' variant="outlined" sx={{ padding: '20px' }}>
                            <Grid container>
                                <Grid item xs={12} sm={12} paddingTop={2}> */}

                {/* {openmatrec && <PaymentTypes props={billData} projectId={projectId} receiptId={billView?.receiptId} />}    */}

                {/* </Grid>
                            </Grid>
                        </Card> */}
                {/* } */}
                {/* </>
                } */}
                {/* Sidebar for other options */}
                <Grid item xs={12} sm={3}>
                    <Box sx={{ display: 'block', alignItems: 'center', '& > :not(style)': { m: 2 } }}>
                        <Card className='POCard' variant="outlined" sx={{ padding: '20px' }}>
                            <Grid container spacing={2} direction={'column'}>
                                {/* <Grid item xs={12}>
                                    <Button variant="contained" fullWidth onClick={handleBack}>
                                        <KeyboardDoubleArrowLeftIcon sx={{ fontSize: '16px' }} />&nbsp;Go Back
                                    </Button>
                                </Grid> */}
                                <Grid item xs={12}>
                                <PaymentTypes props={billData} projectId={projectId} receiptId={billView?.receiptId} status = {billData?.status}/>
                                </Grid>
                            </Grid>
                        </Card>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}
