/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 01-Dec-2024
* @author      : Ananth Kumar L
* @since       : 0.0.1
* Requirement# :
* Purpose      : For Chat UI
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 
*
*
*/
import React, { useState, useEffect, useRef, useContext } from "react";
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import './Chat.scss'
import { Box, Button, ButtonGroup, Chip, Fab, Paper, Stack, TextField, Tooltip } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import TouchAppIcon from '@mui/icons-material/TouchApp';
// import { getBidInfoByQueryParam, postChatBot } from "../../services/ChatBotService";
import { getProjectInfoByQueryParam, postChatBot } from "../../services/ChatBotService";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
// import image from "../../assets/images/minilogo-static.png";
import image from "../../assets/images/google-chat-logo-512x512.png";
import { MessagesInfo, BidInfo, ChatOptions } from "../../viewmodel/Chat";
import { defaultMessage } from "../../data/ChatQuestions";
import { UserContext } from "../../hooks/UserSession";
import { PagingInfo } from "../../queryparams/PagingInfo";
import { BidQueryParam } from "../../queryparams/BidQueryParam";
import { ProjectQueryParam } from "../../queryparams/ProjectQueryParam";
import { tokens, useGlobalContext } from "../../hooks/theme";
import { data } from "../../data/bidsOutTotal";
import dotted from "../../assets/image-processing20200320-1564--unscreen.gif";
import chatlogo from "../../assets/chat.png";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));




interface Props {
    userResponse: string;
    botResponse: {
        purpose: string;
        message: string;
        options?: string[];
        sender: string;
    };
    sendUserResponse: string;
    options: ChatOptions[];
    optionClick: (ChatOptions) => void;
}
let botMessage: any;

const ChatCard: React.FC<Props> = (props) => {
    const { userSessionDetails, setUserDetails } = useContext(UserContext);
    const [expanded, setExpanded] = React.useState(false);
    const [messages, setMessages] = useState<MessagesInfo[]>([]);

    const bodyRef = useRef<HTMLDivElement>(null);

    const [step, setStep] = useState<number>(0);

    const { accessToken, accessTokenPayload } = useOidcAccessToken();

    const [userResponse, setUserResponse] = useState<string>("");

    const [options, setOptions] = useState<ChatOptions[]>([]);
    const [projectInfos, setProjectInfos] = useState<any[]>();
    const { theme, setCopyy } = useGlobalContext();
    const colors = tokens(theme.palette.mode);


    const setMessageResponse = async (userResp: any) => {
        const tempArray: MessagesInfo[] = messages;
        tempArray.push({
            message: userResp,
            sender: "You",
            timestamp: getTimeStamp(),
        });
        console.log("setMessageResponse", tempArray);
        setMessages(tempArray);
    }

    const setBotResponse = (botMsg: any) => {
        let tempArrayTest = messages;
        tempArrayTest.push({
            message: botMsg,
            sender: "GMS",
            timestamp: getTimeStamp(),
        });
        setMessages(tempArrayTest);


    }

    const getProjectIds = (bidInfos: any) => {
        var bidIds: ChatOptions[] = [];
        if (bidInfos != undefined && bidInfos != null && bidInfos.length > 0) {
            for (var i = 0; i < bidInfos.length; i++) {

                bidIds.push({ option: bidInfos[i]?.id?.toString(), type: "BidId" });
            }
        }
        return bidIds;
    }



    useEffect(() => {
        if (messages.length === 0) {
            let defaultMsg: MessagesInfo[] = [];
            if (userSessionDetails) {

                var bidQueryParams: BidQueryParam = {};
                var ProjectQueryParams: ProjectQueryParam = {};
                var pagingInfo: PagingInfo = {};
                if (userSessionDetails.role && userSessionDetails.role != null && userSessionDetails.role.name == "customer") {
                    pagingInfo.page = 0;
                    pagingInfo.size = 10;
                    bidQueryParams.customerUid = accessTokenPayload.oid;
                    bidQueryParams.status = "Incart";
                    ProjectQueryParams.customerUid = accessTokenPayload.oid;
                    // ProjectQueryParams.status = "bidReview";
                    ProjectQueryParams.status = [
                        "PO Generation",
                        "Contract Approval",
                        "Contract Upload",
                        "Payment",
                        "Project Active"
                    ];
                };

                const getProjectInfo = async () => {
                    await getProjectInfoByQueryParam(ProjectQueryParams, pagingInfo, accessToken).then(res => {
                        if (res.status === 200) {
                            console.log("projectInfos", res.data);
                            //const bidInfos = res.data;
                            setProjectInfos(res.data);
                            //to prepare chat options with type
                            let projectIds: ChatOptions[];
                            projectIds = getProjectIds(res.data);
                            //bidIds = ["B5752"];
                            defaultMsg = [defaultMessage(userSessionDetails.firstname)];
                            setMessages(defaultMsg);
                            setOptions(projectIds);
                            console.log(projectIds)

                        }
                        else {
                            //var x = false;
                        }
                    });


                }
                getProjectInfo();
            }
        } else {
            let tempArray = [...messages];
            tempArray.push({
                message: props.sendUserResponse,
                sender: "You",
                timestamp: getTimeStamp(),
            });
            setMessages(tempArray);
            setOptions(props.options);
            setTimeout(() => {
                let temp2 = [...tempArray];
                temp2.push({ ...props.botResponse, timestamp: getTimeStamp() });
                setMessages(temp2);
            }, 1000);
        }
        console.log(props.options)
    }, [props.sendUserResponse, props.botResponse]);



    const getTimeStamp = (): string => {
        const now = new Date();
        const hours = now.getHours();
        const minutes = now.getMinutes();
        const period = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

        return `${formattedHours}:${formattedMinutes} ${period}`;
    };

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };


    const setNextStep = async (userResp: any) => {
        await setMessageResponse(userResp);
        //await setBotResponse("XYZ");
        let data = {
            "question": userResp,
            "context": "project",
            "contextId": "tenantId_orderNo"
        }

        botMessage = await postChatBot(data, accessToken);
        console.log("bot response chat", botMessage);
        if (botMessage.ok) {
            setBotResponse(botMessage.data);
            setStep(prevState => prevState + 1);
            // setUserResponse(Response.option);
        }
    };

    const optionClick = (userResp: any) => {
        if (userResp) {
            setUserResponse(userResp);
            setNextStep(userResp);
            const tempArray = messages;
            tempArray.push({
                purpose: "",
                message: userResp,
                sender: "You",
                timestamp: getTimeStamp(),
            });
            console.log("setMessageResponse", tempArray);
            setMessages(tempArray);
        }
    };

    const dummyRef = useRef<HTMLDivElement>(null);


    useEffect(() => {
        dummyRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]); //

    return (
        <Card sx={{ maxWidth: 450 }} className="chatcard-container" style={{ position: 'fixed', bottom: 15, right: 95, zIndex: 1050 }}>
            <CardHeader className="chat-header" style={{ display: 'flex', alignItems: 'center', }}
                avatar={<Box component='img' src={chatlogo} sx={{ width: "30px", height: "30px" }} />}
                title="GMS AI Assistant"
                titleTypographyProps={{ variant: 'h3', color: 'white' }} >
            </CardHeader>
            <CardContent className='chat-msg-container' sx={{ maxHeight: 450, minHeight: 450 }} >
                {messages.map((chat, index) => (
                    <>
                        {chat.sender === 'GMS' ? (
                            <div key={index}>
                                <span
                                    style={{
                                        fontSize: "14px",
                                        color: "grey",
                                        marginRight: "auto",
                                        marginLeft: "12px",
                                    }}
                                >
                                    {chat.sender}
                                </span>
                                <div
                                    className={`message ${chat.sender}`}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: '12px 16px', // Padding for better readability
                                        whiteSpace: 'normal', // Allow text to wrap
                                        wordBreak: 'break-word', // Break long words
                                        backgroundColor: '#6A0DAD', // Purple background for GMS
                                        color: 'white', // White text for better contrast
                                        borderRadius: '12px', // Rounded corners
                                        maxWidth: '80%',
                                        maxHeight: '80%',// Limit width for readability
                                        marginLeft: '12px', // Align GMS response to the left
                                        marginTop: '8px', // Add spacing between messages
                                    }}
                                >
                                    {chat.message}
                                </div>
                                <span
                                    className="timestamp"
                                    style={{
                                        fontSize: "11px",
                                        color: "grey",
                                        marginRight: "auto",
                                        marginLeft: "12px",
                                    }}
                                >
                                    {chat.timestamp}
                                </span>
                            </div>
                        ) : (
                            <div key={index}>
                                <Stack
                                    direction="column"
                                    justifyContent="flex-end"
                                    alignItems="flex-end"
                                    spacing={2}
                                >
                                    <div
                                        className={`message ${chat.sender}`}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            padding: '12px 16px', // Padding for readability
                                            whiteSpace: 'normal', // Allow text to wrap
                                            wordBreak: 'break-word', // Break long words
                                            backgroundColor: '#E0E0E0', // Light gray for user
                                            color: 'black', // Black text for readability
                                            borderRadius: '12px', // Rounded corners
                                            maxWidth: '80%', // Limit width for readability
                                            marginRight: '12px', // Align user response to the right
                                            marginTop: '8px', // Add spacing between messages
                                        }}
                                    >
                                        {chat.message}
                                    </div>
                                    <span
                                        className="timestamp"
                                        style={{
                                            fontSize: "11px",
                                            color: "grey",
                                            marginTop: "0px",
                                            marginRight: '12px',
                                        }}
                                    >
                                        {chat.timestamp}&#10004;&#10004;
                                    </span>
                                </Stack>
                            </div>
                        )}
                    </>
                ))}

                <div ref={dummyRef} className="dummy-div"></div>
            </CardContent>
            <CardActions sx={{ marginBottom: "10px", flexDirection: "column" }}>
                {/* Scrollable container for options */}
                <Box
                    sx={{
                        maxHeight: '120px', // Height for two buttons, adjust as needed
                        overflowY: 'auto', // Enables vertical scrolling
                        width: '100%', // Full width
                        padding: '5px', // Add some padding for a cleaner look
                        '&::-webkit-scrollbar': {
                            width: '8px', // Custom scrollbar width
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: '#888', // Scrollbar thumb color
                            borderRadius: '4px', // Rounded scrollbar
                        },
                        '&::-webkit-scrollbar-thumb:hover': {
                            backgroundColor: '#555', // Scrollbar hover effect
                        },
                        '&::-webkit-scrollbar-track': {
                            backgroundColor: '#f1f1f1', // Scrollbar track color
                        },
                    }}
                >
                    <Stack direction="column" spacing={2} alignItems="stretch">
                        {options.map((option, index) => (
                            <Button
                                key={index}
                                onClick={() => props.optionClick(option)}
                                color="primary"
                                variant="outlined"
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    padding: '12px 16px',
                                    fontSize: '12px',
                                    textAlign: 'center',
                                    whiteSpace: 'normal',
                                    wordBreak: 'break-word',
                                    height: 'auto',
                                    minHeight: '50px',
                                }}
                            >
                                {option.option}

                            </Button>
                        ))}

                    </Stack>
                </Box>

                {/* Message input field and send button */}
                <Stack direction="row" spacing={2} style={{ width: '100%' }}>
                    <TextField
                        fullWidth
                        className="custom-textfield"
                        placeholder="Type a message.."
                        variant="outlined"
                        value={userResponse}
                        onChange={(e) => setUserResponse(e.target.value)}
                        disabled
                    />
                    <Fab
                        color="primary"
                        aria-label="add"
                        className="chat-fabicon"
                        onClick={() => setNextStep(userResponse)}
                        sx={{ height: "50px" }}
                        disabled
                    >
                        <SendIcon />
                    </Fab>
                </Stack>
            </CardActions>




        </Card >
    );
}

export default ChatCard;

