/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 20-Nov-2023
* @author      : Priyankaa.S
* @since       : 0.0.1
* Requirement# :
* Purpose      : Listing of Bid added to Cart
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE         |   AUTHOR          |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 1495           |  25-03-2024    |     Nandha        | Gps next 2 task only one nexttask  is open other one is not open issue
* 1300           |  26-03-2024    |   shiyamkumar     | GPs Task Crew And Foreman Rendering Issue
* 1300           |  18-04-2024    |   shiyamkumar     | Gps Task ClockIn ClockOut Issue Fix
* 1729           |  29/4/24       |   Vanitha         | Gps Task Delay Related ui  changes
* 1300           |  06-05-2024    |   shiyamkumar     | Gps Task ClockIn ClockOut Issue Fix
* 2211           |  07-06-2024    |   shiyamkumar     | Task Rescheduling Api Removed
*/

import * as React from 'react';
import { Box, Button, Card, Dialog, DialogActions, DialogContent, Fab, FormControl, FormControlLabel, Grid, IconButton, LinearProgress, Paper, Radio, RadioGroup, Stack, styled, TextField, Tooltip, Typography } from "@mui/material";
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import { useJsApiLoader } from "@react-google-maps/api";
import { useCallback, useState, useEffect, useRef, useContext } from "react";
import { getAttachTask, getCompletedUnit, getDependentTaskNames, getMyActiveProjectTasks, getMyTask, getPOIdByTaskId, getTaskAdditionalInfo, saveCompletedUnit, updateTaskAndResourceScheduleStatus, verifyCompletedUnit } from '../../services/TaskService';
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import TurnRightIcon from '@mui/icons-material/TurnRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useStopwatch } from "react-timer-hook";
import "../gps/Gps.scss"
import dayjs from 'dayjs';
import { breakIn, clockIn, getTimelogById } from '../../services/TimelogService';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import HourglassDisabledIcon from '@mui/icons-material/HourglassDisabled';
import WarningIcon from '@mui/icons-material/Warning';
import { getUserSession, setCurrentPageDetails, useHandleUnauthorized, UserContext } from '../../hooks/UserSession';
import { TaskContext } from "../../hooks/UserSession";
import { taskReschedule } from '../../services/ProjectService';
import AddTaskForm from '../task/AddTaskForm';
import AddIcon from '@mui/icons-material/Add';
import UploadIcon from '@mui/icons-material/Upload';
import AttachmentIcon from '@mui/icons-material/Attachment';
import AttachDialog from './AttachDialog';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { useLocation, useNavigate } from 'react-router-dom';
import { approveOrRejectPo } from '../../services/PoService';
import { getTenantDetails } from '../../services/TenantService';
import AddTaskDialog from '../bidDetails/AddTaskDialog';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import { actionableTasks, APPROVE_CONTRACT_TASK, APPROVE_INVOICE_TASK, APPROVE_PO_TASK, CREATE_BOM_TASK, mandatoryTaskList, PAYMENT_VERIFICATION_TASK } from '../../utils/TaskConstants';
import SendIcon from '@mui/icons-material/Send';
import TaskWarningDialog from '../task/TaskWarningDialog';
import DocumentUpload from '../tenantconfiguration/tradeconfiguration/addedittemplate/Fileupload/FileUploader';
import FileUpload from '../fileUploader/FileUploader';
import SkipNextOutlinedIcon from '@mui/icons-material/SkipNextOutlined';
import ReasonDialog from './ReasonDialog';
import ContentDialog from '../task/ContentDialog';


const libraries: any = ['places'];
interface GPSProps {
    onDataReady?: (status: string | undefined, taskName: string | undefined, bidId: any | undefined) => void;
    onData?: (coordinates: any, projectId: any) => void;
};


const GPS: React.FC<GPSProps> = ({ onDataReady, onData }) => {

    const { accessToken, accessTokenPayload } = useOidcAccessToken();
    const [taskList, setTaskList] = useState([] as any[]);
    const [data, setData] = useState<any>();
    const [show, setShow] = useState(false);
    const [hide, setHide] = useState(false);
    const [isTimerstarted, setIsTimerstarted] = useState(false);
    const [endseconds, setEndSeconds] = useState<any>();
    const [startseconds, setStartSeconds] = useState<any>();
    const [timelogId, setTimelogId] = useState<any>();
    const [endTime, setEndTime] = useState<any>();
    const [promptData, setPromptData] = useState<any>();
    const { handleCompleted } = React.useContext(TaskContext);
    const [userRole, setUserRole] = React.useState<any>();
    const [completed, setCompleted] = React.useState<any>(false);
    const [completedUnits, setCompletedUnits] = React.useState<any>("0");
    const [completedPer, setCompletedPer] = React.useState<any>();
    const confirmationState = "confirmation";
    const SalespersonRole = "sales";
    const PurchaseOfficerRole = "purchaseOfficer";
    const AdminRole = "admin";
    const active = "active";
    const interval = "break";
    const scheduled = "scheduled";
    const taskName = 'OTP Generation For Confirmation';
    const [openDialogs, setOpenDialogs] = React.useState(false);
    const [buttonClicked, setButtonClicked] = useState(false);
    const { userSessionDetails } = useContext(UserContext);
    const [tenantId, setTenantId] = React.useState<String>();

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [labourCompletedUnits, setLabourCompletedUnits] = React.useState<any>("");
    const [allocatedUnit, setallocatedUnit] = React.useState<any>();
    const [attachData, setAttachData] = useState<any>();
    const [user, setUser] = useState<any>();
    let [taskNames, setTaskNames] = useState<any>();
    const [showWarning, setShowWarning] = useState(false);
    const [completeTaskWarning, setCompleteTaskWarning] = useState(false);
    const [openReceiptUpload, setOpenReceiptUpload] = useState(false);
    const [referenceId, setReferenceId] = React.useState<any>();
    const [errorDialog, setErrorDialog] = React.useState<any>();
    const [errorTitle, setErrorTitle] = React.useState<any>();
    const [errorMessage, setErrorMessage] = React.useState<any>();
    const [openReasonDialog, setOpenReasonDialog] = React.useState<any>(false);
    const location = useLocation();
    const navigate = useNavigate();
    const { handleUnauthorized } = useHandleUnauthorized();
    const [isDisabledCompleteButton, setIsDisabledCompleteButton] = React.useState(false);
    const [dialogContent, setDialogContent] = React.useState('');
    const [showDialogContent, setShowDialogContent] = React.useState(false);
    const [content, setContent] = React.useState('');


    const handleClicked = () => {
        setShow(false);
    };

    const handleOpen = () => {
        setHide(true);
    };

    const handleOpened = () => {
        setShow(true);
    };


    const handleClosed = () => {
        setHide(false);
    };
    const clickAddManual = () => {
        setOpenDialogs(true);
    }

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
        setErrorDialog(false);
        setErrorTitle(undefined);
        setErrorMessage(undefined);
        setCompletedUnits('');
        setCompleted(false);
    };

    const containerStyle = {
        height: '200px'
    };

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyBHfzWHT7qgElExVxQzVRhCJtW1Z6YRILw",
        libraries: libraries
    });

    const [map, setMap] = useState(null);

    const center = {
        lat: -3.745,
        lng: -38.523
    };

    const onLoad = useCallback(function callback(map: any) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);

        setMap(map)
    }, []);

    const onUnmount = useCallback(function callback(map: any) {
        setMap(null)
    }, []);


    const {
        seconds,
        minutes,
        hours,
        isRunning,
        start,
        pause,
        reset
    } = useStopwatch({ autoStart: false });

    // let stopwatchOffset = new Date();
    // stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + 300);
    let hourTime: any = "00";
    let minuteTime: any = "00";
    let secondTime: any = "00";

    if (taskList[0]?.taskStatus === active) {
        if (taskList[0]?.scheduleStatus === active) {
            hourTime = hours < 10 ? `0${hours}` : `${hours}`;
            minuteTime = minutes < 10 ? `0${minutes}` : `${minutes}`;
            secondTime = seconds < 10 ? `0${seconds}` : `${seconds}`;
        }
    }

    const [timelogData, setTimelogData] = useState<any>({
        startTime: undefined,
        endTime: undefined,
        taskUid: undefined,
        status: undefined,
        resourceId: undefined,
        id: undefined,
        taskId: undefined,
        resourceScheduleId: undefined,
        timeLogId: undefined,
        completedPercent: undefined,
    });

    const clockData = useRef<any>({
        startTime: undefined,
        endTime: undefined,
        taskUid: undefined,
        status: undefined,
        resourceId: undefined,
        id: undefined,
        taskId: undefined,
        resourceScheduleId: undefined,
        timeLogId: undefined,
        completedPercent: undefined,
    });


    const convertDecimalToTime = (decimalHours) => {
        const hours = Math.floor(decimalHours);
        const minutesDecimal = (decimalHours - hours) * 60;
        const minutes = Math.floor(minutesDecimal);
        const seconds = Math.floor((minutesDecimal - minutes) * 60);
        const endTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
        //convertTimeToDecimal(hours, minutes, seconds);
        convertTimeToSeconds(endTime);
        return endTime;
    };

    let runTime: any;
    // let endseconds: any ;

    // let startseconds: number ;
    const convertTimeToSeconds = (endTime: any) => {

        var startTime = endTime.split(":");

        const hrs = parseInt(startTime[0]);
        const min = parseInt(startTime[1]);
        const sec = parseInt(startTime[2]);

        // Calculate the total seconds
        setEndSeconds(hrs * 3600 + min * 60 + sec);
        setStartSeconds(parseInt(hourTime) * 3600 + parseInt(minuteTime) * 60 + parseInt(secondTime));

        const totalseconds = (endseconds - startseconds);

        const currentSec = (totalseconds % 60);
        const currentMin = Math.floor((totalseconds % 3600) / 60);
        const currentHr = Math.floor(totalseconds / 3600);

        const totalSec = currentSec < 10 ? `0${currentSec}` : `${currentSec}`;
        const totalMin = currentMin < 10 ? `0${currentMin}` : `${currentMin}`;
        const totalHr = currentHr < 10 ? `0${currentHr}` : `${currentHr}`;

        runTime = `${String(totalHr).padStart(2, '0')}:${String(totalMin).padStart(2, '0')}:${String(totalSec).padStart(2, '0')}`

        return runTime;
    };

    const timerOn = async () => {
        if (clockData.current.status === scheduled) {
            //Date format hardcoded here bcoz it should match backend date format
            setTimelogData({ ...timelogData, startTime: dayjs().format('YYYY-MM-DD HH:mm:ss').toString() })
            clockData.current.startTime = dayjs().format('YYYY-MM-DD HH:mm:ss').toString();
            // const result = await dateComparator();
            // if (new Date(new Date().setSeconds(0)) !== new Date((taskList[0]?.startTime).replace(/Z/g, ''))) {
            //stringdate = new Date(date).toISOString();
            // tasks[0].startTime = dateFormatter(new Date(new Date().setSeconds(0)));
            // tasks[0].endTime = dateFormatter(new Date(new Date().setSeconds(0)).setHours(new Date(new Date().setSeconds(0)).getHours() + tasks[0].estimatedHours));
            // setTaskList(tasks);
            const wbs = (clockData.current.wbs).split(".")
            wbs.pop();
            // const result = await taskReschedule(clockData.current.projectId, clockData.current.taskId, wbs.join("."), dateFormatter(new Date()), accessToken);
            var newTasks: any;

            if (SalespersonRole === userRole) {
                newTasks = await getMyTask(accessToken);
            }
            else if (PurchaseOfficerRole === userRole || AdminRole === userRole) {
                newTasks = await getMyTask(accessToken);
            } else {
                newTasks = await getMyActiveProjectTasks(accessToken);
            }

            console.log(" after NEW TASKS", newTasks);
            clockData.current.taskUid = newTasks.data[0].taskUid;
            clockData.current.status = newTasks.data[0].scheduleStatus;
            clockData.current.resourceId = newTasks.data[0].resourceId;
            clockData.current.id = newTasks.data[0].id;
            clockData.current.taskId = newTasks.data[0].taskId;
            clockData.current.resourceScheduleId = newTasks.data[0].scheduleId;
            clockData.current.wbs = newTasks.data[0].wbs;
            clockData.current.dependencies = newTasks.data[0].dependencies;
            const response = await clockIn(clockData.current, accessToken);
            console.log(response);
            setData(taskList[0]);
            if (response.status === 201 && response.data !== null) {
                if (response.data.responseCode === 200 && response.data.status === true) {
                    clockData.current.status = "active"
                    setIsTimerstarted(true);
                    reset();
                    setHide(false);

                    var response1: any;
                    if (SalespersonRole === userRole) {
                        response1 = await getMyTask(accessToken);
                    }
                    else if (PurchaseOfficerRole === userRole || AdminRole === userRole) {
                        response1 = await getMyTask(accessToken);
                    } else {
                        response1 = await getMyActiveProjectTasks(accessToken);
                    }
                    if (response1.data !== undefined || null) {
                        setTaskList(response1.data);
                        setTimelogData({
                            ...timelogData,
                            taskUid: response1.data[0].taskUid,
                            status: response1.data[0].scheduleStatus,
                            resourceId: response1.data[0].resourceId,
                            Id: response1.data[0].id,
                            taskId: response1.data[0].taskId,
                            resourceScheduleId: response1.data[0].scheduleId,
                            projectId: response1.data[0].projectId,
                            tasktype: response1.data[0].tasktype,
                            completedPercent: undefined
                        })
                        if (response1.data[0]?.scheduleStatus === active) {
                            const res = await getTimelogById(response1.data[0].scheduleId, accessToken);
                            clockData.current.timeLogId = res?.data?.id;
                            setData(res?.data);
                        }
                        convertDecimalToTime(taskList[0]?.estimatedHours)
                        const jobNo = taskList[0].jobNo;
                        if (response1.data[0].taskname === taskName) {
                            onDataReady?.(active, taskName, jobNo);
                        }
                    }
                } else if (response.data.responseCode === 200 && response?.data?.message === "Task Already Clocked In") {
                    setContent("This task is already clocked In")
                    setShowDialogContent(true);
                }
                else if (response.data.responseCode === 200) {
                    setTaskNames((response?.data?.message).split(";"));
                    setCompleteTaskWarning(false);
                    setShowWarning(true);
                }
                else if (response.data.responseCode === 500) {
                    setErrorDialog(true);
                    setErrorTitle("Unable To Clock In This Task");
                    setErrorMessage(response.data.message);
                }
            }
        } else {
            const res = await getTimelogById(clockData.current.resourceScheduleId, accessToken);
            const diff = calculateTime(res, "resume", res?.data?.startTime.replace("Z", ""));
            const responses = await breakIn(clockData.current.timeLogId, accessToken);
            if (responses.status === 200 || responses.status === 201) {
                reset(diff)
                setIsTimerstarted(true);
                setTimelogId(res?.data?.id);
            }
        }
    };

    const handleSubmit = async (taskType: any) => {
        setOpen(false);
        //Date format hardcoded here bcoz it should match backend date format
        clockData.current.endTime = dayjs().format('YYYY-MM-DD HH:mm:ss').toString();
        clockData.current.status = "completed";
        clockData.current.taskType = taskType;
        setEndTime(new Date().toISOString());
        console.log("clockData", clockData.current);
        const response = await clockIn(clockData.current, accessToken);
        console.log("clock out response", response);
        if (response.status === 201 && response.data !== null) {
            setIsDisabledCompleteButton(false);
            if (response.data.responseCode === 200 && response.data.status === true && response.data.message === "complete successful") {
                setShow(false);
                setHide(false);
                var responses: any;
                if (SalespersonRole === userRole) {
                    responses = await getMyTask(accessToken);
                }
                else if (PurchaseOfficerRole === userRole || AdminRole === userRole) {
                    responses = await getMyTask(accessToken);
                } else {
                    responses = await getMyActiveProjectTasks(accessToken);
                }
                // console.log("HANDLER",responses);
                if (responses.data.length !== 0) {
                    // reset();
                    // pause();
                    const tasks = responses.data;
                    setTaskList(tasks);
                    clockData.current.taskUid = responses.data[0].taskUid;
                    clockData.current.status = responses.data[0].scheduleStatus;
                    clockData.current.resourceId = responses.data[0].resourceId;
                    clockData.current.id = responses.data[0].id;
                    clockData.current.taskId = responses.data[0].taskId;
                    clockData.current.resourceScheduleId = responses.data[0].scheduleId;
                    clockData.current.wbs = responses.data[0].wbs;
                    clockData.current.timeLogId = undefined;
                    if (new Date(responses.data[0].startTime).setHours(0, 0, 0, 0) <= new Date().setHours(0, 0, 0, 0)) {
                        if (!actionableTasks.includes(responses.data[0].taskType) && (responses.data[0]?.taskAction !== "" && JSON.parse(responses.data[0]?.taskAction)?.find((a: any) => (a.action !== "Confirm/Reject" || a.action !== "Upload")))) {
                            timerOn();
                        }
                    } else {
                        reset();
                        pause();
                        setIsTimerstarted(false);
                    }
                } else {
                    setTaskList([]);
                }
                // reset();
                // pause();
                // setToShow(true);
            }
            else if (response.data.responseCode === 200 && response.data.message === "Task Not Completed") {
                setIsDisabledCompleteButton(false);
                setCompleteTaskWarning(true);
                setShowWarning(true);
            }
            else if (response.data.responseCode === 200 && response?.data?.message === "Task Already Completed") {
                setContent("This task has already been Completed")
                setShowDialogContent(true);
            }
            else if (response.data.responseCode === 500) {
                setIsDisabledCompleteButton(false);
                setErrorDialog(true);
                setErrorTitle("Unable To Clock Out This Task");
                setErrorMessage(response.data.message);
            }
        } else {
            setIsTimerstarted(true);
            setIsDisabledCompleteButton(false);
        }
        setCompletedUnits('');

    };


    const stopTimer = async () => {
        let responses = await breakIn(clockData.current.timeLogId, accessToken);
        if (responses.status === 200 || responses.status === 201) {
            setIsTimerstarted(false);
            pause();
            setHide(false);
            handleClose();
        }
    }

    const timerOff = async () => {
        const response = await getMyTask(accessToken);

        // if (data?.timelog?.status === "break") {
        if (taskList[0].queries !== undefined && taskList[0].queries !== "null" && taskList[0].queries !== '' && taskList[0].taskType !== confirmationState && taskList[0].queries !== null && userSessionDetails?.role.name !== "foreman") {
            var object: Object = String(taskList[0].queries).toString()
            const obj = JSON.parse(taskList[0].queries);
            Object.keys(obj).forEach((key) => {
                if (userRole === key) {
                    setPromptData(obj[key]);
                    setOpen(true);
                }
            })

            if (userSessionDetails?.role.name === "foreman") {
                await getCompletedUnit(response?.data[0]?.id, accessToken).then((res) => {
                    console.log(res.data);
                    setLabourCompletedUnits(res.data);
                });
            }
            const taskAdditionalInfo = await getTaskAdditionalInfo(taskList[0]?.id, accessToken);
            setallocatedUnit(taskAdditionalInfo?.data?.value);
            // setCurrentUser();
            // setOpen(true);
        } else {
            stopTimer();
        }

    };

    const handleAddTaskDialog = () => {
        setOpenDialogs(false);
        fetchData();
    }

    const timerStop = async () => {
        setIsDisabledCompleteButton(true);
        setButtonClicked(prev => !prev);
        setIsTimerstarted(true);
        handleCompleted();
        // if (taskList[0]?.taskType === "Verify Task") {
        //     const res = await verifyCompletedUnit(taskList[0]?.id, accessToken);
        //     console.log(res);
        //     if (res.data === false) {
        //         alert("Task is not yet Completed please complete the task");
        //     }
        // }
        // else {
        handleSubmit(taskList[0]?.taskType);
        // }
        setButtonClicked(prev => !prev);
        // }
    };

    const updatePOStatus = async (taskId: any, referenceId: any, status: any) => {
        console.log("updatePOStatus", taskId, status, referenceId);
        const res = await getPOIdByTaskId(taskId, accessToken);
        if (res.data !== null) {
            let result = await approveOrRejectPo(tenantId, res.data, status, accessToken);
            if (result.status === 200 || result.status === 201) {
                var response1: any;
                if (SalespersonRole === userRole) {
                    response1 = await getMyTask(accessToken);
                }
                else if (PurchaseOfficerRole === userRole || AdminRole === userRole) {
                    response1 = await getMyTask(accessToken);
                } else {
                    response1 = await getMyActiveProjectTasks(accessToken);
                }
                if (response1.data !== undefined || null) {
                    setTaskList(response1.data);
                }
            }
        }
    }


    const fetchData = async () => {
        setUserRole(userSessionDetails?.role.name);
        setUser(userSessionDetails);
        const result = await getTenantDetails(accessToken);
        handleUnauthorized(result);
        setTenantId(result.data.tenantid);
        var response: any;
        if (SalespersonRole === userSessionDetails?.role.name) {
            response = await getMyTask(accessToken);
            handleUnauthorized(response);

        }
        else if (PurchaseOfficerRole === userSessionDetails?.role.name || AdminRole === userSessionDetails?.role.name) {
            response = await getMyTask(accessToken);
            handleUnauthorized(response);
        } else {
            response = await getMyActiveProjectTasks(accessToken);
            handleUnauthorized(response);
        }
        if (response?.data?.length !== 0) {
            clockData.current.taskUid = response.data[0].taskUid;
            clockData.current.status = response.data[0].scheduleStatus;
            clockData.current.resourceId = response.data[0].resourceId;
            clockData.current.id = response.data[0].id;
            clockData.current.taskId = response.data[0].taskId;
            clockData.current.projectId = response.data[0].projectId;
            clockData.current.resourceScheduleId = response.data[0].scheduleId;
            clockData.current.wbs = response.data[0].wbs;
            setTaskList(response.data);
            onData?.(response.data[0]?.location, response.data[0]?.projectId);

            const res = await getTimelogById(response.data[0].scheduleId, accessToken);

            if (response.data[0]?.scheduleStatus === active) {
                if (response.data[0].taskname === taskName) {
                    onDataReady?.(active, taskName, response.data[0]?.jobNo);
                }
                clockData.current.timeLogId = res?.data?.id
                if (res?.data?.status === interval) {
                    setIsTimerstarted(false);
                    const diff = calculateTime(res, "resume", res?.data?.startTime.replace("Z", ""));
                    reset(diff)
                    pause()
                } else {
                    const diff = calculateTime(res, undefined, res?.data?.startTime.replace("Z", ""));
                    reset(diff)
                    setIsTimerstarted(true);
                    setTimelogId(res?.data?.id);
                    if (response.status === 201 && taskList[0]?.taskType === confirmationState) {
                        const jobNo = taskList[0].jobNo;
                        if (taskList[0]?.taskname === taskName) {
                            onDataReady?.(active, taskName, jobNo);

                        }
                        setData(res?.data);
                    }
                }
            }
            convertDecimalToTime(taskList[0]?.estimatedHours)
        }
    }

    const calculateTime = (timelog: any, status: any, endTime: any) => {

        let text = timelog?.data?.breakTimes;
        const myArray = text?.replace(/[\[\]']+/g, '').replace(/},{/g, "};{").split(";");

        var breakTimes = 0

        if (myArray !== undefined) {
            for (let i = 0; i < myArray?.length - 1; i++) {
                if (JSON.parse(myArray[i]).endTime !== undefined) {
                    breakTimes += new Date(JSON.parse(myArray[i]).endTime).getTime() - new Date(JSON.parse(myArray[i]).startTime).getTime();
                }
            }
        }

        var diff = ((status === "resume" ? new Date(JSON.parse(myArray[myArray?.length - 1])?.startTime).getTime() : new Date().getTime()) - new Date(endTime).getTime()) - breakTimes;
        var diffSeconds = diff / 1000 % 60;
        var diffMinutes = diff / (60 * 1000) % 60;
        var diffHours = diff / (60 * 60 * 1000) % 60;
        const date = new Date();
        date.setSeconds(date.getSeconds() + diffSeconds);
        date.setMinutes(date.getMinutes() + diffMinutes);
        date.setHours(date.getHours() + diffHours);

        return date;
    };



    const handleCloseDialog = () => {
        setOpenReceiptUpload(false);
    };

    useEffect(() => {
        fetchData();
    }, [buttonClicked]);



    function convertEstimateHrsToTimes(task: any) {
        var remainingSeconds = Math.max((task.estimatedHours * 3600) - ((hours * 3600) + (minutes * 60) + clampedSeconds));
        var remainingHours = Math.floor(remainingSeconds / 3600);
        var remainingMinutes = Math.floor((remainingSeconds % 3600) / 60);

        return (
            <span>
                <span>{remainingHours < 0 ? "00" : remainingHours < 10 ? '0' + remainingHours : remainingHours}</span><span className={isTimerstarted ? 'timeProgress' : 'timeSeprator'}>:</span>
                <span>{remainingHours < 0 ? "00" : remainingMinutes < 10 ? '0' + remainingMinutes : remainingMinutes}</span>
            </span>
        );
    };

    // Example usage:


    const convertLocation = (locationString: any) => {
        let locationObject: any = {}
        let string = locationString;
        let st = string.split(",");
        locationObject['lat'] = Number(st[0]);
        locationObject['lng'] = Number(st[1]);
        return locationObject;
    };

    function per(num: any, amount: any) {
        return num * amount / 100;
    };

    const handleInput = (e: any) => {

        setCompletedPer(per(e.target.value, taskList[0].measurement));

        const enteredValue = parseFloat(e.target.value);
        if (enteredValue >= 0 && enteredValue <= allocatedUnit) {
            setCompletedUnits(e.target.value);
            saveCompletedUnit(taskList[0].id, taskList[0].resourceId, enteredValue, accessToken);
        };

        if (e.target.value !== '') {
            setCompleted(true);
        }
        else {
            setCompleted(false);
        }
    };

    let totalSeconds = 0;
    let clampedSeconds = 0;
    let currentseconds = 0;


    const calculateProgress = (task: any) => {
        var percent = 0;
        if (task.scheduleStatus === active) {
            totalSeconds = (task.estimatedHours) * 3600;
            clampedSeconds = secondTime % 60;
            currentseconds = (hours * 3600) + (minutes * 60) + clampedSeconds;
            percent = (currentseconds / totalSeconds) * 100
        }
        return percent
    }

    const [file, setFile] = useState<any>();

    const upload = async (projectId: any, dependencies: any) => {
        if (projectId != null) {
            if (dependencies !== "") {
                console.log(dependencies);
                let isPreviousTaskCompleted = await checkDependentTaskCompleted(projectId, dependencies);
                if (isPreviousTaskCompleted) {
                    setIsDialogOpen(true);
                }
                else {
                    setIsDialogOpen(false);
                }
            }
            else {
                setIsDialogOpen(true);
            }

        }
    }

    const closeDialog = () => {
        setIsDialogOpen(false);
    };

    const attach = () => {
        getAttachTask(taskList[0]?.id, accessToken).then((res) => {
            console.log(res.data);
            if (res.data !== null) {
                if (res.data.referenceType === "Uploaded") {
                    setIsOpen(true);
                }
                else if (res.data.referenceType === "purchaseOrder") {
                    navigate("/podetails", { state: { projectId: taskList[0].projectId, purchaseId: res?.data?.referenceId, projectView: true } });
                }
                else {
                    navigate("/soldproduction/projectinformation/invoiceview", { state: { rowData: res?.data?.referenceId, role: userRole, projectView: true } });
                }
            }
            else {
                // const url = NavigateLink();
            }
        });
    }

    let receipt: any;
    const onFileUpload = async (file: File) => {
        const reader = new FileReader();
        // Define a callback function to handle the file reading completion
        reader.onload = (event) => {
            if (event.target?.result) {
                // Access the result property of the FileReader object to obtain the file data
                const fileData = event.target.result;
                // You can now use fileData as an object or process it further
                receipt = fileData;
                setFile(receipt);
            }
        };
        // Start reading the file as text
        reader.readAsText(file);
    };

    const addContract = async (project: any, dependencies: any) => {
        if (project !== null) {
            if (dependencies !== "") {
                let isDependentTaskCompleted = await checkDependentTaskCompleted(project, dependencies);
                if (isDependentTaskCompleted) {
                    navigate("/contract", {
                        state: { project } // Pass projectDTO as state to the /contract route
                    });
                }
                else {
                    setCompleteTaskWarning(false);
                    setShowWarning(true);
                }
            }
            else {
                navigate("/contract", {
                    state: { project } // Pass projectDTO as state to the /contract route
                });
            }
        }

    }

    const sendInvoice = async (invoiceId: any, projectId: any, dependencies: any) => {
        if (projectId !== null) {
            if (dependencies !== "") {
                let isDependentTaskCompleted = await checkDependentTaskCompleted(projectId, dependencies);
                if (isDependentTaskCompleted) {
                    let rowData = {};
                    rowData["id"] = invoiceId;
                    rowData["projectId"] = projectId;
                    navigate("/soldproduction/projectinformation/invoiceview", { state: { rowData } });
                }
                else {
                    setCompleteTaskWarning(false);
                    setShowWarning(true);

                }
            }
            else {
                let rowData = {};
                rowData["id"] = invoiceId;
                rowData["projectId"] = projectId;
                navigate("/soldproduction/projectinformation/invoiceview", { state: { rowData } });
            }
        }
    }

    const sendPO = async (purchaseId: any, projectId, dependencies: any) => {
        setCurrentPageDetails(location.pathname, undefined, undefined);
        if (projectId !== null) {
            if (dependencies !== "") {
                let isDependentTaskCompleted = await checkDependentTaskCompleted(projectId, dependencies);
                console.log(isDependentTaskCompleted);
                if (isDependentTaskCompleted) {
                    navigate("/podetails", { state: { purchaseId, projectId } });
                }
                else {
                    setShowWarning(true);
                    setCompleteTaskWarning(false);
                }
            }
            else {
                navigate("/podetails", { state: { purchaseId, projectId } });
            }

        }
    }

    const uploadReceipt = async (rowData: any, projectId: any, dependencies: any) => {
        if (projectId != null) {
            if (dependencies !== "") {
                let isPreviousTaskCompleted = await checkDependentTaskCompleted(projectId, dependencies);
                if (isPreviousTaskCompleted) {
                    let object = {};
                    object["id"] = rowData;
                    setReferenceId(object);
                    setOpenReceiptUpload(true);
                }
                else {
                    setShowWarning(true);
                    setCompleteTaskWarning(false);
                }
            }
            else {
                let object = {};
                object["id"] = rowData;
                setReferenceId(object);
                setOpenReceiptUpload(true);
            }
        }
    }

    const checkDependentTaskCompleted = async (projectId: any, dependencies: any) => {
        let result = await getDependentTaskNames(projectId, dependencies.split(";"), accessToken);
        if (result.status === 200 || result.status === 201) {
            if ((result.data).length !== 0) {
                setTaskNames(result?.data);
                setShowWarning(true);
                return false;
            }
            else {
                setShowWarning(false);
                setCompleteTaskWarning(false);
                return true;
            }
        }
    }

    const skipTask = async (projectId: any, dependencies: any) => {
        if (projectId != null) {
            if (dependencies !== "") {
                let isPreviousTaskCompleted = await checkDependentTaskCompleted(projectId, dependencies);
                if (isPreviousTaskCompleted) {
                    setOpenReasonDialog(true);
                }
                else {
                    setShowWarning(true);
                    setCompleteTaskWarning(false);
                }
            }
            else {
                setOpenReasonDialog(true);
            }
        }
    }

    return (
        <>
            <Grid container direction='column' >
                <Grid item md={12}>
                    <Stack direction="row" spacing={1} justifyContent="space-between">
                        <AccessTimeFilledIcon sx={{ color: "#3c3c3cde" }} />
                        <Typography variant="h4" sx={{ fontWeight: "500", color: "#3c3c3cde" }}>GPS</Typography>

                        <Grid container item textAlign={"end"}>
                            <Grid item xs={12}>
                                <Typography variant="h5" sx={{ fontWeight: "500", color: "#3c3c3cde" }}>
                                    {dayjs().format("DD MMM YYYY")}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5" sx={{ fontWeight: "500", color: "#3c3c3cde" }}>
                                    {dayjs().format("HH:mm A")}
                                </Typography>
                            </Grid>
                        </Grid>

                    </Stack>
                </Grid>




            </Grid>
            <Box mt={2} />

            <Grid item  >

                {/* <Weathercard/> */}
            </Grid>

            <Box mt={2} />
            {/* <Grid item minHeight={200}>
                <Card>

                    {isLoaded && taskList.length !== 0 ? (
                        <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={convertLocation(taskList[0]?.location)}
                            zoom={50}
                            onUnmount={onUnmount}
                            options={{
                                zoomControl: false,
                                streetViewControl: false,
                                mapTypeControl: false,
                                fullscreenControl: false,
                            }}
                        >
                            <MarkerF
                                position={convertLocation(taskList[0]?.location)}

                            />
                            <></>
                        </GoogleMap>
                    ) : <></>}
                </Card>
            </Grid> */}

            {taskList.length === 0 ?

                <Box justifyContent={'center'} textAlign={'center'} >
                    <Card className='timeclockcard p-4'>
                        <Stack direction={"row"} spacing={1}>

                            <TurnRightIcon sx={{ fontSize: "40px" }} />
                            <Typography variant='h4' sx={{ color: "white", marginTop: "6px !important" }} textAlign={"center"}> No Task Available</Typography>
                        </Stack>
                    </Card>
                </Box>
                :
                <>
                    <Grid item mt={1}>

                        <Card className='timeclockcard p-4'>



                            <Grid xs={12}>
                                <Stack direction={'row'} justifyContent="space-between">
                                    <Stack direction={"row"} sx={{ justifyContent: 'start', marginBottom: '1vh' }}>
                                        <TurnRightIcon sx={{ fontSize: "40px", marginRight: '1vh', flexShrink: 0, fontWeight: 'bold' }} />
                                        <Typography variant='h4' sx={{ flexShrink: 0, fontWeight: 'bold', color: "white", marginTop: "6px !important" }} textAlign={"start"}> Active Task</Typography>
                                    </Stack>
                                    {/* <Button
                                        className="dangerButtons"
                                        onClick={() => skipTask(taskList[0]?.projectId, taskList[0]?.dependencies)}
                                        startIcon={<SkipNextOutlinedIcon />}>
                                        Skip
                                    </Button> */}
                                    <Stack direction={"row"} spacing={1}>
                                        <Tooltip title="Skip Task">
                                            <Fab color="error" size="small" aria-label="add" onClick={() => skipTask(taskList[0]?.projectId, taskList[0]?.dependencies)}
                                                disabled={taskList[0]?.scheduleStatus === 'active' || mandatoryTaskList.includes(taskList[0]?.taskType) ? true : false}>
                                                <SkipNextOutlinedIcon />
                                            </Fab>
                                        </Tooltip>
                                        <Tooltip title="Add Task">
                                            <Fab color="primary" size="small" aria-label="add" onClick={clickAddManual}>
                                                <AddIcon />

                                            </Fab>
                                        </Tooltip>
                                    </Stack>
                                </Stack>
                            </Grid>
                            {calculateProgress(taskList[0]) >= 100 && (

                                <Grid xs={12} sx={{ display: 'flex', marginLeft: '2vh', justifyContent: 'flex-end', marginBottom: '1vh' }}>


                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            alignItems: 'center',
                                            backgroundColor: 'red',
                                            color: 'white',
                                            borderRadius: '4px',
                                            padding: '6px',
                                            overflow: 'hidden',

                                            height: '3vh',
                                            fontSize: { xs: '0.75rem', sm: '0.775rem', md: '.7rem' },
                                            marginTop: '1vh'

                                        }}
                                    >

                                        <WarningIcon className='timeProgress ' style={{ fontSize: '.7rem' }} />
                                        <Typography variant="body2" className='timeDelay' sx={{ flexShrink: 0, fontWeight: 'bold', fontSize: 'inherit', }}>
                                            Task delayed
                                        </Typography>
                                    </Box></Grid>
                            )}




                            <Typography className='taskhead'>{taskList[0]?.taskname}</Typography>
                            <br />
                            <div style={{ flexDirection: "row", position: "relative", alignItems: "center" }}>
                                <LinearProgress
                                    variant="determinate"
                                    color={calculateProgress(taskList[0]) >= 100 ? "error" : "inherit"}
                                    sx={{ width: "100%", borderRadius: "4px", backgroundColor: calculateProgress(taskList[0]) === 100 ? "red" : "" }}
                                    value={Math.min(calculateProgress(taskList[0]), 100)}
                                />
                                <img
                                    src={require("../../assets/images/carIconTimeLog.png")}
                                    alt=""
                                    className="progressbar"
                                    style={{
                                        position: "absolute",
                                        left: `${Math.min(calculateProgress(taskList[0]), 94)}%`,
                                        bottom: -6,
                                        transform: "translateX(-50%)",
                                        marginLeft: "11px",

                                    }}
                                />
                            </div>
                            <Grid container direction="row" mt={1}>
                                <Grid item xs={6} >
                                    <Stack direction="row">
                                        <span>{hours}</span><Stack className={isTimerstarted ? 'timeProgress' : 'timeSeprator'}>:</Stack><span>{minutes < 10 ? `0${minutes}` : `${minutes}`}</span>
                                    </Stack>
                                </Grid>
                                <Grid item xs={6} className='alignEnd'>
                                    {convertEstimateHrsToTimes(taskList[0])}
                                </Grid>
                            </Grid>
                            <Typography fontSize={16}></Typography>
                            <Grid container direction='row' mt={2}>
                                {/* <Grid xs={percent >= 100 ? 12 : 12}> */}
                                <Grid container direction='row' alignItems='center'>



                                    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                        <Grid item xs={6}>

                                            <Typography fontSize={16}>
                                                Estimated Time
                                            </Typography>

                                        </Grid>
                                        <Grid item xs={6}>
                                            <Stack direction="row" >
                                                <Typography

                                                    sx={{
                                                        textAlign: "end",
                                                        flexShrink: 0, fontWeight: 'bold',
                                                        textOverflow: 'ellipsis',
                                                        marginLeft: '5px'
                                                    }}>
                                                    {taskList[0]?.estimatedHours} Hrs
                                                </Typography>

                                            </Stack>

                                        </Grid>
                                    </Grid>
                                    <Grid container direction='row' >
                                        <Grid xs={6}>
                                            <Typography fontSize={16}>Task Start Date </Typography>
                                        </Grid>
                                        <Grid xs={6}>
                                            <Typography sx={{ flexShrink: 0, fontWeight: 'bold' }} paddingLeft={2}>{dayjs((taskList[0]?.startTime).replace(/Z/g, '')).format('DD MMM YYYY h:mm A')}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction='row' >
                                        <Grid xs={6}>
                                            <Typography fontSize={16}>Estimated End Date </Typography>
                                        </Grid>
                                        <Grid xs={6}>
                                            <Typography sx={{ flexShrink: 0, fontWeight: 'bold' }} paddingLeft={2}>{dayjs((taskList[0]?.endTime).replace(/Z/g, '')).format('DD MMM YYYY h:mm A')}</Typography>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>

                            <Grid container direction='row' mt={1}>
                                <Grid xs={12}>
                                    <Typography variant='h5' sx={{ marginBottom: '1vh', flexShrink: 0, fontWeight: 'bold', color: "white", marginTop: "6px !important" }} textAlign={"start"}>Project Name</Typography>

                                </Grid>


                                <Grid> <Stack spacing={1}>
                                    <Typography>{taskList[0]?.description}</Typography>

                                    <Stack direction='row'>
                                        <Typography >Order No:<p style={{ flexShrink: 0, fontWeight: 'bold' }}></p> </Typography>
                                        <Typography sx={{ flexShrink: 0, fontWeight: 'bold' }}>{taskList[0]?.jobNo}
                                        </Typography>
                                    </Stack>
                                    {taskList[0]?.taskType === "Verify Task" && <Typography>Completed Unit of labour: {labourCompletedUnits} {taskList[0]?.taskCompletedUnits} </Typography>}
                                </Stack>
                                    {/* {(taskList[0].taskAction !== "") ? ((JSON.parse(taskList[0]?.taskAction)?.find((a: any) => a.action === "Upload")) ? <Button className="primaryTonalButtons" onClick={upload} startIcon={<UploadIcon />}>Upload</Button> : <></>) : null} */}
                                    {(taskList[0].taskAction !== "") ? ((JSON.parse(taskList[0]?.taskAction)?.find((a: any) => a.action === "Attach")) ? <Button className="primaryTonalButtons" onClick={attach} startIcon={<AttachmentIcon />}>Attach</Button> : <></>) : null}
                                    {isDialogOpen && <DocumentUpload open={isDialogOpen} props={taskList[0]} onClose={closeDialog} onFileUpload={onFileUpload} />}
                                    {isOpen && <AttachDialog open={isOpen} props={taskList[0]} onClose={closeDialog} onFileUpload={onFileUpload} />}
                                </Grid>
                            </Grid>
                            <br />
                            <Grid container >
                                {(taskList[0].taskAction !== "" && JSON.parse(taskList[0]?.taskAction)?.find((a: any) => a.action === "Confirm/Reject"))
                                    ?
                                    <Grid item md={12} sx={{ textAlign: "center" }} >
                                        <Stack spacing={1} direction="row"  >
                                            <Button
                                                className='sucessButtons'
                                                onClick={() => updatePOStatus(taskList[0]?.id, taskList[0]?.referenceId, "Approved")}
                                                startIcon={<DoneIcon />}
                                                disabled={isTimerstarted}
                                            >
                                                Confirm
                                            </Button>
                                            <Button
                                                className='sucessButtons'
                                                onClick={() => updatePOStatus(taskList[0]?.id, taskList[0]?.referenceId, "Rejected")}
                                                startIcon={<CloseIcon />}
                                            >
                                                Reject
                                            </Button>
                                        </Stack>
                                    </Grid>
                                    :
                                    (taskList[0].taskType !== "" && taskList[0].taskType === APPROVE_CONTRACT_TASK)
                                        ?
                                        <Grid item md={12} sx={{ textAlign: "center" }} >
                                            <Stack spacing={1} direction="row"  >
                                                <Button
                                                    variant="contained"
                                                    // size="small"
                                                    className='primarySolid'
                                                    onClick={() => addContract(taskList[0]?.projectId, taskList[0]?.dependencies)}
                                                    startIcon={<ArticleOutlinedIcon />}
                                                    disabled={isTimerstarted}
                                                >
                                                    Contract
                                                </Button>
                                            </Stack>
                                        </Grid>
                                        :
                                        (taskList[0].taskAction !== "" && JSON.parse(taskList[0]?.taskAction)?.find((a: any) => a.action === "Upload"))
                                            ?
                                            <Grid item md={12} sx={{ textAlign: "center" }} >
                                                <Stack spacing={1} direction="row"  >
                                                    <Button
                                                        className="primaryTonalButtons"
                                                        onClick={() => upload(taskList[0]?.projectId, taskList[0]?.dependencies)}
                                                        startIcon={<UploadIcon />}>
                                                        Upload
                                                    </Button>
                                                    {/* <Button
                                                        className="dangerButtons"
                                                        onClick={() => skipTask(taskList[0]?.projectId, taskList[0]?.dependencies)}
                                                        startIcon={<SkipNextOutlinedIcon />}>
                                                        Skip
                                                    </Button> */}
                                                </Stack>
                                            </Grid>
                                            :
                                            (taskList[0].taskType !== "" && taskList[0].taskType === APPROVE_INVOICE_TASK)
                                                ?
                                                <Grid item md={12} sx={{ textAlign: "center" }} >
                                                    <Stack spacing={1} direction="row"  >
                                                        <Button
                                                            variant="contained"
                                                            // size="small"
                                                            className='primarySolid'
                                                            onClick={() => sendInvoice(taskList[0]?.referenceId, taskList[0]?.projectId, taskList[0]?.dependencies)}
                                                            startIcon={<SendIcon />}
                                                            disabled={isTimerstarted}
                                                        >
                                                            Send Invoice
                                                        </Button>
                                                    </Stack>
                                                </Grid>
                                                :
                                                (taskList[0].taskType !== "" && taskList[0].taskType === APPROVE_PO_TASK)
                                                    ?
                                                    <Grid item md={12} sx={{ textAlign: "center" }} >
                                                        <Stack spacing={1} direction="row"  >
                                                            <Button
                                                                variant="contained"
                                                                // size="small"
                                                                className='primarySolid'
                                                                onClick={() => sendPO(taskList[0]?.referenceId, taskList[0]?.projectId, taskList[0]?.dependencies)}
                                                                startIcon={<SendIcon />}
                                                                disabled={isTimerstarted}
                                                            >
                                                                Send PO
                                                            </Button>
                                                        </Stack>
                                                    </Grid>
                                                    :
                                                    (taskList[0].taskType !== "" && taskList[0].taskType === PAYMENT_VERIFICATION_TASK)
                                                        ?
                                                        <Grid item md={12} sx={{ textAlign: "center" }} >
                                                            <Stack spacing={1} direction="row"  >
                                                                <Button
                                                                    variant="contained"
                                                                    // size="small"
                                                                    className='primarySolid'
                                                                    onClick={() => uploadReceipt(taskList[0]?.referenceId, taskList[0]?.projectId, taskList[0]?.dependencies)}
                                                                    startIcon={<SendIcon />}
                                                                    disabled={isTimerstarted}
                                                                >
                                                                    Upload Receipt
                                                                </Button>
                                                            </Stack>
                                                        </Grid>
                                                        :
                                                        <Grid item md={12} sx={{ textAlign: "center" }} >
                                                            <Stack spacing={2} direction="row"  >
                                                                {!isTimerstarted ? <Button
                                                                    className='sucessButtons'
                                                                    onClick={timerOn}
                                                                    startIcon={<QueryBuilderIcon />}
                                                                    disabled={isTimerstarted}
                                                                >
                                                                    Clock In
                                                                </Button>
                                                                    :
                                                                    <Button className='dangerButtons' onClick={timerOff} startIcon={<QueryBuilderIcon />} >Clock Out</Button>}
                                                                {!isTimerstarted ?
                                                                    <Button className='disabledTonalButtons' disabled startIcon={<HourglassDisabledIcon />}>Complete Task</Button> :
                                                                    <Button className="primaryTonalButtons" disabled={isDisabledCompleteButton} onClick={timerStop} startIcon={<HourglassDisabledIcon />}>Complete Task</Button>}
                                                            </Stack>
                                                        </Grid>

                                }
                            </Grid>
                        </Card>
                    </Grid>
                    {show ? <Grid item mt={1}>
                        <Card className='timeclockcard p-4'>
                            <Grid container >
                                <Grid item md={6} >
                                    <Stack direction={"row"} spacing={1}>

                                        <TurnRightIcon sx={{ fontSize: "40px" }} />
                                        <Typography variant='h4' sx={{ color: "white", marginTop: "6px !important" }} textAlign={"center"}> Next Task</Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                            <Typography className='taskhead'>{taskList[1]?.taskname}</Typography>
                            <Grid container direction='row' mt={2}>
                                <Grid xs={12}>
                                    <Grid container direction='row' >
                                        <Grid xs={6}>
                                            <Typography fontSize={15}>Estimated Time </Typography>
                                        </Grid>
                                        <Grid xs={6}>
                                            <Typography paddingLeft={2}>{taskList[1]?.estimatedHours} Hrs</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction='row' >
                                        <Grid xs={6}>
                                            <Typography fontSize={15}>Task Start Date </Typography>
                                        </Grid>
                                        <Grid xs={6}>
                                            <Typography paddingLeft={2}>{dayjs((taskList[1]?.startTime).replace(/Z/g, '')).format('DD MMM YYYY h:mm A')}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction='row' >
                                        <Grid xs={6}>
                                            <Typography fontSize={14}>Estimated End Date </Typography>
                                        </Grid>
                                        <Grid xs={6}>
                                            <Typography paddingLeft={2}>{dayjs((taskList[1]?.endTime).replace(/Z/g, '')).format('DD MMM YYYY h:mm A')}</Typography>
                                        </Grid>
                                    </Grid>
                                    {/* </Grid>
                                <Grid xs={5} textAlign={"center"}> */}



                                </Grid>
                            </Grid>
                            <Grid container direction='row' mt={1} >
                                <Stack spacing={1}>
                                    <Typography>{taskList[1]?.description}</Typography>
                                    <Typography>Order No: {taskList[1]?.jobNo}</Typography>
                                </Stack>
                            </Grid>
                            <Grid item className='alignEnd'>
                                <Button onClick={handleClicked} sx={{ color: "white" }}><KeyboardArrowUpIcon /></Button>
                            </Grid>
                        </Card>
                    </Grid> : taskList.length >= 2 ?
                        <Grid item md={12} textAlign={"center"}>
                            <Button fullWidth variant="contained" className='timeclockcard mt-2' onClick={handleOpened}>Next Task<KeyboardArrowDownIcon /></Button>
                        </Grid> : <></>}

                    {hide ? <Grid item mt={1}>
                        <Card className='timeclockcard p-4'>
                            <Grid container >
                                <Grid item md={6} >
                                    <Stack direction={"row"} spacing={1}>

                                        <TurnRightIcon sx={{ fontSize: "40px" }} />
                                        <Typography variant='h4' sx={{ color: "white", marginTop: "6px !important" }} textAlign={"center"}> Next Task</Typography>
                                    </Stack>
                                </Grid>
                            </Grid>

                            <Typography className='taskhead'>{taskList[2]?.taskname}</Typography>
                            <Grid container direction='row' mt={2}>
                                <Grid xs={12}>
                                    <Grid container direction='row' >
                                        <Grid xs={6}>
                                            <Typography fontSize={15}>Estimated Time </Typography>
                                        </Grid>
                                        <Grid xs={6}>
                                            <Typography paddingLeft={2}>{taskList[2]?.estimatedHours} Hrs</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction='row' >
                                        <Grid xs={6}>
                                            <Typography fontSize={15}>Task Start Date </Typography>
                                        </Grid>
                                        <Grid xs={6}>
                                            <Typography paddingLeft={2}>{dayjs((taskList[2]?.startTime).replace(/Z/g, '')).format('DD MMM YYYY h:mm A')}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction='row' >
                                        <Grid xs={6}>
                                            <Typography fontSize={14}>Estimated End Date </Typography>
                                        </Grid>
                                        <Grid xs={6}>
                                            <Typography paddingLeft={2}>{dayjs((taskList[2]?.endTime).replace(/Z/g, '')).format('DD MMM YYYY h:mm A')}</Typography>
                                        </Grid>
                                    </Grid>
                                    {/* </Grid>
                                <Grid xs={5} textAlign={"center"}> */}



                                </Grid>
                            </Grid>
                            <Grid container direction='row' mt={1}>
                                <Stack spacing={1}>
                                    <Typography>{taskList[2]?.description}</Typography>
                                    <Typography>Order No: {taskList[2]?.jobNo}</Typography>
                                </Stack>
                            </Grid>
                            <Grid item className='alignEnd'>
                                <Button onClick={handleClosed} sx={{ color: "white" }}><KeyboardArrowUpIcon /></Button>
                            </Grid>
                        </Card>
                    </Grid>
                        : taskList.length === 3 ?
                            <Grid item md={12} textAlign={"center"}>
                                <Button fullWidth variant="contained" className='timeclockcard mt-2' onClick={handleOpen}>Next Task<KeyboardArrowDownIcon /></Button>
                            </Grid> : <></>}
                    {/* {taskList.length !== 0 ?
                        <Grid item md={12} textAlign={"center"}>
                            <Button fullWidth variant="contained" className='timeclockcard mt-2' onClick={clickAddManual}>
                                Add Task
                            </Button> */}
                    {/* {openDialogs && <AddTaskForm open={openDialogs} onClose={handleAddTaskDialog} projectId={taskList[0]?.projectId} id={taskList[0]?.taskId} />} */}
                    {openDialogs && <AddTaskDialog open={openDialogs} onClose={handleAddTaskDialog} state={"Gps"} projectId={taskList[0]?.projectId} id={taskList[0]?.taskId} />}
                    {/* </Grid> :
                        <></>
                    } */}
                </>
            }
            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={open}
                onClose={handleClose}
            >
                <Box paddingLeft={4} paddingRight={4} paddingTop={2}>
                    <DialogContent >
                        <Typography textAlign={'center'} fontSize={'22px'} fontWeight={'500'} marginBottom={2}>
                            {promptData}
                        </Typography>
                        {userRole === "labour" &&
                            <Grid container>
                                <Grid xs={3}></Grid>
                                <Grid xs={6}>
                                    <TextField
                                        id="outlined-number"
                                        label="No.of Units"
                                        type="number"
                                        value={completedUnits}
                                        inputProps={{ step: "1", min: 0 }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={(e) => handleInput(e)}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid xs={3}></Grid>
                            </Grid>}
                        {userRole === "foreman" &&
                            <Grid container>
                                <Grid xs={3}></Grid>
                                <Grid xs={6} textAlign={'center'}>
                                    <FormControl>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            onClick={() => setCompleted(true)}
                                        >
                                            <FormControlLabel value="yes" control={<Radio />} label="yes" />
                                            <FormControlLabel value="no" control={<Radio />} label="no" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid xs={3}></Grid>
                            </Grid>}
                        {userRole === "sales" && <Typography textAlign={'center'}>
                            You won't be able to complete this task!
                        </Typography>}
                    </DialogContent>
                </Box>
                {userRole !== "sales" ? <DialogActions sx={{ justifyContent: "center" }}>
                    <Button onClick={handleClose} className="dangerButton">Close</Button>
                    {userRole === "foreman" ?
                        <Button disabled={!completed} onClick={() => handleSubmit(taskList[0].taskType)} autoFocus className={completed === true ? "primaryTonalButton" : ""}>
                            Submit
                        </Button> :
                        <Button disabled={!completed} onClick={() => stopTimer()} autoFocus className={completed === true ? "primaryTonalButton" : ""}>
                            Submit
                        </Button>}
                </DialogActions> :
                    <DialogActions sx={{ justifyContent: "center" }}>
                        <Button onClick={handleClose} autoFocus className="primaryTonalButton">
                            OK
                        </Button>
                    </DialogActions>}
            </Dialog>
            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={errorDialog}
                onClose={handleClose}
            >
                <Box paddingLeft={4} paddingRight={4} paddingTop={2}>
                    <DialogContent >
                        <Typography textAlign={'center'} fontSize={'22px'} fontWeight={'500'} marginBottom={2}>
                            {errorTitle}
                        </Typography>
                        {errorMessage}
                    </DialogContent>
                </Box>
                <DialogActions sx={{ justifyContent: "center" }}>
                    <Button onClick={handleClose} className="dangerButton">Close</Button>
                </DialogActions>
            </Dialog>
            <FileUpload open={openReceiptUpload} props={referenceId} onClose={handleCloseDialog} onFileUpload={onFileUpload} />
            <TaskWarningDialog open={showWarning} onClose={() => setShowWarning(false)} taskNames={taskNames} isCompleteWarning={completeTaskWarning} />
            <ReasonDialog open={openReasonDialog} onClose={() => setOpenReasonDialog(false)} taskId={taskList[0]?.id} taskUid={taskList[0]?.taskUid} func={fetchData} />
            <ContentDialog open={showDialogContent} content={content} onClose={() => setShowDialogContent(false)} />
        </>
    );
}

export default GPS;