/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 9-Nov-2023
* @author      : Priyankaa.S
* @since       : 0.0.1
* Requirement# :
* Purpose      : Base class for REST Controller used for preparing the results and validating the parameters etc
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 1728           |  29/4/24    |   Vanitha      |  Document table ui improvements related changes
*
*
*/
import * as React from 'react';
import { styled } from '@mui/material/styles';
import {
    Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Paper, Grid, Box, Tooltip, Button, MenuItem,
    Select, IconButton, TablePagination, Dialog, DialogActions, DialogContent, Typography
} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    Print as PrintIcon,
    Share as ShareIcon,
    Settings as SettingsIcon,
    CheckBox,
} from '@mui/icons-material';
import { getDocument, getDocumentCountByProjectId, getDocumentListByProjectId } from '../../services/DocumentService';
import { useEffect, useState } from 'react';
import { useOidc, useOidcAccessToken } from '@axa-fr/react-oidc';
import { getContractDetails } from '../../services/ContractSigningService';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import { getReceipt, getUploadedVendorBill, getVendorBill } from '../../services/DeliverySlipService';
import Editor from '../ReactQuil/tableModule';
import AttachDialog from '../gps/AttachDialog';
import BlobData from '../invoice/BlobData';
import FileUpload from '../fileUploader/FileUploader';
import { useHandleUnauthorized } from '../../hooks/UserSession';
import DocumentViewer from './DocumentViewer';
import { getBillByReceiptId } from '../../services/BillService';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function createData(
    name: string,
    calories: string,
    fat: string,
    carbs: number,
    protein: number,
) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Agreement', 'Project Contract Agreement', '25/10/2023', 0, 0),
    createData('PO screenshots', 'Purchase Orders of Project', '1/11/2023', 0, 0),
];



const DocumentList: React.FC<any> = (props: any) => {
    const { accessToken, accessTokenPayload } = useOidcAccessToken();

    const [data, setData] = useState<any>(); // Declare openMatrec state variable
    const [response, setResponse] = useState<any>(); // Declare openMatrec state variable
    const [openConfirmationFailed, setOpenConfirmationFailed] = useState<boolean>(false); // Declare openMatrec state variable
    const [isOpen, setIsOpen] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [documentData, setDocumentData] = useState<any>('');
    const [mimeType, setMimeType] = useState<any>('');

    const { handleUnauthorized } = useHandleUnauthorized();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [count, setCount] = React.useState(Number);
    const [isBlobData, setIsBlobData] = useState(false);
    const [imageData, setImageData] = useState<any | null>(null);

    const handleOpenConfirmationFailed = () => {
        setOpenConfirmationFailed(false);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    const modules = {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ 'size': [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image', 'video'],
            ['clean'],
        ],
    };

    const navigate = useNavigate();
    const [data1, setData1] = useState<any>();

    const formatDate = (dateString: string): any => {
        const options: Intl.DateTimeFormatOptions = {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        };
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-US', options).format(date);
    }

    const getContract = async (projectId: any, type: any) => {
        try {
            // Fetch the image data as a Blob
            const response = await getContractDetails(projectId, accessToken);
            console.log('filetype', response, type)
            setResponse(response.data);
            setOpenConfirmationFailed(true);

        } catch (error) {
            console.error('Error fetching user session:', error);
        }
    }
    const downloadImage = async (projectId: any, receiptId: any, type: any) => {
        try {
            // Fetch the image data as a Blob

            // const data = await getInvoiceByProjectId(props.project, 'All', true, accessToken);

            // setInvoiceData(data.data);
            const response = await getReceipt(projectId, receiptId, accessToken);
            console.log(response)
            handleUnauthorized(response);
            setIsBlobData(true);
            setImageData(response)

            //  const blob = new Blob([response.data], { type: 'application/octet-stream' }); // Assuming the response is a PNG image, adjust the MIME type accordingly
            // // const imageBlob = await response.data.blob();
            // const reader = new FileReader();
            // reader.readAsDataURL(blob);
            // reader.onloadend = () => {
            //   const base64data = reader.result as string;
            // setImageData(base64data)
            // // Create a temporary anchor element
            // const a = document.createElement('a');
            // a.href = base64data;
            // a.download = 'image.png'; // Set the filename here

            // // Programmatically click the anchor element to initiate the download
            // a.click();
            // setImgUrl(base64data);
            // };
            // Create a download link
            // const url = URL.createObjectURL(blob);
            // const a = document.createElement('a');
            // a.href = url;
            // a.download = 'image.png'; // Set the filename here
            // document.body.appendChild(a);
            // a.click();
            // document.body.removeChild(a);

            // // Clean up
            // URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading image:', error);
        }
    };
    const getSlip = async (projectId: any, receiptId: any, type: any) => {
        try {
            const res = await getReceipt(projectId, receiptId, accessToken);
            console.log(res);
            setMimeType(type)

            // setResponse(res.data)
            setDocumentData(res.data)
            setIsOpen(true);
        } catch (error) {
            console.error('Error fetching user session:', error);
        }
    }
    const getFileType = (base64Data: string) => {
        if (!base64Data) return '';

        const prefix = base64Data.substring(0, 30); // First few characters
        if (prefix.includes('PDF')) return 'application/pdf';
        if (prefix.includes('PNG')) return 'image/png';
        if (prefix.includes('JPG') || prefix.includes('JFIF')) return 'image/jpeg';
        if (prefix.includes('GIF')) return 'image/gif';
        if (prefix.includes('WORD') || prefix.includes('DOCX')) return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        if (prefix.includes('EXCEL') || prefix.includes('XLSX')) return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        return 'application/octet-stream'; // Default unknown type
    };

    const getVendorBillData = async (projectId, receiptId, type) => {
        try {
            console.log(type);
            const res = await getUploadedVendorBill(projectId, receiptId, accessToken);

            console.log("file type", res, type);
            // setResponse(res.data)
            setDocumentData(res.data);
            setMimeType(type)

            // Try to get MIME type from the response or fallback to type provided
            const mimeType = type?.mimeType;
            setMimeType(mimeType)
            // Create Blob with the appropriate MIME type
            const blob = new Blob([res.data], { type: mimeType });
            const url = URL.createObjectURL(blob);

            console.log("Generated Blob URL:", res.data);

            setIsOpen(true);
        } catch (error) {
            console.error("Error fetching vendor bill:", error);
        }
    };


    const getUploaded = async (documentId: any, row: any, type: any) => {
        if (type === "Uploaded" ) {
            setIsOpen(true);
            try {
                const res = await getDocument(documentId, accessToken);
                console.log(res.data)
                setDocumentData(res.data)
                setMimeType(type)

            } catch (error) {
                console.error('Error fetching user session:', error);
            }
        }
        else if( type === "Bill"){
            setIsOpen(true);

             getBillByReceiptId(row?.projectId, row?.receiptId, accessToken).then((res)=>{
                setDocumentData(res?.data['url']);
             })
 
        }
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(event.target.value);
        setRowsPerPage(newRowsPerPage);
        // setPage(0);
    };

    const handleCancelConfirmation = () => {
        setOpenConfirmationFailed(false);

        //window.location.reload();
    };

    useEffect(() => {
        const handleGetDocumentByProjectId = async () => {
            try {
                const response = await getDocumentListByProjectId(props?.projectId, page, rowsPerPage, accessToken);
                const res = await getDocumentCountByProjectId(props?.projectId, accessToken);
                setCount(res.data)
                handleUnauthorized(response);
                setData(response.data);
                console.log(response.data)
                const data = await fetch(response.data);
                console.log(data)
            } catch (error) {
                console.error('Error fetching user session:', error);
            }
        };
        handleGetDocumentByProjectId();

    }, []);
    useEffect(() => {
        const handleGetDocumentByProjectId = async () => {
            try {
                const response = await getDocumentListByProjectId(props?.projectId, page, rowsPerPage, accessToken);
                handleUnauthorized(response);
                setData(response.data);
                console.log(response.data)
                const data = await fetch(response.data);
                console.log(data)
            } catch (error) {
                console.error('Error fetching user session:', error);
            }
        };
        handleGetDocumentByProjectId();

    }, [rowsPerPage, page]);
    // const pdfUrl = 'https://stggmsdatadev001.blob.core.windows.net/tn1237477/trade/1589/VendorBill/368?sv=2023-08-03&spr=https&se=2025-02-03T11%3A30%3A51Z&sr=b&sp=r&sig=H0Fc691%2FfUtsv13q5mzjRBCtMsNC4bncs99O2HueHVk%3D';

    // const getLocalPdfUrl = async (url: any) => {

    //     const response = await fetch(url);
    //     const blob = await response.blob();

    //     return URL.createObjectURL(blob);
    // };
    const openPdfFromBase64 = async (base64Pdf: string) => {
        // Decode the base64 string into a binary array
        const binaryString = atob(base64Pdf.split(',')[1]); // Remove the base64 prefix (data:application/pdf;base64,)
        const uint8Array = new Uint8Array(binaryString.length);

        // Populate the array with the binary data
        for (let i = 0; i < binaryString.length; i++) {
            uint8Array[i] = binaryString.charCodeAt(i);
        }

        // Create a Blob from the binary data
        const blob = new Blob([uint8Array], { type: 'application/pdf' });

        // Create a Blob URL
        const blobUrl = URL.createObjectURL(blob);

        // Open the PDF in a new tab
        window.open(blobUrl, "_blank");
    };
    const SearchBar = () => (
        <div className='searchBar'>
            <Grid container rowSpacing={1} mt={0} columnSpacing={{ xs: 0, sm: 2, md: 3 }}>
                {/* <Grid item xs={12}>
              <Grid container rowSpacing={0} mt={0} columnSpacing={{ xs: 0, sm: 2, md: 3 }}>
                <Grid item xs={10}></Grid>
                
              </Grid>
            </Grid> */}
                <Grid item xs={3} mt={3}>
                    <Select
                        fullWidth
                        value=""
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        size='small'
                    >
                        <MenuItem value="" disabled>
                            Batch Actions
                        </MenuItem>
                        <MenuItem value="1">Option 1</MenuItem>
                        <MenuItem value="2">Option 2</MenuItem>
                        <MenuItem value="3">Option 3</MenuItem>
                    </Select>
                    {/* <FormHelperText>Helper text goes here</FormHelperText> */}
                </Grid>
                <Grid item xs={3}>

                </Grid>
                <Grid item xs={4}>
                </Grid>
                <Grid item xs={2} className='createInvoice'>
                    <Grid item xs={12} mt={2}>
                        <Tooltip title="Print">
                            <IconButton>
                                <PrintIcon fontSize="medium" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Share">
                            <IconButton>
                                <ShareIcon fontSize="medium" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Settings">
                            <IconButton>
                                <SettingsIcon fontSize="medium" />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
    return (
        <>
            {/* <Grid container justifyContent="center" alignItems="center" mt={3} rowSpacing={2} columnSpacing={{ xs: 4, sm: 2, md: 2 }}>
                <Grid item xs={12} md={10} sm={12}>
                    <Box sx={{ width: '100%', }}>
                        <Paper className='FinanceCard' sx={{ width: '100%', mb: 2 }}>
                            <SearchBar />
                            <TableContainer>
                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                    <TableHead>
                                        <TableRow cl>
                                            <StyledTableCell className='invoiceRows'>File Name</StyledTableCell>
                                            <StyledTableCell className='invoiceRows'>Description</StyledTableCell>
                                            <StyledTableCell className='invoiceRows'>Date</StyledTableCell>
                                            <StyledTableCell className='invoiceRows'>Download</StyledTableCell>
                                            <StyledTableCell className='invoiceRows'>Delete</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data?.map((document) => (
                                            <StyledTableRow key={document?.id}>
                                                <StyledTableCell component="th" scope="row">
                                                    {document?.documentType}
                                                </StyledTableCell>
                                                <StyledTableCell className='invoiceRows'>{document?.description}</StyledTableCell>
                                                <StyledTableCell className='invoiceRows'>{formatDate(document?.createdAt)}</StyledTableCell>
                                                <StyledTableCell className='invoiceRows'><Button onClick={() => getContract(document?.projectId)}>View</Button></StyledTableCell>
                                                <StyledTableCell className='invoiceRows'><DeleteIcon /></StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            
                        </Paper>
                    </Box>
                </Grid >
            </Grid>  */}
            <Box sx={{ width: '100%' }}>
                <Paper sx={{ width: '100%', mb: 2 }}>
                    <TableContainer>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table">
                            <TableHead>
                                <TableRow className='tableHead'>
                                    <TableCell>Document Id</TableCell>

                                    <TableCell>File Name</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Receipt Id</TableCell>

                                    <TableCell>Download</TableCell>
                                    <TableCell>Action</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data?.map((document) => (
                                    <TableRow key={document?.id}>
                                        <TableCell>{document?.id}</TableCell>

                                        <TableCell>{document?.description}</TableCell>
                                        <TableCell>{document?.documentType}</TableCell>
                                        <TableCell>{formatDate(document?.createdAt)}</TableCell>
                                        <TableCell>{document?.receiptId}</TableCell>

                                        <TableCell>
                                            {document?.documentType === "Contract" &&
                                                <Button onClick={() => getContract(document?.projectId, document?.documentType)}>View</Button>}
                                            {document?.documentType === "DeliverySlip" && <Button onClick={() => getSlip(document.projectId, document?.receiptId, document.documentType)}>View</Button>}
                                            {document?.documentType === "Uploaded" && <Button onClick={() => getUploaded(document?.id, document, document.documentType)}>View</Button>}
                                            {document?.documentType === "Bill" && <Button onClick={() => getUploaded(document?.id, document, document.documentType)}>View</Button>}
                                            {document?.documentType === "VendorBill" && <Button onClick={() => getVendorBillData(document.projectId, document?.receiptId, document)}>View</Button>}

                                        </TableCell>
                                        <TableCell>
                                            <DeleteIcon />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        sx={{ border: 'none', }}
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Box>
            <Dialog
                open={openConfirmationFailed}
                onClose={handleOpenConfirmationFailed}
                fullWidth={true}
                maxWidth={'lg'}
            >
                <Box paddingLeft={8} paddingRight={8} paddingTop={2}>
                    <DialogContent >
                        <Typography textAlign={'center'}></Typography>
                        <Typography textAlign={'center'} fontSize={'22px'} fontWeight={'500'} marginBottom={2}>
                            Contract
                        </Typography>
                        {response && (

                            <Box mt={2} textAlign="center">
                                <Editor
                                    value={response}
                                    readOnly={true} onChange={function (content: string): void {
                                        throw new Error('Function not implemented.');
                                    }}
                                />
                            </Box>
                        )}
                    </DialogContent>
                </Box>
                <DialogActions sx={{ justifyContent: "end", marginRight: "3px" }}>
                    <Button onClick={handleCancelConfirmation} className="dangerButton">Ok</Button>
                </DialogActions>

            </Dialog>


            <Dialog
                open={isOpen}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={'lg'}
            >
                {/* <Box paddingLeft={8} paddingRight={8} paddingTop={2}>
                    <DialogContent >
                        <Typography textAlign={'center'}></Typography>
                        <Typography textAlign={'center'} fontSize={'22px'} fontWeight={'500'} marginBottom={2}>
                            Uploaded Document
                        </Typography>
                        {documentData && (
                            <Box mt={2} textAlign="center">
                                {(() => {
                                    const fileType = getFileType(documentData); 
                                    console.log('filetType vendor',fileType);
                                    if (fileType.startsWith('image/')) {
                                        // 🖼️ Display Image
                                        return <img width="500" height="200" src={`data:${fileType};base64,${documentData}`} alt="Uploaded File" />;
                                    }
                                    else if (fileType === 'application/pdf') {
                                        // 📄 Display PDF
                                       // return <iframe width="100%" height="500px" src={`data:${fileType};base64,${documentData}`} title="PDF Document"></iframe>;
                                        return <iframe
                                        title="PDF Viewer"
                                        src={documentData}
                                        style={{ height: '800px', width: '100%' }}
                                    />
                                    }
                                    else {
                                        // 📥 Provide Download Link for Other File Types (e.g., Word, Excel, etc.)
                                     // <iframe width="100%" height="500px" src={`data:${fileType};base64,${documentData}`} title="PDF Document"></iframe>;
                                      return      <iframe
                                            title="PDF Viewer"
                                            src={documentData.data}
                                            style={{ height: '800px', width: '100%' }}
                                        />
                                    } 
                                })()}
                            </Box>
                        )}

                    </DialogContent>
                </Box> */}
                <Box paddingLeft={8} paddingRight={8} paddingTop={2}>
                    <DialogContent>
                        <Typography textAlign={'center'}></Typography>
                        <Typography textAlign={'center'} fontSize={'22px'} fontWeight={'500'} marginBottom={2}>
                            Uploaded Document
                        </Typography>
                        {/* <Button onClick={()=>openPdfFromBase64(pdfUrl)}>uploaded </Button> */}
                        {/* <img src={documentData} />
                        <p>PDF cannot be displayed. <a href={documentData} download>Download it</a></p> */}
                        
                        {mimeType === "image/png" ? (
                            <img
                                src={documentData}
                                alt="PNG Preview"
                                style={{ width: "100%", height: "auto", objectFit: "contain" }}
                                onError={(e) => console.error("Image failed to load", e)}
                            />) : (
                            <Box sx={{ height: "600px", width: "100%" }}>
                                <iframe
                                    src={documentData}
                                    width="100%"
                                    height="100%"
                                    title="Document Viewer"
                                    frameBorder="0"
                                ></iframe>
                            </Box>
                        )}

                        {/* {documentData && (
  <object
    data={documentData}
    type="application/pdf"
    width="100%"
    height="600px"
  >
    <p>PDF cannot be displayed. <a href={documentData} download>Download it</a></p>
  </object>
)} */}


                    </DialogContent>
                </Box>


                <DialogActions sx={{ justifyContent: "end", marginRight: "3px" }}>
                    <Button onClick={handleClose} className="dangerButton">Ok</Button>
                </DialogActions>
            </Dialog>

        </>
    );
}

export default DocumentList;