import { ConfigUrl } from "../auth/OidcConfig";
import { vendorDetails } from "../data/VendorDetails";
import { bidStatusData } from "../data/bidStatusData";
import { BillJson } from "../data/billjson";
import { executeGetData, executePostData, executePutData } from "./ApiBase";

export const getVendor = async () => {
  const url = vendorDetails;
  return url;
}

export const getBillList = async () => {
  // const url = `${Config.baseURL}/subjects?page=0&size=10&sortDir=ASC&sort=firsNumber`;
  // return await executeGet(url, accessToken);
  return BillJson;
};

export const getBidStatus= async()=>{
return bidStatusData;
}

export const getBillLists = async (projectId:any, searchParams:any, page:any, size:any,accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/receipt/getBillList/projectId/${projectId}/searchParams/${searchParams}?page=${page}&size=${size}`;
  return await executeGetData(url, accessToken);
};

export const getBillCount = async (projectId:any, searchParams:any, accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/receipt/getBillCount/projectId/${projectId}/searchParams/${searchParams}`;
  return await executeGetData(url, accessToken);
};

export const reviewBill = async (receiptId:any, status:any, data:any, accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/receipt/reviewBill/receiptId/${receiptId}/status/${status}`;
  return await executePostData(url, data, accessToken);
};

export const getBillByReceiptId = async (projectId:any, receiptId:any, accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/receipt/getBill/projectId/${projectId}/receiptId/${receiptId}`;
  return await executeGetData(url, accessToken);
};

export const getPOlessBill = async (projectId: any, searchParams:any, page:any, size:any, accessToken: any) => {
  const url = `${ConfigUrl.baseURL}/receipt/getPOlessBillList/projectId/${projectId}/searchParams/${searchParams}?page=${page}&size=${size}`;
  return await executeGetData(url, accessToken);
};

export const getPOlessBillCount = async (projectId:any, searchParams:any, accessToken:any) => {
  const url = `${ConfigUrl.baseURL}/receipt/getPOlessBillListCount/projectId/${projectId}/searchParams/${searchParams}`;
  return await executeGetData(url, accessToken);
};

