/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 2-Nov-2023
* @author      : karthikeyan
* @since       : 0.0.1
* Requirement# :
* Purpose      : Create Bid Wizard Form
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE      |    AUTHOR     |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 1415           |  10/04/24   | shiyam Kumar  |  Implement a Dialog Box For Update Detials
* 2218           |  06/06/24   | Vanitha       |  Genenral confoguration no value hasbeen changed implementation
*/

import * as React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from '@mui/material/Card';
import DoneIcon from '@mui/icons-material/Done';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import UploadIcon from '@mui/icons-material/Upload';
import Grid from "@mui/material/Grid";


import TextField from "@mui/material/TextField";
import {
  Backdrop,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Header from "../Header";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import {
  GoogleMap,
  useJsApiLoader,
  MarkerF,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import { Stack } from "@mui/system";
import "../asset/Asset.scss";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import { useNavigate } from "react-router";
import { useLocation, } from "react-router-dom";
import { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./Tenanatconfiguration.scss";
import { getUserSession, useHandleUnauthorized } from "../../hooks/UserSession";
import { format } from "date-fns";
import { generalConfig, getTenantDetails, upadateTenantDetails } from "../../services/TenantService";
import CustomDialog from "../../pages/Dialog";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Switch from '@mui/material/Switch';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import gmslogo from "../../assets/logo.gif";
import { findPreconstructionPeriod } from "../../services/TradeConfigurationService";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';


const containerStyle = {
  width: "100%",
  height: "300px",
};


const libraries: any = ["places"];

const Tenatconfiguration: React.FC = () => {

  const [code, setCode] = useState<any>("");
  const newDate = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss'Z'");
  const [data, setData] = useState<any>('');
  const [showUpdateButton, setShowUpdateButton] = React.useState<any>(false);
  const [isDataChange, setIsDataChange] = React.useState(false);
  const [workingDays, setWorkingDays] = React.useState<any>([]);
  const [workingHours, setWorkingHours] = React.useState<any>({});
  const navigate = useNavigate();

  const [assetData, setAssetData] = React.useState<any>({
    tenantid: "",
    createdAt: newDate,
    updatedAt: newDate,
    createdBy: data?.firstname || "",
    status: "Provision",
    secondaryContact: "",
    primaryContact: "",
    mobileNo: "",
    alternateMobileNo: "",
    name: '',
    website: '',
    address: "",
    country: "",
    zipcode: "",
    state: "",
    description: "",
    city: "",
    location: null,
    overtime:"false",
    tenantLogo: '',
    email: "",
    alternateEmail: '',
    signature: "",
    scheduleWithAi: "false",
    estimateWithAi: "false",
    workingDays: "",
    workingHours: "",
    preconstructionPeriod: ""
  });


  const myColors = [
    "purple",
    "#785412",
    "#452632",
    "#856325",
    "#963254",
    "#254563",
    "white",
  ];
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ align: ["right", "center", "justify"] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [{ color: myColors }],
      [{ background: myColors }],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "color",
    "image",
    "background",
    "align",
  ];


  const [textValue, setTextValue] = useState('');
  const [response, setResponse] = useState<any>();
  const [openSave, setOpenSave] = useState<boolean>(false); // Declare openMatrec state variable
  const [openFailed, setOpenFailed] = useState<boolean>(false); // Declare openMatrec state variable

  const [isSaveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [openUpdate, setOpenUpdate] = useState<boolean>(false);
  const [updateSuccess, setUpdateSuccess] = React.useState(false);
  const [updateError, setUpdateError] = React.useState(false);
  const [preConstructionPeriod, setPreConstructionPeriod] = React.useState<any>(0);
  const [selectedFile, setSelectedFile] = React.useState<File | string>();
  const [image, setImage] = React.useState<any>(null);
  const [isLoading, setIsLoading] = React.useState<any>(false);
  const [isValidPreConstructionPeriod, setIsValidPreConstructionPeriod] = React.useState<any>(true);

  const handleOpenSaveCancel = async () => {
    setOpenSave(false);
    const response = await getTenantDetails(accessToken);
    if (response.status === 200 || response.status === 201) {
      setResponse(response.data);
      window.location.reload();
    }
  };
  const getFile = (event: any) => {
    const file = event.target.files[0];
    setSelectedFile(URL.createObjectURL(file));
    setImage(file);
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          setAssetData({
            ...assetData,
            tenantLogo: reader.result,
            createdBy: data?.firstname,
          })
        }
      };
      reader.onerror = (error) => {
        console.error('Error occurred while reading the file:', error);
      };
    }
    // Do something with the selected file, e.g., upload or process it
    console.log('Selected file:', selectedFile);
  };
  const handleOpenFailed = () => {
    setOpenFailed(false);

  };
  const handleDialogClose = () => {
    setIsValidPreConstructionPeriod(true);
    setOpenUpdate(false);
    setUpdateSuccess(false);
    setUpdateError(false);
  };
  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newTextValue = event.target.value;
    setTextValue(newTextValue);
    setSaveButtonDisabled(newTextValue.trim() === ''); // Enable the button if text is not empty
    if (event.target.name === "preconstructionPeriod") {
      if ((Number(event.target.value)) >= 1) {
        setAssetData({
          ...assetData,
          [event.target.name]: event.target.value,
        });
      }
    }
    else {
      setAssetData({
        ...assetData,
        [event.target.name]: event.target.value,
      });
    }
  };


  const handleProcedureContentChange = (content: string, _, __, editor: any) => {
    // Enable the button if text is not empty
    setAssetData({
      ...assetData,
      description: content,
    })
    setCode(content);
  };

  const getPreconstructionPeriod = async () =>{
    const response = await findPreconstructionPeriod(JSON.stringify(assetData.workingHours), assetData.workingDays, accessToken);
    if(response.status === 200){
      console.log(response)
      setPreConstructionPeriod(response?.data);
      setCount(count + 1);
    }
  }

  const handleUpdateButtonClick = async () => {
    setIsLoading(true);
    setCount(count + 1);
    let validated = validation();

    // Check if any value has been changed
    const hasValueChanged =
      response.data.address !== assetData.address ||
      response.data.description !== assetData.description ||
      response.data.country !== assetData.country ||
      response.data.tenantLogo !== assetData.tenantLogo ||
      response.data.zipCode !== assetData.zipCode ||
      response.data.state !== assetData.state ||
      response.data.city !== assetData.city ||
      response.data.scheduleWithAi !== assetData.scheduleWithAi ||
      response.data.estimateWithAi !== assetData.estimateWithAi ||
      response.data.workingHours !== JSON.stringify(assetData.workingHours) ||
      response.data.workingDays !== assetData.workingDays ||
      response.data.overtime !== assetData.overtime ||
      response.data.preconstructionPeriod !== assetData.preconstructionPeriod ||
      file;
    if (!hasValueChanged) {
      // No value has been changed, show a message or perform any other action
      setIsDataChange(true);
      console.log("No value has been changed.");
      return;
    }

    if (validated === true) {
      const res = await findPreconstructionPeriod(JSON.stringify(workingHours), workingDays.join(","), accessToken);
      if(res.status === 200){
        setPreConstructionPeriod(res?.data);
        setCount(count + 1);
      
        if(res?.data > parseInt(assetData.preconstructionPeriod)){
          setIsValidPreConstructionPeriod(false);
          setIsLoading(false);
        }else{
          Object.assign(assetData, { workingHours: JSON.stringify(workingHours) });
          const response = await upadateTenantDetails(assetData, assetData?.id, accessToken);
          try {
            Object.assign(assetData, { workingHours: JSON.parse(assetData.workingHours) });
            setCount(0);
            console.log('Saving:', response);
            setOpenUpdate(true);
            if (response.ok) {
              setIsLoading(false);
              setUpdateSuccess(true);
            } else {
              setIsLoading(false);
              setUpdateError(true);
            }
          } catch {
            setIsLoading(false);
            console.log('err:', response);
            setUpdateError(true);
          }
        }
      } else {
        setIsLoading(false);
        setOpenUpdate(true);
        setUpdateError(true);
      }
    } else {
      setIsLoading(false);
      setOpenUpdate(true);
      setUpdateError(true);
    }
  }

  let location = useLocation();
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBHfzWHT7qgElExVxQzVRhCJtW1Z6YRILw",
    libraries: libraries
  });
  const { handleUnauthorized } = useHandleUnauthorized();
  const [map, setMap] = React.useState(null);
  const { accessToken } = useOidcAccessToken();
  const [open, setOpen] = React.useState(false);
  const [count, setCount] = React.useState(0);
  const [file, setFile] = useState<File | any>(null);
  let days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const rowData = location?.state?.tenantDetails;
  const [message, setMessage] = React.useState<any>({
    propertyName: "",
    country: "",
    zipcode: "",
    state: "",
    address: "",
    city: "",
    stories: null,
  });
  const [currentLocation, setCurrentLocation] = useState<{ lat: number; lng: number } | null>(null);

  const fetchRoleInform = async () => {
    try {
      const response = await getUserSession(accessToken);

      // Handle 401 Unauthorized error
        handleUnauthorized(response)
  

      setData(response)

    } catch (error) {
      console.error('Error fetching user session:', error);
    }
  };

  const handleSaveButtonClick = async () => {
    setCount(count + 1);
    let validated = validation();
    if (validated === true) {
      Object.assign(assetData, { workingHours: JSON.stringify(workingHours) });
      const response = await generalConfig(assetData, accessToken);
      try {
        console.log('Saving:', response);
        handleUnauthorized(response);

        if (response.ok) {
          setOpenSave(true);
        }
  
        else {
          setOpenFailed(true)
        }

      }
      catch {
        console.log('err:', textValue);
      }
    }
    else {
      handleClick('Not Inserted');
    }
  };
  const handleCardClick = () => {
    const fileInput = document.getElementById("image-upload-input") as HTMLInputElement;
    fileInput.click();
  };
  const componentData = React.useRef<any>();
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const [toastMessage, setToastMessage] = React.useState("");
  const inputRef = React.useRef<any>();

  const onLoad = React.useCallback(function callback(map: any) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    const bounds = new window.google.maps.LatLngBounds(assetData.location);
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null);
  }, []);

  const onClick = (e: google.maps.MapMouseEvent) => {
    componentData.current = { lat: e.latLng?.lat(), lng: e.latLng?.lng() };
    let locationObject: any = {};
    const geocoder = new google.maps.Geocoder();
    const fulladdress: any[] = [];
    geocoder.geocode({ location: componentData.current }).then((response) => {
      if (response.results[0]) {
        // eslint-disable-next-line array-callback-return
        response.results[0].address_components.map((address: any) => {
          if (
            address.types.includes("street_number") ||
            address.types.includes("sublocality_level_1") ||
            address.types.includes("sublocality_level_2") ||
            address.types.includes("route") ||
            address.types.includes("landmark") ||
            address.types.includes("premise")
          ) {
            fulladdress.push(address.long_name);
            locationObject["address"] = fulladdress.join(",");
          } else if (address.types.includes("locality")) {
            locationObject["city"] = address.long_name;
          } else if (address.types.includes("administrative_area_level_1")) {
            locationObject["state"] = address.long_name;
          } else if (address.types.includes("country")) {
            locationObject["country"] = address.long_name;
          } else if (address.types.includes("postal_code")) {
            locationObject["zipCode"] = address.long_name;
          }
        });
      }
      setAssetData({
        ...assetData,

        zipcode: locationObject?.zipCode || '',
        country: locationObject?.country || '',
        address: locationObject?.address || '',
        city: locationObject?.city || '',
        state: locationObject?.state || '',
        location: Object.values(componentData.current).join(",") || ''
      });
    });
  };
  const convertLocation = (locationString: any) => {
    let locationObject: any = {};

    // Check if locationString is a string
    if (typeof locationString === 'string') {
      let st = locationString?.split(",");

      // Check if split operation yielded at least two elements
      if (st.length >= 2) {
        locationObject['lat'] = Number(st[0]);
        locationObject['lng'] = Number(st[1]);
        // console.log(locationObject);

      } else {
        console.error("Invalid location format. Unable to extract latitude and longitude.");
      }
    } else {
      console.error("Invalid location. Input must be a string.");
    }

    return locationObject;
  }

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };
  const showPosition = (position: any) => {
    // Ensure that assetData is defined before attempting to access or modify its properties
    if (assetData) {
      componentData.current = {
        lat: position?.coords?.latitude,
        lng: position?.coords?.longitude,
      };
      assetData["location"] = componentData?.current;
      // setAssetData({ ...assetData,  location: componentData.current });
    } else {
      console.log("assetData is not defined");
    }
  };


  React.useEffect(() => {

    (async () => {
      const oldresponse = await getTenantDetails(accessToken);
      setResponse(oldresponse);
      console.log(oldresponse);

      handleUnauthorized(response)
      if (oldresponse.data !== undefined && (oldresponse.status === 200 || oldresponse.status === 201)) {
        if(oldresponse.data?.workingHours !== null){
          setWorkingHours(JSON.parse(oldresponse.data?.workingHours));
        }
        setAssetData(oldresponse.data);
        if (oldresponse.data?.workingDays !== null) {
          setWorkingDays(oldresponse.data?.workingDays.split(","))
        }
        setSelectedFile(oldresponse.data.tenantLogo)
        setShowUpdateButton(true);
      }

      const res = await findPreconstructionPeriod(oldresponse.data?.workingHours, oldresponse.data?.workingDays, accessToken);
      if(res.status === 200){
        console.log(res)
        setPreConstructionPeriod(res?.data);
        setCount(count + 1);
      }

    })()
    getLocation();
    fetchRoleInform();
    setWorkingHours({});
    setWorkingDays([]);
    // locationDetails();
    const assetDataObject = location.state?.tenantDetails;
    // setResponse(assetDataObject);
    if (assetDataObject !== undefined) {
      setAssetData(assetDataObject);
    }
    navigator.geolocation.getCurrentPosition(
      position => {
        const { latitude, longitude } = position.coords;
        setCurrentLocation({ lat: latitude, lng: longitude });
      },
      error => {
        console.error('Error getting current location:', error);
        // Handle errors if necessary
      }
    );
    setCode(rowData?.description);
    getPreconstructionPeriod();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const inputchange = (e: any) => {
    const newTextValue = e.target.value;
    setTextValue(newTextValue);
    setSaveButtonDisabled(newTextValue.trim() === '');
    setAssetData({
      ...assetData,
      [e.target.name]: e.target.value,

    });
  };

  const inputTimeChange = (e: any, fieldName: any) => {

    if (workingHours !== undefined && workingHours !== null) {
      setWorkingHours({ ...workingHours, [fieldName]: dayjs(e.$d).format('HH:mm') });
    } else {
      setWorkingHours({ ...workingHours, [fieldName]: dayjs(e.$d).format('HH:mm') });
    }
  };

  const handleChange = (event: any) => {
    setAssetData({ ...assetData, [event.target.name]: (event.target.checked).toString() });
  };

  const handleWorkingDayChange = (event: any) => {
    console.log(event.target.value)
    if (assetData.workingDays !== undefined) {
      if (workingDays.includes(event.target.value)) {
        workingDays.splice(workingDays.indexOf(event.target.value), 1);
        setAssetData({ ...assetData, ["workingDays"]: workingDays.join(",") });
      } else {
        workingDays.push(event.target.value);
        setAssetData({ ...assetData, ["workingDays"]: workingDays.join(",") });
      }
    } else {
      setAssetData({ ...assetData, ["workingDays"]: event.target.value });
      workingDays.push(event.target.value);
    }
  };

  const handleClick = (message: any) => {
    setOpen(true);
    setToastMessage(message);
  };

  const validation = () => {
    let validated: any = true;
    if (assetData?.email === "") {
      message.email = "Enter Your email id ";
      validated = false;
    }
    if (assetData?.primaryContact === "") {
      message.primaryContact = "Enter Primary Contact";
      validated = false;
    }
    if (assetData?.state === "") {
      message.state = "Enter Your State";
      validated = false;
    }
    if (assetData?.city === "") {
      message.city = "Enter Your City";
      validated = false;
    }
    if (assetData?.country === "") {
      message.country = "Enter Your Country";
      validated = false;
    }
    if (assetData?.tenantLogo === "") {
      message.tenantLogo = "Upload TenantLogo";
      validated = false;
    }

    if (assetData?.zipcode === "") {
      message.zipCode = "Enter Your ZipCode";
      validated = false;
    }
    if (assetData?.address === "") {
      message.address = "Enter Your address";
      validated = false;
    }
    if (assetData?.createdAt === "") {
      message.createdAt = "Enter Your Created Date ";
      validated = false;
    }

    if (assetData?.name === "") {
      message.tenantid = "Enter Your Company Name";
      validated = false;
    }

    if (workingHours !== undefined && workingHours !== null && Object.keys(workingHours).length !== 0) {
      if (workingHours.startTime === undefined || workingHours.endTime === undefined) {
        validated = false;
      }
    } else {
      validated = false;
    }

    if (assetData.workingDays === undefined || workingDays.length === 0) {
      validated = false;
    }

    if (assetData?.preconstructionPeriod === "" || assetData?.preconstructionPeriod === null) {
      validated = false;
    }
    return validated;
  };


  const handlePlaceChanged = () => {
    const [place] = inputRef.current.getPlaces();
    const fulladdress: any[] = [];
    let locationObject: any = {};
    if (place) {
      place.address_components.map((address: any) => {
        if (
          address.types.includes("street_number") ||
          address.types.includes("sublocality_level_1") ||
          address.types.includes("sublocality_level_2") ||
          address.types.includes("route") ||
          address.types.includes("landmark") ||
          address.types.includes("premise")
        ) {
          fulladdress.push(address.long_name);
          locationObject["address"] = fulladdress.join(",");
        } else if (address.types.includes("locality")) {
          locationObject["city"] = address.long_name;
        } else if (address.types.includes("administrative_area_level_1")) {
          locationObject["state"] = address.long_name;
        } else if (address.types.includes("country")) {
          locationObject["country"] = address.long_name;
        } else if (address.types.includes("postal_code")) {
          locationObject["zipCode"] = address.long_name;
        } else {
          locationObject["zipCode"] = "";
          locationObject["address"] = fulladdress.join(",");
        }
      });
      componentData.current = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };

      setAssetData({
        ...assetData,
        createdBy: data?.firstname || '',
        mobileNo: data?.mobileNo || '',
        zipcode: locationObject?.zipCode || '',
        country: locationObject?.country || '',
        address: locationObject?.address || '',
        city: locationObject?.city || '',
        state: locationObject?.state || '',
        location: Object.values(componentData.current).join(",") || ''
      });
    }
  };

  const handleCancel = () => {

    navigate('/commondashboard');
  };

  return (
    <>
     <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
    >
        <img src={gmslogo} alt="Image" className="overlay" style={{ height: "60px" }} />
    </Backdrop>
    <Box sx={{ width: "100%" }}>
      <Box
        display={smScreen ? "flex" : "block"}
        flexDirection={smScreen ? "row" : "column"}
        justifyContent={smScreen ? "space-between" : "start"}
        alignItems={smScreen ? "center" : "start"}
        mx="5px"
        p="3px"
        mb="40px"
      >
        <Header title="General configuration" subtitle={""} />
        <Box>
          <FormControlLabel
            name='estimateWithAi'
            checked={assetData.estimateWithAi === "true" ? true : assetData.estimateWithAi === "false" ? false : false}
            control={<Switch color="primary" />}
            label="Estimate With AI"
            onChange={(e) => handleChange(e)}
            labelPlacement="start"
          />
        </Box>
      </Box>
      <Grid
        container
        sx={{ p: 0 }}
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >

        <Grid item xs={12} md={10}>
          <Grid
            container
            sx={{ p: 0 }}
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {rowData === undefined ? <Grid item xs={12} md={6}>
              <TextField
                required
                id="outlined-required"
                label="Company Name"
                fullWidth
                size="small"
                onChange={handleTextChange}
                name="name"
                helperText={(assetData?.name === '' && count !== 0) ? message?.name : null}
                error={(assetData?.name === '' && count !== 0)}
                value={assetData?.name}
              />
            </Grid> : <></>}
            <Grid item xs={12} md={12}>
              <Typography>Description</Typography>
              {assetData?.description ? (
                <ReactQuill
                  theme="snow"
                  modules={modules}
                  formats={formats}
                  value={assetData?.description}
                  onChange={handleProcedureContentChange}
                />
              ) : (
                <ReactQuill
                  theme="snow"
                  modules={modules}
                  formats={formats}
                  onChange={handleProcedureContentChange}
                />)}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={2}>
          <Box sx={{ marginTop: '80px' }}>
            <Card variant="outlined" className='tenantImageUpload' onClick={handleCardClick} raised sx={{ maxWidth: 200, maxHeight: 160, margin: '0 auto', padding: '0.1em', marginTop: '20px' }}

            >
              <label>
                {typeof selectedFile === "string" ? (
                  <img src={selectedFile} alt="Selected" className='provisioningImage configImg' />
                ) : (
                  <Stack sx={{ textAlign: 'center', cursor: "pointer", }}>
                    <Typography>
                      <UploadIcon />
                    </Typography>
                    <Typography className="logoText">
                      Click to Upload company logo
                    </Typography>
                  </Stack>
                )}
              </label>
              <input
                type="file"
                id="image-upload-input"
                onChange={(e) => getFile(e)}
                style={{ display: "none" }}
                accept="image/*"
              />

            </Card>
          </Box>
        </Grid>
        <Grid
          container
          sx={{ pt: 4, pl: 3, mt: 5 }}
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={12} md={4}>
            <TextField
              id="outlined-multiline-static"
              label="Primary Contact Name"
              size="small"
              fullWidth
              required
              defaultValue=""
              value={assetData?.primaryContact}
              helperText={(assetData?.primaryContact === '' && count !== 0) ? message?.primaryContact : null}
              error={(assetData?.primaryContact === '' && count !== 0)}
              name="primaryContact"
              onChange={handleTextChange}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              id="outlined-multiline-static"
              label="Primary Mobile Number"
              size="small"
              fullWidth
              required
              onChange={handleTextChange}
              name="mobileNo"
              defaultValue=""
              value={assetData?.mobileNo}
              helperText={(assetData?.mobileNo === '' && count !== 0) ? message?.mobileNo : null}
              error={(assetData?.mobileNo === '' && count !== 0)}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              id="outlined-multiline-static"
              label="Primary e-Mail ID"
              size="small"
              fullWidth
              required
              value={assetData?.email}
              onChange={handleTextChange}
              name="email"
              helperText={(assetData?.email === '' && count !== 0) ? message?.email : null}
              error={(assetData?.email === '' && count !== 0)}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              id="outlined-multiline-static"
              label="Secondary Contact Name"
              onChange={handleTextChange}
              size="small"
              name="secondaryContact"
              fullWidth
              value={assetData?.secondaryContact} />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="outlined-multiline-static"
              label="Secondary Mobile Number"
              onChange={handleTextChange}
              name="alternateMobileNo"
              size="small"
              fullWidth
              value={assetData?.alternateMobileNo} />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              id="outlined-multiline-static"
              label="Secondary e-Mail ID"
              onChange={handleTextChange}
              name="alternateEmail"
              size="small"
              fullWidth
              value={assetData?.alternateEmail} />
          </Grid>
          <Grid item xs={12} md={4}>
            {isLoaded && (
              <StandaloneSearchBox
                onLoad={(ref) => (inputRef.current = ref)}
                onPlacesChanged={handlePlaceChanged}
              >
                <TextField
                  error={assetData?.address === "" && count !== 0}
                  id="Street address"
                  label="Street address"

                  name="address"
                  size="small"
                  fullWidth
                  value={assetData?.address}
                  required
                  helperText={(assetData?.address === '' && count !== 0) ? message?.address : null}
                  onChange={(e) => inputchange(e)} />
              </StandaloneSearchBox>
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              error={assetData?.city === "" && count !== 0}
              id="demo-helper-text-aligned"
              label="City"
              size="small"
              fullWidth
              required
              name="city"
              value={assetData?.city}
              helperText={assetData?.city === "" && count !== 0 ? message.city : null}
              onChange={(e) => inputchange(e)} />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              error={assetData?.state === "" && count !== 0}
              id="demo-helper-text-aligned-no-helper"
              label="State"
              size="small"
              fullWidth
              name="state"
              required
              value={assetData?.state}
              onChange={(e) => inputchange(e)}
              helperText={assetData?.state === "" && count !== 0 ? message?.state : null} />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              error={assetData?.country === "" && count !== 0}
              id="demo-helper-text-aligned-no-helper"
              label="Country"
              size="small"
              fullWidth
              value={assetData?.country}
              helperText={assetData?.country === "" && count !== 0 ? message?.country : null}
              name="country"
              required
              onChange={(e) => inputchange(e)} />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              error={assetData?.zipcode === "" && count !== 0}
              id="demo-helper-text-aligned-no-helper"
              label="Zip Code"
              size="small"
              name="zipCode"
              fullWidth
              value={assetData?.zipcode}
              required
              helperText={assetData?.zipcode === "" && count !== 0 ? message.zipcode : null}
              onChange={(e) => inputchange(e)} />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="outlined-multiline-static"
              label="Website Address"
              size="small"
              fullWidth
              name="website"
              onChange={handleTextChange}
              value={assetData?.website} />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id="outlined-multiline-static"
              label="PreConstruction Period"
              size="small"
              fullWidth
              type="number"
              required
              name="preconstructionPeriod"
              disabled={workingHours === undefined || workingHours === null}
              error={((assetData?.preconstructionPeriod === "" || assetData?.preconstructionPeriod === null) && count !== 0) || (preConstructionPeriod > parseInt(assetData.preconstructionPeriod) && count !== 0)}
              helperText={((preConstructionPeriod > parseInt(assetData.preconstructionPeriod)) || assetData?.preconstructionPeriod === null) && `Please enter a value greater than or equalling ${preConstructionPeriod} days bcoz your working hours`}
              onChange={handleTextChange}
              value={assetData?.preconstructionPeriod} />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControlLabel label={"Enable Overtime for your Organization?"} labelPlacement="start" control={<Checkbox checked={assetData?.overtime !== undefined ? JSON.parse(assetData?.overtime) : false} value={assetData?.overtime !== undefined ? !JSON.parse(assetData?.overtime) : true} onChange={(e) => { setAssetData({ ...assetData, ['overtime']: e.target.value }) }} />} />
          </Grid>
          <Grid item xs={12}>
            <Grid container direction={"row"} spacing={2}>
              <Grid item xs={12} md={4} pt={2}>
                <Typography variant="h5" pb={1}>Working Hours:</Typography>
                <Grid container direction={"row"} spacing={2}>
                  <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        label="Work start time"
                        name="startTime"
                        slotProps={{
                          textField: {
                            fullWidth: true, size: "small",
                            error: (workingHours?.startTime === undefined && count !== 0)
                          }
                        }}
                        value={workingHours?.startTime === undefined ? null : dayjs(workingHours?.startTime, 'HH:mm')}
                        closeOnSelect={false}
                        onChange={(e) => inputTimeChange(e, "startTime")}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={6} pt={2}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <TimePicker
                        label="Work end time"
                        name="endTime"
                        slotProps={{
                          textField: {
                            fullWidth: true, size: "small",
                            error: (workingHours?.endTime === undefined && count !== 0)
                          }
                        }}
                        disabled={workingHours?.startTime === undefined}
                        minTime={dayjs(workingHours?.startTime, 'HH:mm').add(1, 'hour')}
                        value={workingHours?.endTime === undefined ? null : dayjs(workingHours?.endTime, 'HH:mm')}
                        closeOnSelect={false}
                        onChange={(e) => inputTimeChange(e, "endTime")}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="h5" pb={1}>Working Days:</Typography>
                <Box pl={10}>
                  {days.map((value: any, index: any) => {
                    return <FormControlLabel control={<Checkbox checked={(assetData?.workingDays !== undefined && assetData?.workingDays !== null) ? assetData?.workingDays.split(",").includes((value).toString()) : false} value={value} onChange={(e) => handleWorkingDayChange(e)} />} label={value} />
                  })}
                  {((assetData.workingDays === undefined || workingDays.length === 0) && count !== 0) && <Typography color={"red"}>{"must select one or more wokring days"}</Typography>}
                </Box>
              </Grid>
            </Grid>
          </Grid>
          {(assetData?.tenantLogo === "" && count !== 0) && <Grid xs={12} mt={2}>
            <Grid container direction="row"
              sx={{
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Typography color={"red"} variant="h5">upload tenant logo</Typography>
            </Grid>
          </Grid>}
          <Grid item xs={12} md={12}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Card sx={{ minWidth: "100%", p: 2 }}>
                <Stack direction={"row"}>
                  <Typography>
                    <MapOutlinedIcon fontSize="small" />
                  </Typography>
                  <Typography>Choose from MAP</Typography>
                </Stack>
                {isLoaded ? (
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={(assetData.location !== undefined && assetData.location !== null) ? convertLocation(assetData.location) : null}
                    zoom={15}
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                    onClick={onClick}
                  >
                    <MarkerF
                      position={(assetData.location !== undefined && assetData.location !== null) ? convertLocation(assetData.location) : null}
                    >
                    </MarkerF>

                    <></>

                  </GoogleMap>
                ) : <></>}
              </Card>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={1} ></Grid>
        <Grid container justifyContent="flex-end">
          <Box m={2} className="poFooter">
            <Button
              sx={{ marginRight: "32px" }}
              variant="contained"
              className={`ErrorTonalButton`}
              onClick={handleCancel}
            >
              Cancel
            </Button>

            {showUpdateButton ? (
              <Button
                className="button"
                variant="contained"
                size="medium"
                onClick={() => handleUpdateButtonClick()}
              >
                Update
              </Button>
            ) : (
              <Button
                className="button"
                variant="contained"
                size="medium"
                onClick={handleSaveButtonClick}
                disabled={isSaveButtonDisabled}

              >
                Save
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
      <Dialog
        open={openSave}
        onClose={handleOpenSaveCancel}
      >
        <Box paddingLeft={8} paddingRight={8}>
          <DialogContent>
            <Typography textAlign={'center'}><DoneIcon sx={{ fontSize: "80px", color: "#0ea347", stroke: "#ffffff", strokeWidth: 1 }} /></Typography>
            <Typography textAlign={'center'} fontSize={'22px'} fontWeight={'500'} marginBottom={2}>
              Added Successfully
            </Typography>
          </DialogContent>
        </Box>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button onClick={handleOpenSaveCancel} className="successButton">Ok</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openFailed}
        onClose={handleOpenFailed}
      >
        <Box paddingLeft={8} paddingRight={8} paddingTop={2}>
          <DialogContent>
            <Typography textAlign={'center'}><ErrorOutlineIcon sx={{ fontSize: "80px", color: "#ffc107", stroke: "#ffffff", strokeWidth: 1 }} /></Typography>
            <Typography textAlign={'center'} fontSize={'22px'} fontWeight={'500'} marginBottom={2}>
              Error Occured
            </Typography>
          </DialogContent>
        </Box>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button onClick={handleOpenFailed} className="successButton">Ok</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={!isValidPreConstructionPeriod}
        onClose={handleDialogClose}
      >
        <DialogTitle className='error'>
          <Box >
            <Typography textAlign={'center'}>
              <CancelOutlinedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
              <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'} >
                  Error
              </Typography>
          </Box>
        </DialogTitle>
        <Box paddingTop={2}>
          <DialogContent>
            <Typography textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>
              Unable to set the entered PreConstruction period.
            </Typography>
            <Typography textAlign={'center'} fontSize={'12px'} fontWeight={'500'} marginBottom={2}>
              {"(Your PreConstruction period is proportinal to set the working hours)"}
            </Typography>
          </DialogContent>
        </Box>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button variant='contained' sx={{ backgroundColor: "#df3333ed" }} onClick={handleDialogClose}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isDataChange} >
        <DialogTitle className='success' sx={{ backgroundColor: "rgb(255,193,0) !important" }}>
          <Box paddingLeft={8} paddingRight={8}>
            <Typography textAlign={'center'}>
              <InfoOutlinedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
          </Box>
        </DialogTitle>
        <Box paddingLeft={8} paddingRight={8}>
          <DialogContent >
            <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>No Value has been Changed</DialogContentText>
          </DialogContent>
          <DialogActions style={{ justifyContent: "center" }}>
            <Button variant='contained' className="NoChangeButton" onClick={() => handleDialogClose()}>Ok</Button>
          </DialogActions>
        </Box>
      </Dialog>
      <CustomDialog
        open={openUpdate}
        onClose={() => handleDialogClose()}
        success={updateSuccess}
        error={updateError}
        Content={updateSuccess ? "Updated successfully" : "Error could not updated"}
      />
    </Box>

    </>
  );
}

export default Tenatconfiguration;