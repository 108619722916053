import React from 'react';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { DialogTitle, Typography, DialogContent, DialogContentText, DialogActions, Button, Dialog } from '@mui/material';
import { Box } from '@mui/system';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';

const WarningDailog = ({ open, onClose, success, error, Content}) => {
    const handleClose = () => {
        onClose();
    };

    return (
        <>
            {success && (
                <Dialog open={open} >
                    <DialogTitle className='success'>
                        <Box paddingLeft={8} paddingRight={8}>
                            <Typography textAlign={'center'}>
                                <CheckCircleOutlinedIcon sx={{ fontSize: "50px", color: "white" }} />
                            </Typography>
                            <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'} >
                                Success
                            </Typography>
                        </Box>
                    </DialogTitle>
                    <Box paddingLeft={8} paddingRight={8}>
                    <DialogContent>
                        <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>
                            {Content}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: "center" }}>
                        <Button variant='contained' className="successButton" onClick={handleClose}>Ok</Button>
                    </DialogActions>
                    </Box>
                </Dialog>
            )}

            {error &&  (
                <Dialog open={open} >
                                   <DialogTitle className='warning'>
                    <Box paddingLeft={4} paddingRight={4}>
                        <Typography textAlign={'center'}>
                            <WarningAmberRoundedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
                        <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'} >
                            Warning
                        </Typography>
                    </Box>
                </DialogTitle>
                <Box paddingLeft={4} paddingRight={4}>
                    <DialogContent>
                        <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>
                            {Content}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: "center" ,marginBottom:'5px'}}>
                        <Button variant='contained' sx={{ backgroundColor: "#df3333ed" }} onClick={handleClose}>Proceed To Manual Reconciliation</Button>
                    </DialogActions>
                    </Box>
                </Dialog>
            )}
        </>
    );
};

export default WarningDailog;