/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 
* @author      : Asish
* @since       : 0.0.1
* Requirement# :
* Purpose      : Common Dashboard
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 
*
*/
import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';

import Button from '@mui/material/Button';
import OutlinedInput from '@mui/material/OutlinedInput';


import { Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormGroup, FormLabel, InputLabel, MenuItem, Rating, Theme, Typography, useTheme } from '@mui/material';
import TextField from '@mui/material/TextField';

import Grid from '@mui/material/Grid';

import Select, { SelectChangeEvent } from '@mui/material/Select';



import Stack from '@mui/material/Stack';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';







/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 17-apr-2024
* @author      : Asish
* @since       : 0.0.1
* Requirement# :
* Purpose      : ui  for adding employee details
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 
*
*
*/
import "../Employment.scss";
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { validQuestionTrade } from '../../../../services/TradeConfigurationService';
import { createResource, getResourceByEmail, updateResource } from '../../../../services/ResourceService';
import dayjs from 'dayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import CustomDialog from '../../../Dialog';
import { getRoles } from '../../../../services/RoleSevice';
import { useNavigate } from 'react-router-dom';
import { getUserDetails } from '../../../../services/EmployeeService';
import { getTenantDetails } from '../../../../services/TenantService';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


function getStyles(name: string, personName: string[], theme: Theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}


const Employementdetails: React.FC<any> = (props: any) => {
    const { accessToken } = useOidcAccessToken();
    const theme = useTheme();
    const navigate = useNavigate();
    const [additionalInfo, setAdditionalInfo] = React.useState<any>();

    const [skill, setSkill] = React.useState<any[]>([]); // Initialize as an empty array

    const [roles, setRoles] = React.useState<any[]>([]);
    const unit = [
        { name: "Weekly", value: "weekly" },
        { name: "Monthly", value: "monthly" },
        { name: "Daily", value: "daily" },
        { name: "Hourly", value: "hourly" },
    ];
    const [openDialogs, setOpenDialogs] = React.useState(false);
    const [createSuccess, setCreateSuccess] = React.useState(false);
    const [updated, setUpdated] = React.useState(false);

    const [count, setCount] = React.useState(0);
    const [noChange, setNoChange] = React.useState(false);
    const [userData, setUserData] = React.useState<any>({
        status: 'Active',
        employeeId: null,
        designation: '',
        cost: '',
        performance: '5',
        skills: [], // Change this to an empty array
        hireDate: dayjs(''),
        canOverallocate: false,
        name: props.firstname,
        unit: '',
        additionalInfo: ''
    });
    const [oldUserData, setOldUserData] = React.useState<any>({
        employeeId: null,
        designation: '',
        cost: '',
        performance: '',
        skills: [], // Change this to an empty array
        hireDate: dayjs(''),
        unit: '',
        additionalInfo: ''
    });
    const [message, setMessage] = React.useState<any>({
        cost: '',
        performance: '0',
        skills: [], // Change this to an empty array
        hireDate: '',
    });

    const [isTenantCanWorkExtraTime, setIsTenantCanWorkExtraTime] = React.useState<any>();
    const [content, setContent] = React.useState<any>();

    const onclose = () => {
        handleProfileData();
        setUpdated(true);
        setCreateSuccess(false);
        setNoChange(false);
        setOpenDialogs(false);
    }

    const handleTrades = () => {
        validQuestionTrade(accessToken)
            .then(res => {
                setSkill(res.data)
            })

    }

    const validation = () => {
        let validated: any = true;

        if (userData.skills.length === 0) {
            message.skills = 'Enter Your skills';
            validated = false;

        }

        if (userData.hireDate?.$d?.toString() === "Invalid Date") {
            message.hireDate = 'Enter Your hiredate';
            validated = false;

        }

        if (userData.performance === '') {
            message.performance = 'Enter Your performance';
            validated = false;

        }
        if (userData.cost === '') {
            message.cost = 'Enter Your COST';
            validated = false;

        }
        if (userData.unit === '') {
            message.unit = 'Select Your Unit';
            validated = false;

        }

        if (isTenantCanWorkExtraTime) {
            if ((userData.additionalInfo === '' || userData.additionalInfo === undefined || userData.additionalInfo === null) && userData.unit !== 'monthly') {
                message.overTimeCost = 'Enter OverTimeCost';
                validated = false;

            }
        }

        return validated;

    }
    const handleDateChange = (event: any) => {

        const formattedDate = dayjs(event.$d);
        setUserData({
            ...userData,
            hireDate: formattedDate,
        });
    }
    const onCancel = () => {
        navigate('/admin');

    }


    const handleInputChange = (event: any) => {
        const { name, value } = event.target;

        // If the field being changed is 'hiredate', format it accordingly
        if (name === 'skills' && Array.isArray(value)) {
            // If the field name is 'skills' and the value is an array, convert it to a comma-separated string
            setUserData({
                ...userData,
                skills: value.join(', ') // Convert array to string with comma and space
            });
        } else {
            // For other fields, proceed with default behavior
            setUserData({
                ...userData,
                [name]: value
            }); 
        }
    };

    const handleAdditionalInfoChange = (event: any, fieldName: any) => {
        const { name, value } = event.target;
        setAdditionalInfo({ ...additionalInfo, [fieldName]: value });
        setUserData({ ...userData, "additionalInfo": value })
        console.log(additionalInfo)
    }

    const handleSelectChange = (fieldName: string) => (event: SelectChangeEvent<string>) => {
        setUserData((prevUser) => ({
            ...prevUser,
            designation: event.target.value,
        }));
    };
    const formatCost = (cost) => parseFloat(cost).toFixed(2);

    const createResources = (e) => {
        console.log(userData);
        const dataToSend = { ...userData, skills: JSON.stringify(userData.skills), additionalInfo: JSON.stringify(additionalInfo) };
        setOpenDialogs(true)
        setCount(count + 1);

        let validated = validation();
        if (validated === true) {
            try {
                const response = createResource(props.email, dataToSend, accessToken)
                    .then(res => {
                        //console.log(res);

                        if (res.ok === true) {
                            setCreateSuccess(true);
                            setContent('Employee details added successfully');
                        }
                        // Additional handling if needed
                    });
            } catch (error) {
                setCreateSuccess(false)
                console.error('Error:', error);
            }

        }
        else {
            console.log("not valid")
        }


    }
    const employeeRoles = async () => {
        try {
            const response = await getRoles(accessToken);
            //console.log(response.data);
            setRoles(response.data)

        } catch (error) {
            console.error('Error fetching roles:', error);
        }
    };
    const updateResources = (e) => {

        setCount(count + 1);
        let validated = validation();

        // Convert props?.rows?.skills to ["Siding","Gutter"] and then stringify
        const olduserSkills = JSON.stringify(Array.isArray(oldUserData.skills) ? oldUserData.skills : []);        // let skillData = JSON?.parse(props?.rows?.skills)

        // Convert userData.skills to ["Siding","Gutter"] and then stringify
        const userSkillsString = JSON.stringify(Array.isArray(userData.skills) ? userData.skills : []);
        const areCostsEqual = formatCost(oldUserData.cost) === formatCost(userData.cost);
        const oldHireDate = dayjs(oldUserData.hireDate);
        const newHireDate = dayjs(userData.hireDate);

        const oldHireDateISO = oldHireDate.isValid() ? oldHireDate.toISOString() : null;
        const newHireDateISO = newHireDate.isValid() ? newHireDate.toISOString() : null;

        if (areCostsEqual && oldUserData.performance === userData.performance
            && oldHireDateISO === newHireDateISO && olduserSkills === userSkillsString
            && oldUserData.designation === userData.designation
            && oldUserData.unit === userData.unit && JSON.stringify(additionalInfo) === JSON.stringify(oldUserData.additionalInfo)) {
            setNoChange(true);

        }
        else {
            if (validated === true) {
                const dataToSend = { ...userData, skills: JSON.stringify(userData.skills), additionalInfo: JSON.stringify(additionalInfo) };
                let userId = null;
                if (userData.designation === 'admin') {
                    userId = oldUserData?.employeeId || userData?.employeeId || userData.user;

                }
                else {
                    userId = oldUserData?.employeeId || userData?.employeeId;

                }

                updateResource(dataToSend, userId, accessToken)
                    .then(res => {
                        setOpenDialogs(true);

                        //console.log(res);
                        try {
                            if (res.ok === true) {

                                setCreateSuccess(true);
                                setContent('Employee details Updated successfully');

                            }
                        }
                        catch (error) {
                            setCreateSuccess(false);

                            console.error('Error:', error);
                        }
                        // Additional handling if needed
                    });
            }
            else {
                setOpenDialogs(true);
                setCreateSuccess(false);
                setContent("Error Couldn't Updated");
            }
        }

    }
    const handleNoChange = (event?: React.SyntheticEvent | Event, reason?: string) => {

        setNoChange(false)

    }

    const handleProfileData = async () => {
        const response = await getUserDetails(userData?.employeeId, accessToken);

        if (response.status === 200 || response.status === 201) {
            getResourceByEmail(response?.data?.email, accessToken)
                .then(res => {
                    console.log(res);
                    if (res.data !== null) {
                        setOldUserData({
                            ...oldUserData,
                            role: res.data.designation || '',
                            employeeId: res.data.user || '',
                            hireDate: res.data.hireDate || '',
                            skills: res.data?.skills ? JSON.parse(res.data.skills) : [],
                            cost: res.data.cost || '',
                            designation: res.data.designation,
                            performance: res.data.performance,
                            unit: res.data.unit

                        });
                    }
                });
            // Your logic here, for example:
            setUpdated(true);
        } else {
            console.error('Error fetching user details:', response.status);
            // Optionally handle errors here, such as showing an error message or retrying
        }

    };

    const getTenantDetail = async () => {
        const response = await getTenantDetails(accessToken);
        if (response.data !== undefined && (response.status === 200 || response.status === 201)) {
            setIsTenantCanWorkExtraTime(JSON.parse(response.data?.overtime));
        }
    }
    // getTradeNames
    React.useEffect(() => {
        //getLocation();

        getTenantDetail();
        handleTrades();
        if (props?.rows?.id !== null) {
            setUserData({
                ...userData,
                role: props.rows.designation || '',
                employeeId: props.rows.user || '',
                hireDate: props.rows.hireDate || '',
                skills: props?.rows?.skills ? JSON.parse(props.rows.skills) : [],
                cost: props.rows.cost || '',
                designation: props.rows.designation,
                performance: props?.rows?.performance || '',
                unit: props?.rows?.unit,
                additionalInfo: props?.rows?.additionalInfo === undefined ? "" : JSON.parse(props?.rows?.additionalInfo)
            });

            setAdditionalInfo(props?.rows?.additionalInfo === undefined ? "" : JSON.parse(props?.rows?.additionalInfo));

            setOldUserData({
                ...oldUserData,
                role: props.rows.designation || '',
                employeeId: props.employeeId || '',
                hireDate: props.rows.hireDate || '',
                skills: props?.rows?.skills ? JSON.parse(props.rows.skills) : [],
                cost: props.rows.cost || '',
                designation: props.rows.designation,
                performance: props?.rows?.performance || '',
                unit: props?.rows?.unit || '',
                additionalInfo: props?.rows?.additionalInfo === undefined ? "" : JSON.parse(props?.rows?.additionalInfo)
            });
        }
        else {
            setUserData({
                ...userData,
                designation: props?.role,
                employeeId: props.employeeId
            });
            setOldUserData({
                ...oldUserData,
                designation: props?.role,
                employeeId: props.employeeId
            });
            console.log(userData);
        }
        employeeRoles();
        console.log(userData?.skills)

    }, []);
    const handleChange = (event: SelectChangeEvent<typeof skill>) => {
        //console.log(event)
        const {
            target: { value },
        } = event;
        setUserData({
            ...userData,
            // On autofill we get a stringified value.
            skills: typeof value === 'string' ? value.split(',') : value,
        });
    };


    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} >
                <Grid xs={12} md={12}>
                    <Typography variant="h3" component="h2" align='left' sx={{ mt: 1, mx: 1, py: 1 }}>
                        Employment details
                    </Typography>
                </Grid>
                <Card sx={{ minWidth: '100%', p: 3 }} className='contractCard'>
                    <Grid
                        container
                        spacing={{ xs: 2, md: 3 }}
                    >
                        <Grid item xs={12} md={12}>
                            <Typography variant='h6'>
                                Let's get down to Employee job specifics
                            </Typography>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                id="outlined-multiline-static"
                                label="Employee ID"
                                size="small"
                                fullWidth
                                defaultValue=""
                                name="employeeId"
                                value={userData.employeeId}
                                onChange={handleInputChange}
                                InputProps={{ readOnly: true }} // Make the TextField read-only

                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                <DemoContainer components={['DatePicker']}  >
                                    {props.rows.id !== null && props.rows.id !== undefined ? (
                                        <DatePicker label="Hire Date" value={dayjs(userData ? userData.hireDate : '')}
                                            slotProps={{ textField: { fullWidth: true, size: 'small' } }}
                                            name='hireDate'
                                            onChange={(e: any) => handleDateChange(e)}
                                            disabled={props.rows.hireDate !== null || props.rows.hireDate !== undefined} // Disable if role is not 'admin'

                                        />) :
                                        (<DatePicker label="Hire Date" value={userData.hireDate}
                                            slotProps={{ textField: { fullWidth: true, size: 'small', error: (userData.hireDate?.$d?.toString() === 'Invalid Date' && count !== 0), } }}
                                            name='hireDate'
                                            onChange={(e: any) => handleDateChange(e)}


                                        />)}
                                </DemoContainer>
                            </LocalizationProvider>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label" required>Role</InputLabel>
                                <Select
                                    required
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={userData.designation}
                                    label="Role"
                                    onChange={handleSelectChange('roles')}
                                    size='small'
                                >
                                    {roles?.filter(role => role.name !== 'customer') // Filter out 'labour' and 'admin' roles
                                        .map(role => (
                                            <MenuItem key={role.id} value={role.name}>
                                                {role.name}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth size="small">
                                <InputLabel id="demo-multiple-name-label" required>Skills</InputLabel>
                                <Select
                                    labelId="demo-multiple-name-label"
                                    id="demo-multiple-name"
                                    multiple
                                    value={userData?.skills || []} // Ensure value is always an array
                                    onChange={handleChange}
                                    input={<OutlinedInput label="Skills" />}
                                    MenuProps={MenuProps}
                                    name="skills"
                                    required
                                    error={(userData?.skills.length === 0 && count !== 0)}

                                >
                                    {skill?.map((name) => (
                                        <MenuItem
                                            key={name}
                                            value={name}
                                            style={getStyles(name, userData.skills, theme)}
                                        >
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>

                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <Stack spacing={1}>
                                <FormControl fullWidth className="unitStyle" >
                                    <InputLabel id="demo-simple-select-label" required>Unit</InputLabel>
                                    <Select
                                        required
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={userData.unit}
                                        label="Unit"
                                        onChange={handleInputChange}
                                        size='small'
                                        name="unit"

                                    >
                                        {unit.map(role => (
                                                <MenuItem value={role.value}>
                                                    {role.name}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Stack>
                        </Grid>

                        
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="outlined-multiline-static"
                                label="Cost"
                                size="small"
                                fullWidth
                                defaultValue=""
                                value={userData.cost}
                                required
                                name="cost"
                                onChange={handleInputChange}
                                error={(userData?.cost === '' && count !== 0)}
                                helperText={(userData?.cost === '' && count !== 0) ? message.cost : null}
                            />
                        </Grid>
                        {isTenantCanWorkExtraTime && <Grid item xs={12} md={6}>
                            <TextField
                                id="outlined-multiline-static"
                                label="Overtime cost per hour"
                                required
                                size="small"
                                type='number'
                                inputProps={{ min: 1 }}
                                fullWidth
                                defaultValue=""
                                value={additionalInfo?.overTimeCost}
                                name="overTimeCost"
                                onChange={(e) => handleAdditionalInfoChange(e, "overTimeCost")}
                                error={((additionalInfo?.overTimeCost === '' || additionalInfo?.overTimeCost === undefined) && count !== 0) && userData.unit !== 'monthly'}
                                helperText={((additionalInfo?.overTimeCost === '' || additionalInfo?.overTimeCost === undefined) && count !== 0) ? message.overTimeCost : "Overtime cost not applicable for monthly pay resource."}
                                disabled={userData.unit === 'monthly'}
                            />
                        </Grid>}
                        <Grid item xs={12} md={6} pl={10}>
                            <Typography variant="body1">Performance</Typography>
                            <Stack spacing={1}>
                                <Rating name="performance" value={userData.performance} size="medium" onChange={(e: any) => handleInputChange(e)} aria-required />
                            </Stack>
                        </Grid>
                        <Grid container spacing={2} justifyContent="flex-end " mt={2}>
                            {(props?.rows?.id !== null && props?.rows?.id !== undefined) || updated === true ? (
                                <Grid item mt={1}>
                                    <Button className='successButton' variant="contained" size="medium" onClick={updateResources} >
                                        Update
                                    </Button>
                                </Grid>
                            ) : (
                                <Grid item mt={1}>
                                    <Button className='successButton' variant="contained" size="medium" onClick={createResources} >
                                        Save
                                    </Button>
                                </Grid>
                            )}
                            <Grid item>
                                <Button className='dangerButton' variant="contained" size="medium" onClick={onCancel}>
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>

                    </Grid>


                </Card>

                <CustomDialog
                    open={openDialogs}
                    onClose={() => onclose()}
                    success={createSuccess}
                    error={!createSuccess}
                    Content={content !== undefined ? content : " Error Couldnot create"} />


                <Dialog open={noChange} >
                    <DialogTitle className='success' sx={{ backgroundColor: "rgb(255,193,0) !important" }}>
                        <Box paddingLeft={8} paddingRight={8}>
                            <Typography textAlign={'center'}>
                                <InfoOutlinedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
                        </Box>
                    </DialogTitle>
                    <Box paddingLeft={8} paddingRight={8}>
                        <DialogContent >
                            <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>No Value has been Changed</DialogContentText>
                        </DialogContent>
                        <DialogActions style={{ justifyContent: "center" }}>
                            <Button variant='contained' className="NoChangeButton" onClick={handleNoChange}>Ok</Button>
                        </DialogActions>
                    </Box>
                </Dialog>

            </Grid>

        </Box>

    )
};

export default Employementdetails;
