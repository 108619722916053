export function formatFileName(fileName: string | null | undefined): string {
    if (!fileName) return '';

    return fileName
        .replace(/([a-z])([A-Z])/g, '$1 $2')  // CamelCase to space-separated
        .replace(/_/g, ' ')                  // Convert snake_case to space-separated
        .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')  // Handle cases like 'PDFFile'
        .replace(/\s+/g, ' ')                // Remove multiple spaces
        .trim()                              // Trim spaces at the beginning or end
        .split(' ')                          // Split into words
        .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
        .join(' ');                          // Join words back
}
