/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 27-Dec-2024
* @author      : Kanimozhi
* @since       : 0.0.1
* Requirement# :
* Purpose      : Gantt Chart task view 
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/ 
* Issue/WorkItem |   DATE        |   AUTHOR     |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 3157          |  31/12/2024    |   Kanimozhi  |   single and multi project data implemented 
* 3157          |  21/01/2025    |   Kanimozhi  |   project name fileds added
* 3157          |  23/01/2025    |   Kanimozhi  |   project id fileds added
* 3438          |  03/02/2025    |   Kanimozhi  |   Project name changed 
* 3623          |  08/02/2025    |   Kanimozhi  |   Rendering slow fixed
* 
*/

import React, { useEffect, useState } from 'react';
import { deleteTaskbyId, getMyTask, getTaskByProjectId } from '../services/TaskService';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import GanttChartComponent from '../components/treegridGantt/Gantt/GanttChartComponent';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Box, Stack, Typography, Grid, CircularProgress } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { getAllProjectByStatus, taskReschedule } from '../services/ProjectService';
import dayjs from 'dayjs';
import AddTaskDialog from '../components/bidDetails/AddTaskDialog';
import AddIcon from '@mui/icons-material/Add';
import { getProjectTasks } from '../services/TaskService';

const GanttParentPage: React.FC<any> = () => {
    const { accessToken } = useOidcAccessToken();
    const [ganttData, setGanttData] = useState<any[]>([]);
    const [tasks, setTasks] = useState<any>([]);
    const location = useLocation();
    let data = location?.state?.project;
    console.log('data', data);
    let navigate = useNavigate();
    const [openDialogs, setOpenDialogs] = React.useState(false);
    const [taskList, setTaskList] = useState([] as any[]);
    const statuses = ['Payment', 'Project Active', 'Payment Approval', 'PO Generation', 'On Hold', 'Construction Complete', 'Project Complete', 'Project Closed',];
    const [groupedTasks, setGroupedTasks] = useState({});
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const fetchData = async () => {
            // console.log('test', data);
            try {
                if (data && data.id) {
                    const response = await getProjectTasks(data.id, accessToken);
                    // console.log('resource',res.data);
                    setTasks(response.data);
                } else {
                    // If `data` is undefined, null, or empty, fetch all projects and their tasks
                    const projectsResponse = await getAllProjectByStatus(statuses, 0, 100, accessToken);
                    console.log('projects', projectsResponse);
                    const projects = projectsResponse.data.map((proj) => ({
                        id: proj.id,
                        jobNo: proj.jobNo,
                        ProjectName: proj.trade
                    }));

                    const grouped = {};
                    for (const { id, jobNo, ProjectName } of projects) {
                        // const response = await getTasksByProjectId(id, accessToken);
                        const response = await getProjectTasks(id, accessToken);
                        grouped[id] = {
                            ProjectName,
                            jobNo,
                            tasks: response.data,
                            ProjectId: id,
                        };
                    }
                    setGroupedTasks(grouped);
                    console.log('grouped', grouped);
                }
                setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
            }

            finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [accessToken]);  // Re-run this effect whenever `data` or `accessToken` changes

    const handleGanttDataUpdate = async (updatedData: any) => {
        if (!updatedData?.Changes || updatedData?.Changes.length === 0) {
            console.warn('No changes detected in the updated data.');
            return;
        }

        for (const updatedItem of updatedData.Changes) {
            console.log('Processing updated item:', updatedItem);

            const taskId = parseInt(updatedItem?.id);
            const matchingTask = tasks.map((phase: any) => phase.tasks).flat().filter((task: any) => task?.taskId === taskId);

            console.log('matching task', matchingTask[0]);
            if (!matchingTask) {
                console.warn(`No matching task found for taskId: ${taskId}`);
                continue;
            }

            if (updatedItem.Deleted) {
                console.log('Deleting task:', matchingTask);

                try {
                    const result = await deleteTaskbyId(matchingTask[0], accessToken);
                    console.log(`Task ${taskId} deleted successfully:`, result);
                } catch (error) {
                    console.error(`Error deleting task ${taskId}:`, error);
                }
            } else {
                console.log('Updating task:', matchingTask);

                try {
                    const wbsParts = (matchingTask[0].wbs || "").split(".").slice(0, -1);
                    const startDate = updatedItem?.Start
                        ? dayjs(updatedItem?.Start).format('YYYY-MM-DDTHH:mm:ss[Z]')
                        : matchingTask.startTime;
                    const endDate = dayjs(updatedItem?.End).format('YYYY-MM-DDTHH:mm:ss[Z]');

                    reschedule(
                        taskId,
                        wbsParts.join("."),
                        startDate,
                        endDate,
                        updatedItem
                    );

                    console.log(`Task ${taskId} updated successfully.`);
                    setGanttData(updatedData);
                } catch (error) {
                    console.error(`Error updating task ${taskId}:`, error);
                }
            }
        }
    };

    const reschedule = async (taskId: any, wbs: any, startTime: any, endTime: any, updatedata: any) => {

        console.log('updatedata', updatedata)

        try {
            const result = await taskReschedule(data.id, taskId, wbs, startTime, endTime, accessToken);
            console.log('result', result);

            const response = await getProjectTasks(data.id, accessToken);
        } catch (error) {
            console.error('Error in reschedule:', error);
        }
    };

    const handleClose = (state: any) => {
        navigate('/soldproduction');
    };
    const clickAddManual = async () => {
        const response1 = await getMyTask(accessToken);
        setTaskList(response1.data);
        setOpenDialogs(true);
    }

    const handleAddTaskDialog = () => {
        setOpenDialogs(false);
    }

    return (

        <>


            <Grid item xs={12}>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mb={3}
                >
                    <Button
                        onClick={handleClose}
                        variant="contained"
                        startIcon={<ArrowBackIcon />}
                        className="greyTonalButton"
                        sx={{ marginLeft: "32px" }}
                    >
                        Previous
                    </Button>
                    <Button
                        onClick={clickAddManual}
                        variant="contained"
                        startIcon={<AddIcon />}
                        className="createbidbtnnext"
                        sx={{ marginRight: "32px" }}
                    >
                        Add Task
                    </Button>
                </Box>



                {data && (<Typography
                    variant="h4"
                    sx={{ mb: 2, color: 'text.primary', p: 2 }}
                >
                    {data.description} - Project ID :{data.jobNo}
                </Typography>)}
            </Grid>
            {loading ? (
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    height="50vh" // Ensures it takes half of the viewport height
                >
                    <CircularProgress />
                    <Typography variant="h6" color="textSecondary" sx={{ mt: 2 }}>
                        Loading, please wait...
                    </Typography>
                </Box>

            ) : (
                <>
                    {data ? (
                        <GanttChartComponent selected={tasks} projectId={data?.id} status="single" onGanttDataUpdate={handleGanttDataUpdate} />
                    ) : (
                        <GanttChartComponent selected={Object.values(groupedTasks)} projectId={data?.id} status="multi" onGanttDataUpdate={handleGanttDataUpdate} />
                    )}
                    {openDialogs &&
                        <AddTaskDialog open={openDialogs}
                            onClose={handleAddTaskDialog}
                            state={"Gps"}
                            projectId={data.id}
                            id={taskList[0]?.taskId} />}
                </>

            )}

        </>
    );
};

export default GanttParentPage;
