import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Typography, Box, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, FormControl, InputLabel, Select, MenuItem, IconButton, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Backdrop, CircularProgress } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import dayjs from 'dayjs';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { checkDeliveryExists, confirmDeliveyBill, getDIResults, uploadBill, uploadReceipt } from '../../services/DeliverySlipService';
import { type } from 'os';
import { getCOAAccountTypes } from '../../services/JobLedgerService';
import { getGradesByNameAndVendorId, getMaterialsByNameAndGrade } from '../../services/materialService';
import { getUnReceivedPODetails, getPOItemDetails } from '../../services/PoService';
import { getVendorNames, getVendorsAddressByName, getMaterialsByAddress } from '../../services/VendorService';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import CloseIcon from '@mui/icons-material/Close';
import CustomDialog from '../../pages/Dialog';
import WarningDailog from '../../pages/warningDialog';

const DeliveryPOComponent = (props: any) => {
    const { accessToken, accessTokenPayload } = useOidcAccessToken();

    const [totalCost, setTotalCost] = React.useState<any>('');
    const [poData, setPoData] = React.useState(props?.poData);
    const [newRows, setNewRows] = React.useState<any>([]);
    const [updated, setUpdated] = React.useState(false);
    const [poItems, setPoItems] = React.useState<any>([]);
    const [notPOItems, setNotPOItems] = React.useState(false);
    const [notLinkedItems, setNotLinkedItems] = React.useState<any>([]);
    const [notLinkedLessItems, setNotLinkedLessItems] = React.useState<any>([]);
    const [bill, setBill] = useState<any[]>([]); // Initialize with an empty array
    const [type, setType] = useState('');
    const [orderNo, setOrderNo] = useState('');
    const [billNo, setBillNo] = useState('');
    const [billDate, setBillDate] = useState('');
    const [pOId, setPOId] = useState<any[]>([]);
    const [isOpen, setIsOpen] = useState(true);
    const [isPaid, setIsPaid] = useState(false);
    const [isAck, setIsAck] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState('');
    const [accountType, setAccountType] = useState('');
    const [paid, setPaid] = useState('');
    const [lessData, setLessData] = React.useState(false);

    const [validatePO, setValidatePO] = React.useState(false);
    const [excessPOItems, setExcessPOItems] = React.useState<any>([]);
    const [excessData, setExcessData] = React.useState(false);
    const [validateDI, setValidateDI] = React.useState(false);
    const [lessPOItems, setLessPOItems] = React.useState<any>([]);

    const [openDialogs, setOpenDialogs] = React.useState(false);
    const [createSuccess, setCreateSuccess] = React.useState(false);
    const [dcuploaded, setDcUploaded] = React.useState(false);
    const [createConfirmSuccess, setCreateComfirmSuccess] = React.useState(false);
    const [createConfirmError, setCreateCofirmError] = React.useState(false);
    const [poIds, setPoIds] = React.useState<any>(['']);
    const [selectedPO, setSelectedPO] = React.useState<any>();
    const [createErrorDI, setCreateErrorDI] = React.useState(false);
    const [createSuccessDI, setCreateSuccessDI] = React.useState(false);

    const [createError, setCreateError] = React.useState(false);
    const [dragOver, setDragOver] = useState(false);
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState<any>([]);
    const [selectedfile, SetSelectedFile] = useState<{ filename: any; filetype: any; fileimage: string | ArrayBuffer | null; datetime: any; filesize: string; }[]>([]);
    const [imagePreview, setImagePreview] = useState<string | null>(null);
    const [selectedItems, setSelectedItems] = useState<any>([]);
    const [openReturnable, setOpenReturnable] = React.useState(false);

    const [vendorAddressList, setVendorAddressList] = React.useState<any>();
    const [vendorAddress, setVendorAddress] = React.useState<any>();
    const [vendorName, setVendorName] = React.useState<any>();
    const [vendorList, setVendorList] = React.useState<any>([]);
    const [accountList, setAccountList] = React.useState<any>([]);
    const [count, setCount] = React.useState<any>(0);
    const [openVendorForm, setOpenVendorForm] = React.useState(false);
    const [confirmResult, setConfirmResult] = React.useState('');

    const [materialData, setMaterialData] = React.useState<any>();
    const [vendorId, setVendorId] = React.useState<any>();
    const [open, setOpen] = React.useState<boolean>(false);
    const [openConfirmForm, setOpenConfirmForm] = React.useState<boolean>(false);

    const [gradeList, setGradeList] = React.useState<any>();
    const [materialList, setMaterialList] = React.useState<any>([]);
    const DELIVERYSLIP = 'DeliverySlip';

    const onInputChange = (e: any, index: number) => {
        const { name, value } = e.target;

        // Prevent negative numbers and zero for numeric fields
        if ((name === "quantity" || name === "itemCode") && (Number(value) <= 0 || isNaN(Number(value)))) {
            return; // Ignore invalid input
        }

        // Create a copy of the poItems array
        const updatedPoItems = [...poData.poItems];

        // Update the specific item's property
        updatedPoItems[index][name] = value;
        updatedPoItems[index].totalCost = Number(poData?.poItems?.reduce((acc, item) => acc + Number(item.cost || 0), 0)).toFixed(2);

        // Update state with the modified poItems array
        setPoData({ ...poData, poItems: updatedPoItems });

        console.log(poData);
    };

    const dsVerification = () => {
        setOpen(true);
    }

    useEffect(() => {
        getDIResponse();
    }, []);

    useEffect(() => {
        getUnReceivedPODetails(accessToken).then((res => {
            console.log(res.data)
            if (res.data) {
                setPoIds(res.data)
            }
        }));

        // handleOpenWithTimeout();
        getVendors();
        getAccountTypes();
    }, [isOpen]);

    useEffect(() => {

    }, []);

    const onselect = async (e: any) => {
        setType(e.target.value);
    };

    const onselected = async (e: any) => {
        // console.log(e.target.value)
        setSelectedPO(e.target.value);
    };

    const onselection = async (e: any) => {
        // console.log(e.target.value)
        setAccountType(e.target.value);
    };


    const manualReturnable = async (e: any) => {
        // console.log(e.target.value)
        setAccountType(e.target.value);
    };

    const handlepaymentmethod = async (e: any) => {
        console.log(e.target.value)
        setPaymentMethod(e.target.value);
    };

    const handleOrder = async (e: any) => {
        setOrderNo(e.target.value);
    };

    const handlebillNo = async (e: any) => {
        setBillNo(e.target.value);
    };

    const handlebillDate = async (e: any) => {
        setBillDate(dayjs(e.$d).format('YYYY-MM-DD') + "T00:00:00.000Z");
    };

    const handleToggle = () => {
        setIsAck((prev) => !prev);
    };

    const onClose = () => {
        props.handleClose();
        // window.location.reload();
    }


    const handleDialogClose = () => {
        setOpenDialogs(false);


        onClose();
        window.location.reload();
    };
    const handleConfirmDialogClose = () => {
        setOpenConfirmForm(false);
        setOpenDialogs(false);
        setDcUploaded(false)
        onClose();
    };
    const handleDragOver = useCallback((event: any) => {
        event.preventDefault();
        setDragOver(true);
    }, []);

    const handleDragLeave = useCallback((event: any) => {
        event.preventDefault();
        setDragOver(false);
    }, []);

    const handleDrop = useCallback(
        (event: any) => {
            event.preventDefault();
            setDragOver(false);
            const files = event.dataTransfer.files;
            console.log(files)
            if (files && files[0]) {
                handleFileChange(files[0]);
            }
        },
        []
    );

    const handleFileChange = (file: File) => {
        console.log(file);
        setLoading(true);
        setFile(file);
        const formData = new FormData();
        formData.append('file', file);

        const reader = new FileReader();
        reader.onloadend = () => {
            setLoading(false);
            if (reader.result === null) {
                setImagePreview(null);
            } else {
                setImagePreview(reader.result as string);
            }
        };
        reader.readAsDataURL(file);
    };


    const filesizes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    let images: any = [];
    const handleChange = useCallback(
        (event: any) => {
            for (let i = 0; i < event.target.files.length; i++) {
                images.push((event.target.files[i]));
                let reader = new FileReader();
                let file = event.target.files[i];
                setFile(images);
                reader.onloadend = () => {
                    SetSelectedFile((preValue) => {
                        return [
                            ...preValue,
                            {
                                // id: shortid.generate(),
                                filename: event.target.files[i].name,
                                filetype: event.target.files[i].type,
                                fileimage: reader.result,
                                datetime: event.target.files[i].lastModifiedDate.toLocaleString('en-IN'),
                                filesize: filesizes(event.target.files[i].size)
                            }
                        ]
                    });
                }
                if (event.target.files[i]) {
                    reader.readAsDataURL(file);
                }
            }
        },
        []
    );
    const addRows = () => {
        if (!validateRows()) {
            setUpdated(false);
            return;
        }

        const addNewItem = {
            cost: "",
            costCode: "",
            customerName: '',
            delivery_status: "",
            description: "",
            id: "",
            itemCode: "",
            itemStatus: "",
            quantity: "",
            // rate: "",
            received_quantity: '',
            referenceType: "",
            service: "",
            // totalCost: ""

        };

        // Add the new item directly to poItems
        const updatedPoItems = [...poData.poItems, addNewItem];

        // Update state
        setPoData({ ...poData, poItems: updatedPoItems });
    };
    const HandleValidateDiClose = () => {
        setValidateDI(false);
        setDcUploaded(true);
        addRows();
    }

    const getVendors = async () => {
        // let result = await getAllVendors(accessToken);
        let result = await getVendorNames(accessToken);
        // handleUnauthorized(result);
        setVendorList(result.data);
    }

    const getAccountTypes = async () => {
        let result = await getCOAAccountTypes(accessToken);
        if (result.data !== undefined) {
            setAccountList(result.data);
        }
    }


    const getVendorAddressList = async (vendorName: any) => {
        let result = await getVendorsAddressByName(vendorName, accessToken);
        console.log(result.data)
        if (result.data !== undefined) {
            setVendorAddressList(result.data);
        }
    }

    const openReturnableItems = () => {
        setOpenReturnable(true);
    }
    const openVendorRegForm = () => {
        setOpenVendorForm(true);
    };




    const openForm = () => {
        setOpen(true);
    }

    const validateRows = () => {
        // Check if all rows are valid
        for (const item of poData.poItems) {
            if (!item.description || !item.itemCode || !item.quantity) {
                return false;
            }
        }
        return true;
    };
    const validatePOLinked = () => {
        // Check if all rows are valid
        for (const item of poData.poItems) {
            if (!item.poItemId) {
                if (item.itemStatus !== 'returnable') {
                    return false;
                }

            }
        }
        return true;

    };
    const getPONotLinked = () => {
        // Initialize an array to store descriptions that match the condition
        const notLinkedItems: any = [];

        // Check if any rows are not linked
        for (const item of poData.poItems) {
            if (!item.poItemId && item.itemStatus !== 'returnable') {
                notLinkedItems.push(item.description); // Add description to the array
            }
        }

        // Validation for the notLinkedItems
        if (notLinkedItems.length === 0) {
            // If no items were found, show an appropriate message or handle it
            console.log('No unlinked items found.');
            return null; // or you can return an empty array or a specific message
        }
        console.log(notLinkedItems)
        // Join the descriptions with a comma separator and return the result
        return notLinkedItems.join(', '); // Join descriptions with comma and space
    };
    const updatedPoItems = () => {
        setUpdated(true);
        const updatedItems = poItems
            .map((item) => {
                // Find the matching item in poData.poItems
                const matchingItem = poData.poItems.find((poItem) => poItem.poItemId === item.id);
                let received_quantity = 0;
                if (item.received_quantity > 0) {
                    received_quantity = item.quantity - item.received_quantity;
                }
                else {
                    received_quantity = item.quantity;
                }
                // Check if the matching item's quantity is greater than the current item's quantity
                if (matchingItem && matchingItem.quantity > received_quantity) {
                    // Calculate the quantity difference
                    const quantityDifference = matchingItem.quantity - received_quantity;

                    // Update the matching item in poData.poItems with the new fields
                    setPoData((prevPoData) => ({
                        ...prevPoData,
                        poItems: prevPoData.poItems.map((poItem) =>
                            poItem.poItemId === item.id
                                ? { ...poItem, isQuantityGreater: true, quantityDifference: quantityDifference }
                                : poItem
                        ),
                    }));
                    // Return the updated item
                    return { ...item, isQuantityGreater: true, quantityDifference };
                }

                // If no matching item is found or quantity is not greater, return null
                return null;
            })
            .filter((item) => item !== null); // Filter out unchanged items

        return updatedItems;
    };

    const lessPoItems = () => {
        setUpdated(true);
        const updatedItems = poItems
            .map((item) => {
                // Find the matching item in poData.poItems
                const matchingItem = poData.poItems.find((poItem) => poItem.poItemId === item.id);
                let received_quantity = 0;
                if (item.received_quantity > 0) {
                    received_quantity = item.quantity - item.received_quantity;
                }
                else {
                    received_quantity = item.quantity;
                }
                // Check if the matching item's quantity is greater than the current item's quantity
                if (matchingItem && matchingItem.quantity < received_quantity) {
                    // Calculate the quantity difference
                    const quantityDifference = received_quantity - matchingItem.quantity;

                    // Update the matching item in poData.poItems with the new fields
                    setPoData((prevPoData) => ({
                        ...prevPoData,
                        poItems: prevPoData.poItems.map((poItem) =>
                            poItem.poItemId === item.id
                                ? { ...poItem, isQuantityGreater: false, quantityDifference: quantityDifference }
                                : poItem
                        ),
                    }));
                    // Return the updated item
                    return { ...item, isQuantityGreater: true, quantityDifference };
                }

                // If no matching item is found or quantity is not greater, return null
                return null;
            })
            .filter((item) => item !== null); // Filter out unchanged items

        return updatedItems;
    };
    const handleCheckboxChange = (index) => {
        setSelectedItems((prevSelectedItems) => {
            if (prevSelectedItems.includes(index)) {
                console.log(prevSelectedItems)
                return prevSelectedItems.filter((item) => item !== index);
            } else {
                return [...prevSelectedItems, index];
            }
        });
    };

    const pendingPoItems = () => {
        setUpdated(true);
        const updatedItems = poItems
            .map((item) => {
                // Find the matching item in poData.poItems
                const matchingItem = poData.poItems.find((poItem) => poItem.poItemId === item.id);

                // Check if the matching item's quantity is greater than the current item's quantity
                if (matchingItem && item.quantity > matchingItem.quantity) {
                    // Calculate the quantity difference
                    const quantityDifference = item.quantity - matchingItem.quantity;

                    // Update the matching item in poData.poItems with the new fields
                    setPoData((prevPoData) => ({
                        ...prevPoData,
                        poItems: prevPoData.poItems.map((poItem) =>
                            poItem.poItemId === item.id
                                ? { ...poItem, isQuantityGreater: true, quantityDifference: quantityDifference }
                                : poItem
                        ),
                    }));
                    console.log(poData, poItems)
                    // Return the updated item
                    return { ...item, isQuantityGreater: false, quantityDifference };
                }

                // If no matching item is found or quantity is not greater, return null
                return null;
            })
            .filter((item) => item !== null); // Filter out unchanged items

        return updatedItems;
    };

    const getPurchaseItems = (poId: any) => {
        getPOItemDetails(poId, accessToken).then((res => {
            console.log(res.data)
            if (res.data) {
                getPurchaseItems(res.data?.id);
                setPoItems(res.data)

            }

        }))
    }

    const handleMarkableClose = () => {
        setUpdated(false);
        setValidatePO(false);
    }
    const markReturnable = () => {
        const updatedPoItems = [...poData.poItems]; // Create a copy of poItems

        for (const item of updatedPoItems) {
            if (!item.poItemId && item.itemStatus !== 'returnable') {
                item.itemStatus = 'returnable'; // Update the itemStatus to 'returnable'
                item.poItemId = null;

                console.log(item)
            }
        }
        console.log(updatedPoItems)
        setUpdated(true)
        // Update the state with the modified poItems
        setPoData({ ...poData, poItems: updatedPoItems });
        setNotPOItems(false);
        setUpdated(true);
        ConfirmDeliveryItems();
    };

    const calculateTotalCost = (materialList: any) => {
        let total = 0;
        for (let i = 0; i < materialList?.length; i++) {
            total = materialList[i]?.totalCost + total;
        }
        setTotalCost(total.toString());
    }
    const getDIResponse = () => {

        console.log(props?.receiptId);

        if (props?.receiptId != null) {
            setLoading(true);

            getDIResults(props?.receiptId, accessToken)
                .then((resData) => {
                    console.log(resData.data);
             

                    if (resData?.data) {
                        setPoData(resData?.data);
                        getPurchaseItems(resData?.data?.id);
                        if (resData?.data?.poItems != null && resData?.data?.poItems.length > 0) {
                            setValidateDI(true);
                            setCreateErrorDI(false);
                            setCreateSuccessDI(true)

                        }
                        else {
                            setValidateDI(true);
                            setCreateErrorDI(true);
                            setCreateSuccessDI(false);

                        }
                        // Ensure poItems exists before accessing its length
                        if (!resData?.data?.poItems || !Array.isArray(resData.data.poItems) || resData.data.poItems.length === 0) {
                            addRows();
                        }
                    } else {
                        console.error("Received undefined data from API");
                        addRows(); // Add default rows if data is missing
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                })
                .finally(() => {
                    setLoading(false); // Ensure loading is always turned off
                });
        }
    };


    const handleMarkReturnable = () => {
        // Update the status of selected items to 'returnable' and others to null
        const updatedPoItems = poData.poItems.map((item, index) => {
            if (selectedItems.includes(index)) {
                return { ...item, itemStatus: 'returnable', poItemId: null };
            } else {
                return { ...item, itemStatus: 'delivered' };
            }
        });
        // Update the poData state with updatedPoItems
        console.log(updatedPoItems)
        setPoData({ ...poData, poItems: updatedPoItems });
        setOpenReturnable(false);
    };
    const onSelectChange = (e: any, index: number) => {
        const { name, value } = e.target;

        // Create a copy of the poItems array
        const updatedPoItems = [...poData.poItems];

        // Update the specific item's property
        updatedPoItems[index][name] = value;
        console.log(updatedPoItems);
        // Update state with the modified poItems array
        setPoData({ ...poData, poItems: updatedPoItems });
    };
    const handleRemoveItem = (index: any) => {
        let newArray = [...poData?.poItems];
        newArray.splice(index, 1)
        // setInvoiceData({ ...invoiceData, taxInfo: newArray });
        let totalTaxAmount = 0

        newRows.pop();
        setPoData({ ...poData, poItems: newArray });
    }
    const ConfirmDeliveryItems = () => {

        if (!updated) {
            setNotLinkedItems([]);
            setLessPOItems([]);
            setExcessPOItems([]);


            if (!validateRows()) {
                setUpdated(false);
                return;
            }

            const excessData = updatedPoItems();
            console.log(excessData, excessData.length > 0);
            const lessData = lessPoItems();
            console.log(excessData, lessData);

            if (excessData.length > 0) {
                setExcessPOItems(excessData);
                setValidatePO(true);
                setExcessData(true);
                setNotPOItems(false);
            }

            if (lessData.length > 0) {
                setLessPOItems(lessData);
                setValidatePO(true);
                setLessData(true);
                setNotPOItems(false);
            }
            if (!validatePOLinked()) {
                setNotLinkedItems(getPONotLinked() || []);
                setValidatePO(true)
                setNotPOItems(false);
                // return;
            }
            else if (excessData.length <= 0 && lessData.length <= 0 && !validatePOLinked()) {
                confirmDeliveyBill(poData?.receiptId, poData, accessToken).then(res => {
                    console.log(res);
                    if (res.data) {
                        setOpenConfirmForm(true);
                        if (res.data && (res.status === 200 || res.status === 201)) {
                            setCreateComfirmSuccess(true);
                            setCreateCofirmError(false);
                            setConfirmResult(res.data);
                        } else {
                            setOpenConfirmForm(true);
                            setCreateCofirmError(true);
                        }
                    } else {
                        setOpenConfirmForm(true);
                        setCreateCofirmError(true);
                    }
                });
            }
        }
        else {
            const res = confirmDeliveyBill(poData?.receiptId, poData, accessToken).then(res => {
                console.log(res);
                if (res.data) {
                    setOpenConfirmForm(true);
                    if (res.data && (res.status == 200 || res.status == 201)) {
                        setCreateComfirmSuccess(true);
                        setCreateCofirmError(false)

                        setConfirmResult(res.data);
                    }
                    // else {
                    //     setOpenConfirmForm(true);
                    //     setCreateCofirmError(true);
                    //     setConfirmResult(res.data);

                    // }

                }
                else {
                    setOpenConfirmForm(true);
                    setCreateCofirmError(true)
                }
            });
            console.log(res);
        }
    }

    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth={'lg'}
                open={props.open}
            // onClose={()=>onClose()}
            >
                {loading ? (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={loading}
                    >
                        <Stack direction="column" spacing={2} alignItems="center">
                            <Typography variant="h6">Automatic Reconciliation Using Document Intelligence</Typography>
                            <CircularProgress color="inherit" />
                        </Stack>
                    </Backdrop>
                ) : (
                    <>
                        <DialogTitle>
                            <Box display="flex" alignItems="center">
                                <Box flexGrow={1} >
                                    <Typography className='unPaidHead'>PO DeliverSlip Comparison</Typography>
                                </Box>
                                <Box>
                                    <IconButton onClick={() => onClose()}>
                                        <CloseIcon sx={{ color: "red" }} />
                                    </IconButton>
                                </Box>
                            </Box>
                        </DialogTitle>
                        <DialogContent  >

                            <Grid item xs={12}>
                                <Grid container className='invoiceHeader' rowSpacing={0} columnSpacing={0}>
                                    {/* Header - Tenant Details */}
                                    <Grid item xs={12} sm={6}>
                                        <Typography>
                                            <img src={poData?.tenantLogo} alt="Tenant Logo" className="logo" />
                                        </Typography>
                                        <Typography paddingTop={2}>{poData?.tenantAddress}</Typography>
                                        <Typography>{poData?.tenantCity}, {poData?.tenantState}</Typography>
                                        <Typography>{poData?.tenantCountry}, {poData?.tenantPrimaryContact}</Typography>
                                    </Grid>

                                    {/* Header - Invoice Info */}
                                    <Grid item xs={12} sm={6} className='alignEnd'>
                                        <Stack>
                                            <Typography className='invoiceNumber' display="flex" alignItems="center">Delivery Number:&nbsp;#{poData?.receiptId}</Typography>
                                            <Typography display="flex" alignItems="center">PO Number:&nbsp;#{poData?.id}</Typography>
                                            <Typography>Date Issued: {dayjs(poData?.billDate).format("DD MMM YYYY")}</Typography>
                                            <Typography display="flex" alignItems="center">Delivery Date: {poData?.deliveryDate && dayjs(poData?.deliveryDate).isValid()
                                                ? dayjs(poData?.deliveryDate).format('DD MMM YYYY')
                                                : "No delivery date available."}
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                </Grid>

                                <Box m={2} mb={4} sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
                                    {/* Vendor Details */}
                                    <Box sx={{ flex: 1 }}>
                                        <Typography variant="h5" sx={{ fontSize: "16px", color: "primary.secondary" }} mb={1}>
                                            Vendor Details:
                                        </Typography>
                                        <Box ml={1}>
                                            <Typography display="flex" alignItems="center">{poData?.vendorName}</Typography>
                                            <Typography display="flex" alignItems="center">{poData?.vendorEmail}</Typography>
                                            <Typography display="flex" alignItems="center">{poData?.vendorAddress}</Typography>
                                            <Typography display="flex" alignItems="center">{poData?.vendorMobileNo}</Typography>
                                        </Box>
                                    </Box>

                                    {/* Customer Details */}
                                    <Box sx={{ flex: 1 }}>
                                        <Typography variant="h5" sx={{ fontSize: "16px", color: "primary.secondary" }} mb={1}>
                                            Customer Details:
                                        </Typography>
                                        <Box ml={1}>
                                            <Typography display="flex" alignItems="center">{poData?.customerName}</Typography>
                                            <Typography display="flex" alignItems="center">{poData?.customerEmail}</Typography>
                                            {poData?.customerAddress && (() => {
                                                try {
                                                    const addressObj = JSON.parse(poData.customerAddress);
                                                    return (
                                                        <>
                                                            <Typography display="flex" alignItems="center">A{addressObj.address || "N/A"},{addressObj.city || "N/A"}, {addressObj.city || "N/A"},{addressObj.zipCode || "N/A"},{addressObj.country || "N/A"}</Typography>
                                                        </>
                                                    );
                                                } catch (error) {
                                                    console.error("Invalid JSON in customerAddress:", error);
                                                    return <Typography display="flex" alignItems="center">Invalid Address Data</Typography>;
                                                }
                                            })()}                                            <Typography display="flex" alignItems="center">{poData?.customerMobileNo}</Typography>
                                        </Box>
                                    </Box>
                                </Box>

                                <TableContainer component={Paper} sx={{ marginBottom: 3 }}>
                                    <Table>
                                        <TableHead>
                                            <TableRow className='tableHead'>
                                                <TableCell>Item Name</TableCell>
                                                <TableCell>Code</TableCell>
                                                <TableCell>Quantity</TableCell>
                                                <TableCell>Link PO Items</TableCell>
                                                <TableCell>Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {poData?.poItems?.length > 0 ? (
                                                poData?.poItems?.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>
                                                            <TextField id="standard-basic" variant="standard"
                                                                type="text"
                                                                value={row?.description}
                                                                name="description"
                                                                error={!updated && !row?.description}
                                                                helperText={!updated && !row?.description ? ("enter description ") : ("")}
                                                                sx={{
                                                                    '& .MuiInputBase-input': {
                                                                        color: !row.poItemId && row.itemStatus !== 'returnable' ? 'red !important' : 'inherit',
                                                                    },
                                                                }}
                                                                onChange={(e) => onInputChange(e, index)}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField id="standard-basic" variant="standard"
                                                                type="number"
                                                                value={row?.itemCode}
                                                                name="itemCode"
                                                                error={!updated && !row?.itemCode}
                                                                helperText={!updated && !row?.itemCode ? ("enter item code ") : ("")}
                                                                sx={{
                                                                    '& .MuiInputBase-input': {
                                                                        color: !row.poItemId && row.itemStatus !== 'returnable' ? 'red !important' : 'inherit',
                                                                    },
                                                                }}
                                                                onChange={(e) => onInputChange(e, index)}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField id="standard-basic" variant="standard"
                                                                type="number"
                                                                value={row?.quantity}
                                                                name="quantity"
                                                                error={!updated && !row?.quantity}
                                                                helperText={!updated && !row?.quantity ? ("enter quantity ") : ("")}
                                                                sx={{
                                                                    '& .MuiInputBase-input': {
                                                                        color: !row.poItemId && row.itemStatus !== 'returnable' ? 'red !important' : 'inherit',
                                                                    },
                                                                }}
                                                                onChange={(e) => onInputChange(e, index)}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            {row?.itemStatus === 'returnable' ? (
                                                                <Box sx={{ display: 'flex', alignItems: 'center', color: 'green' }}>
                                                                    <Typography variant="body2" color="green">
                                                                        Returnable Item
                                                                    </Typography>
                                                                    <CheckCircleIcon fontSize="small" sx={{ marginLeft: 1 }} />
                                                                </Box>
                                                            ) : (
                                                                <FormControl fullWidth size="small">
                                                                    <InputLabel id="po-select-label">Purchase Order</InputLabel>
                                                                    <Select
                                                                        labelId="po-select-label"
                                                                        name="poItemId"
                                                                        label="PO Item name"
                                                                        value={row?.poItemId}
                                                                        onChange={(e) => onSelectChange(e, index)}
                                                                        displayEmpty
                                                                    >
                                                                        <MenuItem value="" disabled>
                                                                            Select a Purchase Order
                                                                        </MenuItem>
                                                                        {poItems.map((item) => {
                                                                            const isDisabled = poData.poItems.some((poItem) => poItem.poItemId === item.id);
                                                                            return (
                                                                                <MenuItem key={item.id} value={item.id} disabled={isDisabled}>
                                                                                    {item.description}
                                                                                </MenuItem>
                                                                            );
                                                                        })}
                                                                    </Select>
                                                                </FormControl>
                                                            )}
                                                        </TableCell>
                                                        <TableCell>
                                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                <IconButton onClick={() => handleRemoveItem(index)}>
                                                                    <DeleteIcon fontSize='small' />
                                                                </IconButton>
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                ))
                                            ) : (
                                                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', height: '100%' }}>
                                                    <Stack>
                                                        <Stack><Typography>Cannot able to read Delivery Items</Typography></Stack>
                                                        <Stack> <Button onClick={addRows}>Add Row</Button></Stack>
                                                    </Stack>
                                                </Box>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                <Grid item xs={12} sm={12} mb={2}>
                                    <Box className="alignEnd">
                                        <Stack direction="column" alignItems="flex-end" spacing={1}>
                                            <Button onClick={() => setOpenReturnable(true)}>Mark Returnable Items?</Button>
                                            <Button onClick={addRows}>Add Row</Button>
                                        </Stack>
                                    </Box>
                                </Grid>

                            </Grid>
                        </DialogContent>
                        <DialogActions>     <Button className='sucessButton' onClick={ConfirmDeliveryItems}>Complete</Button>
                            <Button className='primaryTonalButton' onClick={onClose}>Cancel</Button></DialogActions>
                    </>)} </Dialog>
            {openReturnable && (
                <Dialog open={openReturnable} onClose={() => setOpenReturnable(false)}>
                    <DialogTitle>Mark Returnable Items</DialogTitle>
                    <DialogContent>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Select</TableCell>
                                        <TableCell>Item Name</TableCell>
                                        <TableCell>Code</TableCell>
                                        <TableCell>Quantity</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {poData.poItems.filter(row => row.description?.trim() !== "" && row.itemCode?.trim() !== "" && row.quantity).length > 0 ? (
                                        poData.poItems
                                            .filter(row => row.description?.trim() !== "" && row.itemCode?.trim() !== "" && row.quantity) // Exclude empty items
                                            .map((row, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>
                                                        <Checkbox
                                                            checked={selectedItems?.includes(index)}
                                                            onChange={() => handleCheckboxChange(index)}
                                                        />
                                                    </TableCell>
                                                    <TableCell>{row.description}</TableCell>
                                                    <TableCell>{row.itemCode}</TableCell>
                                                    <TableCell>{row.quantity}</TableCell>
                                                </TableRow>
                                            ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={4} align="center">
                                                No items available
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenReturnable(false)}>Cancel</Button>
                        <Button onClick={handleMarkReturnable}>Apply</Button>
                    </DialogActions>
                </Dialog>
            )}
            {validatePO && (<>   <Dialog open={validatePO} >
                <DialogTitle className='warning'>
                    <Box paddingLeft={8} paddingRight={8}>
                        <Typography textAlign={'center'}>
                            <WarningAmberRoundedIcon sx={{ fontSize: "50px", color: "white" }} /></Typography>
                        <Typography textAlign={'center'} color='white' fontSize={'20px'} fontWeight={'400'} >
                            Warning
                        </Typography>
                    </Box>
                </DialogTitle>
                <Box paddingLeft={8} paddingRight={8}>
                    <DialogContent>
                        <DialogContentText textAlign={'center'} fontSize={'18px'} fontWeight={'500'}>
                            <div>
                                {excessData && (
                                    <ul>
                                        <small style={{ color: 'black' }}>There are some excess Items in Delivery Slips</small>
                                        {excessPOItems.map(item => (
                                            <li key={item.id}>
                                                Item:<span style={{ color: "#cd4a4a !important" }} className="danger">&nbsp; {item.description}</span>, Quantity Difference:<span style={{ color: "#cd4a4a !important" }}> &nbsp;{item.quantityDifference}</span>
                                            </li>
                                        ))}
                                        <small>These will be Considered as Returnable.</small>
                                    </ul>
                                )}
                                <Grid mt={2}></Grid>
                                {lessData && (
                                    <ul>
                                        <small style={{ color: 'black' }}>There are some Less Quantity Items in Delivery Slips</small>
                                        {lessPOItems.map(item => (
                                            <li key={item.id}>
                                                Item:<span style={{ color: "#cd4a4a !important" }} className="danger">&nbsp; {item.description}</span>, Quantity Difference:<span style={{ color: "#cd4a4a !important" }}> &nbsp;{item.quantityDifference}</span>
                                            </li>
                                        ))}
                                        <small>These will be Considered as Partially Received.</small>
                                    </ul>
                                )}
                                {/* { notLinkedItems.length > 0 && (
                                    <p>


                                        <>
                                            {notLinkedItems} were not linked with PO items. If you are not linking, they will be considered as returnable items.
                                        </>

                                    </p>
                                )} */}
                                {notLinkedItems.length > 0 && (
                                    <p>Some of the items were not  linked with PO items. If you are not linking, they will be considered as returnable items.</p>
                                )}
                            </div>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: "center" }}>
                        <Button variant='contained' className='sucessButton' onClick={() => { markReturnable() }} >Proceed</Button>
                        <Button variant='contained' className='primaryTonalButton' onClick={() => handleMarkableClose()}>Cancel</Button>

                    </DialogActions>
                </Box>
            </Dialog> </>)}
            <WarningDailog
                open={validateDI}
                onClose={HandleValidateDiClose}
                success={createSuccessDI}
                error={createErrorDI}
                Content={createSuccessDI ? "Successfully Reading Using Document Intelligence ,Verify All The Details Are Correct " : "Failed To Read Using Document Intelligence, Please Fill The Information Manually"} />
            <CustomDialog
                open={openDialogs}
                onClose={handleDialogClose}
                success={createSuccess}
                error={createError}
                Content={createSuccess ? "Uploaded succesfully" : "Failed"} />
            <CustomDialog
                open={openConfirmForm}
                onClose={handleConfirmDialogClose}
                success={createConfirmSuccess}
                error={createConfirmError}
                Content={createConfirmSuccess && !dcuploaded ? "Uploaded succesfully" : dcuploaded && createConfirmSuccess ? confirmResult : dcuploaded && createConfirmError ? confirmResult : "failed"} />
        </>
    );
};
export default DeliveryPOComponent;