/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 24-Jan-2024
* @author      : Vijay Kumar.S
* @since       : 0.0.1
* Requirement# :
* Purpose      : For adding invoice 
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
*
*
*
*/
import * as React from 'react';
import Box from '@mui/material/Box';
import { Button, Card, Divider, FormControl, Grid, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import Header from '../../Header';
import './InvoiceForm.scss'
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { changeInvoiceStatus, getInvoiceByProjectIdAndInvoiceId, getTotalAmount } from '../../../services/InvoiceServices';
import { useLocation, useNavigate } from 'react-router-dom';
import PaymentTypes from '../../paymentTypes/PaymentTypes';
import dayjs from 'dayjs';
import { TaskContext, UserContext, getUserSession, isUserHasPermission, getPreviousPageDetails, useHandleUnauthorized } from '../../../hooks/UserSession';
import CustomDialog from '../../../pages/Dialog';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { useContext } from 'react';



const InvoiceFormView: React.FC<any> = () => {
    const { accessToken, accessTokenPayload } = useOidcAccessToken();
    const [invoiceData, setInvoiceData] = React.useState({} as any);
    const [role, setRole] = React.useState<any>([] as any[]);
    const [user, setUser] = React.useState<any>();
    const [openDialogs, setOpenDialogs] = React.useState(false);
    const [createSuccess, setCreateSuccess] = React.useState(false);
    const [createError, setCreateError] = React.useState(false);
    const [openSendDialogs, setOpenSendDialogs] = React.useState(false);
    const [createSendSuccess, setCreateSendSuccess] = React.useState(false);
    const [createSendError, setCreateSendError] = React.useState(false);
    const [taxShow, setTaxShow] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [openTaxDialogs, setOpenTaxDialogs] = React.useState(false);
    const [createTaxSuccess, setTaxCreateSuccess] = React.useState(false);
    const [createTaxError, setTaxCreateError] = React.useState(false);
    const [newRows, setNewRows] = React.useState<any>([]);
    const [newContent, setNewContent] = React.useState<any>([]);
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();
    const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
    const { userSessionDetails } = useContext(UserContext);
    const { handleUnauthorized } = useHandleUnauthorized();
    let data = location?.state?.rowData;
    let status = location?.state?.status;
    let projectView = location?.state?.projectView;
    const { isTaskCompleted, handleCompleted } = React.useContext(TaskContext);

    const getInvoice = async () => {
        let result = await getInvoiceByProjectIdAndInvoiceId(data?.projectId, data?.id, accessToken);
        handleUnauthorized(result);
        let finalData = result?.data;
        if (finalData?.status === "Draft" && finalData?.taxInfo === null) {
            finalData['taxInfo'] = [{
                name: "",
                percentage: "",
                value: "",
            }]
            finalData['taxAmount'] = 0
            setInvoiceData(finalData);
        }
        else {
            setInvoiceData(finalData);
        }
        if (finalData?.status !== "Draft") {
            setTaxShow(true);
        }
        else {
            setTaxShow(false);
        }
    }
    const updateInvoiceStatus = async () => {
        handleCompleted();
        setOpenSendDialogs(true);
        let result = await changeInvoiceStatus(data?.id, "Un Paid", accessToken);
        if (result.status === 204 || result.status === 201) {
            setCreateSendSuccess(true);
            getInvoice();
        }
        else {
            setCreateSendError(true);
        }
    }
    const addRows = () => {
        const taxComponent = {
            name: "",
            percentage: "",
            value: "",
            reason: "",
            status: "active",
            updatedAt: null,
            createdBy: userSessionDetails?.firstname
        };

        const updateTaxInfo: any = [
            // copy the current users state
            ...invoiceData.taxInfo,
            // now you can add a new object to add to the array
            taxComponent,
        ];
        newRows.push(updateTaxInfo.indexOf(taxComponent));

        // update the state to the updatedUsers
        setInvoiceData({ ...invoiceData, taxInfo: updateTaxInfo });
    };
    const onInputChange = (e: any, index: any, subTotal: any) => {
        if ((e.target.value) >= 0) {
            let taxValue = (e.target.value * subTotal) / 100;
            let newArray = [...invoiceData?.taxInfo];
            newArray[index][e.target.name] = e.target.value;
            newArray[index]["value"] = taxValue;
            let totalTaxAmount = 0
            invoiceData?.taxInfo?.map((d: any) => {
                if (typeof (d.value) !== "string") {
                    totalTaxAmount = totalTaxAmount + d.value;
                }
            })
            let totalInvoicedAmount = subTotal + totalTaxAmount;
            setInvoiceData({ ...invoiceData, taxInfo: newArray, taxAmount: totalTaxAmount, invoicedAmount: totalInvoicedAmount, invoiceId: data?.id });
        }
    };
    const addContent = () => {
        const items = {
            costCode: "",
            description: "",
            industryCode: "",
            quantity: "",
            rate: "",
            subTotal: "",
            reason: "",
            status: "active",
            updatedAt: null,
            createdBy: userSessionDetails?.firstname
        };

        const updateContent: any = [
            // copy the current users state
            ...invoiceData.items,
            // now you can add a new object to add to the array
            items,
        ];
        newContent.push(updateContent.indexOf(items));

        // update the state to the updatedUsers
        setInvoiceData({ ...invoiceData, items: updateContent });
    };

    const onInputTaxNameChange = (e: any, index: any) => {
        let newArray = [...invoiceData?.taxInfo];
        newArray[index][e.target.name] = e.target.value;
        setInvoiceData({ ...invoiceData, taxInfo: newArray });
    };
    const onInputContentChange = (e: any, index: any) => {
        let newArray = [...invoiceData?.items];
        let taxInfos = [...invoiceData?.taxInfo];
        if (e.target.name === "rate") {
            // if (e.target.value > 0 || e.target.value === '') {
            newArray[index]['rate'] = e.target.value;
            if (newArray[index]['quantity'] !== '') {
                newArray[index]['subTotal'] = newArray[index]['quantity'] * e.target.value;
                let total = 0
                let totalTaxAmount = 0
                invoiceData?.items?.map((d: any) => {
                    if (typeof (d?.subTotal) !== "string") {
                        total = total + d?.subTotal;
                    }
                })
                taxInfos?.map((d: any, i: any) => {
                    taxInfos[i]['value'] = (total * d?.percentage) / 100;
                    totalTaxAmount = totalTaxAmount + taxInfos[i]['value'];
                })
                setInvoiceData({ ...invoiceData, items: newArray, taxInfo: taxInfos, invoicedAmount: total + totalTaxAmount, total: total });

            }
            else {
                setInvoiceData({ ...invoiceData, items: newArray });
            }
            // }
        }
        else if (e.target.name === "quantity") {
            if (e.target.value > 0 || e.target.value === '') {
                newArray[index]['quantity'] = e.target.value;
                if (newArray[index]['rate'] !== '') {
                    newArray[index]['subTotal'] = newArray[index]['rate'] * e.target.value;
                    let total = 0
                    let totalTaxAmount = 0
                    invoiceData?.items?.map((d: any) => {
                        if (typeof (d?.subTotal) !== "string") {
                            total = total + d?.subTotal;
                        }
                    })
                    taxInfos?.map((d: any, i: any) => {
                        taxInfos[i]['value'] = (total * d?.percentage) / 100;
                        totalTaxAmount = totalTaxAmount + taxInfos[i]['value'];
                    })
                    setInvoiceData({ ...invoiceData, items: newArray, taxInfo: taxInfos, invoicedAmount: total + totalTaxAmount, total: total });
                }
                else {
                    setInvoiceData({ ...invoiceData, items: newArray });
                }
            }
        }
        else {
            newArray[index][e.target.name] = e.target.value;
            setInvoiceData({ ...invoiceData, items: newArray });
        }
    };

    const validation = () => {
        let validated: any = true;
        // if ((invoiceData?.taxInfo).length === 0) {
        //     setOpenTaxDialogs(true);
        //     setTaxCreateError(true);
        //     validated = false;
        // }
        invoiceData?.taxInfo.map((d: any, index: any) => {
            if (d.name === "" || d.percentage === "" || (d?.reason === "" && newRows.includes(index))) {
                setError(true);
                validated = false;
            }
        })
        invoiceData?.items.map((d: any, index: any) => {
            if (d.industryCode === "" || d.costCode === "" || d.description === "" || d.quantity === "" || d.rate === "" || (d.reason === "" && newContent.includes(index))) {
                setError(true);
                validated = false;
            }
        })
        return validated;
    }
    const getInvoicedAmount = async () => {
        let dataSet = { ...invoiceData };
        // let d = JSON.stringify(dataSet?.taxInfo);
        // dataSet.taxInfo = d;
        // dataSet.items = JSON.stringify(dataSet?.items);
        let validated = validation();
        setOpenDialogs(true);
        if (validated === true) {
            let result = await getTotalAmount(dataSet, accessToken);
            handleUnauthorized(result);
            if (result.status === 201 || result.status === 204) {
                setNewRows([]);
                setNewContent([]);
                setCreateSuccess(true);
                getInvoice();
            }
            else {
                setCreateError(true);
            }
        }
    }
    const handleRemoveItem = (index: any) => {
        let newArray = [...invoiceData?.taxInfo];
        newArray.splice(index, 1)
        // setInvoiceData({ ...invoiceData, taxInfo: newArray });
        let totalTaxAmount = 0
        newArray?.map((d: any) => {
            if (typeof (d.value) !== "string") {
                totalTaxAmount = totalTaxAmount + d.value;
            }
        })
        newRows.pop();
        let totalInvoicedAmount = invoiceData?.total + totalTaxAmount;
        setInvoiceData({ ...invoiceData, taxInfo: newArray, taxAmount: totalTaxAmount, invoicedAmount: totalInvoicedAmount });
    }

    const handleRemoveContent = (index: any) => {
        let newArray = [...invoiceData?.items];
        let taxInfos = [...invoiceData?.taxInfo];
        let total = invoiceData?.total - Number(newArray[index]['subTotal']);
        let totalTaxAmount = 0
        taxInfos?.map((d: any, i: any) => {
            taxInfos[i]['value'] = (total * d?.percentage) / 100;
            totalTaxAmount = totalTaxAmount + taxInfos[i]['value'];
        })
        let totalInvoicedAmount = total + totalTaxAmount;
        newArray.splice(index, 1)
        let contentIndex = newContent.indexOf(index);
        if (contentIndex > -1) {
            newContent.splice(contentIndex, 1);
        }
        setInvoiceData({ ...invoiceData, items: newArray, taxInfo: taxInfos, invoicedAmount: totalInvoicedAmount, total: total });
    }

    const handleBack = () => {
        const previousPageDetails = getPreviousPageDetails();
        if (previousPageDetails !== undefined) {
            navigate(previousPageDetails.path, { state: { rowData: data?.projectId, user: userSessionDetails, selectedTab: previousPageDetails.tabPosition, tabName: previousPageDetails.tabName, projectView: projectView } });
        }
        else {
            navigate("/soldproduction")
        }

    }

    React.useEffect(() => {
        // getUserSession(accessToken)
        //     .then(res => {

        setRole(userSessionDetails?.role.name);

        // })
        getInvoice();
        // }
    }, []);

    return (
        <>
            <Box
                display={smScreen ? "flex" : "block"}
                flexDirection={smScreen ? "row" : "column"}
                justifyContent={smScreen ? "space-between" : "start"}
                alignItems={smScreen ? "center" : "start"}
            //m="10px 0"
            >
                <Header title="Edit Invoice" subtitle="" />
            </Box>
            <CustomDialog
                open={openDialogs}
                onClose={() => setOpenDialogs(false)}
                success={createSuccess}
                error={createError}
                Content={createSuccess ? "Invoice saved" : "Invoice Not saved"} />
            <CustomDialog
                open={openSendDialogs}
                onClose={() => setOpenSendDialogs(false)}
                success={createSendSuccess}
                error={createSendError}
                Content={createSendSuccess ? "Invoice sent to customer successfully" : "Invoice unsent to customer"} />
            <CustomDialog
                open={openTaxDialogs}
                onClose={() => setOpenTaxDialogs(false)}
                success={createTaxSuccess}
                error={createTaxError}
                Content={createTaxSuccess ? "" : "Tax Component Not Added"}
            />
            <Grid container rowSpacing={2}>
                <Grid item xs={12} sm={9}>
                    <Box
                        sx={{
                            display: 'block',
                            alignItems: 'center',

                            '& > :not(style)': { m: 2 },

                        }}
                    >
                        <Card className='POCard' variant="outlined" sx={{ padding: '48px' }}>
                            <Grid container className='invoiceHeader' rowSpacing={0} columnSpacing={0}>
                                {/* <Grid item xs={12} sm={12} sx={{display:'flex', justifyContent:'space-between'}}>
                                    <Box>
                                        <Typography className='companyName'>MATERIO</Typography>
                                        <Typography paddingTop={2}>Office 149, 450 South Brand Brooklyn</Typography>
                                        <Typography>San Diego County, CA 91905, USA</Typography>
                                        <Typography>+1 (123) 456 7891, +44 (876) 543 2198</Typography>
                                    </Box>
                                    <Box>
                                        <Typography className='companyName'>MATERIO</Typography>
                                        <Typography paddingTop={2}>Office 149, 450 South Brand Brooklyn</Typography>
                                        <Typography>San Diego County, CA 91905, USA</Typography>
                                        <Typography>+1 (123) 456 7891, +44 (876) 543 2198</Typography>
                                    </Box>
                                </Grid> */}
                                <Grid item xs={12} sm={6}>
                                    {/* <Typography className='companyName'>ABC Construction</Typography> */}
                                    <Typography>
                                        <img
                                            src={invoiceData?.tenantLogo}
                                            alt=''
                                            className="logo"
                                        />
                                    </Typography>
                                    <Typography paddingTop={2}>{invoiceData?.tenantAddress}</Typography>
                                    <Typography>{invoiceData?.city},&nbsp;{invoiceData?.state}</Typography>
                                    <Typography>{invoiceData?.primaryContact},&nbsp;{invoiceData?.secondaryContact}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6} className='alignEnd'>
                                    <Stack>
                                        <Typography className='invoiceNumber'>Invoice #{invoiceData?.invoiceNo}</Typography>
                                        <Typography paddingTop={2}>Date Issued: {dayjs(invoiceData?.invoiceDate).format("DD MMM YYYY")}</Typography>
                                        <Typography>Date Due: {dayjs(invoiceData?.dueDate).format("DD MMM YYYY")}</Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                            <Grid container rowSpacing={2} columnSpacing={2} paddingTop={3}>
                                <Grid item xs={12} sm={6}>
                                    <Typography className='subHeader'>Invoice To:</Typography>
                                    <Typography paddingTop={2}>{invoiceData?.customerName}</Typography>
                                    <Typography>{invoiceData?.customerAddress}</Typography>
                                    <Typography>{invoiceData?.customerCity} {invoiceData?.customerState}</Typography>
                                    <Typography>{invoiceData?.customerMobileNo}</Typography>
                                    <Typography>{invoiceData?.customerEmail}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography className='subHeader'>Billing Address:</Typography>
                                    <Typography paddingTop={2}>{invoiceData?.customerName}</Typography>
                                    <Typography>{invoiceData?.billingAddress}</Typography>
                                    <Typography>{invoiceData?.billingCity} {invoiceData?.billingState}</Typography>
                                    <Typography>{invoiceData?.customerMobileNo}</Typography>
                                    <Typography>{invoiceData?.customerEmail}</Typography>
                                </Grid>
                            </Grid>
                            <Grid container rowSpacing={2} columnSpacing={2} paddingTop={4}>
                                <TableContainer component={Paper}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Industry</TableCell>
                                                <TableCell>Cost Code</TableCell>
                                                <TableCell>Description</TableCell>
                                                <TableCell >Quantity</TableCell>
                                                <TableCell >Rate</TableCell>
                                                <TableCell >Sub Total</TableCell>
                                                <TableCell >Reason</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {invoiceData?.items?.map((data: any, index: any) => {
                                                return (
                                                    <TableRow
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell component="th" scope="row">
                                                            <FormControl>
                                                                <TextField
                                                                    name="industryCode"
                                                                    id="outlined-size-small"
                                                                    size="small"
                                                                    value={data?.industryCode}
                                                                    variant='standard'
                                                                    error={(data?.industryCode === '' && error)}
                                                                    onChange={(e) => onInputContentChange(e, index)}
                                                                    disabled={invoiceData?.status === "Generated" && newContent.includes(index) ? false : true}
                                                                />
                                                            </FormControl>
                                                        </TableCell>
                                                        <TableCell>
                                                            <FormControl>
                                                                <TextField
                                                                    name="costCode"
                                                                    id="outlined-size-small"
                                                                    size="small"
                                                                    value={data?.costCode}
                                                                    variant='standard'
                                                                    error={(data?.costCode === '' || data?.costCode === null) && error}
                                                                    onChange={(e) => onInputContentChange(e, index)}
                                                                    disabled={invoiceData?.status === "Generated" ? false : true}
                                                                />

                                                            </FormControl>
                                                        </TableCell>
                                                        <TableCell>
                                                            <FormControl>
                                                                <TextField
                                                                    name="description"
                                                                    id="outlined-size-small"
                                                                    size="small"
                                                                    value={data?.description}
                                                                    variant='standard'
                                                                    error={(data?.description === '' && error)}
                                                                    disabled={invoiceData?.status === "Generated" && newContent.includes(index) ? false : true}
                                                                    onChange={(e) => onInputContentChange(e, index)}
                                                                />

                                                            </FormControl>
                                                        </TableCell>
                                                        <TableCell>
                                                            <FormControl>
                                                                <TextField
                                                                    name="quantity"
                                                                    id="outlined-size-small"
                                                                    size="small"
                                                                    type="number"
                                                                    value={data?.quantity}
                                                                    variant='standard'
                                                                    error={(data?.quantity === '' && error)}
                                                                    onChange={(e) => onInputContentChange(e, index)}
                                                                    disabled={invoiceData?.status === "Generated" && newContent.includes(index) ? false : true}
                                                                />

                                                            </FormControl>
                                                        </TableCell>
                                                        <TableCell>
                                                            <FormControl>
                                                                <TextField
                                                                    name="rate"
                                                                    id="outlined-size-small"
                                                                    size="small"
                                                                    value={data?.rate}
                                                                    type="number"
                                                                    variant='standard'
                                                                    error={(data?.rate === '' && error)}
                                                                    onChange={(e) => onInputContentChange(e, index)}
                                                                    disabled={invoiceData?.status === "Generated" && newContent.includes(index) ? false : true}
                                                                />

                                                            </FormControl>
                                                        </TableCell>
                                                        {/* <TableCell align="right">{data?.rate}</TableCell> */}
                                                        <TableCell>
                                                            {/* ${(data?.subTotal)} */}
                                                            <FormControl>
                                                                <TextField
                                                                    name="subTotal"
                                                                    id="outlined-size-small"
                                                                    size="small"
                                                                    value={Number(data?.subTotal).toFixed(2)}
                                                                    variant='standard'
                                                                    disabled
                                                                // error={(row?.name === '' && count !== 0)}
                                                                // onChange={(e) => materialInputChange(e, index)}
                                                                />

                                                            </FormControl>
                                                        </TableCell>
                                                        <TableCell>
                                                            <FormControl>
                                                                <TextField
                                                                    name="reason"
                                                                    id="outlined-size-small"
                                                                    size="small"
                                                                    value={data?.reason}
                                                                    multiline
                                                                    variant='standard'
                                                                    error={(data?.reason === '' && error && newContent.includes(index))}
                                                                    onChange={(e) => onInputContentChange(e, index)}
                                                                    disabled={invoiceData?.status === "Generated" && newContent.includes(index) ? false : true}
                                                                />
                                                            </FormControl>
                                                        </TableCell>
                                                        <TableCell>
                                                            {invoiceData?.status === "Generated" && (invoiceData?.items).length !== 0 && newContent.includes(index) ?
                                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                    <IconButton onClick={() => handleRemoveContent(index)} disabled={(invoiceData?.items).length === 1 ? true : false}>
                                                                        <DeleteIcon fontSize='small'
                                                                        />
                                                                    </IconButton>
                                                                </Box>
                                                                :
                                                                <></>
                                                            }
                                                        </TableCell>
                                                    </TableRow>)
                                            })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                {invoiceData?.status === "Generated" ?
                                    <Grid container rowSpacing={2} columnSpacing={2}>
                                        <Grid item xs={12} sm={7}>
                                        </Grid>
                                        <Grid item xs={6} sm={2}>
                                        </Grid>
                                        <Grid item xs={6} sm={3} className='alignEnd'>
                                            <Box>
                                                <Stack direction={'row'}>
                                                    <Button onClick={addContent}>Add Row</Button>
                                                </Stack>
                                            </Box>
                                        </Grid>
                                    </Grid> :
                                    <Grid container rowSpacing={2} columnSpacing={2} paddingBottom={3}>
                                    </Grid>
                                }
                            </Grid>
                            <Grid container rowSpacing={2} columnSpacing={1} paddingTop={3} >
                                <Grid item xs={12} sm={8}>
                                    <Stack direction={'row'}>
                                        <Typography className='subHeader'>Salesperson:</Typography>
                                        <Typography>&nbsp;&nbsp;{invoiceData?.salesPersonName}</Typography>
                                    </Stack>
                                    <Typography>Thanks for your business</Typography>
                                </Grid>
                                <Grid item xs={6} sm={2} className='alignEnd'>
                                    <Box>
                                        <Typography className='subHeader' textAlign={'right'}>Subtotal:</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={6} sm={2} className='alignEnd'>
                                    <Box>
                                        <Typography textAlign={'right'}>${Number(invoiceData?.total).toFixed(2)}</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                            {invoiceData?.taxExemption === false ?
                                <Grid container rowSpacing={1} columnSpacing={1}>
                                    {invoiceData?.taxInfo?.map((tax, index) => (
                                        <><Grid item xs={12} sm={newRows.includes(index) ? 4 : 6}>
                                        </Grid>
                                            <Grid item xs={12} sm={2}>
                                                <Box>
                                                    <TextField
                                                        label="Tax Name"
                                                        id="outlined-size-small"
                                                        size="small"
                                                        value={tax?.name}
                                                        disabled={taxShow && !newRows.includes(index)}
                                                        error={tax?.name === '' && error}
                                                        name="name"
                                                        onChange={(e) => onInputTaxNameChange(e, index)}
                                                    />
                                                </Box>
                                            </Grid>

                                            <Grid item xs={6} sm={2} className='alignEnd'>
                                                <Box>
                                                    <TextField
                                                        label="Percentage"
                                                        id="outlined-size-small"
                                                        size="small"
                                                        value={tax?.percentage}
                                                        name="percentage"
                                                        type='number'
                                                        InputProps={{
                                                            inputProps: { min: 0 }
                                                        }}
                                                        error={tax?.percentage === '' && error}
                                                        disabled={taxShow && !newRows.includes(index)}
                                                        onChange={(e) => onInputChange(e, index, invoiceData?.total)}
                                                    />
                                                </Box>
                                            </Grid>
                                            {invoiceData?.status === "Generated" && newRows.includes(index) ?
                                                <Grid item xs={12} sm={2}>
                                                    <Box>
                                                        <TextField
                                                            label="Reason"
                                                            id="outlined-size-small"
                                                            size="small"
                                                            value={tax?.reason}
                                                            disabled={taxShow && !newRows.includes(index)}
                                                            error={tax?.reason === '' && error}
                                                            name="reason"
                                                            onChange={(e) => onInputTaxNameChange(e, index)}
                                                        />
                                                    </Box>
                                                </Grid>
                                                :
                                                <></>
                                            }
                                            <Grid item xs={6} sm={2} className='alignEnd'>
                                                <Box>
                                                    <Stack direction={'row'} spacing={1}>
                                                        <TextField
                                                            label="Value"
                                                            id="outlined-size-small"
                                                            size="small"
                                                            type='number'
                                                            value={tax?.value ? Number(tax.value).toFixed(2) : ''}
                                                            name="value"
                                                            disabled
                                                        // onChange={(e) => onInputChange(e, index)}
                                                        />
                                                        {invoiceData?.status === "Generated" && (invoiceData?.taxInfo).length !== 0 && newRows.includes(index) ?
                                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                <IconButton onClick={() => handleRemoveItem(index)}>
                                                                    <DeleteIcon fontSize='small'
                                                                    />
                                                                </IconButton>
                                                            </Box>
                                                            :
                                                            <></>
                                                        }
                                                    </Stack>
                                                </Box>
                                            </Grid>
                                        </>
                                    ))}
                                </Grid> :
                                <></>
                            }
                            {invoiceData?.status === "Generated" && invoiceData?.taxExemption === false ?
                                <Grid container rowSpacing={2} columnSpacing={2}>
                                    <Grid item xs={12} sm={7}>
                                    </Grid>
                                    <Grid item xs={6} sm={2}>
                                    </Grid>
                                    <Grid item xs={6} sm={3} className='alignEnd'>
                                        <Box>
                                            <Stack direction={'row'}>
                                                <Button onClick={addRows}>Add Row</Button>
                                            </Stack>
                                        </Box>
                                    </Grid>
                                </Grid> :
                                <Grid container rowSpacing={2} columnSpacing={2} paddingBottom={3}>
                                </Grid>
                            }
                            <Grid container rowSpacing={2} paddingBottom={3}>
                                <Grid item xs={12} sm={8}>
                                </Grid>
                                <Grid item xs={6} sm={2} className='alignEnd'>
                                    <Box>
                                        <Typography className='subHeader'>Total:</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={6} sm={2} className='alignEnd'>
                                    <Box>
                                        <Typography textAlign={'right'}>${Number(invoiceData?.invoicedAmount)?.toFixed(2)}</Typography>
                                        {/* <Typography textAlign={'right'}>{invoiceData?.tax}%</Typography> */}
                                    </Box>
                                </Grid>
                            </Grid>
                            <Divider></Divider>
                            <Grid container rowSpacing={2} columnSpacing={2} paddingTop={3} paddingBottom={3}>
                                <Grid item xs={12}>
                                    <Stack direction={'row'}>
                                        <Typography className='subHeader'>Note:&nbsp;&nbsp;</Typography>
                                        <Typography> It was a pleasure working with you and your team. We hope you will keep us in
                                            mind for future projects. Thank You!</Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                            {invoiceData?.status === "Generated" && invoiceData?.taxExemption === false ?
                                <Grid container rowSpacing={2} columnSpacing={2}>
                                    <Grid item xs={12} className='alignEnd'>
                                        <Button className='sucessButton' variant="contained" size="medium" onClick={getInvoicedAmount}>Save</Button>
                                    </Grid>
                                </Grid> :
                                <></>
                            }
                        </Card>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Box
                        sx={{
                            display: 'block',
                            alignItems: 'center',

                            '& > :not(style)': { m: 2 },

                        }}
                    >
                        {role !== "customer" && (
                            <Card className='POCard' variant="outlined" sx={{ padding: '20px' }}>
                                <Grid container spacing={2} direction={'column'}>
                                    <Grid item xs={12} sm={12}>
                                        <Button variant="contained" fullWidth onClick={handleBack}>
                                            <KeyboardDoubleArrowLeftIcon sx={{ fontSize: '16px' }} />&nbsp;Go Back
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={12}>
                                        <Button variant="contained" fullWidth onClick={updateInvoiceStatus} disabled={invoiceData?.status === "Generated" ? false : true}>
                                            <SendOutlinedIcon sx={{ fontSize: '16px' }} />&nbsp;Send Invoice
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Card>
                        )}
                        {invoiceData?.status === "Paid" || invoiceData?.status === "Paid Approved" ?
                            <></> :
                            <>
                                {isUserHasPermission("PaymentCreate") &&
                                    <Card className='POCard' variant="outlined" sx={{ padding: '20px' }}>
                                        <Grid container>
                                            <Grid item xs={12} sm={12} paddingTop={2}>

                                                <PaymentTypes props={invoiceData} projectId={data?.projectId} invoiceId={data?.id} status = {invoiceData?.status}/>

                                            </Grid>
                                        </Grid>
                                    </Card>
                                }
                            </>
                        }
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}

export default InvoiceFormView;