import React, { useContext, useEffect, useState } from 'react';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import dayjs from 'dayjs';
import { changeInvoiceStatus, getInvoiceByProjectIdAndInvoiceId, getInvoiceTemplate } from '../../../services/InvoiceServices';
import { useLocation, useNavigate } from 'react-router-dom';
import { Backdrop, Box, Button, Card, Grid, useMediaQuery, useTheme } from '@mui/material';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import PaymentTypes from '../../paymentTypes/PaymentTypes';
import { getPreviousPageDetails, isUserHasPermission, TaskContext, UserContext } from '../../../hooks/UserSession';
import CustomDialog from '../../../pages/Dialog';
import Header from '../../Header';
import gmslogo from "../../../assets/logo.gif";

const InvoicePreview = (props) => {
  const [invoiceData, setInvoiceData] = React.useState({} as any);
  const [htmlTemplate, setHtmlTemplate] = useState("");
  const { accessToken, accessTokenPayload } = useOidcAccessToken();
  const { userSessionDetails } = useContext(UserContext);
  const [role, setRole] = React.useState<any>([] as any[]);
  const [openSendDialogs, setOpenSendDialogs] = React.useState(false);
  const [createSendSuccess, setCreateSendSuccess] = React.useState(false);
  const [createSendError, setCreateSendError] = React.useState(false);
  const { isTaskCompleted, handleCompleted } = React.useContext(TaskContext);
  const [isLoading, setIsLoading] = React.useState(false);
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const location = useLocation();
  const navigate = useNavigate();

  let data = location?.state?.rowData;
  let projectView = location?.state?.projectView;

  useEffect(() => {
    setRole(userSessionDetails?.role.name);
    fetchInvoiceData();
    setIsLoading(true);
  }, []);

  const handleBack = () => {
    const previousPageDetails = getPreviousPageDetails();
    if (previousPageDetails !== undefined) {
      navigate(previousPageDetails.path, { state: { rowData: data?.projectId, user: userSessionDetails, selectedTab: previousPageDetails.tabPosition, tabName: previousPageDetails.tabName, projectView: projectView } });
    }
    else {
      navigate("/soldproduction")
    }
  }

  const updateInvoiceStatus = async () => {
    handleCompleted();
    setOpenSendDialogs(true);
    let result = await changeInvoiceStatus(data?.id, "Un Paid", accessToken);
    if (result.status === 204 || result.status === 201) {
      setCreateSendSuccess(true);
      fetchInvoiceData();
    }
    else {
      setCreateSendError(true);
    }
  }

  const fetchInvoiceData = async () => {
    const res = await getInvoiceTemplate(accessToken);
    console.log("html template", res)
    const htmltemplatestring = res.data;
    if(htmltemplatestring !== ""){
      setIsLoading(false);
    }
    const result = await getInvoiceByProjectIdAndInvoiceId(data?.projectId, data?.id, accessToken);
    let finalData = result?.data;
    setInvoiceData(finalData);
    const getSafeValue = (value, defaultValue = '--') =>
      value !== null && value !== undefined ? value : defaultValue;

    const itemsRows = (finalData?.items || []).map(item => `
      <tr>
        <td>${getSafeValue(item?.industryCode)}</td>
        <td>${getSafeValue(item?.costCode)}</td>
        <td>${getSafeValue(item?.description)}</td>
        <td>${getSafeValue(item?.quantity)}</td>
        <td>${Number(getSafeValue(item?.rate)).toFixed(2)}</td>
        <td><strong>${Number(getSafeValue(item?.subTotal)).toFixed(2)}</strong></td>
      </tr>
  `).join('');


    const totalTaxValue = (finalData?.taxInfo || []).reduce(
      (total, tax) => total + (tax?.value || 0),
      0
    );


    const taxinfo = (finalData?.taxInfo || []).map(tax => `
      <tr>
          <td>${getSafeValue(tax?.name)}</td>
          <td>${getSafeValue(tax?.percentage)}%</td>
          <td>${getSafeValue(tax?.value).toFixed(2)}</td>
      </tr>
  `).join('');


    const invoiceDate = dayjs(finalData?.invoiceDate).format("DD MMM YYYY");
    const dueDate = dayjs(finalData?.dueDate).format("DD MMM YYYY");
    const invoicedAmount = Number(finalData?.invoicedAmount)?.toFixed(2);

    const boundHtml = htmltemplatestring
      .replace(/{{logo}}/g, getSafeValue(finalData?.tenantLogo))
      .replace(/{{tenantAddress}}/g, getSafeValue(finalData?.tenantAddress))
      .replace(/{{tenantcity}}/g, getSafeValue(finalData?.city))
      .replace(/{{tenantstate}}/g, getSafeValue(finalData?.state))
      .replace(/{{tenantEmail}}/g, getSafeValue(finalData?.email))
      .replace(/{{tenantMobileNo}}/g, getSafeValue(finalData?.mobileNo))
      .replace(/{{invoiceno}}/g, getSafeValue(finalData?.invoiceNo))
      .replace(/{{invoiceDate}}/g, getSafeValue(invoiceDate))
      .replace(/{{dueDate}}/g, getSafeValue(dueDate))
      .replace(/{{customerName}}/g, getSafeValue(finalData?.customerName))
      .replace(/{{customerAddress}}/g, getSafeValue(finalData?.customerAddress))
      .replace(/{{customerCity}}/g, getSafeValue(finalData?.customerCity))
      .replace(/{{customerState}}/g, getSafeValue(finalData?.customerState))
      .replace(/{{customerMobileNo}}/g, getSafeValue(finalData?.customerMobileNo))
      .replace(/{{customerEmail}}/g, getSafeValue(finalData?.customerEmail))
      .replace(/{{billingAddress}}/g, getSafeValue(finalData?.billingAddress))
      .replace(/{{billingCity}}/g, getSafeValue(finalData?.billingCity))
      .replace(/{{billingState}}/g, getSafeValue(finalData?.billingState))
      .replace(/{{invoiceNumber}}/g, getSafeValue(finalData?.invoiceNumber))
      .replace(/{{invoiceDate}}/g, getSafeValue(finalData?.invoiceDate))
      .replace(/{{customerName}}/g, getSafeValue(finalData?.customerName))
      .replace(/{{totalAmount}}/g, Number(getSafeValue(finalData?.totalAmount)).toFixed(2))
      .replace(/{{items}}/g, getSafeValue(itemsRows))
      .replace(/{{taxinfo}}/g, getSafeValue(taxinfo))
      .replace(/{{totalTaxValue}}/g, Number(getSafeValue(totalTaxValue)).toFixed(2))
      .replace(/{{subtotal}}/g, Number(getSafeValue(finalData?.total)).toFixed(2))
      .replace(/{{salesPersonName}}/g, getSafeValue(finalData?.salesPersonName))
      .replace(/{{invoicedAmount}}/g, Number(getSafeValue(invoicedAmount)).toFixed(2));
    setHtmlTemplate(boundHtml);

  };

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <img src={gmslogo} alt="Image" className="overlay" style={{ height: "60px" }} />
      </Backdrop>
      <Box
        display={smScreen ? "flex" : "block"}
        flexDirection={smScreen ? "row" : "column"}
        justifyContent={smScreen ? "space-between" : "start"}
        alignItems={smScreen ? "center" : "start"}
      >
        <Header title="Invoice" subtitle="" />
      </Box>
      {htmlTemplate === "" ? <></> :
        <Grid container rowSpacing={2}>
          <Grid item xs={12} sm={9}>
            <div>
              <div dangerouslySetInnerHTML={{ __html: htmlTemplate }} />
            </div>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Box
              sx={{
                display: 'block',
                alignItems: 'center',

                '& > :not(style)': { m: 2 },
              }}
            >
              {role !== "customer" && (
                <Card className='POCard' variant="outlined" sx={{ padding: '20px' }}>
                  <Grid container spacing={2} direction={'column'}>
                    <Grid item xs={12} sm={12}>
                      <Button variant="contained" fullWidth onClick={handleBack}>
                        <KeyboardDoubleArrowLeftIcon sx={{ fontSize: '16px' }} />&nbsp;Go Back
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Button variant="contained" fullWidth onClick={updateInvoiceStatus} disabled={invoiceData?.status === "Generated" ? false : true}>
                        <SendOutlinedIcon sx={{ fontSize: '16px' }} />&nbsp;Send Invoice
                      </Button>
                    </Grid>
                  </Grid>
                </Card>
              )}
              {invoiceData?.status === "Paid" || invoiceData?.status === "Paid Approved" ?
                <></> :
                <>
                  {isUserHasPermission("PaymentCreate") &&
                    <Card className='POCard' variant="outlined" sx={{ padding: '20px' }}>
                      <Grid container>
                        <Grid item xs={12} sm={12} paddingTop={2}>
                          <PaymentTypes props={invoiceData} projectId={data?.projectId} invoiceId={data?.id} status = {invoiceData?.status}/>
                        </Grid>
                      </Grid>
                    </Card>}
                </>}
            </Box>
          </Grid>

        </Grid>
      }
      <CustomDialog
        open={openSendDialogs}
        onClose={() => setOpenSendDialogs(false)}
        success={createSendSuccess}
        error={createSendError}
        Content={createSendSuccess ? "Invoice sent to customer successfully" : "Invoice unsent to customer"} />
    </>
  );
};

export default InvoicePreview;
