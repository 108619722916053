import { CssBaseline } from '@mui/material';
import { ComponentType } from 'react';

//import { style } from './style.js';

const pageStyles: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'right',
  padding: '10px',
  minHeight: '100vh', // Make sure the container stretches to the full height of the viewport  
};

const containerStyles: React.CSSProperties = {
  marginTop: '8rem',
  padding: '2rem',
  borderRadius: '8px',
  boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Optional: Add a shadow
  backgroundColor: 'white', // Container background color
};

export const CallBackSuccess: ComponentType<any> = ({ configurationName }) => (
  <>
    <div style={pageStyles}>
      <CssBaseline />
      <div className="oidc-callback">
        <div className="oidc-callback__container">
          {/* <h1 className="oidc-callback__title">Authentication complete for {configurationName}</h1> */}
          {/* <h1 className="oidc-callback__title">Authentication complete </h1>
          <p className="oidc-callback__content">You will be redirected to your application.</p> */}
        </div>
      </div>
    </div>
    {/* <div className="oidc-callback">
      <div className="oidc-callback__container">
        <h1 className="oidc-callback__title">Authentication complete for {configurationName}</h1>
        <p className="oidc-callback__content">You will be redirected to your application.</p>
      </div>
    </div> */}
  </>
);