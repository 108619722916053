/*
* Copyright (C) GMS Inc - All Rights Reserved
* This file is part of GMSCloud. - Proprietary and confidential
* Unauthorized copying of this file, via any medium is strictly prohibited
* 
* Created on   : 31-Jan-2025
* @author      : Priyankaa.S
* @since       : 0.0.1
* Requirement# :
* Purpose      : Review Bill uploaded from Desktop/mobile
* -----------------------------------------------------------------------------
* Revision History
* -----------------------------------------------------------------------------
* Requirement/
* Issue/WorkItem |   DATE    |   AUTHOR  |   DESCRIPTION OF CHANGE
* -----------------------------------------------------------------------------
* 
*
*
*/
import React, { useCallback } from "react";
import {
    DialogContent, DialogActions,
    DialogTitle, Dialog, Box, Typography, Card, CardContent, Grid, Button, IconButton,
    FormControl, InputLabel, Select,
    MenuItem, TextField, Paper,
    CircularProgress, FormControlLabel,
    Checkbox, createFilterOptions,
    Autocomplete, RadioGroup, Radio, Switch,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    FormLabel,
    FormHelperText
} from '@mui/material';
import '../paymentTypes/unPaidList/MakePaymentList'
import CloseIcon from '@mui/icons-material/Close';
import { useOidc, useOidcAccessToken } from '@axa-fr/react-oidc';
import { useEffect, useState } from 'react';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { useNavigate } from 'react-router-dom';
import CustomDialog from '../../pages/Dialog';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { confirmDeliveyBill, uploadBill, uploadReceipt } from "../../services/DeliverySlipService";
import { getAllVendors, getMaterialsByAddress, getVendorNames, getVendorsAddressByName } from "../../services/VendorService";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VendorRegister from "../vendor/VendorRegister";
import { getGradesByNameAndVendorId, getMaterialsByNameAndGrade } from "../../services/materialService";
import AddMaterialForm from "../materialManagement/AddMaterialForm";
import { getCOAAccountTypes } from "../../services/JobLedgerService";
import { styled } from '@mui/material/styles';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
import { useHandleUnauthorized, UserContext } from "../../hooks/UserSession";
import DeleteIcon from '@mui/icons-material/Delete';
import { getBillByReceiptId, reviewBill } from "../../services/BillService";


const ReviewBill: React.FC<any> = (props) => {
    const navigate = useNavigate();
    const { accessToken, accessTokenPayload } = useOidcAccessToken();
    const [bill, setBill] = useState<any>(); // Initialize with an empty array
    const [type, setType] = useState('');
    const [orderNo, setOrderNo] = useState('');
    const [billNo, setBillNo] = useState('');
    const [billDate, setBillDate] = useState<any>();
    const [isOpen, setIsOpen] = useState(true);
    const [isPaid, setIsPaid] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState('');
    const [accountType, setAccountType] = useState('');
    const [paid, setPaid] = useState('');
    const [cost, setCost] = useState('');
    const [category, setCategory] = useState('');
    const [expenseType, setExpenseType] = useState('');
    const [totalCost, setTotalCost] = React.useState<any>('');
    const [poData, setPoData] = React.useState({} as any);
    const [newRows, setNewRows] = React.useState<any>([]);
    const [updated, setUpdated] = React.useState(false);
    const [taxInfo, setTaxInfo] = React.useState<any>([]);
    const [total, setTotal] = React.useState<any>(0);

    const [openDialogs, setOpenDialogs] = React.useState(false);
    const [createSuccess, setCreateSuccess] = React.useState(false);
    const [dcuploaded, setDcUploaded] = React.useState(false);
    const [createConfirmSuccess, setCreateComfirmSuccess] = React.useState(false);
    const [createConfirmError, setCreateCofirmError] = React.useState(false);

    const [createError, setCreateError] = React.useState(false);
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState<any>([]);
    const [selectedfile, SetSelectedFile] = useState<{ filename: any; filetype: any; fileimage: string | ArrayBuffer | null; datetime: any; filesize: string; }[]>([]);
    const [imagePreview, setImagePreview] = useState<string | null>(null);

    const [vendorAddressList, setVendorAddressList] = React.useState<any>();
    const [vendorAddress, setVendorAddress] = React.useState<any>();
    const [vendorName, setVendorName] = React.useState<any>();
    const [vendorList, setVendorList] = React.useState<any>([]);
    const [accountList, setAccountList] = React.useState<any>([]);
    const [count, setCount] = React.useState<any>(0);
    const [openVendorForm, setOpenVendorForm] = React.useState(false);
    const [confirmResult, setConfirmResult] = React.useState('');

    const [materialData, setMaterialData] = React.useState<any>();
    const [vendorId, setVendorId] = React.useState<any>();
    const [openAddMaterialForm, setOpenAddMaterialForm] = React.useState<boolean>(false);
    const [openConfirmForm, setOpenConfirmForm] = React.useState<boolean>(false);
    const { userSessionDetails } = React.useContext(UserContext);
    const [error, setError] = React.useState(false);
    const paymentTypes = ["Credit Card", "Vendor Account", "Cash"];
    const paymentMode = ["Personal Card", "Company Card"];
    const [payment, setPayment] = useState('');
    const [documentData, setDocumentData] = React.useState<any>();
    const [reviewStatus, setReviewStatus] = useState('');
    const [gradeList, setGradeList] = React.useState<any>();
    const [materialList, setMaterialList] = React.useState<any>([]);
    const [material, setMaterial] = React.useState<any>(
        {
            'companyName': '',
            'name': '',
            'quantity': '',
            'unit': '',
            'totalCost': '',
            'grade': '',
            'unitCost': '',
            'vendorId': '',
            'vendorMaterialMapId': '',
            'address': '',

        }
    );

    const [billData, setBillData] = React.useState<any>(
        {
            'vendorid': '',
            'paid': Boolean,
            'paymentMethod': '',
            'reason': '',
            'receiptInfo': '',
            'total': '',
            'billNo': '',
            'billDate': new Date(),
            'accountType': '',
            'category': '',
            'expenseType': '',
            'taxInfos': [{
                name: "",
                percentage: "",
                value: "",
            }]
        }
    );
    const DELIVERYSLIP = 'DeliverySlip';
    const BILL = 'Bill'
    const { handleUnauthorized } = useHandleUnauthorized();
    const [errors, setErrors] = useState({
        companyName: false,
        address: false
    });

    const filter = createFilterOptions<any>();

    const onClose = () => {
        props.handleClose();
    }
    useEffect(() => {
        const fetData = async () => {
            const response = await getBillByReceiptId(props?.projectId, props?.billDetails, accessToken);
            console.log(response);
            if (response?.data['name'] === "VendorBill") {
                setType("Bill")
            } else {
                setType(response?.data?.name)
            }
            setBill(response.data);
            setOrderNo(response?.data?.jobNo)
            setCategory(response?.data?.category)
            setIsPaid(response?.data?.paid)
            if (response?.data?.paid) {
                setPaid("Yes");
            } else {
                setPaid("No");
            }
            setBillNo(response?.data?.billNo)
            setPayment(response?.data?.payment)
            setPaymentMethod(response?.data?.paymentMethod)
            console.log(response?.data?.url)
            setDocumentData(response?.data['url']);
            setTotalCost(response?.data?.total)
        }
        fetData();
        getVendors();
        getAccountTypes();
    }, [isOpen]);

    const onselect = async (e: any) => {
        setType(e.target.value);
    };

    const onselection = async (e: any) => {
        setAccountType(e.target.value);
    };

    const handlepaymentmethod = async (e: any) => {
        console.log(e.target.value, e.target.checked)
        setPaymentMethod(e.target.value);
    };

    const handlepayment = async (e: any) => {
        setPayment(e.target.value);
    };

    const handleOrder = async (e: any) => {
        setOrderNo(e.target.value);
    };

    const handlebillNo = async (e: any) => {
        setBillNo(e.target.value);
    };

    const handlebillDate = async (e: any) => {
        setBillDate(dayjs(e.$d).format('YYYY-MM-DD') + "T00:00:00.000Z");
    };

    const handleDialogClose = () => {
        setOpenDialogs(false);
        onClose();
        window.location.reload();
    };
    useEffect(() => {
        // console.log('Updated billData:', billData);
    }, [billData]);

    const handleConfirmDialogClose = () => {
        setOpenConfirmForm(false);
    };
    const handlePercentageChange = (e, index) => {
        const { value, name } = e.target;
        console.log('value', value, 'name', name);

        if (!isNaN(value)) {
            handleTax(name, value, index);  // Pass correct parameters
        }
    };


    const handleTax = (name, value, index) => {
        console.log('name', name, 'value', value);
        console.log(totalCost)
        const updatedTaxInfos = [...billData?.taxInfos];
        console.log('updatedTax', updatedTaxInfos);

        updatedTaxInfos[index][name] = value;

        if (name === 'percentage') {
            console.log('succusee');


            const taxValue = (totalCost * value) / 100;
            console.log(taxValue);
            updatedTaxInfos[index].value = taxValue.toFixed(2);
        }

        setBillData((prevData) => ({
            ...prevData,
            taxInfos: updatedTaxInfos,
        }));
    };

    const onInputTaxNameChange = (e: any, index: any) => {
        let newArray = [...billData?.taxInfos];
        newArray[index][e.target.name] = e.target.value;
        setBillData({ ...billData, taxInfos: newArray });
    };

    const addTaxRows = () => {
        const taxComponent = {
            name: "",
            percentage: "",
            value: ""
        };

        const updateTaxInfo: any = [
            ...billData.taxInfos,
            taxComponent,
        ];
        newRows.push(updateTaxInfo.indexOf(taxComponent));
        setTaxInfo(updateTaxInfo)

        setBillData({ ...billData, taxInfos: updateTaxInfo });
    };
    React.useEffect(() => {
        if (category === "Material") {
            // console.log("hiiiiiiiiiii",material)
            calculateTotalCost();
        }
        else {
            calculateCosts(); // Calculate costs whenever selected details are updated

        }
    }, [billData]);
    const calculateCosts = () => {
        if (billData?.taxInfos.length > 0) {
            let total = 0;
            let totalTaxAmount = 0;

            let taxInfos = [...billData?.taxInfos];
            let hasTaxInfoError = false;



            if (!billData?.taxInfos || billData?.taxInfos.length === 0) {
                hasTaxInfoError = true;
            }


            taxInfos?.map((d: any, i: any) => {
                taxInfos[i]['value'] = (totalCost * d?.percentage) / 100;
                totalTaxAmount = totalTaxAmount + taxInfos[i]['value'];
            })
            setTotal(parseFloat(totalCost || 0) + totalTaxAmount);
            setBillData({ ...billData, taxInfos: taxInfos, total: total });
        }
        // console.log(total)
    };
    const handleUploadChange = async (status: any) => {

        let hasTaxInfoError = false;

        if (!billData?.taxInfos || billData?.taxInfos.length === 0) {
            hasTaxInfoError = true;
        }

        const updatedTaxInfos = billData?.taxInfos?.map((tax) => {
            if (!tax.name.trim() || tax.percentage === '') {
                hasTaxInfoError = true;
                return { ...tax, error: true };
            }
            return { ...tax, error: false }; 
        });

        const newErrors = {
            taxInfos: hasTaxInfoError,
            companyName: !vendorName,
            address: !vendorAddress
        };

        if (hasTaxInfoError) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                taxInfos: true,
            }));
            setError(true);
            return;
        }

        setErrors(newErrors);

        if (!Object.values(newErrors).some((error) => error)) {
            setOpenDialogs(true);
            setReviewStatus(status);
            try {

                if (type === BILL) {
                    setBillData({
                        'vendorid': vendorId,
                        'companyName': vendorName,
                        'paid': isPaid,
                        'paymentMethod': paymentMethod,
                        'reason': status,
                        'receiptInfo': JSON.stringify(materialList),
                        'total': totalCost.toString(),
                        'billNo': billNo,
                        'billDate': new Date(),
                        'category': category,
                        'totalCost': total,

                        'accountType': accountType,
                        'expenseType': expenseType,
                        'taxInfos': billData?.taxInfos
                    })
                    let formData = {
                        'vendorid': vendorId,
                        'companyName': vendorName,
                        'paid': isPaid,
                        'paymentMethod': paymentMethod,
                        'reason': status,
                        'receiptInfo': JSON.stringify(materialList),
                        'total': totalCost.toString(),
                        'billNo': billNo,
                        'totalCost': total,
                        'billDate': new Date(),
                        'category': category,
                        'accountType': accountType,
                        'expenseType': expenseType,
                        'taxInfos': billData?.taxInfos
                    }
                    console.log(formData, vendorId)
                    const data = await reviewBill(props?.billDetails, status, formData, accessToken)
                    if (data?.status === 200) {
                        setCreateSuccess(true);
                    } else {
                        setCreateError(true);
                    }
                }
                // }
                else {
                    console.error("Invalid file:", file);
                    setCreateError(true);
                }
            }
            catch (error) {
                console.error("Error uploading:", error);
            }
        }
    };

    const getVendors = async () => {
        // let result = await getAllVendors(accessToken);
        let result = await getVendorNames(accessToken);
        handleUnauthorized(result);
        setVendorList(result.data);
    }

    const getAccountTypes = async () => {
        let result = await getCOAAccountTypes(accessToken);
        if (result.data !== undefined) {
            setAccountList(result.data);
        }
    }

    const handleChangeVendor = (e: any, value: any) => {
        // props.changeVendorName(value?.companyName);
        if (value !== null) {
            getVendorAddressList(value?.companyName);
            setVendorName(value?.companyName);
            setMaterial({ ...material, "companyName": value?.companyName });
        }
    }

    const getVendorAddressList = async (vendorName: any) => {
        let result = await getVendorsAddressByName(vendorName, accessToken);
        console.log(result.data)
        if (result.data !== undefined) {
            setVendorAddressList(result.data);
        }
    }

    const openVendorRegForm = () => {
        setOpenVendorForm(true);
    };
    const handleAddNewRow = () => {
        setBillData((prevData) => ({
            ...prevData,
            taxInfos: Array.isArray(prevData?.taxInfos)
                ? [...prevData.taxInfos, { name: '', percentage: '', value: '' }]
                : [{ name: '', percentage: '', value: '' }], // Ensure array format
        }));
    };

    const handleChangeAddress = async (e: any) => {
        setVendorAddress(e.target.value);
        setMaterial({ ...material, "address": e.target.value });
        let res = await getMaterialsByAddress(e.target.value, accessToken);
        console.log(res.data)
        if (res.data !== undefined) {
            console.log(res.data)
            setMaterialData(res?.data);
            setVendorId(res?.data[0]?.vendorId);
            if (res?.data[0]?.vendorId != null) {
                setUpdated(true);
            }
            material['vendorId'] = res?.data[0]?.vendorId;
        }
    }

    const handleChangeMaterialName = (e: any, value: any, index: number) => {
        setMaterialList((prevList) => {
            const updatedList = prevList.map((item, i) =>
                i === index ? { ...item, name: value?.name } : item
            );

            return updatedList;
        });

        if (value?.name) {
            getMaterialGrades(value?.name, vendorId);
        }

        setMaterialList((prevList) => {
            if (value?.name !== '' && prevList[index]?.grade) {
                getMaterials(value?.name, prevList[index]?.grade, index);
            }
            return prevList;
        });
    };


    const getMaterialGrades = async (name: any, vendorId: any) => {
        if (!name || !vendorId) return; // Prevent unnecessary API calls

        let materials = {
            name: name,
            vendorId: vendorId,
        };

        try {
            let result = await getGradesByNameAndVendorId(materials, accessToken);

            if (result?.data) {
                setGradeList(result.data);
            } else {
                setGradeList([]); // Ensure empty state if no data is returned
            }
        } catch (error) {
            console.error("Error fetching material grades:", error);
        }
    };

    const openForm = () => {
        setOpenAddMaterialForm(true);
    }

    const getMaterials = async (materialName: any, grade: any, index: number) => {
        if (materialName !== '' && grade !== '') {
            let finalMaterialData = {
                name: materialName,
                grade: grade,
                vendorId: vendorId,
                address: vendorAddress,
            };

            let result = await getMaterialsByNameAndGrade(finalMaterialData, accessToken);
            console.log(result.data);

            if (result?.data !== undefined) {
                setMaterialList((prevList) => {
                    return prevList.map((item, i) =>
                        i === index
                            ? {
                                ...item,
                                unit: result.data.unit,
                                unitCost: result.data.cost,
                                vendorMaterialMapId: result.data.vendorMaterialMapId,
                                grade: grade,
                                totalCost:
                                    item.quantity !== ''
                                        ? result.data.cost * item.quantity
                                        : item.totalCost,
                            }
                            : item
                    );
                });
            }
        }
    };

    const handleChangeGrade = (e: any, index: number) => {
        console.log(index);

        // Update the specific material in materialList
        setMaterialList((prevMaterialList) =>
            prevMaterialList.map((item, i) =>
                i === index ? { ...item, grade: e.target.value } : item
            )
        );

        // Call getMaterials if name and grade are not empty
        if (materialList[index]?.name && e.target.value) {
            getMaterials(materialList[index].name, e.target.value, index);
        }
    };


    const handleChangeQuantity = (e: any, index: number) => {
        const value = e.target.value;

        if (value > 0 || value === '') {
            setMaterialList((prevList) => {
                // Clone the existing list
                const updatedList = [...prevList];

                // Clone the specific item to update
                updatedList[index] = {
                    ...updatedList[index],
                    quantity: value,
                    totalCost: updatedList[index]?.unitCost * value, // Recalculate total cost
                };

                console.log("Updated Material List:", updatedList);
                return updatedList;
            });

            // Optionally, update total cost
            calculateTotalCost();
        }
    };

    const handleListChange = (material) => {
        console.log("New Material:", material);

        setMaterialList((prevList) => {
            // Create a new row based on the input material
            const newRow = { ...material };

            // Add (push) the new row to the material list
            const updatedList = [...prevList, newRow];

            console.log("Updated Material List:", updatedList);

            // Calculate the total cost based on the latest state
            calculateTotalCost();

            return updatedList;




        });
        console.log(materialList)
        // Reset input fields after adding the row (optional)
        // setMaterial({
        //     companyName: '',
        //     name: '',
        //     quantity: '',
        //     unit: '',
        //     totalCost: '',
        //     grade: '',
        //     unitCost: '',
        //     vendorId: '',
        //     vendorMaterialMapId: '',
        //     address: '',
        // });
    };



    const handlePaid = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === "Yes") {
            setPaid(event.target.value)
            setIsPaid(true);
        } else {
            setPaid(event.target.value)
            setIsPaid(false);
        }
    }

    const handleCost = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === "Yes") {
            setExpenseType(event.target.value)
        } else {
            setExpenseType(event.target.value)
        }
    }

    const validation = () => {
        let validated: any = true;
        // if ((invoiceData?.taxInfo).length === 0) {
        //     setOpenTaxDialogs(true);
        //     setTaxCreateError(true);
        //     validated = false;
        // }
        billData?.taxInfos.map((d: any, index: any) => {
            if (d.name === "" || d.percentage === "") {
                setError(true);
                validated = false;
            }
        })
        billData?.items.map((d: any, index: any) => {
            if (d.industryCode === "" || d.costCode === "" || d.description === "" || d.quantity === "" || d.rate === "") {
                setError(true);
                validated = false;
            }
        })
        return validated;
    }

    const validateRows = () => {
        // Check if all rows are valid
        for (const item of poData.poItems) {
            if (!item.description || !item.itemCode || !item.quantity || !item.rate || !item.cost) {
                return false;
            }
        }
        return true;
    };

    const calculateTotalCost = () => {
        let total = 0;
        for (let i = 0; i < materialList?.length; i++) {
            total = materialList[i]?.totalCost + total;
        }
        console.log(materialList, total)
        setTotalCost(total);
        calculateCosts();
    }
    const handleChanged = (e, id, field) => {
        // updatedRows[index][field] = e.target.value;
        // Find the corresponding item in poItems using the id from rows
        const updatedPoItems = (poData?.poItems || []).map(item => {
            if (item?.id === id) {
                return { ...item, [field]: e?.target?.value };
            }
            return item;
        });

        // Update the poData with the updated poItems
        setPoData({
            ...poData,
            poItems: updatedPoItems.poItems,

        });
        if (e.target.name == "quantity") {
            calculateTotalCost();
        }
        //setRows(updatedRows);
    };
    const onInputChange = (e: any, index: any, subTotal: any) => {
        if ((e.target.value) >= 0) {
            let taxValue = (e.target.value * subTotal) / 100;
            let newArray = [...billData?.taxInfos];
            console.log(newArray)
            newArray[index][e.target.name] = e.target.value;
            newArray[index]["value"] = taxValue;
            let totalTaxAmount = 0
            billData?.taxInfos?.map((d: any) => {
                if (typeof (d.value) !== "string") {
                    totalTaxAmount = totalTaxAmount + d.value;
                }
            })
            let totalInvoicedAmount = subTotal + totalTaxAmount;
            setTotalCost(totalInvoicedAmount)
            setBillData({ ...billData, taxInfos: newArray, taxAmount: totalTaxAmount, total: totalInvoicedAmount });
        }
    };

    const handleRemoveItem = (index: any) => {
        let newArray = [...billData?.taxInfos];
        newArray.splice(index, 1)
        // setInvoiceData({ ...invoiceData, taxInfo: newArray });
        let totalTaxAmount = 0
        newArray?.map((d: any) => {
            if (typeof (d.value) !== "string") {
                totalTaxAmount = totalTaxAmount + d.value;
            }
        })
        newRows.pop();
        let totalInvoicedAmount = billData?.total + totalTaxAmount;
        setBillData({ ...billData, taxInfos: newArray, taxAmount: totalTaxAmount, total: totalInvoicedAmount });
    }

    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={props.open}
            // onClose={()=>onClose()}
            >
                <DialogTitle>
                    <Box display="flex" alignItems="center">
                        <Box flexGrow={1} >
                            <Typography className='unPaidHead'>Review Bill</Typography>
                        </Box>
                        <Box>
                            <IconButton onClick={() => onClose()}>
                                <CloseIcon sx={{ color: "red" }} />
                            </IconButton>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent sx={{ backgroundColor: "#f8f7fa" }} >

                    {(bill === '') ? (
                        <CardContent className='noInvoice'>
                            <Typography variant="body1" className='noInvoiceFound'><ReceiptIcon /></Typography>
                            <Typography variant="body1" className='noInvoiceFound'>No Bill review</Typography>
                        </CardContent>
                    ) :
                        (
                            !dcuploaded && (
                                <Card className='contractCard' >
                                    <CardContent >
                                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                                            <Grid item xs={6}>
                                                {!dcuploaded && (
                                                    <>
                                                        <Typography variant='h5' color="rgb(60 60 60 / 87%)" mb={2}>Upload Type</Typography>
                                                        <FormControl variant="outlined" fullWidth size="small">
                                                            <InputLabel id="demo-select-small" required>Type</InputLabel>
                                                            <Select
                                                                // error={selectedData.assetUid === undefined && completed}
                                                                required
                                                                labelId="demo-select-small-label"
                                                                id="demo-select-small"
                                                                value={type}
                                                                label="Type"
                                                                onChange={(e) => onselect(e)}
                                                                disabled
                                                                name='Type'
                                                            >
                                                                <MenuItem value="DeliverySlip">Delivery Slip</MenuItem>
                                                                <MenuItem value="Bill">Bill</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </>
                                                )}
                                            </Grid>
                                            {type === BILL && <>
                                                <Grid item xs={6} >
                                                    <Typography mb={2} variant='h5' color="rgb(60 60 60 / 87%)">Category</Typography>
                                                    <FormControl variant="outlined" fullWidth size="small">
                                                        <InputLabel id="demo-select-small" required>Category</InputLabel>
                                                        <Select
                                                            // error={selectedData.assetUid === undefined && completed}
                                                            required
                                                            labelId="demo-select-small-label"
                                                            id="demo-select-small"
                                                            value={bill?.category}
                                                            label="Account Type"
                                                            // onChange={(e) => onselection(e)}
                                                            disabled
                                                            name='category'
                                                        >
                                                            <MenuItem value={bill?.category}>{bill?.category}</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <FormControl variant="outlined" fullWidth size="small">
                                                        <InputLabel id="demo-select-small" required>Project Order No</InputLabel>
                                                        <Select
                                                            // error={selectedData.assetUid === undefined && completed}
                                                            required
                                                            labelId="demo-select-small-label"
                                                            id="demo-select-small"
                                                            value={orderNo}
                                                            label="Project Order No"
                                                            // onChange={(e) => handleOrder(e)}
                                                            disabled
                                                            name='orderNo'
                                                        >
                                                            <MenuItem value={bill?.jobNo}>{bill?.customerName} - {bill?.jobNo}</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    {/* <Typography variant='h5' color="rgb(60 60 60 / 87%)">Account Type</Typography> */}
                                                    <FormControl variant="outlined" fullWidth size="small">
                                                        <InputLabel id="demo-select-small" required>Account Type</InputLabel>
                                                        <Select
                                                            // error={selectedData.assetUid === undefined && completed}
                                                            required
                                                            labelId="demo-select-small-label"
                                                            id="demo-select-small"
                                                            value={accountType}
                                                            label="Account Type"
                                                            onChange={(e) => onselection(e)}
                                                            name='accountType'
                                                        >
                                                            {accountList?.map((acc: any, index: any) => {
                                                                return (
                                                                    <MenuItem key={index} value={acc}>{acc}</MenuItem>
                                                                )
                                                            })
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            id="demo-helper-text-aligned"
                                                            label="Bill Number"
                                                            name="billNumber"
                                                            size="small"
                                                            fullWidth
                                                            onChange={(e) => handlebillNo(e)}
                                                            value={bill?.billNo}
                                                            disabled
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormControl fullWidth>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DemoContainer components={['DatePicker']} >
                                                                <DatePicker label="Bill Date"
                                                                    slotProps={{ textField: { fullWidth: true, size: 'small', helperText: (billDate !== undefined) ? 'Please fill this field' : '' } }}
                                                                    value={bill?.billDate !== undefined ? dayjs(billDate) : null}
                                                                    onChange={handlebillDate}
                                                                    disabled
                                                                />
                                                            </DemoContainer>
                                                        </LocalizationProvider>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormControl>
                                                        <FormLabel id="demo-row-radio-buttons-group-label">Is this bill a part of Jobcost/Overhead Cost?</FormLabel>
                                                        <RadioGroup
                                                            row
                                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                                            name="row-radio-buttons-group"
                                                            value={expenseType}
                                                            onChange={handleCost}
                                                        >
                                                            <FormControlLabel value="Jobcost" control={<Radio />} label="Jobcost" />
                                                            <FormControlLabel value="Overhead" control={<Radio />} label="Overhead" />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <FormControl>
                                                        <FormLabel id="demo-row-radio-buttons-group-label">Has this bill been paid for</FormLabel>
                                                        <RadioGroup
                                                            row
                                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                                            name="row-radio-buttons-group"
                                                            value={paid}
                                                            onChange={handlePaid}
                                                        >
                                                            <FormControlLabel value="Yes" control={<Radio />} label="Yes" disabled />
                                                            <FormControlLabel value="No" control={<Radio />} label="No" disabled />

                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                            </>
                                            }

                                            <Grid item xs={6}>
                                                <Grid container spacing={2}>
                                                    {isPaid ?
                                                        <Grid item xs={12}>
                                                            {type === BILL && <Typography variant='h5' color="rgb(60 60 60 / 87%)">Select the payment method used</Typography>}
                                                        </Grid> : <></>}
                                                    {isPaid ? <Grid item xs={12} sm={9} md={9} lg={9} xl={9}>
                                                        {type === BILL &&
                                                            <>
                                                                {paymentTypes?.map((p: any, index: any) => {
                                                                    return (
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox icon={<RadioButtonUncheckedIcon />}
                                                                                    checkedIcon={<CheckCircleIcon />}
                                                                                    name="checkbox"
                                                                                    checked={paymentTypes === p ? true : false}
                                                                                    value={p}
                                                                                    onChange={(e) => handlepaymentmethod(e)}
                                                                                    size='small'
                                                                                    disabled={true}
                                                                                />
                                                                            }
                                                                            label={p}
                                                                        />
                                                                    )
                                                                })
                                                                }

                                                                {paymentMethod === "Credit Card" && <>
                                                                    {paymentMode?.map((pm: any, index: any) => {
                                                                        <FormControlLabel
                                                                            control={
                                                                                <Checkbox icon={<RadioButtonUncheckedIcon />}
                                                                                    checkedIcon={<CheckCircleIcon />}
                                                                                    name="checkbox"
                                                                                    checked={payment === pm ? true : false}
                                                                                    value={pm}
                                                                                    onChange={(e) => handlepayment(e)}
                                                                                    size='small'
                                                                                    disabled={true}
                                                                                />
                                                                            }
                                                                            label={pm}
                                                                        />
                                                                    })}
                                                                </>
                                                                }
                                                            </>
                                                        }
                                                    </Grid> : <></>}
                                                </Grid>
                                            </Grid>
                                        </Grid>


                                        {type === BILL &&
                                            <>
                                                <Grid container spacing={2} mb={2}>
                                                    <Grid item xs={6}>

                                                        <FormControl fullWidth size="small">
                                                            <Autocomplete
                                                                value={material?.companyName}
                                                                onChange={(e, newValue) => {
                                                                    if (typeof newValue === 'string') {
                                                                        handleChangeVendor(e, newValue);
                                                                    } else if (newValue && newValue.inputValue) {
                                                                        openVendorRegForm();
                                                                    } else {
                                                                        handleChangeVendor(e, newValue);
                                                                    }
                                                                }}
                                                                // onChange={(e, newValue) => setVendorNameAndId(e, newValue,index)}
                                                                filterOptions={(options, params) => {
                                                                    const filtered = filter(options, params);

                                                                    const { inputValue } = params;
                                                                    // Suggest the creation of a new value
                                                                    const isExisting = options.some((option) => inputValue === option?.companyName);
                                                                    if (inputValue !== '' && !isExisting) {
                                                                        filtered.push({
                                                                            inputValue,
                                                                            companyName: `Add Vendor`,
                                                                        });
                                                                    }

                                                                    return filtered;
                                                                }}
                                                                selectOnFocus
                                                                clearOnBlur
                                                                handleHomeEndKeys
                                                                id="free-solo-with-text-demo"
                                                                options={vendorList}
                                                                getOptionLabel={(option) => {
                                                                    // Value selected with enter, right from the input
                                                                    if (typeof option === 'string') {
                                                                        return option;
                                                                    }
                                                                    // Add "xxx" option created dynamically
                                                                    if (option.inputValue) {
                                                                        return option.inputValue;
                                                                    }
                                                                    // Regular option
                                                                    return option?.companyName;
                                                                }}

                                                                renderOption={(props, option) => <li {...props}>{option?.companyName}</li>}

                                                                freeSolo
                                                                renderInput={(params) => (
                                                                    <TextField {...params} label="Vendor Name" size='small'
                                                                        helperText={error && material.companyName === '' ? 'Required' : ''}
                                                                        error={error && material.companyName === ''}
                                                                    />
                                                                )}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={6}>

                                                        <FormControl fullWidth size="small">
                                                            <InputLabel id="demo-simple-select-label"
                                                                error={error && material.address === ''}
                                                            >Vendor Address</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={material.address}
                                                                label="Vendor Address"
                                                                name="address"
                                                                onChange={(e) => handleChangeAddress(e)}
                                                                required

                                                                // disabled={vendorList?.companyName === '' || vendorList?.companyName === undefined ? true : false}
                                                                error={error && material.address === ''}
                                                            >
                                                                {vendorAddressList?.map((vendor) => (
                                                                    <MenuItem value={vendor?.address}>{vendor?.address}</MenuItem>
                                                                ))}
                                                            </Select>
                                                            {/* {error && material.address === '' && <Typography color="error">Please select vendor address</Typography>} */}
                                                            <FormHelperText>{error && material.address === '' ? 'Required' : ''}</FormHelperText>
                                                        </FormControl>
                                                    </Grid>

                                                    {(category === "Material" && updated === true) && <Grid container paddingTop={4}>
                                                        <TableContainer component={Paper}>
                                                            <Table aria-label="simple table">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        {/* <TableCell>Industry</TableCell> */}
                                                                        <TableCell >Code</TableCell>
                                                                        <TableCell >Description</TableCell>
                                                                        <TableCell >Grade</TableCell>
                                                                        <TableCell>Quantity</TableCell>
                                                                        <TableCell >Unit Cost</TableCell>
                                                                        <TableCell >Amount</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {materialList.length === 0 ? (
                                                                        <TableRow>
                                                                            <TableCell colSpan={8} align="center">
                                                                                <Button
                                                                                    variant="contained"
                                                                                    color="primary"
                                                                                    onClick={() => handleListChange(material)}
                                                                                >
                                                                                    Add Row
                                                                                </Button>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    ) : (materialList?.map((row, index) => (
                                                                        <TableRow key={index}>
                                                                            {/* <TableCell>
                                                            <input
                                                              type="text"
                                                              value={row.industry}
                                                              onChange={(e) => handleChange(e, index, 'industry')}
                                                            />
                                                          </TableCell> */}
                                                                            <TableCell>
                                                                                <TextField id="standard-basic" variant="standard"
                                                                                    type="text"
                                                                                    value={row?.costCode}
                                                                                    onChange={(e) => handleChanged(e, index, 'code')}
                                                                                />
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {/* <TextField id="standard-basic" variant="standard"
                                                                            type="text"
                                                                            value={row?.name}
                                                                            onChange={(e) => handleChanged(e, index, 'description')}
                                                                        /> */}
                                                                                <FormControl fullWidth size="small">
                                                                                    <Autocomplete
                                                                                        value={row?.name}
                                                                                        onChange={(e, newValue) => {
                                                                                            if (typeof newValue === 'string') {
                                                                                                handleChangeMaterialName(e, newValue, index);
                                                                                            } else if (newValue && newValue.inputValue) {
                                                                                                openForm();
                                                                                            } else {
                                                                                                handleChangeMaterialName(e, newValue, index);
                                                                                            }
                                                                                        }}
                                                                                        filterOptions={(options, params) => {
                                                                                            const filtered = filter(options, params);

                                                                                            const { inputValue } = params;
                                                                                            // Suggest the creation of a new value
                                                                                            const isExisting = options.some((option) => inputValue === option?.name);
                                                                                            if (inputValue !== '' && !isExisting) {
                                                                                                filtered.push({
                                                                                                    inputValue,
                                                                                                    name: `Add Material`,
                                                                                                });
                                                                                            }

                                                                                            return filtered;
                                                                                        }}
                                                                                        selectOnFocus
                                                                                        clearOnBlur
                                                                                        handleHomeEndKeys
                                                                                        id="free-solo-with-text-demo"
                                                                                        options={materialData}
                                                                                        getOptionLabel={(option) => {
                                                                                            // Value selected with enter, right from the input
                                                                                            if (typeof option === 'string') {
                                                                                                return option;
                                                                                            }
                                                                                            // Add "xxx" option created dynamically
                                                                                            if (option.inputValue) {
                                                                                                return option.inputValue;
                                                                                            }
                                                                                            // Regular option
                                                                                            return option.name;
                                                                                        }}
                                                                                        renderOption={(props, option) => <li {...props}>{option?.name}
                                                                                        </li>}
                                                                                        // disabled={disabledMaterial}
                                                                                        freeSolo
                                                                                        disabled={vendorName?.companyName === '' || vendorAddress?.address === '' ? true : false}
                                                                                        renderInput={(params) => (
                                                                                            <TextField {...params} size='small' variant="standard"
                                                                                                error={(materialData?.companyName === '' && count !== 0)}
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </FormControl>
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <FormControl variant="standard" fullWidth size="small">
                                                                                    <InputLabel id="demo-simple-select-label" error={(row?.grade === '' && count !== 0)} >Grade</InputLabel>
                                                                                    <Select
                                                                                        labelId="demo-simple-select-label"
                                                                                        id="demo-simple-select"
                                                                                        value={row?.grade}
                                                                                        label="Grade"
                                                                                        name="grade"
                                                                                        onChange={(e) => handleChangeGrade(e, index)}
                                                                                        required
                                                                                        // disabled={material?.name === '' ? true : false}
                                                                                        error={(row?.grade === '' && count !== 0)}
                                                                                    >
                                                                                        {gradeList?.map((grade) => (
                                                                                            <MenuItem value={grade}>{grade}</MenuItem>
                                                                                        ))}
                                                                                    </Select>
                                                                                </FormControl>
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <FormControl fullWidth>
                                                                                    <TextField variant="standard"
                                                                                        id="demo-helper-text-aligned"
                                                                                        label="Quanitity"
                                                                                        name="quantity"
                                                                                        size="small"
                                                                                        fullWidth
                                                                                        // disabled={row?.name === '' || row?.grade === '' ? true : false}
                                                                                        value={row?.quantity}
                                                                                        error={(row?.quantity === '' && count !== 0)}
                                                                                        onChange={(e) => handleChangeQuantity(e, index)}
                                                                                        type="number" />
                                                                                </FormControl>
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                <FormControl fullWidth>
                                                                                    <TextField variant="standard"
                                                                                        id="demo-helper-text-aligned"
                                                                                        name="unit"
                                                                                        size="small"
                                                                                        fullWidth
                                                                                        disabled
                                                                                        value={row?.unit}
                                                                                    />
                                                                                </FormControl>
                                                                            </TableCell>
                                                                            <TableCell>
                                                                                {/* <TextField id="standard-basic" variant="standard"
                                                                            type="text"
                                                                            value={row?.totalCost}
                                                                            onChange={(e) => handleChanged(e, index, 'amount')}
                                                                        /> */}
                                                                                <FormControl fullWidth>
                                                                                    <TextField variant="standard"
                                                                                        id="demo-helper-text-aligned"
                                                                                        name="subTotal"
                                                                                        size="small"
                                                                                        fullWidth
                                                                                        disabled
                                                                                        value={row?.totalCost !== undefined || row?.totalCost !== '' ? (Number(row?.totalCost)).toFixed(2) : null}
                                                                                    />
                                                                                </FormControl>
                                                                            </TableCell>
                                                                            <TableCell>

                                                                            </TableCell>
                                                                        </TableRow>
                                                                    )))}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    </Grid>
                                                    }
                                                    {/* <Grid item xs={4}>
                                                <FormControl fullWidth size="small">
                                                    <Autocomplete
                                                        value={material?.name}
                                                        onChange={(e, newValue) => {
                                                            if (typeof newValue === 'string') {
                                                                handleChangeMaterialName(e, newValue);
                                                            } else if (newValue && newValue.inputValue) {
                                                                openForm();
                                                            } else {
                                                                handleChangeMaterialName(e, newValue);
                                                            }
                                                        }}
                                                        filterOptions={(options, params) => {
                                                            const filtered = filter(options, params);

                                                            const { inputValue } = params;
                                                            // Suggest the creation of a new value
                                                            const isExisting = options.some((option) => inputValue === option?.name);
                                                            if (inputValue !== '' && !isExisting) {
                                                                filtered.push({
                                                                    inputValue,
                                                                    name: `Add Material`,
                                                                });
                                                            }

                                                            return filtered;
                                                        }}
                                                        selectOnFocus
                                                        clearOnBlur
                                                        handleHomeEndKeys
                                                        id="free-solo-with-text-demo"
                                                        options={materialData}
                                                        getOptionLabel={(option) => {
                                                            // Value selected with enter, right from the input
                                                            if (typeof option === 'string') {
                                                                return option;
                                                            }
                                                            // Add "xxx" option created dynamically
                                                            if (option.inputValue) {
                                                                return option.inputValue;
                                                            }
                                                            // Regular option
                                                            return option.name;
                                                        }}
                                                        renderOption={(props, option) => <li {...props}>{option?.name}
                                                        </li>}
                                                        // disabled={disabledMaterial}
                                                        freeSolo
                                                        disabled={vendorName?.companyName === '' || vendorAddress?.address === '' ? true : false}
                                                        renderInput={(params) => (
                                                            <TextField {...params} label="Material Name" size='small'
                                                                error={(materialData?.companyName === '' && count !== 0)}
                                                            />
                                                        )}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormControl fullWidth size="small">
                                                    <InputLabel id="demo-simple-select-label" error={(material?.grade === '' && count !== 0)} >Grade</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={material?.grade}
                                                        label="Grade"
                                                        name="grade"
                                                        onChange={(e) => handleChangeGrade(e)}
                                                        required
                                                        disabled={material?.name === '' ? true : false}
                                                        error={(material?.grade === '' && count !== 0)}
                                                    >
                                                        {gradeList?.map((grade) => (
                                                            <MenuItem value={grade}>{grade}</MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id="demo-helper-text-aligned"
                                                        label="Quanitity"
                                                        name="quantity"
                                                        size="small"
                                                        fullWidth
                                                        disabled={material?.name === '' || material?.grade === '' ? true : false}
                                                        value={material?.quantity}
                                                        error={(material?.quantity === '' && count !== 0)}
                                                        onChange={(e) => handleChangeQuantity(e)}
                                                        type="number" />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id="demo-helper-text-aligned"
                                                        label="Unit"
                                                        name="unit"
                                                        size="small"
                                                        fullWidth
                                                        disabled
                                                        value={material?.unit}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={4}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        id="demo-helper-text-aligned"
                                                        label="Sub Total"
                                                        name="subTotal"
                                                        size="small"
                                                        fullWidth
                                                        disabled
                                                        value={material?.totalCost !== undefined || material?.totalCost !== '' ? (Number(material?.totalCost)).toFixed(2) : null}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        name="cost"
                                                        id="outlined-size-small"
                                                        size="small"
                                                        label="Unit Cost"
                                                        type="number"
                                                        disabled
                                                        value={material?.unitCost}
                                                    />
                                                </FormControl>
                                            </Grid> */}
                                                    <Grid container rowSpacing={2} columnSpacing={2}>
                                                        <Grid item xs={12} sm={7}>
                                                        </Grid>
                                                        <Grid item xs={6} sm={2}>
                                                        </Grid>
                                                        <Grid item xs={6} sm={3} className='alignEnd'>
                                                            <Box>
                                                                {materialList?.length > 0 && <Stack direction={'row'}>
                                                                    <Button onClick={() => handleListChange(material)}>Add Row</Button>
                                                                </Stack>
                                                                }
                                                            </Box>

                                                        </Grid>
                                                    </Grid>
                                                    {/* <Grid item xs={4}>
                                                <Button onClick={() => handleListChange(material)} size="large" startIcon={<AddIcon fontSize='small' />}>Add</Button>
                                            </Grid> */}
                                                    {/* <Grid container rowSpacing={2} columnSpacing={2}>
                                                        <Grid item xs={12} sm={7}>
                                                        </Grid>
                                                        <Grid item xs={6} sm={2}>
                                                        </Grid>
                                                        <Grid item xs={6} sm={3} className='alignEnd'>
                                                            <Box>
                                                                <Stack direction={'row'}>
                                                                    <Button onClick={addTaxRows}>Add Tax</Button>
                                                                </Stack>
                                                            </Box>
                                                        </Grid>
                                                    </Grid> */}
                                                    <Grid container rowSpacing={1} columnSpacing={1} mb={2} mt={2}>
                                                        {billData?.taxInfos?.map((tax, index) => (
                                                            <React.Fragment key={index}>
                                                                <Grid item xs={12} sm={6}>
                                                                    {/* Empty grid item for layout purposes */}
                                                                </Grid>
                                                                <Grid item xs={12} sm={2}>
                                                                    <Box>
                                                                        <TextField
                                                                            label="Tax Name"
                                                                            id="outlined-size-small"
                                                                            size="small"
                                                                            value={tax?.name}
                                                                            error={tax?.name === '' && error}
                                                                            // error={error && tax?.name.trim() === ''}
                                                                            helperText={error && tax?.name.trim() === '' ? 'Required' : ''}
                                                                            name="name"
                                                                            onChange={(e) => onInputTaxNameChange(e, index)}
                                                                        />
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item xs={6} sm={2} className='alignEnd'>
                                                                    {/* <Box>
              <TextField
                label="Percentage"
                id="outlined-size-small"
                size="small"
                value={tax?.percentage}
                name="percentage"
                type='number'
                error={tax?.percentage === '' && error}
                onChange={(e) => onInputChange(e, index)}
              />
            </Box> */}
                                                                    <Box>
                                                                        <TextField
                                                                            label="Percentage"
                                                                            id="outlined-size-small"
                                                                            size="small"
                                                                            value={tax?.percentage}
                                                                            name="percentage"
                                                                            type='number'
                                                                            error={tax?.percentage === '' && error}
                                                                            // error={error && tax?.percentage === ''}
                                                                            helperText={error && tax?.percentage === '' ? 'Required' : ''}
                                                                            onChange={(e) => handlePercentageChange(e, index)}
                                                                        />
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item xs={6} sm={2} className='alignEnd'>
                                                                    <Box>
                                                                        <Stack direction={'row'} spacing={1}>
                                                                            <TextField
                                                                                label="Value"
                                                                                id="outlined-size-small"
                                                                                size="small"
                                                                                type='number'
                                                                                value={tax?.value ? Number(tax.value).toFixed(2) : ''}
                                                                                name="value"
                                                                                disabled
                                                                            />
                                                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>

                                                                                <IconButton onClick={() => handleRemoveItem(index)}>
                                                                                    <DeleteIcon fontSize='small' />
                                                                                </IconButton>
                                                                            </Box>
                                                                        </Stack>
                                                                    </Box>
                                                                </Grid>
                                                            </React.Fragment>
                                                        ))}
                                                        <Grid container rowSpacing={1} columnSpacing={1} mb={2} className="alignEnd p-2 mt-2">
                                                            <Button onClick={addTaxRows}>
                                                                Add Tax Row
                                                            </Button>
                                                        </Grid>
                                                    </Grid>

                                                    <Grid xs={12}>
                                                        <Grid container spacing={2} sx={{ display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                                                            <Grid item xs={3}>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                            </Grid>
                                                            <Grid item xs={2} className='alignEnd'>
                                                                <Typography>Sub Total:</Typography>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Typography>{Number(totalCost).toFixed(2)}</Typography>
                                                            </Grid>
                                                            <Grid item xs={1}>

                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid xs={12}>
                                                        <Grid container spacing={2} sx={{ display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                                                            <Grid item xs={3}>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                            </Grid>
                                                            <Grid item xs={2} className='alignEnd'>
                                                                <Typography>Total:</Typography>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                {total && <Typography>${Number(total).toFixed(2)}</Typography>}
                                                            </Grid>
                                                            <Grid item xs={1}>

                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        }
                                        {!dcuploaded && (
                                            <Grid item xs={12} pt={1}>
                                                <Box>
                                                    <iframe
                                                        src={documentData}
                                                        width="100%"
                                                        height="100%"
                                                        title="PDF Viewer"
                                                        frameBorder="0"
                                                    ></iframe>
                                                </Box>
                                            </Grid>

                                        )}
                                    </CardContent>
                                </Card>
                            )

                        )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleUploadChange("Approve")} className='sucessButton'>Approve</Button>
                    <Button className='ErrorTonalButton' onClick={() => handleUploadChange("Rejected")}>Reject</Button>
                </DialogActions>
            </Dialog>

            <AddMaterialForm open={openAddMaterialForm}
                onClose={() => setOpenAddMaterialForm(false)}></AddMaterialForm>

            <VendorRegister
                open={openVendorForm}
                onClose={() => setOpenVendorForm(false)}
            />

            <CustomDialog
                open={openDialogs}
                onClose={handleDialogClose}
                success={createSuccess}
                error={createError}
                Content={createSuccess ? reviewStatus + " " + "succesfully" : "Failed"} />
            <CustomDialog
                open={openConfirmForm}
                onClose={handleConfirmDialogClose}
                success={createConfirmSuccess}
                error={createConfirmError}
                Content={createConfirmSuccess && !dcuploaded ? "Uploaded succesfully" : dcuploaded && createConfirmSuccess ? confirmResult : dcuploaded && createConfirmError ? confirmResult : "failed"} />
        </>
    );
}

export default ReviewBill;