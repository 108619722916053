import React, { useCallback, useEffect, useState } from "react";
import {
    Box,
    Card,
    CardContent,
    Checkbox,
    FormControlLabel,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Paper,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Stack,
    IconButton,
    TextField,
    Grid,
    Divider,
    FormControl,
    RadioGroup,
    Radio,
    styled,
    Switch,
    CircularProgress,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import PhoneIcon from '@mui/icons-material/Phone';
import BadgeIcon from '@mui/icons-material/Badge';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import CloseIcon from '@mui/icons-material/Close';
import { useHandleUnauthorized } from "../../hooks/UserSession";
import { checkPOSlip, getPOAndSlip, getPOItems, getPOSlip } from "../../services/PoService";
import { useOidcAccessToken } from "@axa-fr/react-oidc";
import dayjs from "dayjs";
import DeleteIcon from '@mui/icons-material/Delete';
import FileUpload from "../fileUploader/FileUpload";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { getReceiptByProjectAndPO, rejectVendorBill, uploadBill, uploadVendorBill } from "../../services/DeliverySlipService";
import { getCOAAccountTypes } from "../../services/JobLedgerService";
import CustomDialog from "../../pages/Dialog";

interface MaterialData {
    id: number;
    name: string;
    cost: number;
    code: string;
    project: string;
    vendor: string;
    unitCost: string;
    customer: string;
    quantity: string;
}
const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
        borderRadius: 22 / 2,
        '&::before, &::after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            width: 16,
            height: 16,
        },
        '&::before': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
            left: 12,
        },
        '&::after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                theme.palette.getContrastText(theme.palette.primary.main),
            )}" d="M19,13H5V11H19V13Z" /></svg>')`,
            right: 12,
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        width: 16,
        height: 16,
        margin: 2,
    },
}));


const DSVendorBillComparison: React.FC<any> = (props: any) => {

    const { handleUnauthorized } = useHandleUnauthorized();
    const { accessToken, accessTokenPayload } = useOidcAccessToken();
    const [bill, setBill] = useState<any[]>([]); // Initialize with an empty array
    const [type, setType] = useState('');
    const [orderNo, setOrderNo] = useState('');
    const [billNo, setBillNo] = useState('');
    const [billDate, setBillDate] = useState('');
    const [pOId, setPOId] = useState<any[]>([]);
    const [isOpen, setIsOpen] = useState(true);
    const [isPaid, setIsPaid] = useState(false);
    const [isAck, setIsAck] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState('');
    const [accountType, setAccountType] = useState('');
    const [paid, setPaid] = useState('');
    const [totalCost, setTotalCost] = React.useState<any>('');
    const [poData, setPoData] = React.useState({} as any);
    const [newRows, setNewRows] = React.useState<any>([]);
    const [updated, setUpdated] = React.useState(false);
    const [total, setTotal] = React.useState<any>('');
    const [update, setUpdate] = useState(false);

    const [openDialogs, setOpenDialogs] = React.useState(false);
    const [createSuccess, setCreateSuccess] = React.useState(false);
    const [dcuploaded, setDcUploaded] = React.useState(false);
    const [createConfirmSuccess, setCreateComfirmSuccess] = React.useState(false);
    const [createConfirmError, setCreateCofirmError] = React.useState(false);

    const [createError, setCreateError] = React.useState(false);
    const [dragOver, setDragOver] = useState(false);
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState<any>([]);
    const [selectedfile, SetSelectedFile] = useState<{ filename: any; filetype: any; fileimage: string | ArrayBuffer | null; datetime: any; filesize: string; }[]>([]);
    const [imagePreview, setImagePreview] = useState<string | null>(null);

    const [vendorAddressList, setVendorAddressList] = React.useState<any>();
    const [vendorAddress, setVendorAddress] = React.useState<any>();
    const [vendorName, setVendorName] = React.useState<any>();
    const [vendorList, setVendorList] = React.useState<any>([]);
    const [accountList, setAccountList] = React.useState<any>([]);
    const [count, setCount] = React.useState<any>(0);
    const [openVendorForm, setOpenVendorForm] = React.useState(false);
    const [confirmResult, setConfirmResult] = React.useState('');

    const [materialData, setMaterialData] = React.useState<any>();
    const [vendorId, setVendorId] = React.useState<any>();
    const [openAddMaterialForm, setOpenAddMaterialForm] = React.useState<boolean>(false);
    const [openConfirmForm, setOpenConfirmForm] = React.useState<boolean>(false);
    const [selectedDetailData, setSelectedDetailData] = useState<any>([]);
    const [showButton, setShowButton] = useState<any>([]);
    const [rejected, setRejected] = React.useState<boolean>(false);
    const [rejectedBills, setRejectedBills] = React.useState<boolean>(false);

    const [rejectedData, setRejectedData] = useState<any>([]);
    const [gradeList, setGradeList] = React.useState<any>();
    const [materialList, setMaterialList] = React.useState<any>([]);
    const [material, setMaterial] = React.useState<any>(
        {
            'companyName': '',
            'name': '',
            'quantity': '',
            'unit': '',
            'totalCost': '',
            'grade': '',
            'unitCost': '',
            'vendorId': '',
            'vendorMaterialMapId': '',
            'address': '',

        }
    );

    const [billData, setBillData] = React.useState<any>(
        {
            'vendorid': '',
            'paid': Boolean,
            'paymentMethod': '',
            'reason': '',
            'receiptInfo': '',
            'total': '',
            'billNo': '',
            'billDate': new Date(),
            'accountType': '',
            'isAcknowledged': Boolean
        }
    );
    const DELIVERYSLIP = 'DeliverySlip';
    const BILL = 'Bill'
    const [selectedMaterials, setSelectedMaterials] = useState<Set<number>>(new Set());
    const [dialogOpen, setDialogOpen] = useState(false);
    const [taxShow, setTaxShow] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [open, setOpen] = useState(false);
    const [openRejectBill, setOpenRejectBill] = useState(false);
    const [selectedReason, setSelectedReason] = useState(null);
    const rejectionReasons = [
        "Incorrect billing amount",
        "Duplicate billing",
        "Billing quantity mismatch",
        "Billing item mismatch",
        "All items and quantities are mismatched",
        "All of the above issues"
    ];
    const handleClickOpen = () => {
        // Validate fields
        const newErrors = {
            billNo: !billNo.trim(),
            billDate: !billDate,
            accountType: !accountType,
            file: file === null || file.length === 0,
        };

        // console.log('error',newErrors);
        // Set errors in state
        setErrors(newErrors);

        // Check if there are any validation errors
        const hasErrors = Object.values(newErrors).some((error) => error);
        // const  areRowsValid= addRows();

        if (!hasErrors) {
            setOpen(true);

        } else {
            console.error("Validation errors detected. Dialog will not open.");
        }
    };
    const handleViewRejectBills = () => {
        getRejectedBills();

        setOpenRejectBill(true);


    };
    const handleDialogOpen = () => {
        setBillNo('');
        setAccountType('');
        setBillDate('');

        SetSelectedFile([
            {
                filename: '',
                filetype: '',
                fileimage: null,
                datetime: '',
                filesize: ''
            }
        ]); // Wrap object inside an array

        setDialogOpen(true);
    };

    const handleRejectBill = async () => {
        // console.log("Rejecting bill with reason:", selectedReason,poData.poItems);
        const newErrors = {
            billNo: !billNo.trim(),
            billDate: !billDate,
            accountType: !accountType,
            file: file === null || file.length === 0,

        };
        setErrors(newErrors);

        if (!Object.values(newErrors).some((error) => error)) {
            //console.log("Upload file:", file);
            setRejected(true);
            try {
                if (file !== null && file.length > 0) {
                    const formData = new FormData();
                    file.map((file: any) => {
                        formData.append('file', file);
                    });
                    //console.log(formData, file);
                    formData.append('name', "VendorBill")
                    setBillData({
                        'vendorid': poData.vendorId,
                        'paid': false,
                        'paymentMethod': '',
                        'reason': selectedReason,
                        'receiptInfo': JSON.stringify(poData.poItems),
                        'total': Number(total.toFixed(2)),
                        'billNo': billNo,
                        'billDate': new Date(),
                        'accountType': accountType,
                        'isAcknowledged': isAck,
                        'taxInfos': poData?.taxInfos,


                    })
                    formData.append('data', JSON.stringify({
                        'vendorid': poData.vendorId,
                        'paid': isPaid,
                        'paymentMethod': paymentMethod,
                        'reason': selectedReason,
                        'receiptInfo': JSON.stringify(poData.poItems),
                        'total': Number(total.toFixed(2)),
                        'billNo': billNo,
                        'billDate': new Date(),
                        'accountType': accountType,
                        'isAcknowledged': isAck,
                        'taxInfos': poData?.taxInfos,
                    }))
                    // console.log('billData',billData)

                    // console.log(props?.projectId, props?.rowData?.purchaseOrderId, formData)
                    // console.log('new',formData)
                    const data = await rejectVendorBill(props?.projectId, props?.rowData?.purchaseOrderId, formData, accessToken);

                    if (data?.status === 200) {
                        setCreateSuccess(true);
                    } else {
                        setCreateError(true);
                    }


                }
                else {
                    console.error("Invalid file:", file);
                    setCreateError(true);
                }
            }
            catch (error) {
                console.error("Error uploading:", error);
            }
        }
    };

    // Function to handle closing the dialog
    const handleClose = () => {
        setOpen(false);
        setOpenRejectBill(false);
    };

    const handleAddNewRow = () => {
        setPoData((prevData) => ({
            ...prevData,
            taxInfos: Array.isArray(prevData?.taxInfos)
                ? [...prevData.taxInfos, { name: '', percentage: '', value: '' }]
                : [{ name: '', percentage: '', value: '' }], // Ensure array format
        }));
    };

    const [errors, setErrors] = useState({
        billNo: false,
        billDate: false,
        accountType: false,
        file: file
    });
    const [rows, setRows] = useState([
        {
            industry: '',
            code: '',
            description: 'E',
            quantity: '',
            unitCost: '',
            amount: '',
        },
    ]);


    const getPOItem = async () => {
        if (props.rowData) {
            //console.log("hiii")
            let result = await getPOSlip(props?.projectId, props?.rowData?.purchaseOrderId, accessToken);
            //console.log(result);
            handleUnauthorized(result);
            let finalData = result.data;
            var add;
            if (finalData?.customerAddress) {
                add = JSON?.parse(finalData?.customerAddress);
                finalData['customerAddress'] = add?.address
            }

            if (finalData?.status === "Draft" && finalData?.taxInfos === null) {
                finalData['taxInfos'] = [{
                    name: "",
                    percentage: "",
                    value: "",
                }]
                //console.log(finalData)
                if (finalData) {
                    setPoData(finalData);

                }
            }
            else {
                if (finalData) {
                    setPoData(finalData);

                }
                console.log(finalData)

            }
            // if (finalData?.status !== "Draft") {
            setTaxShow(true);
            // }
            // else {
            //     setTaxShow(false);
            // }
        }

    }
    const validateRows = () => {
        // Check if all rows are valid
        for (const item of poData.poItems) {
            if (!item.description || !item.itemCode || !item.quantity || !item.rate || !item.cost) {
                //console.log("hiii")
                return false;
            }
        }
        return true;
    };
    //console.log(poData)
    const handleChange = (e, index) => {

        // updatedRows[index][field] = e.target.value;
        // Find the corresponding item in poItems using the id from rows
        // const updatedPoItems = poData.poItems.map(item => {
        //     if (item.id === id) {
        //         return {
        //             ...item,
        //             [field]: e.target.value, // Update the field with the new value
        //         };
        //     }
        //     return item;
        // });
        // // Update the poData with the updated poItems
        // setPoData({
        //     ...poData,
        //     poItems: updatedPoItems,

        // });
        //setRows(updatedRows);
        let newArray = [...poData.poItems];
        let taxInfos = [...poData?.taxInfos];
        if (e.target.name === "rate") {
            setUpdate(true);
            if (e.target.value > 0 || e.target.value === '') {
                newArray[index]['rate'] = e.target.value;
                if (newArray[index]['received_quantity'] !== '') {
                    newArray[index]['subTotal'] = newArray[index]['received_quantity'] * e.target.value;
                    newArray[index]['cost'] = newArray[index]['received_quantity'] * e.target.value;

                    let total = 0
                    let totalTaxAmount = 0
                    poData?.poItems?.map((d: any) => {
                        if (typeof (d?.subTotal) !== "string") {
                            total = total + d?.subTotal;
                        }
                    })
                    taxInfos?.map((d: any, i: any) => {
                        taxInfos[i]['value'] = (total * d?.percentage) / 100;
                        totalTaxAmount = totalTaxAmount + taxInfos[i]['value'];
                    })
                    setPoData({ ...poData, poItems: newArray, taxInfos: taxInfos, totalCost: total + totalTaxAmount, total: total });

                }
                else {
                    setPoData({ ...poData, poItems: newArray });
                }
            }
        }
        else if (e.target.name === "quantity") {
            setUpdate(true);

            if (e.target.value > 0 || e.target.value === '') {
                newArray[index]['quantity'] = e.target.value;
                if (newArray[index]['rate'] !== '') {
                    newArray[index]['subTotal'] = newArray[index]['rate'] * e.target.value;
                    newArray[index]['cost'] = newArray[index]['quantity'] * e.target.value;

                    let total = 0
                    let totalTaxAmount = 0
                    poData?.poItems?.map((d: any) => {
                        if (typeof (d?.subTotal) !== "string") {
                            total = total + d?.subTotal;
                        }
                    })
                    taxInfos?.map((d: any, i: any) => {
                        taxInfos[i]['value'] = (total * d?.percentage) / 100;
                        totalTaxAmount = totalTaxAmount + taxInfos[i]['value'];
                    })
                    setPoData({ ...poData, poItems: newArray, taxInfos: taxInfos, totalCost: total + totalTaxAmount, total: total });
                }
                else {
                    setPoData({ ...poData, poItems: newArray });
                }
            }
        }
        else {
            newArray[index][e.target.name] = e.target.value;
            setPoData({ ...poData, poItems: newArray });
        }
        // const { name, value } = e.target;

        // // Create a copy of the poItems array
        // const updatedPoItems = [...poData.poItems];

        // // Update the specific item's property
        // updatedPoItems[index][name] = value;
        // updatedPoItems[index].totalCost = Number(poData?.poItems?.reduce((acc, item) => acc + Number(item.cost || 0), 0)).toFixed(2);
        // // Update state with the modified poItems array
        // setPoData({ ...poData, poItems: updatedPoItems });

        //console.log(poData);
    };
    const handleFileUpload = (file: File) => {
        //console.log("Uploaded file:", file);
        // Handle the uploaded file (e.g., upload it to a server)
    };

    React.useEffect(() => {
        getPOItem();
        getAccountTypes();
    }, [accessToken]);
    React.useEffect(() => {
        getPOItem();

    }, []);
    const handleCheckboxChange = (id: number) => {
        //console.log(id);
        setSelectedMaterials((prevSelected) => {
            const updatedSelected = new Set(prevSelected);
            if (updatedSelected.has(id)) {
                updatedSelected.delete(id); // Deselect the item
            } else {
                updatedSelected.add(id); // Select the item
            }
            return updatedSelected;
        });
        //console.log(selectedMaterials)
    };
    // To retrieve the selected details
    const selectedDetails = Array.from(selectedMaterials).map((index) => poData.poItems[index]);
    // Function to map selected materials and update the state
    const selectedDataInform = () => {
        const details = Array.from(selectedMaterials).map((index) => poData.poItems[index]);
        setSelectedDetailData(details);
    };
    // Calculate the cost of each selected item and store in the state

    const calculateCosts = () => {
        let total = 0;
        let totalTaxAmount = 0;
        if (poData?.poItems !== undefined && poData?.poItems?.length > 0) {


            let newArray = [...poData.poItems];
            let taxInfos = [...poData?.taxInfos];
            let hasTaxInfoError = false;

            let add = 0.0;
            const totalCost = poData?.poItems?.reduce((sum, item) => {
                return sum + (item.cost || 0);  // Ensure cost is a number and handle undefined values
            }, 0);

            setTotalCost(totalCost);
            if (!poData?.taxInfos || poData?.taxInfos.length === 0) {
                hasTaxInfoError = true;
            }
            if (hasTaxInfoError) {
                console.error("Tax info is missing or incomplete. Cannot proceed.");
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    taxInfos: true,
                }));
                setError(true);

                handleAddNewRow();
            }


            poData?.poItems?.map((d: any) => {
                if (typeof (d?.subTotal) !== "string") {
                    total = totalCost + d?.subTotal;
                }
            })
            taxInfos?.map((d: any, i: any) => {
                taxInfos[i]['value'] = (totalCost * d?.percentage) / 100;
                totalTaxAmount = totalTaxAmount + taxInfos[i]['value'];
            })
            setTotal(totalCost + totalTaxAmount)
            setPoData({ ...poData, poItems: newArray, taxInfos: taxInfos, totalCost: totalCost + totalTaxAmount, total: total });
        }

    };
    const handleRemoveContent = () => {
        try {
            let total = 0;
            let totalTaxAmount = 0;

            // Calculate the total of all subTotals
            if (update) {
                poData?.poItems?.forEach((d: { subTotal: number | string }) => {
                    if (typeof d?.subTotal !== "string") {
                        total += d.subTotal;
                    }
                });
            } else {
                poData?.poItems?.forEach((d: { totalCost: number | string }) => {
                    if (typeof d?.totalCost !== "string") {
                        total += d.totalCost;
                    }
                });
            }


            // Calculate the tax values and total tax amount
            const updatedTaxInfos = poData?.taxInfos?.map((d: { percentage: number }, i: number) => {
                const taxValue = (total * d.percentage) / 100;
                totalTaxAmount += taxValue;
                return { ...d, value: taxValue }; // Create a new object to avoid mutation
            });

            // Calculate the total invoiced amount
            const grandTotal = total + totalTaxAmount;

            // Update the state immutably
            setTotal(grandTotal);
            setPoData((prevPoData: any) => ({
                ...prevPoData,
                taxInfos: updatedTaxInfos,
            }));
        } catch (error) {
            console.error("Error in handleRemoveContent:", error);
        }
    };



    const handleDialogClose = () => {
        setOpenDialogs(false);
        window.location.reload();
    };
    const handleRejectDialogClose = () => {
        setRejected(false);
        window.location.reload();
    };
    // Example usage
    React.useEffect(() => {
        selectedDataInform(); // Populate the selected details when the component mounts or data changes
    }, [selectedMaterials, poData]);

    React.useEffect(() => {
        calculateCosts(); // Calculate costs whenever selected details are updated
    }, [poData]);
    React.useEffect(() => {
        fetchPOSlipStatus();
    }, []);

    const calculateTotalCost = () => {
        return Array.from(selectedMaterials).reduce(
            (total, id) => total + (poData?.poItems?.find((material) => material.id === id)?.cost || 0),
            0
        );
    };
    const onInputTaxNameChange = (e: any, index: any) => {
        let newArray = [...poData?.taxInfos];
        newArray[index][e.target.name] = e.target.value;
        setPoData({ ...poData, taxInfos: newArray });
    };
    const handleRemoveItem = (index: any) => {
        let newArray = [...poData?.taxInfos];
        newArray.splice(index, 1)
        let totalTaxAmount = 0
        newArray?.map((d: any) => {
            if (typeof (d.value) !== "string") {
                totalTaxAmount = totalTaxAmount + d.value;
            }
        })
        newRows.pop();
        let totalPoAmount = poData?.subTotal + totalTaxAmount;
        setPoData({ ...poData, taxInfos: newArray, purchaseAmount: totalPoAmount });
    }
    // const handlePercentageChange = (e, index) => {
    //     const { value } = e.target;
    //     // Ensure the value is a valid number
    //     if (!isNaN(value)) {
    //       onInputChange(e, index); // Call the existing onInputChange function
    //       // Optionally, you can recalculate the tax value here if needed
    //     }
    //   };
    // const onInputChange = (e: any, index: any, ) => {
    //     // if ((e.target.value) >= 0) {
    //     //     let taxValue = (e.target.value * subTotal) / 100;
    //     //     let newArray = [...poData?.taxInfos];
    //     //     newArray[index][e.target.name] = e.target.value;
    //     //     newArray[index]["value"] = taxValue;
    //     //     let totalTaxAmount = 0
    //     //     poData?.taxInfos?.map((d: any) => {
    //     //         if (typeof (d.value) !== "string") {
    //     //             totalTaxAmount = totalTaxAmount + d.value;
    //     //         }
    //     //     })
    //     //     let totalPoAmount = subTotal + totalTaxAmount;
    //     //     setPoData({ ...poData, taxInfos: newArray, purchaseAmount: totalPoAmount });
    //     // }

    //     const { name, value } = e.target;

    //     // Create a copy of the poItems array
    //     const updatedPoItems = [...poData.poItems];

    //     // Update the specific item's property
    //     updatedPoItems[index][name] = value;
    //     updatedPoItems[index].totalCost = Number(poData?.poItems?.reduce((acc, item) => acc + Number(item.cost || 0), 0)).toFixed(2);
    //     // Update state with the modified poItems array
    //     setPoData({ ...poData, poItems: updatedPoItems });

    //     //console.log(poData);
    // };

    // // const onInputTaxNameChange = (e: any, index: any) => {
    // //     let newArray = [...poData?.taxInfo];
    // //     newArray[index][e.target.name] = e.target.value;
    // //     setPoData({ ...poData, taxInfo: newArray });
    // // };



    useEffect(() => {
        handleRemoveContent();


    }, [totalCost]);
    const handlePercentageChange = (e, index) => {
        const { value, name } = e.target;
        console.log('value', value, 'name', name);

        if (!isNaN(value)) {
            onInputChange(name, value, index); // Pass name, value, and index to onInputChange
        }
    };

    const onInputChange = (name, value, index) => {
        console.log('name', name, 'value', value);

        const updatedTaxInfos = [...poData.taxInfos];
        console.log('updatedTax', updatedTaxInfos);

        updatedTaxInfos[index][name] = value;

        if (name === 'percentage') {
            console.log('succusee');


            const taxValue = (totalCost * value) / 100;
            console.log(taxValue);
            updatedTaxInfos[index].value = taxValue.toFixed(2);
        }

        setPoData((prevData) => ({
            ...prevData,
            taxInfos: updatedTaxInfos,
        }));
    };

    //   const onInputTaxNameChange = (e, index) => {
    //     const { name, value } = e.target;
    //     const updatedTaxInfos = [...poData.taxInfos];
    //     updatedTaxInfos[index][name] = value;
    //     setPoData((prevData) => ({
    //       ...prevData,
    //       taxInfos: updatedTaxInfos,
    //     }));
    //   };
    const fetchPOSlipStatus = async () => {
        try {
            const result = await checkPOSlip(props?.projectId, props?.rowData?.purchaseOrderId, accessToken);
            setShowButton(result.data); // Simplified condition
        } catch (error) {
            console.error("Error fetching PO slip status:", error);
            return false; // Optional: Handle cases where the fetch fails
        }
    };
    // const handleUploadChange = async () => {
    //     const newErrors = {
    //         billNo: !billNo.trim(),
    //         billDate: !billDate,
    //         accountType: !accountType,
    //         file: file === null || file.length === 0,


    //     };
    //     setErrors(newErrors);

    //     if (!Object.values(newErrors).some((error) => error)) {
    //         setOpenDialogs(true);
    //         //console.log("Upload file:", file);
    //         try {
    //             if (file !== null && file.length > 0) {
    //                 const formData = new FormData();
    //                 file.map((file: any) => {
    //                     formData.append('file', file);
    //                 });
    //                 //console.log(poData,file,billNo);
    //                 formData.append('name', "VendorBill")
    //                 setBillData({
    //                     'vendorid': poData.vendorId,
    //                     'paid': false,
    //                     'paymentMethod': '',
    //                     'reason': '',
    //                     'receiptInfo': JSON.stringify(poData.poItems),
    //                     'total': total,
    //                     'billNo': billNo,
    //                     'billDate': new Date(),
    //                     'accountType': accountType,
    //                     'isAcknowledged': isAck,
    //                     'taxInfos': poData?.taxInfos
    //                 })
    //                 formData.append('data', JSON.stringify({
    //                     'vendorid': poData.vendorId,
    //                     'paid': isPaid,
    //                     'paymentMethod': paymentMethod,
    //                     'reason': '',
    //                     'receiptInfo': JSON.stringify(poData.poItems),
    //                     'total': total,
    //                     'billNo': billNo,
    //                     'billDate': new Date(),
    //                     'accountType': accountType,
    //                     'isAcknowledged': isAck,
    //                     'taxInfos': poData?.taxInfos
    //                 }))
    //                 console.log('billData',billData)

    //                 console.log(props?.projectId, props?.rowData?.purchaseOrderId, formData)

    //                 console.log('formdata',formData);
    //                 const data = await uploadVendorBill(props?.projectId, props?.rowData?.purchaseOrderId, formData, accessToken);
    //                 if (data?.status === 200) {
    //                     setCreateSuccess(true);
    //                 } else {
    //                     setCreateError(true);
    //                 }


    //             }
    //             else {
    //                 console.error("Invalid file:", file);
    //                 setCreateError(true);
    //             }
    //         }
    //         catch (error) {
    //             console.error("Error uploading:", error);
    //         }
    //     }
    // };

    const handleUploadChange = async () => {
        let hasTaxInfoError = false;

        // Ensure taxInfos is an array before validation
        if (!poData?.taxInfos || poData?.taxInfos.length === 0) {
            hasTaxInfoError = true;
        }

        // Validate each row in taxInfos
        const updatedTaxInfos = poData?.taxInfos?.map((tax) => {
            if (!tax.name.trim() || tax.percentage === '') {
                hasTaxInfoError = true;
                return { ...tax, error: true }; // Mark invalid rows
            }
            return { ...tax, error: false }; // Valid rows
        });

        // Validate other required fields
        const newErrors = {
            billNo: !billNo.trim(),
            billDate: !billDate,
            accountType: !accountType,
            file: file === null || file.length === 0,
            taxInfos: hasTaxInfoError,
        };

        // If taxInfos is empty, show an error and add a new row
        if (hasTaxInfoError) {
            console.error("Tax info is missing or incomplete. Cannot proceed.");
            setErrors((prevErrors) => ({
                ...prevErrors,
                taxInfos: true,
            }));
            setError(true);
            return;
        }

        setErrors(newErrors);

        if (!Object.values(newErrors).some((error) => error)) {
            setOpenDialogs(true);

            try {
                if (file !== null && file.length > 0) {
                    const formData = new FormData();
                    file.forEach((file: any) => {
                        formData.append("file", file);
                    });

                    formData.append("name", "VendorBill");
                    setBillData({
                        vendorid: poData.vendorId,
                        paid: false,
                        paymentMethod: "",
                        reason: "",
                        companyName: poData?.vendorName,
                        receiptInfo: JSON.stringify(poData.poItems),
                        total: total,
                        billNo: billNo,
                        billDate: new Date(),
                        accountType: accountType,
                        isAcknowledged: isAck,
                        taxInfos: poData?.taxInfos,
                    });

                    formData.append(
                        "data",
                        JSON.stringify({
                            vendorid: poData.vendorId,
                            paid: isPaid,
                            paymentMethod: paymentMethod,
                            companyName: poData?.vendorName,
                            reason: "",
                            receiptInfo: JSON.stringify(poData.poItems),
                            total: total,
                            billNo: billNo,
                            billDate: new Date(),
                            accountType: accountType,
                            isAcknowledged: isAck,
                            taxInfos: poData?.taxInfos,
                        })
                    );

                    console.log("billData", billData);
                    console.log(props?.projectId, props?.rowData?.purchaseOrderId, formData);

                    const data = await uploadVendorBill(
                        props?.projectId,
                        props?.rowData?.purchaseOrderId,
                        formData,
                        accessToken
                    );

                    if (data?.status === 200) {
                        setCreateSuccess(true);
                    } else {
                        setCreateError(true);
                    }
                } else {
                    console.error("Invalid file:", file);
                    setCreateError(true);
                }
            } catch (error) {
                console.error("Error uploading:", error);
            }
        }
    };

    const addRows = () => {
        if (!validateRows()) {
            setUpdated(false);
            return;
        }

        const addNewItem = {
            cost: "",
            costCode: "",
            customerName: '',
            delivery_status: "",
            description: "",
            id: "",
            itemCode: "",
            itemStatus: "",
            quantity: "",
            rate: "",
            received_quantity: '',
            referenceType: "",
            service: "",
            totalCost: ""

        };

        // Add the new item directly to poItems
        const updatedPoItems = [...poData.poItems, addNewItem];

        // Update state
        setPoData({ ...poData, poItems: updatedPoItems });
    };

    const handlebillNo = async (e: any) => {
        setBillNo("");
        setBillNo(e.target.value);
        // Remove error message for billNo when user types
        if (errors.billNo) {
            setErrors(prev => ({ ...prev, billNo: false }));
        }
    };

    const handlebillDate = async (e: any) => {
        setBillDate(dayjs(e.$d).format('YYYY-MM-DD') + "T00:00:00.000Z");
        if (errors.billDate) {
            setErrors(prev => ({ ...prev, billDate: false }));
        }
    };

    const handlepaymentmethod = async (e: any) => {
        //console.log(e.target.value)
        setPaymentMethod(e.target.value);
    };
    const handleToggle = () => {
        setIsAck((prev) => !prev);
    };
    const handleDragOver = useCallback((event: any) => {
        event.preventDefault();
        setDragOver(true);
    }, []);

    const handleDragLeave = useCallback((event: any) => {
        event.preventDefault();
        setDragOver(false);
    }, []);

    const handleDrop = useCallback(
        (event: any) => {
            event.preventDefault();
            setDragOver(false);
            const files = event.dataTransfer.files;
            //console.log(files)
            if (files && files[0]) {
                handleFileChange(files[0]);
            }
        },
        []
    );

    const handleFileChange = (file: File) => {
        //console.log(file);
        setLoading(true);
        setFile(file);
        const formData = new FormData();
        formData.append('file', file);

        const reader = new FileReader();
        reader.onloadend = () => {
            setLoading(false);
            if (reader.result === null) {
                setImagePreview(null);
            } else {
                setImagePreview(reader.result as string);
            }
        };
        reader.readAsDataURL(file);
    };


    const filesizes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
    const getRejectedBills = async () => {
        try {
            const res = await getReceiptByProjectAndPO(props?.projectId, props?.rowData?.purchaseOrderId, accessToken);
            console.log(res.data)
            if (res.data && res.data.length > 0) {
                setRejectedBills(true);
                setRejectedData(res.data);
                console.log('rejectedData', rejectedData[0]);

            }
            // setResponse(res.data)
            // setResponse(res.data)
            // setOpenConfirmationFailed(true);
        } catch (error) {
            console.error('Error fetching user session:', error);
        }
    }
    let images: any = [];
    const handleFileDataChange = useCallback(
        (event: any) => {
            for (let i = 0; i < event.target.files.length; i++) {
                images.push((event.target.files[i]));
                let reader = new FileReader();
                let file = event.target.files[i];
                setFile(images);
                reader.onloadend = () => {
                    SetSelectedFile((preValue) => {
                        return [
                            ...preValue,
                            {
                                // id: shortid.generate(),
                                filename: event.target.files[i].name,
                                filetype: event.target.files[i].type,
                                fileimage: reader.result,
                                datetime: event.target.files[i].lastModifiedDate.toLocaleString('en-IN'),
                                filesize: filesizes(event.target.files[i].size)
                            }
                        ]
                    });
                }
                if (event.target.files[i]) {
                    reader.readAsDataURL(file);
                }
            }
        },
        []
    );

    const handleRemoveFile = useCallback((index: number) => {
        SetSelectedFile((prevFiles) => {
            // console.log('prevFiles',prevFiles);
            const updatedFiles = prevFiles.filter((_, i) => i !== index); // Remove the file at the specified index
            //   console.log('updataedfile',updatedFiles.length    );

            // Clear the file error if no files are left
            if (updatedFiles.length === 0) {
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    file: file,
                }));
            }

            return updatedFiles;
        });
    }, []);


    const onselection = async (e: any) => {
        // //console.log(e.target.value)
        setAccountType(e.target.value);
          // Remove error when user selects an account type
    if (errors.accountType) {
        setErrors(prev => ({ ...prev, accountType: false }));
    }
    };
    const getAccountTypes = async () => {
        let result = await getCOAAccountTypes(accessToken);
        if (result.data !== undefined) {
            setAccountList(result.data);
        }
    }
    const handleRemoveListItem = (index: any) => {
        let newArray = [...poData?.poItems];
        newArray.splice(index, 1)
        // setInvoiceData({ ...invoiceData, taxInfo: newArray });
        let totalTaxAmount = 0

        newRows.pop();
        setPoData({ ...poData, poItems: newArray });
    }
    return (
        <Box >
            {/* Button to Open Dialog */}
            {showButton && <Button variant="contained" color="primary" onClick={handleDialogOpen}>
                Upload Vendor Bill
            </Button>}

            {/* Dialog */}
            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} maxWidth="lg" fullWidth>
                <DialogTitle fontSize={'20px'} fontWeight={'400'} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    Vendor Bill Delivery Slip Comparison
                    <IconButton edge="end" color="inherit" onClick={() => setDialogOpen(false)} sx={{ position: 'absolute', right: 15 }}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <Box mb={2} className="invoiceHeader" >

                        {/* <Typography variant="h4" mb={2}>
                            Vendor Order Details:
                        </Typography> */}
                        <Box mb={1} sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', }}>

                                {/* Tenant Details */}
                                <Grid item xs={12} sm={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', textAlign: 'left' }}>
                                    <Typography>
                                        <img src={poData?.tenantLogo} alt='' className="logo" />
                                    </Typography>
                                    <Typography paddingTop={2}>{poData?.tenantAddress}</Typography>
                                    <Typography>{poData?.tenantCity}</Typography>
                                    <Typography>{poData?.tenantState}</Typography>
                                    <Typography>{poData?.tenantCountry}</Typography>
                                    {poData?.tenantPrimaryContact && (
                                        <Typography>{poData?.tenantPrimaryContact}</Typography>
                                    )}
                                    {poData?.tenantSecondaryContact && (
                                        <Typography>{poData?.tenantSecondaryContact}</Typography>
                                    )}

                                </Grid>

                                {/* Delivery Details Section - Aligned to the End */}
                                {/* <Grid item xs={12} sm={6} className='alignEnd '>
                                    <Typography variant="h5" sx={{ fontSize: "16px", color: "primary.secondary", fontWeight: 'bold' }} mb={1}>
                                        Delivery Details:
                                    </Typography>

                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                        <Typography className="invoiceNumber" display="flex" alignItems="center" sx={{ textAlign: 'left' }}>
                                            <AccountTreeIcon sx={{ fontSize: 13, color: 'grey.700' }} />&nbsp;PO Number:&nbsp;#{poData?.id}
                                        </Typography>

                                        <Typography display="flex" alignItems="center" sx={{ textAlign: 'left' }}>
                                            <AccountTreeIcon sx={{ fontSize: 13, color: 'grey.700' }} />&nbsp;Delivery Date: {poData?.deliveryDate && dayjs(poData?.deliveryDate).isValid()
                                                ? dayjs(poData?.deliveryDate).format('DD MMM YYYY') // Format or bind the valid date
                                                : "No delivery date available." // Message for invalid or empty date
                                            }
                                        </Typography>
                                    </Box>
                                </Grid> */}

                                <Grid item xs={12} sm={6} className='alignEnd '>
                                    <Stack>
                                        <Grid item xs={12} className='billingAddressContainer'>
                                            <Typography className='invoiceNumber poHeader'> Delivery Details:</Typography>
                                            <Typography>PO Number:&nbsp;#{poData?.id}</Typography>
                                            <Typography>Delivery Date:{poData?.deliveryDate && dayjs(poData?.deliveryDate).isValid()
                                                ? dayjs(poData?.deliveryDate).format('DD MMM YYYY') // Format or bind the valid date
                                                : "No delivery date available." // Message for invalid or empty date
                                            }</Typography>
                                        </Grid>

                                    </Stack>
                                </Grid>


                            </Grid>
                        </Box>




                    </Box>


                    <Grid container spacing={2} p={2} sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', marginBottom: '2vh' }}>
                        <Grid item xs={12} sm={6} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            {/* Vendor Details */}
                            <Box ml={1}>
                                <Typography variant="h5" sx={{ fontSize: "16px", color: "primary.secondary" }} mb={1}>
                                    Vendor Details:
                                </Typography>

                                <Typography display="flex" alignItems="center">
                                    <BadgeIcon sx={{ fontSize: 13, color: 'grey.700' }} />&nbsp;{poData?.vendorName}
                                </Typography>
                                <Typography display="flex" alignItems="center">
                                    <EmailIcon sx={{ fontSize: 13, color: 'grey.700' }} />&nbsp;{poData?.vendorEmail}
                                </Typography>
                                <Typography display="flex" alignItems="center">
                                    <ContactMailIcon sx={{ fontSize: 13, color: 'grey.700' }} />&nbsp;{poData?.vendorAddress}
                                </Typography>
                                <Typography display="flex" alignItems="center">
                                    <PhoneIcon sx={{ fontSize: 13, color: 'grey.700' }} />&nbsp;{poData?.vendorMobileNo}
                                </Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            {/* Customer Details */}
                            <Box ml={1}>
                                <Typography variant="h5" sx={{ fontSize: "16px", color: "primary.secondary" }} mb={1}>
                                    Customer Details:
                                </Typography>

                                <Typography display="flex" alignItems="center">
                                    <BadgeIcon sx={{ fontSize: 13, color: 'grey.700' }} />&nbsp;{poData?.customerName}
                                </Typography>
                                <Typography display="flex" alignItems="center">
                                    <EmailIcon sx={{ fontSize: 13, color: 'grey.700' }} />&nbsp;{poData?.customerEmail}
                                </Typography>
                                <Typography display="flex" alignItems="center">
                                    <ContactMailIcon sx={{ fontSize: 13, color: 'grey.700' }} />&nbsp;{poData?.customerAddress}
                                </Typography>
                                <Typography display="flex" alignItems="center">
                                    <PhoneIcon sx={{ fontSize: 13, color: 'grey.700' }} />&nbsp;{poData?.customerMobileNo}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>


                    <Grid container spacing={2}>

                        <Grid item xs={12} sm={6}>
                            <Typography m={1}>
                                <FormControl fullWidth>
                                    <TextField
                                        id="demo-helper-text-aligned"
                                        label="Bill Number"
                                        name="billNumber"
                                        size="small"
                                        onChange={(e) => handlebillNo(e)}
                                        value={billNo}
                                        error={errors.billNo}
                                        helperText={errors.billNo ? "Please enter a bill number" : ""}
                                    />
                                </FormControl>
                            </Typography>
                            <Typography m={1}>
                                <FormControl fullWidth>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker']}>
                                            <DatePicker
                                                label="Billing Date"

                                                value={billDate !== undefined ? dayjs(billDate) : null}
                                                onChange={handlebillDate}
                                                slotProps={{
                                                    textField: {
                                                        fullWidth: true,
                                                        size: "small",
                                                        error: errors.billDate,
                                                        helperText: errors.billDate ? "Please select a billing date" : "",
                                                    },
                                                }}
                                            />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </FormControl>
                            </Typography>

                        </Grid>
                        <Grid item xs={12} sm={6} paddingTop={20}>
                            <Typography m={1}>
                                <FormControl variant="outlined" fullWidth size="small">
                                    <InputLabel id="demo-select-small" required>Account Type</InputLabel>
                                    <Select
                                        // error={selectedData.assetUid === undefined && completed}
                                        required
                                        labelId="demo-select-small-label"
                                        id="demo-select-small"
                                        value={accountType}
                                        label="Account Type"
                                        onChange={(e) => onselection(e)}
                                        name='accountType'
                                    >

                                        {accountList?.map((acc: any, index: any) => {
                                            return (
                                                <MenuItem key={index} value={acc}>{acc}</MenuItem>
                                            )
                                        })
                                        }

                                    </Select>
                                    {errors.accountType && <Typography color="error">Please select an account type</Typography>}

                                </FormControl>
                            </Typography>
                            <Typography m={1}>

                                <FormControl>
                                    <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                                        <FormControlLabel value="Jobcost" control={<Radio />} label="Jobcost" />
                                        <FormControlLabel value="Overhead" control={<Radio />} label="Overhead" />
                                    </RadioGroup>
                                </FormControl>
                            </Typography>
                        </Grid>
                    </Grid>

                    <TableContainer component={Paper} sx={{ marginBottom: 3 }}>
                        <Table>
                            <TableHead>
                                <TableRow className='tableHead'>
                                    {/* <TableCell sx={{ maxWidth: "50px" }}>Check</TableCell> */}
                                    <TableCell>Item Name</TableCell>
                                    <TableCell>Code</TableCell>
                                    <TableCell>Quantity</TableCell>
                                    <TableCell>PO Item Id</TableCell>

                                    <TableCell>Unit Cost</TableCell>
                                    <TableCell>Amount</TableCell>
                                    {/* <TableCell>Action</TableCell> */}
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {poData?.poItems?.map((row, index) => (
                                    <TableRow key={index}>
                                        {/* <TableCell>
                                          <input
                                            type="text"
                                            value={row.industry}
                                            onChange={(e) => handleChange(e, index, 'industry')}
                                          />
                                        </TableCell> */}
                                        {/* <TableCell size='small'>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={selectedMaterials.has(index)}
                                                        onChange={() => handleCheckboxChange(index)}
                                                    />
                                                }
                                                label=""
                                            />
                                        </TableCell> */}

                                        <TableCell>
                                            <TextField id="standard-basic" variant="standard"
                                                type="text"
                                                value={row?.description}
                                                onChange={(e) => handleChange(e, index)}
                                                name="description" // Add name here
                                                disabled

                                                error={!updated && !row?.description}
                                                helperText={!updated && !row?.description ? ("enter description ") : ("")}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField id="standard-basic" variant="standard"
                                                type="text"
                                                value={row?.itemCode}
                                                name="itemCode" // Add name here
                                                disabled

                                                onChange={(e) => handleChange(e, index)}
                                                error={!updated && !row?.itemCode}
                                                helperText={!updated && !row?.itemCode ? ("enter item code ") : ("")}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField id="standard-basic" variant="standard"
                                                type="text"
                                                value={row?.received_quantity}
                                                onChange={(e) => handleChange(e, index)}
                                                error={!updated && !row?.received_quantity}
                                                name="received_quantity" // Add name here
                                                disabled

                                                helperText={!updated && !row?.received_quantity ? ("enter quantity ") : ("")}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField id="standard-basic" variant="standard"
                                                type="text"
                                                value={row?.poItemId}
                                                onChange={(e) => handleChange(e, index)}
                                                error={!updated && !row?.poItemId}
                                                name="poItemId" // Add name here
                                                disabled

                                                helperText={!updated && !row?.poItemId ? ("enter poItemId ") : ("")}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField id="standard-basic" variant="standard"
                                                type="text"
                                                value={row?.rate}
                                                onChange={(e) => handleChange(e, index)}
                                                name="rate" // Add name here

                                                error={!updated && !row?.rate}
                                                helperText={!updated && !row?.rate ? ("enter rate ") : ("")}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TextField id="standard-basic" variant="standard"
                                                type="text"
                                                value={row?.cost}
                                                onChange={(e) => handleChange(e, index)}
                                                error={!updated && !row?.cost}
                                                name="cost" // Add name here
                                                sx={{
                                                    "&:read-only": {
                                                        color: "gray", // Example style for read-only fields
                                                    }
                                                }}
                                                InputProps={{
                                                    readOnly: true, // Change to true if this field should be read-only
                                                }}
                                                helperText={!updated && !row?.cost ? ("enter cost ") : ("")}
                                            />
                                        </TableCell>
                                        {/* <TableCell>
                                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                        <IconButton onClick={() => handleRemoveItem(index)}>
                                                                            <DeleteIcon fontSize='small'
                                                                            />
                                                                        </IconButton>
                                                                    </Box>
                                                                </TableCell> */}

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box>

                        {/* <Stack direction={'row'} className='alignEnd'>
                            <Button onClick={addRows}>Add Row</Button>
                        </Stack> */}
                        <Box sx={{ display: 'flex', mt: 1, mb: 2 }} className="alignEnd">
                            <Typography ml={2} sx={{ textAlign: 'left' }}>Sub Total:</Typography>
                            <Typography ml={2} sx={{ textAlign: 'right' }}>${totalCost}</Typography>
                        </Box>
                        {/* <Grid container rowSpacing={1} columnSpacing={1} mb={2}>
                            {poData?.taxInfos?.map((tax, index) => (
                                <><Grid item xs={12} sm={6}>
                                </Grid>
                                    <Grid item xs={12} sm={2}>
                                        <Box>
                                            <TextField
                                                label="Tax Name"
                                                id="outlined-size-small"
                                                size="small"
                                                value={tax?.name}
                                                disabled={taxShow && !newRows.includes(index)}
                                                error={tax?.name === '' && error}
                                                name="name"
                                                onChange={(e) => onInputTaxNameChange(e, index)}
                                            />
                                        </Box>
                                    </Grid><Grid item xs={6} sm={2} className='alignEnd'>
                                        <Box>
                                            <TextField
                                                label="Percentage"
                                                id="outlined-size-small"
                                                size="small"
                                                value={tax?.percentage}
                                                name="percentage"
                                                type='number'
                                                error={tax?.percentage === '' && error}
                                                disabled={taxShow && !newRows.includes(index)}
                                                onChange={(e) => onInputChange(e, index, totalCost)}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6} sm={2} className='alignEnd'>
                                        <Box>
                                            <Stack direction={'row'} spacing={1}>
                                                <TextField
                                                    label="Value"
                                                    id="outlined-size-small"
                                                    size="small"
                                                    type='number'
                                                    value={tax?.value ? Number(tax.value).toFixed(2) : ''}
                                                    name="value"
                                                    disabled
                                                // onChange={(e) => onInputChange(e, index)}
                                                />
                                                {poData?.status === "Generated" && (poData?.taxInfos).length !== 0 && newRows.includes(index) ?
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <IconButton onClick={() => handleRemoveItem(index)}>
                                                            <DeleteIcon fontSize='small'
                                                            />
                                                        </IconButton>
                                                    </Box>
                                                    :
                                                    <></>
                                                }
                                            </Stack>
                                        </Box>
                                    </Grid>

                                </>
                            ))}
                        </Grid> */}
                        <Grid container rowSpacing={1} columnSpacing={1} mb={2}>
                            {poData?.taxInfos?.map((tax, index) => (
                                <React.Fragment key={index}>
                                    <Grid item xs={12} sm={6}>
                                        {/* Empty grid item for layout purposes */}
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        <Box>
                                            <TextField
                                                label="Tax Name"
                                                id="outlined-size-small"
                                                size="small"
                                                value={tax?.name}
                                                error={tax?.name === '' && error}
                                                // error={error && tax?.name.trim() === ''}
                                                helperText={error && tax?.name.trim() === '' ? 'Required' : ''}
                                                name="name"
                                                onChange={(e) => onInputTaxNameChange(e, index)}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6} sm={2} className='alignEnd'>
                                        {/* <Box>
              <TextField
                label="Percentage"
                id="outlined-size-small"
                size="small"
                value={tax?.percentage}
                name="percentage"
                type='number'
                error={tax?.percentage === '' && error}
                onChange={(e) => onInputChange(e, index)}
              />
            </Box> */}
                                        <Box>
                                            <TextField
                                                label="Percentage"
                                                id="outlined-size-small"
                                                size="small"
                                                value={tax?.percentage}
                                                name="percentage"
                                                type='number'
                                                error={tax?.percentage === '' && error}
                                                // error={error && tax?.percentage === ''}
                                                helperText={error && tax?.percentage === '' ? 'Required' : ''}
                                                onChange={(e) => handlePercentageChange(e, index)}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6} sm={2} className='alignEnd'>
                                        <Box>
                                            <Stack direction={'row'} spacing={1}>
                                                <TextField
                                                    label="Value"
                                                    id="outlined-size-small"
                                                    size="small"
                                                    type='number'
                                                    value={tax?.value ? Number(tax.value).toFixed(2) : ''}
                                                    name="value"
                                                    disabled
                                                />
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>

                                                    <IconButton onClick={() => handleRemoveItem(index)}>
                                                        <DeleteIcon fontSize='small' />
                                                    </IconButton>
                                                </Box>
                                            </Stack>
                                        </Box>
                                    </Grid>
                                </React.Fragment>
                            ))}
                            <Grid container rowSpacing={1} columnSpacing={1} mb={2} className="alignEnd p-2 mt-2">
                                <Button onClick={handleAddNewRow}>
                                    Add Row
                                </Button>
                            </Grid>
                        </Grid>
                        <Box mt={2} sx={{ textAlign: 'right' }}>
                            <Typography variant="h5" sx={{ fontSize: "16px", color: "primary.secondary", textAlign: 'right', marginBottom: '2vh' }}>
                                Summary
                            </Typography>
                            {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                                <Typography ml={2} sx={{ textAlign: 'left' }}>Total Selected Items:</Typography>
                                <Typography ml={2} sx={{ textAlign: 'right' }}>{selectedMaterials.size}</Typography>
                            </Box> */}

                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 1, mb: 2, }}>

                                <Button variant="contained"
                                    color="primary" onClick={handleViewRejectBills} > View Rejected Bills </Button>

                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography sx={{ textAlign: 'left', mr: 2 }}>Total Cost: </Typography>
                                    <Typography sx={{ textAlign: 'right' }}>${isNaN(Number(total)) ? "0.00" : Number(total).toFixed(2)}</Typography>
                                </Box>
                            </Box>


                        </Box>
                    </Box>
                    <Card variant="outlined">
                        <CardContent>
                            {/* {poData?.taxExemption === false ? */}

                            {/* {poData?.status === "Generated" && poData?.taxExemption === false ?
                        <Grid container rowSpacing={2} columnSpacing={2}>
                            <Grid item xs={12} sm={7}>
                            </Grid>
                            <Grid item xs={6} sm={2}>
                            </Grid>
                            <Grid item xs={6} sm={3} className='alignEnd'>
                                <Box>
                                    <Stack direction={'row'}>
                                        <Button onClick={addRows}>Add Row</Button>
                                    </Stack>
                                </Box>
                            </Grid>
                        </Grid> :
                        <></>
                    } */}
                            {/* <Grid container rowSpacing={2} columnSpacing={2} paddingBottom={3} paddingTop={1}>
                                <Grid item xs={12} sm={8}>
                                </Grid>
                                <Grid item xs={6} sm={2} className='alignEnd'>
                                    <Box>
                                        <Typography className='subHeader' textAlign={'right'}>Total:</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={6} sm={2} className='alignEnd'>
                                    <Box>
                                        <Typography textAlign={'right'}>${Number(poData?.purchaseAmount)?.toFixed(2)}</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Divider></Divider> */}
                            <Grid container spacing={3} style={{ paddingTop: 15 }}>
                                {/* Row 1: Bill Number and Bill Date */}

                                {/* Row 2: Radio Group */}
                                <Grid item xs={12} pt={1}>
                                    <Box>
                                        <Paper
                                            variant="outlined"
                                            onDragOver={handleDragOver}
                                            onDragLeave={handleDragLeave}
                                            onDrop={handleDrop}
                                            sx={{
                                                border: "2px dashed #007BFF",
                                                borderRadius: 2,
                                                padding: 2,
                                                textAlign: "center",
                                                cursor: "pointer",
                                                color: "#007BFF",
                                                bgcolor: "#e6f7ff",
                                            }}
                                        >
                                            <input
                                                accept="image/*,.pdf"
                                                style={{ display: 'none' }}
                                                id="raised-button-file"
                                                multiple
                                                type="file"
                                                onChange={handleFileDataChange}
                                            />
                                            <label htmlFor="raised-button-file">
                                                <Box display="flex" flexDirection="column" alignItems="center">
                                                    <IconButton color="primary" aria-label="upload picture" component="span">
                                                        <CloudUploadIcon style={{ fontSize: 60 }} />
                                                    </IconButton>
                                                    <Typography>Upload</Typography>
                                                    <Typography>Drag and drop files here or click to select files</Typography>
                                                </Box>
                                            </label>
                                            {loading && (
                                                <CircularProgress
                                                    size={24}
                                                    style={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        marginTop: '-12px',
                                                        marginLeft: '-12px',
                                                    }}
                                                />
                                            )}
                                            {errors.file && <Typography color="error">Please upload at least one file</Typography>}

                                        </Paper>

                                        {/* <Grid container justifyContent="center" style={{ marginTop: 16 }}>
                                            {selectedfile.map((i: any) => {
                                                console.log(typeof (i.fileimage))
                                                return (
                                                    <iframe
                                                        title="PDF Viewer"
                                                        src={i.fileimage}
                                                        style={{ height: '800px', width: '100%' }}
                                                    />
                                                )
                                            }
                                            )}
                                        </Grid > */}
                                        <Grid container justifyContent="center" style={{ marginTop: 16 }}>
                                            {selectedfile.length > 0 && selectedfile.map((file: any, index) => (
                                                <Grid item key={index} style={{ position: 'relative', marginBottom: 16 }}>
                                                    {/* Conditionally render the delete button */}
                                                    {file.fileimage && (
                                                        <>
                                                            {/* PDF Viewer */}
                                                            {file.filetype === 'application/pdf' ? (
                                                                <iframe
                                                                    title="PDF Viewer"
                                                                    src={file.fileimage}
                                                                    style={{ height: '800px', width: '100%' }}
                                                                />
                                                            ) : (
                                                                <img
                                                                    src={file.fileimage}
                                                                    alt={file.filename}
                                                                    style={{ maxWidth: '100%', maxHeight: '800px' }}
                                                                />
                                                            )}
                                                            {/* Remove Button */}
                                                            <IconButton
                                                                style={{
                                                                    position: 'absolute',
                                                                    top: 8,
                                                                    right: 8,
                                                                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                                                }}
                                                                onClick={() => handleRemoveFile(index)}
                                                            >
                                                                <DeleteIcon color="error" />
                                                            </IconButton>
                                                        </>
                                                    )}
                                                </Grid>
                                            ))}

                                        </Grid>

                                    </Box>
                                </Grid>

                                {/* Row 3: Payment Method */}

                                {/* <Grid item xs={12} sm={6}>
                                    <>
                                    <Grid item xs={12}>
                                    <Typography variant="h5" color="rgb(60 60 60 / 87%)">Payment Method</Typography>
                                </Grid>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    icon={<RadioButtonUncheckedIcon />}
                                                    checkedIcon={<CheckCircleIcon />}
                                                    name="checkbox"
                                                    onChange={(e) => handlepaymentmethod(e)}
                                                    size="small"
                                                />
                                            }
                                            label="Credit Card"
                                        />
                                        {paymentMethod === "Credit Card" && (
                                            <>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            icon={<RadioButtonUncheckedIcon />}
                                                            checkedIcon={<CheckCircleIcon />}
                                                            name="checkbox"
                                                            onChange={(e) => handlepaymentmethod(e)}
                                                            size="small"
                                                        />
                                                    }
                                                    label="Personal Card"
                                                />
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            icon={<RadioButtonUncheckedIcon />}
                                                            checkedIcon={<CheckCircleIcon />}
                                                            name="checkbox"
                                                            value={paymentMethod}
                                                            onChange={(e) => handlepaymentmethod(3)}
                                                            size="small"
                                                        />
                                                    }
                                                    label="Company Card"
                                                />
                                            </>
                                        )}
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    icon={<RadioButtonUncheckedIcon />}
                                                    checkedIcon={<CheckCircleIcon />}
                                                    name="checkbox"
                                                    value={paymentMethod}
                                                    onChange={(e) => handlepaymentmethod(e)}
                                                    size="small"
                                                />
                                            }
                                            label="Vendor Account"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    icon={<RadioButtonUncheckedIcon />}
                                                    checkedIcon={<CheckCircleIcon />}
                                                    name="checkbox"
                                                    value={paymentMethod}
                                                    onChange={(e) => handlepaymentmethod(e)}
                                                    size="small"
                                                />
                                            }
                                            label="Cash"
                                        />
                                          <Stack direction="row" alignItems="center" mt={1}>
                                        <FormControlLabel control={<Android12Switch onChange={handleToggle} />} label="" />
                                        <Typography variant="h6">Is Acknowledge Bill?</Typography>
                                    </Stack>
                                    </>
                                </Grid> */}

                            </Grid>

                            {/* <FileUpload onFileUpload={handleFileUpload} /> */}


                            {/* <Grid container rowSpacing={2} columnSpacing={2} paddingTop={3}>

                                <Grid item xs={6} sm={2} className='alignEnd'>
                                    <Box>
                                        <Typography className='subHeader' textAlign={'right'}>Subtotal:</Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={6} sm={2} className='alignEnd'>
                                    <Box>
                                        <Typography textAlign={'right'}>${poData?.subTotal !== undefined ? (poData?.subTotal).toFixed(2) : null}</Typography>
                                        <Typography ml={2}>Total Cost: ${calculateTotalCost()}</Typography>
                                    </Box>
                                </Grid>
                            </Grid> */}
                        </CardContent>
                    </Card>

                </DialogContent>

                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        // onClick={() => alert(`Processing ${selectedMaterials.size} items with total cost $${calculateTotalCost()}`)}
                        className="dangerButton"
                        onClick={handleClickOpen}

                    >
                        Reject Bill
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleUploadChange()}
                        className="successButton"
                    >
                        Approve Bill
                    </Button>
                    <Button
                        className="dangerButton"
                        onClick={() => setDialogOpen(false)} color="secondary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={open} // Use the `open` state for controlling the dialog
                onClose={handleClose} // Use the `handleClose` function to close the dialog
                maxWidth="sm"
                fullWidth
                aria-labelledby="responsive-dialog-title"
            >
                {/* Dialog Title with Close Button */}
                <DialogTitle id="responsive-dialog-title">
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"

                    >
                        <span>Select a Reason for Rejection</span>
                        <IconButton sx={{ color: 'red' }} edge="end" onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <Divider />

                {/* Dialog Content with List of Rejection Reasons */}
                <DialogContent>
                    <Box>
                        {rejectionReasons.map((reason: any, index) => (
                            <Paper
                                key={index}
                                sx={{
                                    mb: 2, // Margin bottom for spacing between cards
                                    p: 2, // Padding inside the card
                                    cursor: "pointer", // Show pointer cursor on hover
                                    backgroundColor: selectedReason === reason ? "primary.main" : "background.paper",
                                    color: selectedReason === reason ? "common.white" : "text.primary",
                                    "&:hover": {
                                        backgroundColor: "action.hover",
                                        color: "text.primary",
                                    },
                                }}
                                onClick={() => setSelectedReason(reason)} // Set the selected reason on click
                            >
                                <Typography variant="body1">{reason}</Typography>
                            </Paper>
                        ))}
                    </Box>
                </DialogContent>

                {/* Dialog Actions with Cancel Button */}
                <DialogActions>
                    {/* Cancel Button */}
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>

                    {/* Submit Button */}
                    {selectedReason && (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                console.log("Selected Reason:", selectedReason);
                                handleRejectBill(); // Call the function to reject the bill

                            }}
                        >
                            Submit
                        </Button>
                    )}
                </DialogActions>
            </Dialog>




            <Dialog
                open={openRejectBill} // Use the `open` state for controlling the dialog
                onClose={handleClose} // Use the `handleClose` function to close the dialog
                maxWidth="sm"
                fullWidth
                aria-labelledby="responsive-dialog-title"
                sx={{
                    "& .MuiDialog-paper": {
                        width: '100%',
                        maxWidth: '600px',
                        height: 'auto',
                        maxHeight: '80vh',
                        //   overflowY: 'auto',
                    },
                }}
            >
                {/* Dialog Title with Close Button */}
                <DialogTitle id="responsive-dialog-title">
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <span>View Rejected Bills </span>
                        <IconButton sx={{ color: 'red' }} edge="end" onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <Divider />

                {/* Dialog Content with List of Rejection Reasons */}
                <DialogContent>

                    <Box>
                        {rejectedData.length > 0 ? (
                            rejectedData.map((file, index) => {
                                const fileSrc = `data:application/pdf;base64,${file}`;

                                const isPdfFile = file.startsWith('JVBER');
                                const isImageFile = file.startsWith('/9j/');

                                return (
                                    <Grid item key={index} style={{ position: 'relative', marginBottom: 16 }}>
                                        {isPdfFile ? (
                                            <iframe
                                                title="PDF Viewer"
                                                src={fileSrc}
                                                style={{ height: '800px', width: '100%' }}
                                            />
                                        ) : isImageFile ? (
                                            <img
                                                src={fileSrc}
                                                //   style={{ maxWidth: '100%', maxHeight: '800px' }}
                                                style={{ height: '800px', width: '100%' }}
                                                alt="image"
                                            />
                                        ) : (
                                            <Typography variant="body1" color="textSecondary">
                                                Unsupported file type
                                            </Typography>
                                        )}
                                    </Grid>
                                );
                            })
                        ) : (
                            <Typography variant="body1" color="textSecondary">
                                No rejected receipts found.
                            </Typography>
                        )}
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>


            <CustomDialog
                open={openDialogs}
                onClose={handleDialogClose}
                success={createSuccess}
                error={createError}
                Content={createSuccess ? "Uploaded succesfully" : "Failed"} />
            <CustomDialog
                open={rejected}
                onClose={handleRejectDialogClose}
                success={createSuccess}
                error={createError}
                Content={createSuccess ? "Rejected Vendor Bill" : "Failed"} />
        </Box>
    );
};

export default DSVendorBillComparison;
